

   <div #masterDetailInner class="flex flex-col h-full gap-4 shadow-inner bg-default" [class.p-5]="!nested" [class.nested]="nested">
    <ng-container *ngIf="template">
        <ng-container
          *ngTemplateOutlet="template; context: templateContext"
        ></ng-container></ng-container>
   </div>


