import { Component, OnInit, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
    selector: 'app-formly-attribute-repeat',
    templateUrl: './formly-attribute-repeat.component.html',
    styleUrls: ['./formly-attribute-repeat.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class FormlyAttributeRepeatComponent extends FieldArrayType implements OnInit {
    constructor(private cdRef: ChangeDetectorRef) {
        super();
    }
    ngOnInit(): void {
        this.field.className = this.field.className + ' repeat dense';
    }

    addRow(): void {
        this.add();
   //     this.field.model.map((item: any, index: number) => (Object.assign(item, {seqNo : index+1})));
        this.cdRef.detectChanges();
    }
    override remove(index: number): void {
        super.remove(index);
        this.field.model.map((item: any, i: number) => (Object.assign(item, {seqNo : i+1})));
        if(this.field.model.length === 0) {
            this.add();
        }
        this.cdRef.detectChanges();
    }

    drop(event: CdkDragDrop<string[]>): void {
        if (this.field.fieldGroup) {
            moveItemInArray(this.field.fieldGroup, event.previousIndex, event.currentIndex);
            moveItemInArray(this.field.model, event.previousIndex, event.currentIndex);
            this.field.model.map((item: any, index: number) => (Object.assign(item, {seqNo : index+1})));
            this.cdRef.detectChanges();
        }
    }

}
