import { ICellEditorParams } from '@ag-grid-community/all-modules';
import { ICellEditorAngularComp } from '@ag-grid-community/angular';
import { AfterViewInit, Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatMenuTrigger, MatMenu } from '@angular/material/menu';
import { PriceBreak } from 'app/shared/models/products/product-models';
import { siglaLog } from 'app/shared/utility/logging-util';

@Component({
  selector: 'app-ag-grid-price-break-typeahead',
  templateUrl: './ag-grid-price-break-typeahead.component.html',
  styleUrls: ['./ag-grid-price-break-typeahead.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AgGridPriceBreakTypeaheadComponent implements ICellEditorAngularComp, AfterViewInit {

  @ViewChild('priceBreakMenuTrigger') priceBreakMenuTrigger!: MatMenuTrigger;
  @ViewChild('priceBreakMenu') priceBreakMenu!: MatMenu;
  params!: any;
  priceByLabel: 'Day' | 'Night' | 'Hour' = 'Day';
  priceBreakList: PriceBreak[] = new Array();
  minPriceBy!: number | null;
  maxPriceBy!: number | null;
  minPriceByChecked!: boolean;
  maxPriceByChecked!: boolean;
  hasError = false;
  constructor() {

  }

  getValue(): string {
    return this.params.value;
  }

  agInit(params: ICellEditorParams): void {
    this.params = params;
    siglaLog(params)
    if (this.params.data?.priceBreakList) {
      this.priceBreakList = this.params.data?.priceBreakList;
    }
    this.params.savePriceBreak$()?.subscribe((priceBreak: PriceBreak | undefined) => {
      siglaLog(priceBreak);
      if (priceBreak && priceBreak.rateUId === this.params.data.rateUId) {
        this.priceBreakList.push(priceBreak);
        this.close();
        this.updateCellData();
      }
    });
    this.params.deletePriceBreak$()?.subscribe((priceBreak: PriceBreak | undefined) => {
      if (priceBreak && priceBreak.rateUId === this.params.data.rateUId) {
        this.priceBreakList.splice(this.priceBreakList.findIndex((p: PriceBreak) => p.priceBreakUId === priceBreak.priceBreakUId), 1);
        this.updateCellData();
      }
    });
  }

  ngAfterViewInit(): void {

  }

  updateCellData(): void {
    this.params.value = this.priceBreakList;
    this.params?.node?.setDataValue(this.params.colDef.field, this.params.value);
  }

  handleMenuClose(): void {
    if (this.priceBreakMenuTrigger.menuOpen) {
      this.close();
    }
  }


  checkMinBy(): void {
    this.minPriceByChecked = !this.minPriceByChecked;
    if (this.minPriceByChecked) {
      this.minPriceBy = null;
    }
  }

  checkMaxBy(): void {
    this.maxPriceByChecked = !this.maxPriceByChecked;
    if (this.maxPriceByChecked) {
      this.maxPriceBy = null;
    }
  }

  remove(priceBreak: PriceBreak): void {
    this.params?.deletePriceBreak(priceBreak);
  }

  submit(): void {
    this.validatePriceBreak();
  }

  checkValidation(): void {
    if (this.priceBreakList.some((priceBreak: PriceBreak) => priceBreak.minPriceBy === this.minPriceBy || priceBreak.maxPriceBy === this.maxPriceBy)) {
      this.hasError = true;
    } else {
      this.hasError = false;
    }
  }

  validatePriceBreak(): void {
    if (this.priceBreakList.some((priceBreak: PriceBreak) => priceBreak.minPriceBy === this.minPriceBy || priceBreak.maxPriceBy === this.maxPriceBy)) {
      this.hasError = true;
    } else {
      this.hasError = false;
      this.savePriceBreak();
    }

  }

  savePriceBreak(): void {
    let priceBreak = new PriceBreak();
    priceBreak = Object.assign(priceBreak, {
      minPriceBy: this.minPriceBy,
      maxPriceBy: this.maxPriceBy,
      status: 'Ready',
      rateUId: this.params?.data?.rateUId
    });
    this.params?.savePriceBreak(priceBreak);
  }

  close(): void {
    if (this.priceBreakMenuTrigger) {
      this.priceBreakMenuTrigger.closeMenu();
      this.resetToDefaults();
    }
  }

  resetToDefaults(): void {
    this.minPriceBy = null;
    this.maxPriceBy = null;
    this.maxPriceByChecked = false;
    this.minPriceByChecked = false;
  }

}
