/* eslint-disable @typescript-eslint/naming-convention */

import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
@Component({
    selector: 'app-formly-rich-content',
    templateUrl: './formly-rich-content.component.html',
    styleUrls: ['./formly-rich-content.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class FormlyRichContentComponent extends FieldType implements OnInit {
    override formControl: any;
    constructor() {
        super();
    }

    ngOnInit(): void {
        this.field.className = 'rich-content';

    }

}
