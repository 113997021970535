<ng-container [ngSwitch]="to.matButtonType">
    <ng-container *ngSwitchCase="'mat-button'">
        <button (click)="onClick()" mat-button [color]="to.matButtonColor">{{to.label}}</button>
    </ng-container>
    <ng-container *ngSwitchCase="'mat-stroked-button'">
        <button (click)="onClick()" mat-stroked-button [color]="to.matButtonColor">{{to.label}}</button>
    </ng-container>
    <ng-container *ngSwitchCase="'mat-flat-button'">
        <button (click)="onClick()" mat-flat-button [color]="to.matButtonColor">{{to.label}}</button>
    </ng-container>
    <ng-container *ngSwitchCase="'mat-icon-button'">
        <button (click)="onClick()" mat-icon-button>
            <mat-icon  [svgIcon]="to.matButtonIcon"></mat-icon>
        </button>
    </ng-container>
    <ng-container *ngSwitchCase="'mat-fab'">
        <button (click)="onClick()"
            mat-fab
            [color]="to.matButtonColor"
            aria-label="Example icon button with a delete icon"
        >
            <mat-icon  [svgIcon]="to.matButtonIcon"></mat-icon>
        </button>
    </ng-container>
    <ng-container *ngSwitchCase="'mat-mini-fab'">
        <button (click)="onClick()"
            mat-mini-fab
            [color]="to.matButtonColor"
            aria-label="Example icon button with a menu icon"
        >
            <mat-icon  [svgIcon]="to.matButtonIcon"></mat-icon>
        </button>
    </ng-container>
    <ng-container *ngSwitchCase="'mat-raised-button'">
        <button (click)="onClick()" mat-raised-button [color]="to.matButtonColor">{{to.label}}</button>
    </ng-container>
</ng-container>
