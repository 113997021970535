
import {
    Component,
    ChangeDetectorRef,
    QueryList,
    ViewChildren,
    ElementRef,
    ViewEncapsulation,
    AfterViewInit,
    Input,
} from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { pageAnimation } from '../../animations/page.animation';



@Component({
    selector: 'app-page-layout-wrapper',
    templateUrl: './page-layout-wrapper.component.html',
    styleUrls: ['./page-layout-wrapper.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: [pageAnimation],
})

export class PageLayoutWrapperComponent implements AfterViewInit {
    @Input() historyDisabled: boolean = false;
    @ViewChildren('outletElement') outletElementList: QueryList<ElementRef> | undefined;
    @ViewChildren(RouterOutlet) routerOutletList: QueryList<RouterOutlet> | undefined;
     animationState: ActivatedRoute | undefined;

    constructor(
        private cd: ChangeDetectorRef,
        public router: Router,
        public activatedRoute: ActivatedRoute,
    ) {

    }

    ngAfterViewInit(): void {
        if( this.routerOutletList) {
            this.routerOutletList.forEach((outlet: any) => outlet.minimised = false);
            this.cd.detectChanges();
        }
    }

    public onRouterChange(outlet: RouterOutlet): void {
        this.animationState = outlet.isActivated
            ? outlet.activatedRoute
            : undefined;
        this.cd.detectChanges();
    }


}
