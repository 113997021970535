<app-image-uploader
    *ngIf="block || data"
    [error]="error"
    #uploader
    (upload)="upload($event)"
    (click)="remove($event)"
    [options]="imageOptions"
    #imageUploader
></app-image-uploader>

<ng-template #metaData let-imageData>
    <input
        mat-input
        style="margin-top: -2rem"
        class="z-10 w-full h-6 px-1 mx-1 overflow-hidden font-bold leading-6 rounded-sm bg-card text-md text-secondary"
        placeholder="Alt text"
        *ngIf="imageData?.file"
        (ngModelChange)="ngModelChange.emit(block)"
        [(ngModel)]="imageData.file.alt"
    />
    <input
        #caption
        [ngClass]="{ 'opacity-100 h-4 mb-0': caption.value }"
        class="z-10 w-full px-1 mx-1 overflow-hidden font-bold leading-4 text-md text-secondary caption"
        placeholder="Caption"
        *ngIf="imageData?.file"
        (ngModelChange)="ngModelChange.emit(block)"
        [(ngModel)]="imageData.file.caption"
    />
</ng-template>

<ng-container
    *ngTemplateOutlet="
        metaData;
        context: {
            $implicit: block?.data || data
        }
    "
></ng-container>
