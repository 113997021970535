<span
    (click)="openLink(); $event.stopPropagation()"
    class="font-bold underline"
>
    {{ params?.value }}
</span>

<a
    *ngIf="params?.value"
    mat-icon-button
    class="flex items-center justify-center"
    matTooltip="Open in new Tab"
    (click)="openLinkInNewTab(); $event.stopPropagation()"
>
    <mat-icon class="cursor-pointer icon-size-4" svgIcon="open_in_new">
    </mat-icon>
</a>
