export class ApiPathConfig
{

    public apiPath: string;

    constructor(apiPath: string) {
        if (apiPath != null && apiPath.length > 0 && apiPath.charAt(apiPath.length - 1) !== '/')
        {
            apiPath += '/';
        }
        this.apiPath = apiPath;
    }

}
