<div
    class="flex items-center justify-start px-2 ml-2 border border-current border-solid rounded-full cursor-pointer text-product-group"
    matTooltip="Product Group"
>
   <span class="text-xs font-bold leading-3 uppercase whitespace-nowrap">{{ params?.data?.productSeriesName }}</span>

</div>
<a
*ngIf="params?.data?.productSeriesName"
mat-icon-button
class="flex items-center justify-center "
matTooltip="Open in new Tab"
(click)="openInNewTab($event)"
>
<mat-icon class="cursor-pointer icon-size-4" svgIcon="open_in_new">
</mat-icon>
</a>
