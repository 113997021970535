import { Component, ElementRef, AfterViewInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldType, FormlyConfig, FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-intl-phone',
  templateUrl: './formly-intl-phone.component.html',
  styleUrls: ['./formly-intl-phone.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FormlyIntlPhoneComponent extends FieldType {
  @ViewChild('phone', { static: false, read: ElementRef }) phoneElement!: ElementRef;
  override formControl!: FormControl;
  override defaultOptions?: FormlyFieldConfig = {
    templateOptions: {
    },
    modelOptions: {
      updateOn: 'blur'
    }
  };
}
