import { CdkScrollable } from '@angular/cdk/scrolling';
import {
    Component,
    OnInit,
    Input,
    TemplateRef,
    OnDestroy,
    HostBinding,
    ViewEncapsulation,
    ViewChild,
    AfterViewInit,
    ChangeDetectorRef,
    OnChanges,
    SimpleChanges,
    ComponentRef
} from '@angular/core';
import { ProgressBarMode } from '@angular/material/progress-bar';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { BehaviorSubject, merge, Observable, of, Subscription } from 'rxjs';
import { PageData, PageHeaderConfig } from '../../interfaces/page-data';
import { PageHistoryService } from 'app/shared/services/page-history/page-history.service';
import { delay, filter, take } from 'rxjs/operators';



@Component({
    selector: 'app-standard-page-layout',
    templateUrl: './standard-page-layout.component.html',
    styleUrls: ['./standard-page-layout.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class StandardPageLayoutComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
    @Input() headingPrefix: string | undefined;
    @Input() heading: string | undefined;
    @Input() description: string | undefined;
    @Input() headerHeaderRowTemplate: TemplateRef<any> | undefined;
    @HostBinding('class.loading') @Input() loading = false;
    @Input() progressLoading = false;
    @Input() progressMode: ProgressBarMode = 'indeterminate';
    @Input() pageThemeColor: string = '#0694a2';
    @Input() childRoute: boolean = true;
    @ViewChild(CdkScrollable) scrollableElement!: CdkScrollable;
    pageHeaderConfig$: undefined | BehaviorSubject<PageHeaderConfig>;
    currentPath: string | undefined;
    scrollSubscription!: Subscription;
    scrolledToBottom = false;
    fixedHeaderVisibility: boolean = true;


    constructor(
        public pageHistory: PageHistoryService,
        public activatedRoute: ActivatedRoute,
        public router: Router,
        public cdRef: ChangeDetectorRef,
    ) {

        if (this.activatedRoute.firstChild) {
            this.activatedRoute.firstChild.data.pipe(take(1)).subscribe(
                (data: PageData) => {
                    this.currentPath = this.activatedRoute?.routeConfig?.path;
                    this.setPageData(data);
                });
        } else {
            this.activatedRoute.data.pipe(take(1)).subscribe(
                (data: PageData) => {
                    this.currentPath = this.activatedRoute?.routeConfig?.path;
                    this.setPageData(data);
                });
        }

        const events: Observable<any> = this.router.events;
        events.pipe(filter((event) => event instanceof NavigationEnd)
        ).pipe(delay(1000), take(1)).subscribe(() => {
            this.updateHistoryData();
        });
    }

    setPageData(data: PageData): void {
        if (data) {
            const prefix = this.headingPrefix;
            const heading = this.heading;
            const desc = this.description;
            this.headingPrefix = data?.headingPrefix ? data?.headingPrefix : prefix;
            this.heading = data?.heading ? data?.heading : heading;
            this.description = data?.description ? data?.description : desc;
        }
    }

    ngOnInit(): void { }

    ngAfterViewInit(): void {
        this.observeScroll();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.updateHistoryData();
    }


    updateHistoryData(): void {
        const snapshot: any = this.activatedRoute.firstChild?.firstChild?.snapshot ||
            this.activatedRoute.firstChild?.snapshot ||
            this.activatedRoute.snapshot;
        const component: any = snapshot?.routeConfig?.component;
        const uid: any = snapshot.parent.params.id || snapshot.params.id || '';
        const id = component.name + '_' + component.ɵcmp?.id + '_' + uid;
        this.pageHistory.updatePageData(id, this.heading, this.headingPrefix, this.description);
    }


    ngOnDestroy(): void {
        this.scrollSubscription.unsubscribe();
    }

    observeScroll(): void {
        if (this.scrollableElement) {
            this.scrollSubscription = this.scrollableElement.elementScrolled().subscribe(() => {
                const offsetFromBottom = this.scrollableElement.measureScrollOffset('bottom');
                this.scrolledToBottom = offsetFromBottom === 0;
                this.cdRef.detectChanges();
            });
        }
    }

    onActivate(componentRef: Record<string, any>): void {
        this.pageHeaderConfig$ = componentRef['pageHeaderConfig$'];
    }
}
