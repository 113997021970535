import { Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-time-picker',
  templateUrl: './formly-time-picker.component.html',
  styleUrls: ['./formly-time-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class FormlyTimePickerComponent extends FieldType implements OnInit {
  override formControl: any;
  override defaultOptions = {
      className:'time-picker',
      templateOptions: {
          timePickerOptions: {
              format: 24
          },
      },
  };

  constructor() {
      super();
  }

  ngOnInit(): void {
      
  }

}
