<div class="flex flex-row items-center gap-1" *ngIf="heading !== 'Heading Goes Here'">
    <div>
        <h5 class="font-extrabold leading-7 tracking-tight truncate " [ngClass]="{'text-xl' : size !== 'small' && size !== 'extra-small', 'text-lg' : size === 'small', 'text-md' : size === 'extra-small' }">
            {{ heading }}
        </h5>
        <span class="text-sm font-bold">{{subHeading}}</span>
    </div>

    <mat-icon *ngIf="tooltip" [matTooltip]="tooltip" svgIcon="info" class="icon-size-4"></mat-icon>
</div>

<ng-content></ng-content>
