
import { IconsMockApi } from 'app/mock-api/ui/icons/api';

import { NavigationMockApi } from 'app/mock-api/common/navigation/api';


export const mockApiServices = [
    // AcademyMockApi,
    //  ActivitiesMockApi,
    //  AnalyticsMockApi,
    // AuthMockApi,
    //   CalendarMockApi,
    //   ChatMockApi,
    //  ContactsMockApi,
    //   CryptoMockApi,
    //   ECommerceInventoryMockApi,
    //   FileManagerMockApi,
    //   FinanceMockApi,
    //   HelpCenterMockApi,
    IconsMockApi,
    //   MailboxMockApi,
    //   MessagesMockApi,
    NavigationMockApi,
    //  NotesMockApi,
    //  NotificationsMockApi,
    //   ProjectMockApi,
    //  SearchMockApi,
    //  ScrumboardMockApi,
    //    ShortcutsMockApi,
    //   TasksMockApi,
    // UserMockApi
];
