import { trigger, animate, transition, style, query } from '@angular/animations';

export const pageAnimation =

    trigger('pageAnimation', [

        transition( '* => *', [

            query(':enter',
                [
                    style({ opacity: 0.1 })
                ],
                { optional: true }
            ),

            query(':leave',
                [
                    style({ opacity: 0.9 }),
                    animate('0.25s', style({ opacity: 0.1 }))
                ],
                { optional: true }
            ),

            query(':enter',
                [
                    style({ opacity: 0.1 }),
                    animate('0.25s', style({ opacity: 0.9 }))
                ],
                { optional: true }
            )

        ])

    ]);
