<div
class="router-animation"
[@top]="animationDirection.top"
(@top.done)="animationEnd()"
[@bottom]="animationDirection.bottom"
(@bottom.done)="animationEnd()"
[@left]="animationDirection.left"
(@left.done)="animationEnd()"
[@right]="animationDirection.right"
(@right.done)="animationEnd()"
[class.animating]="animating"
[class.loading]="loading"
>
    <ng-content></ng-content>
</div>
