import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionHeadingComponent } from './components/section-heading/section-heading.component';
import { AuxiliaryLinkDirective } from './directives/auxiliary-link/auxiliary-link.directive';
import { NavPositionDirective } from './directives/nav-position/nav-position.directive';
import { DialogWrapperComponent } from './components/dialog-wrapper/dialog-wrapper.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PrintDocumentComponent } from './components/print-document/print-document.component';
import { ResizeObserverDirective } from './directives/resize-observer/resize-observer.directive';
import { CarouselNavComponent } from './components/carousel-nav/carousel-nav.component';
import { CarouselNavItemDirective } from './directives/carousel-nav-item.directive';
import { FormStateObserverDirective } from './directives/form-state-observer/form-state-observer.directive';
import { HumanReadableProductNameComponent } from './components/human-readable-product-name/human-readable-product-name.component';
import { MatMenuModule } from '@angular/material/menu';


@NgModule({
  declarations: [
    SectionHeadingComponent,
    AuxiliaryLinkDirective,
    NavPositionDirective,
    DialogWrapperComponent,
    PrintDocumentComponent,
    ResizeObserverDirective,
    CarouselNavComponent,
    CarouselNavItemDirective,
    FormStateObserverDirective,
    HumanReadableProductNameComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    MatMenuModule
  ],
  exports: [
    SectionHeadingComponent,
    AuxiliaryLinkDirective,
    NavPositionDirective,
    DialogWrapperComponent,
    PrintDocumentComponent,
    ResizeObserverDirective,
    CarouselNavComponent,
    CarouselNavItemDirective,
    FormStateObserverDirective,
    HumanReadableProductNameComponent
  ]
})
export class GeneralUtilitiesModule { }
