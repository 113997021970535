import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-phone-link',
  templateUrl: './formly-phone-link.component.html',
  styleUrls: ['./formly-phone-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class FormlyPhoneLinkComponent extends FieldType {
  override formControl: any;


}
