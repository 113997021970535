import { ICellEditorAngularComp } from '@ag-grid-community/angular';
import { Component, AfterViewInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-ag-grid-calendar-date-picker',
    templateUrl: './ag-grid-calendar-date-picker.component.html',
    styleUrls: ['./ag-grid-calendar-date-picker.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AgGridCalendarDatePickerComponent implements ICellEditorAngularComp, AfterViewInit {
    params: any;
    range: boolean = true;
    fromDate: Date | null = null;
    toDate: Date | null = null;
    matDatepickerFilter!: (d: Date | null) => boolean;
    constructor() {

    }

    agInit(params: any): void {
        this.params = params;
        if (this.params.data.fromDate) {
            this.fromDate = new Date(this.params.data.fromDate);
            this.fromDate.setHours(24, 0, 0, 0);
        }
        if (this.params.data.toDate) {
            this.toDate = new Date(this.params.data.toDate);
            this.toDate.setHours(24, 0, 0, 0);
        }
        if (this.fromDate && this.toDate) {
            this.range = this.toDate !== null || new Date(this.fromDate).getTime() === new Date(this.toDate).getTime();
        }
        // this.datePickerService.calendarDateList$.subscribe((list) => {
        //     if (list) {
        //         const dateList = new Array();
        //         list.forEach((date: CalendarDate) => {
        //             siglaLog(this.params)
        //             if (this.params.data.id !== date.id) {
        //                 if (date.fromDate && date.toDate) {
        //                     const range = this.getDatesInRange(new Date(date.fromDate), new Date(date.toDate));
        //                     dateList.push(...range);
        //                 } else {
        //                     if (date.fromDate) {
        //                         dateList.push(new Date(date.fromDate));
        //                     }
        //                 }
        //             }
        //         });
        //         if (params.data.nature === 'On Sale') {
        //             this.matDatepickerFilter = (date: Date | null): boolean => {
        //                 const zeroHours = new Date(date).setHours(0, 0, 0, 0);
        //                 return !dateList.includes(new Date(zeroHours).getTime());
        //             };
        //         }
        //     }
        // });
    }

    getDatesInRange(startDate: Date, endDate: Date): number[] {
        const dates = [];
        if (startDate && endDate) {
            const date = new Date(startDate.getTime());
            while (date <= endDate) {
                dates.push(new Date(date).getTime());
                date.setDate(date.getDate() + 1);
            }
        }
        return dates;
    }

    // dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
    ngAfterViewInit(): void {

    }

    getValue(): string {
        return this.params.value;
    }

    pickerChange(): void {
        if (!this.range) {
            this.params.data.toDate = null;
        }
    }

    dateChange(): void {
        if (this.range && this.fromDate && this.toDate) {
            if (new Date(this.fromDate).getTime() === new Date(this.toDate).getTime()) {
                this.range = false;
                this.params.data.toDate = null;
            } else {
                this.params.data.fromDate = this.fromDate;
                this.params.data.toDate = this.toDate;
            }
            this.params.data.type = 'Date Range';
        } else if (!this.range && this.fromDate) {
            this.params.data.type = 'Date';
            this.params.data.fromDate = this.fromDate;
            this.params.node.setDataValue(this.params.colDef, {
                fromDate: this.fromDate,
                toDate: null
            });
        }


    }

    endDateChange(): void {
        // this.dateChange();
        this.params.node.setDataValue(this.params.colDef, {
            fromDate: this.fromDate,
            toDate: this.toDate
        });
    }

}
