<mat-checkbox
    [checked]="weekDays.includes('Mon')"
    (change)="onChange($event.checked, 'Mon')"
    >Mon</mat-checkbox
>
<mat-checkbox
    [checked]="weekDays.includes('Tue')"
    (change)="onChange($event.checked, 'Tue')"
    >Tue</mat-checkbox
>
<mat-checkbox
    [checked]="weekDays.includes('Wed')"
    (change)="onChange($event.checked, 'Wed')"
    >Wed</mat-checkbox
>
<mat-checkbox
    [checked]="weekDays.includes('Thu')"
    (change)="onChange($event.checked, 'Thu')"
    >Thu</mat-checkbox
>
<mat-checkbox
    [checked]="weekDays.includes('Fri')"
    (change)="onChange($event.checked, 'Fri')"
    >Fri</mat-checkbox
>
<mat-checkbox
    [checked]="weekDays.includes('Sat')"
    (change)="onChange($event.checked, 'Sat')"
    >Sat</mat-checkbox
>
<mat-checkbox
    [checked]="weekDays.includes('Sun')"
    (change)="onChange($event.checked, 'Sun')"
    >Sun</mat-checkbox
>
