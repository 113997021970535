import { ICellEditorAngularComp } from '@ag-grid-community/angular';
import { Component, AfterViewInit, ViewEncapsulation } from '@angular/core';
import moment from 'moment';

@Component({
    selector: 'app-ag-grid-datepicker',
    templateUrl: './ag-grid-datepicker.component.html',
    styleUrls: ['./ag-grid-datepicker.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AgGridDatepickerComponent
    implements ICellEditorAngularComp, AfterViewInit
{
    inputValue!: Date;
    params: any;
    minDate: Date | null = null;
    maxDate: Date | null = null;

    agInit(params: any): void {
        this.params = params;

        const d = new Date(params.value);
        this.inputValue = this.formatDateWithTimezonOffset(d);

        this.updateDateRange();
    }

    updateDateRange(): void {
        if (this.params.colDef.field === 'startDate') {
            const md = new Date(this.params.data.finishDate);
            this.maxDate = this.formatDateWithTimezonOffset(md);
        }

        if (this.params.colDef.field === 'finishDate') {
            const sd = new Date(this.params.data.startDate);
            this.minDate = this.formatDateWithTimezonOffset(sd);
        }
    }

    formatDateWithTimezonOffset(d: Date): Date {
        return new Date(
            d.getFullYear(),
            d.getMonth(),
            d.getDate(),
            d.getHours(),
            d.getMinutes() - d.getTimezoneOffset()
        );
    }

    ngAfterViewInit(): void {}

    getValue(): string {
        return moment(this.inputValue).format('YYYY-MM-DD');
    }
}
