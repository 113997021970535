<ng-content select="[submitLabel='Save']"></ng-content>
<ng-content select="[submitLabel='Assign']"></ng-content>
<ng-content select="formly-form"></ng-content>
<ng-content select="[bottom-content]"></ng-content>
<ng-content select="app-form-actions"></ng-content>

<div
    *ngIf="debug"
    class="absolute w-1/2 p-2 overflow-scroll text-white bg-gray-600 rounded-lg shadow-lg z-99 max-h-100 debug-component"
    style="top: calc(100% - 48px)"
>
    <div
        class="flex items-center justify-start"
        (click)="debugExpanded = !debugExpanded"
    >
        <span class="text-sm font-bold underline">Form Debug </span>
        <mat-icon
            [class.rotate-90]="debugExpanded"
            class="text-white transform"
            svgIcon="mat_outline:keyboard_arrow_right"
        ></mat-icon>
    </div>
    <div *ngIf="debugExpanded" [@expandCollapse] class="w-full whitespace-pre">
        {{ debugFormValueJSON }}
    </div>
</div>
