import { Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-repeat',
  templateUrl: './formly-repeat.component.html',
  styleUrls: ['./formly-repeat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class FormlyRepeatComponent  extends FieldArrayType implements OnInit {
  @Output() addHandler = new EventEmitter();
    ngOnInit(): void {
        this.field.className = this.field.className + ' repeat dense';
    }
    override add(): void {
      super.add();
      this.field?.templateOptions?.addHandler(this.model);
    }

    override remove(index: number): void {
      this.field?.templateOptions?.removeHandler(this.model[index]);
      super.remove(index);
    }

}

