import { Injectable, OnDestroy } from '@angular/core';
import { Activity, ActivityMemo, ActivityTracking, ChangeItem, Error } from 'app/shared/models/core/core-models';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ChangeService } from '../change/change.service';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { ModelName } from 'app/shared/models/model-name/model-name';

/**
 * This service is used to track changes to a model. Changes may be current or past, the important thing is for the application
 * to report on any action that affected a model it may be presenting or working on
 * This service implements the change service to report on activity on models that may occur outside the page that hosts the
 * activity service, such as a pop-over screen or change web socket
 * Only actions on models that match the model name in this service will be recorded in the activity list
 * Activities will list:
 * - Model UId
 * - The type of action occurring/occurred
 * - An activity state:
 *   > Active
 *   > Complete
 *   > Error
 * - Timestamp of last state change. Complete state will set timestamp from the model's updatedDate property
 */

@Injectable({
    providedIn: 'root',
})
export class ActivityService implements OnDestroy {

    private readonly _activity$ = new Subject<Activity>(); // Using subject as only new activity actions are applicable

    private readonly _activityTrackingList: ActivityTracking[] = [];

    private readonly _destroying$ = new Subject<void>();

    constructor(
        private changeService: ChangeService
    ) {

        this.serviceInit();

    }

    ngOnDestroy(): void {
        this._destroying$.next(undefined);
        this._destroying$.complete();
    }


    /**
     * Configure
     */

    serviceInit(): void {

        // Change service. Listen for events not occurring inside this activity service and notify listener

        this.changeService.change$.pipe(takeUntil(this._destroying$)).subscribe((changeItem: ChangeItem) => {

            const index = this._activityTrackingList.findIndex((tracking) => tracking.modelName === changeItem.modelName);

            if (index >= 0) {

                const activityTracking = this._activityTrackingList[index];

                // Check memo for activity id. If activity UId found and is in this service's activities then don't
                // add it as a new activity

                let newActivity = true;

                if (changeItem.memo) {
                    const memo = changeItem.memo as ActivityMemo;
                    if (memo.activityUId) {
                        newActivity = activityTracking.activityList.findIndex((activity: Activity) => activity.activityUId === memo.activityUId) === -1;
                    }
                }

                if (newActivity) {

                    const changeActivity = {
                        activityUId: uuidv4(),
                        thisInstance: false,
                        modelUId: changeItem.modelUId,
                        modelObject: changeItem.modelObject,
                        state: 'Complete',
                        updatedDate: changeItem.modelObject['UpdatedDate'] ?? new Date()
                    } as Activity;

                    if (changeItem.changeType === 'Added' || changeItem.changeType === 'Added Remotely') {
                        changeActivity.activityType = 'Adding';
                    }
                    else if (changeItem.changeType === 'Updated' || changeItem.changeType === 'Updated Remotely') {
                        changeActivity.activityType = 'Updating';
                    }
                    else if (changeItem.changeType === 'Deleted' || changeItem.changeType === 'Deleted Remotely') {
                        changeActivity.activityType = 'Deleting';
                    }

                    activityTracking.activityList.push(changeActivity);

                    this._activity$.next(changeActivity);

                }

            }

        });

    }


    /**
     * Accessors
     */

    get activity$(): Observable<Activity> {
        return this._activity$.asObservable();
    }


    /**
     * Tracking actions
     */

    resetTracking(modelName: ModelName): void {

        const index = this._activityTrackingList.findIndex((tracking) => tracking.modelName === modelName);

        if (index >= 0) {
            this._activityTrackingList[index].activityList = [];
        }

    }


    /**
     * Activity actions
     */

    newActivity(activityType: 'Loading' | 'Adding' | 'Updating' | 'Deleting', modelName: ModelName, modelUId?: string): Activity {

        const index = this._activityTrackingList.findIndex((tracking) => tracking.modelName === modelName);

        let activityTracking: ActivityTracking;

        if (index === -1) {
            activityTracking = {
                modelName: modelName,
                activityList: []
            } as ActivityTracking;
            this._activityTrackingList.push(activityTracking);
        }
        else {
            activityTracking = this._activityTrackingList[index];
        }

        const newActivity = {
            activityUId: uuidv4(),
            thisInstance: true,
            modelUId: modelUId,
            modelName: modelName,
            activityType: activityType,
            state: 'Active',
            updatedDate: new Date()
        } as Activity;

        activityTracking.activityList.push(newActivity);

        this._activity$.next(newActivity);

        return newActivity;

    }

    completeActivity(activity: Activity | undefined, model?: any): void {

        if (activity) {
            this.completeActivityByUId(activity.modelName, activity.activityUId, model);
        }

    }

    completeActivityByUId(modelName: string, activityUId: string, model?: any): void {

        const index = this._activityTrackingList.findIndex((tracking) => tracking.modelName === modelName);

        if (index >= 0) {

            const activityTracking = this._activityTrackingList[index];

            const savedActivity = activityTracking.activityList.find((activity) => activity.activityUId === activityUId);

            if (savedActivity && savedActivity.state === 'Active') {
                savedActivity.state = 'Complete';
                savedActivity.modelObject = model;
                savedActivity.updatedDate = model.updatedDate ?? new Date();

                // Only automatically set activity modelUId if no modelUId override has been supplied
                if (!savedActivity.modelUId) {
                    const modelUIdName = _.camelCase(modelName) + 'UId';
                    const modelUId = model[modelUIdName] as string | undefined;
                    if (modelUId) {
                        savedActivity.modelUId = modelUId;
                    }
                }


                this._activity$.next(savedActivity);

                // Notify change

                if (model && ['Adding', 'Updating', 'Deleting'].includes(savedActivity.activityType)) {

                    const changeItem = {
                        modelName: modelName,
                        modelUId: savedActivity.modelUId,
                        modelObject: model,
                        memo: this.getMemo(savedActivity)
                    } as ChangeItem;

                    if (savedActivity.activityType === 'Adding') {
                        changeItem.changeType = 'Added';
                    }
                    else if (savedActivity.activityType === 'Updating') {
                        changeItem.changeType = 'Updated';
                    }
                    else if (savedActivity.activityType === 'Deleting') {
                        changeItem.changeType = 'Deleted';
                    }

                    this.changeService.notify(changeItem);

                }
            }

        }

    }


    failActivity(activity: Activity | undefined, error: Error): void {

        if (activity) {
            this.failActivityByUId(activity.modelName, activity.activityUId, error);
        }

    }

    failActivityByUId(modelName: string, activityUId: string, error: Error): void {


        const index = this._activityTrackingList.findIndex((tracking) => tracking.modelName === modelName);

        if (index >= 0) {

            const activityTracking = this._activityTrackingList[index];

            const savedActivity = activityTracking.activityList.find((activity) => activity.activityUId === activityUId);

            if (savedActivity && savedActivity.state === 'Active') {
                savedActivity.state = 'Error';
                savedActivity.updatedDate = new Date();
                savedActivity.error = error;
                this._activity$.next(savedActivity);
            }

        }

    }


    getActivityList(modelName: ModelName): Activity[] {

        const index = this._activityTrackingList.findIndex((tracking) => tracking.modelName === modelName);

        if (index >= 0) {
            const activityTracking = this._activityTrackingList[index];
            return activityTracking.activityList;
        }
        else {
            return [];
        }

    }


    getMemo(activity: Activity): ActivityMemo {
        return {
            activityUId: activity.activityUId
        } as ActivityMemo;
    };


}


