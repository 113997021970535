import { AgRendererComponent } from '@ag-grid-community/angular';
import { GridApi, IAfterGuiAttachedParams, ICellRendererParams } from '@ag-grid-community/core';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Renderer2,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { asyncScheduler } from 'rxjs';
import { InlineEditActions } from './ag-grid-action-cell-renderer-types';

@Component({
    selector: 'app-ag-grid-action-cell-renderer',
    templateUrl: './ag-grid-action-cell-renderer.component.html',
    styleUrls: ['./ag-grid-action-cell-renderer.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AgGridActionCellRendererComponent implements AgRendererComponent {
    @ViewChild('inlineEditTrigger') inlineEditTrigger: MatMenuTrigger | undefined;
    @ViewChild('matMenu') matMenu!: MatMenu;
    public params: any | undefined;
    public agGridApi: GridApi | undefined;
    public editing: boolean = false;
    public inlineEditActions: undefined | InlineEditActions;
    public disabled = false;
    public disableEdit = false;
    public disableDelete = false;
    public deleteToolTip: string = '';
    public showRemoveButton = true;
    public agGridRowsWrapper: HTMLElement | undefined;
    public agGridRowWrapper: HTMLElement | undefined;
    public agGridRowBoundingRect: DOMRect | undefined;
    public columnWidthStyles: string = '';

    constructor(
        public cdRef: ChangeDetectorRef,
        public renderer: Renderer2,
        public elementRef: ElementRef
    ) { }

    refresh(params: ICellRendererParams): boolean {
        this.params = params;
        return true;
    }


    handleMatMenuTriggerOnAdd(): void {
        if (this.inlineEditActions?.onRowEditStart) {
            asyncScheduler.schedule(() => this.openInlineEdit(), 0);
        }

    }

    agInit(params: Record<string, any> & ICellRendererParams): void {
        this.params = params;
        this.inlineEditActions = this.params['inlineEditActions'];
        this.disableEdit = this.params['disableEdit'];
        this.disableDelete = this.params['disableDelete'];
        this.deleteToolTip = this.params['deleteToolTip'];
        this.agGridApi = this.params?.api;
        if (this.inlineEditActions) {
            this.inlineEditActions.rowDisable = this.disable;
            this.inlineEditActions.rowEnable = this.enable;
        }
        this.handleMatMenuTriggerOnAdd();
    }
    rowEditStart(menuInner: any): void {
        this.agGridRowWrapper =
            this.agGridRowWrapper === undefined
                ? this.elementRef.nativeElement.parentElement.parentElement
                : this.agGridRowWrapper;
        this.agGridRowsWrapper =
            this.agGridRowsWrapper === undefined
                ? this.elementRef.nativeElement.parentElement.parentElement
                    .parentElement
                : this.agGridRowsWrapper;
        this.agGridRowBoundingRect =
            this.agGridRowWrapper?.getBoundingClientRect();
        this.renderer.addClass(this.agGridRowWrapper, 'ag-grid-inline-editing');
        this.renderer.addClass(
            this.agGridRowsWrapper,
            'ag-grid-inline-editing-wrapper'
        );
        this.editing = true;
        this.generateColumnWidthStyles();
        const field = menuInner.querySelector('formly-field-mat-input > *');
        field?.focus();
    }

    openInlineEdit(): void {
        if (this.params.data.addNew) {
            if (!this.inlineEditTrigger?.menuOpen) {
                this.inlineEditTrigger?.openMenu();
                this.params.data.addNew = false;
            }
        }
    }

    rowEditCancel(): void {
        this.renderer.removeClass(
            this.agGridRowWrapper,
            'ag-grid-inline-editing'
        );
        this.renderer.removeClass(
            this.agGridRowsWrapper,
            'ag-grid-inline-editing-wrapper'
        );
        this.editing = false;
    }

    rowCopy(): void {
        if (this.params && this.agGridApi) {
            this.setLoadingState(true);
            if (this.inlineEditActions?.onRowCopy !== undefined) {
                this.inlineEditActions?.onRowCopy(this.params.node.data);
            }
        }
    }
    rowDelete(): void {
        if (this.params && this.agGridApi) {
            this.setLoadingState(true);
            if (this.inlineEditActions?.onRowDelete !== undefined) {
                this.inlineEditActions?.onRowDelete(this.params.node.data);
            }
        }
    }

    setLoadingState(loading: boolean): void {
        if (this.inlineEditActions?.onSetLoadingState !== undefined) {
            this.inlineEditActions?.onSetLoadingState(loading);
        }
    }

    disable = (): void => {
        this.disabled = true;
    };

    enable = (): void => {
        this.disabled = false;
    };

    generateColumnWidthStyles(): void {
        if (this.agGridRowWrapper) {
            this.columnWidthStyles = '';
            const children = Array.from(this.agGridRowWrapper.children);
            children.forEach((child, i) => {
                // eslint-disable-next-line @typescript-eslint/quotes, quotes
                const property: string = '--col-' + (i + 1) + ':';
                this.columnWidthStyles =
                    this.columnWidthStyles +
                    property +
                    child.clientWidth +
                    'px;';
            });
        }
    }

    getColumnWidthStyles(): string {
        return this.columnWidthStyles;
    }
}
