import { Component,ChangeDetectionStrategy, ViewEncapsulation, OnInit} from '@angular/core';
import { FormlyFieldSelect } from '@ngx-formly/material/select';

@Component({
  selector: 'app-formly-attribute-separator',
  templateUrl: './formly-attribute-separator.component.html',
  styleUrls: ['../../styles/ng-select.scss','./formly-attribute-separator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class FormlyAttributeSeparatorComponent  extends FormlyFieldSelect implements OnInit  {
    override formControl: any;
    override ngOnInit(): void {
        this.field.className = this.field.className + ' separator';
    }
}

