<div class="flex flex-col justify-center gap-1 align-start">
    <div class="flex items-center justify-start" *ngIf="parentName">
        <div class="px-2 text-xs font-bold leading-3 uppercase border border-current border-solid rounded-full text-product-group"
            matTooltip="Product Type">
            {{parentName}}
        </div>
    </div>

    <div class="flex items-center">
        <span (click)="openLink(); $event.stopPropagation()" class="font-bold underline">
            {{ params?.value }}
        </span>

        <a *ngIf="params?.value" mat-icon-button class="flex items-center justify-center" matTooltip="Open in new Tab"
            (click)="openLinkInNewTab(); $event.stopPropagation()">
            <mat-icon class="cursor-pointer icon-size-4" svgIcon="open_in_new">
            </mat-icon>
        </a>
    </div>
</div>
