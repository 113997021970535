import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { ImageUploaderOptions } from 'app/shared/models/common/image-uploader-models';
import { environment } from 'environments/environment';
import { ContentBlock } from '../../types/editor';

@Component({
    selector: 'app-carousel',
    templateUrl: './carousel.component.html',
    styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {
    @Input() block: ContentBlock | undefined;
    @Input() editable: boolean = true;
    @Output() ngModelChange = new EventEmitter();

    imageOptions: ImageUploaderOptions = {
        uploadUrl: environment.api.siglaApi + '/common/upload/image',
        allowedImageTypes: ['image/png', 'image/jpeg', 'image/png'],
        thumbnailResizeMode: 'fill',
        autoUpload: true,
        resizeOnLoad: true,
        thumbnailWidth: 220,
        thumbnailHeight: 220,
    };
    constructor() { }

    ngOnInit(): void {
    }

    add(): void {
        if (this.block?.data?.images) {
            this.block?.data?.images.push({ file: { url: '', alt: '', caption: '' } });
        }

    }

    remove(block: ContentBlock | undefined): void {
        if (this.block?.data?.images && block) {
            const id = block.id;
            const index = this.block?.data?.images.findIndex((image: any) => image.id === id);
            this.block?.data?.images.splice(index);
        }
    }

}
