import { ICellRendererParams } from '@ag-grid-community/all-modules';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-ag-grid-price-break-renderer',
  templateUrl: './ag-grid-price-break-renderer.component.html',
  styleUrls: ['./ag-grid-price-break-renderer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AgGridPriceBreakRendererComponent implements ICellRendererAngularComp {
  params!: any;
  constructor() { }
  refresh(params: ICellRendererParams): boolean {
    return true;
  }
  agInit(params: ICellRendererParams): void {
    this.params = params;

  }

}
