import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { SearchBusinessCriteria } from 'app/shared/models/commercial/search-models';
import { BusinessSearchService } from 'app/shared/services/commercial/business/business-search.service';
import { ExchangeRateService } from 'app/shared/services/commercial/exchange-rate/exchange-rate.service';
import { siglaLog } from 'app/shared/utility/logging-util';
import moment from 'moment';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

export const minLengthValidation = (
    error: any,
    field: FormlyFieldConfig
): string =>
    `Minimum of ${field?.templateOptions?.minLength} characters required`;

export const maxLengthValidationMessage = (
    error: any,
    field: FormlyFieldConfig
): string =>
    `Maximum of ${field?.templateOptions?.minLength} characters required`;

export const minValidation = (
    error: any,
    field: FormlyFieldConfig
): string =>
    `Minimum value should be ${field?.templateOptions?.min}`;

export const maxValidationMessage = (
    error: any,
    field: FormlyFieldConfig
): string =>
    `Maximum value should be ${field?.templateOptions?.max}`;

export const requiredValidationMessage = (
    error: any,
    field: FormlyFieldConfig
): string =>
    field?.templateOptions?.label
        ? `${field?.templateOptions?.label} is required`
        : 'This field is required';

export const emailValidator = (
    control: AbstractControl,
    field: FormlyFieldConfig
): ValidationErrors | null => {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    siglaLog(re.test(control.value));
    return re.test(control.value) ? null : { email: true };
};

export const longitudeValidator = (
    control: AbstractControl,
    field: FormlyFieldConfig
): ValidationErrors | null => {
    if (control.value >= -180 && control.value <= 180) {
        return null;
    } else {
        return { longitude: true };
    }
};

export const latitudeValidator = (
    control: AbstractControl,
    field: FormlyFieldConfig
): ValidationErrors | null => {
    if (control.value >= -90 && control.value <= 90) {
        return null;
    } else {
        return { latitude: true };
    }
};


export const fieldMatchValidator = (
    control: AbstractControl
): ValidationErrors | null => {
    const { fromCurrency, toCurrency } = control.value;

    // avoid displaying the message error when values are empty
    if (!toCurrency || !fromCurrency) {
        return null;
    }

    if (toCurrency !== fromCurrency) {
        return null;
    }

    return { fieldMatch: { message: 'Different currencies required' } };
};

export const dateRangeValidator = (
    control: AbstractControl
): ValidationErrors | null => {
    const startDate = control.get('startDate')?.value;
    const finishDate = control.get('finishDate')?.value;

    // avoid displaying the message error when values are empty
    if (!startDate || !finishDate) {
        return null;
    }

    if (new Date(finishDate).getTime() > new Date(startDate).getTime()) {
        return null;
    }

    return {
        fieldMatch: {
            message: 'End date should be a later date than start date',
        },
    };
};

export const fieldExistsValidationConfig = (
    exchangeRateService: ExchangeRateService,
    businessSearch: BusinessSearchService
): ValidationErrors => ({
    validationMessages: [
        { name: 'fieldExists', message: 'Currencies already supplied' },
        { name: 'businessNameExists', message: 'Business with same name already exists' },
    ],
    validators: [
        {
            name: 'fieldExists',
            validation: (control: FormControl): ValidationErrors | null => {
                const { fromCurrency, toCurrency, exchangeRateUId } = control.value;

                if (!toCurrency || !fromCurrency) {
                    return of(null);
                }
                return exchangeRateService
                    .checkExistingExchangeRateList(fromCurrency, toCurrency, exchangeRateUId)
                    .pipe(map((isAdded) => (isAdded ? null : { fieldExists: true })));
            },
        },
        {
            name: 'businessNameExists',
            validation: (control: FormControl): ValidationErrors | null => {
                const { name, businessUId } = control.value;

                if (!name) {
                    return of(null);
                }
                const search: SearchBusinessCriteria | any = { name };
                return businessSearch.checkBusinessNameExists(search, businessUId).pipe(
                    map((isExists) => {
                        if (isExists) {
                            return { businessNameExists: true };
                        } else {
                            return null;
                        }
                    })
                );
            },
        },
    ],
});

export const minDateValidator = (
    control: AbstractControl,
    field: FormlyFieldConfig
): ValidationErrors | null => {
    siglaLog(control, field);
    const dateFormat = 'DD/MM/YYYY';
    moment(control.value, dateFormat, true).isValid(); // return true
    if (moment(control.value, dateFormat, true).isValid()) {
        if (
            new Date(control.value).getTime() <
            new Date(
                field?.templateOptions?.['datepickerOptions']?.min
            ).getTime()
        ) {
            return { minDate: true };
        }
    }
    return null;
};

export const maxDateValidator = (
    control: AbstractControl,
    field: FormlyFieldConfig
): ValidationErrors | null => {
    const dateFormat = 'DD/MM/YYYY';
    moment(control.value, dateFormat, true).isValid(); // return true
    if (moment(control.value, dateFormat, true).isValid()) {
        if (
            new Date(control.value).getTime() >
            new Date(
                field?.templateOptions?.['datepickerOptions']?.max
            ).getTime()
        ) {
            return { maxDate: true };
        }
    }
    return null;
};





export const emailValidationMessage = (
    error: any,
    field: FormlyFieldConfig
): string => 'Email is Invalid';

export const latitudeValidationMessage = (
    error: any,
    field: FormlyFieldConfig
): string => 'Latitude value must be between -90 and 90';

export const longitudeValidationMessage = (
    error: any,
    field: FormlyFieldConfig
): string => 'Longitude value must be between -180 and 180';

export const minDateValidationMessage = (
    error: any,
    field: FormlyFieldConfig
): string =>
    `Not valid, ${field.templateOptions?.label} should be a future date than ` +
    moment(field?.templateOptions?.['datepickerOptions']?.min).format(
        'DD/MM/YYYY'
    );

export const maxDateValidationMessage = (
    error: any,
    field: FormlyFieldConfig
): string =>
    `Not valid, ${field.templateOptions?.label} should not be a future date than ` +
    moment(field?.templateOptions?.['datepickerOptions']?.max).format(
        'DD/MM/YYYY'
    );

export const inValidPhoneNumberMessage = (
    error: any,
    field: FormlyFieldConfig
): string =>
    ` ${field.templateOptions?.label} is not a valid Phone Number `;

export const noEndDateValidationMessage = (
    error: any,
    field: FormlyFieldConfig
): string =>
    ` ${field.templateOptions?.['endDateKey']} is required`;

export const dateRangeRequiredValidator = (field: FormlyFieldConfig): ValidatorFn => (control: AbstractControl): ValidationErrors | null => {

    const value = control.value;
    if (!value && field?.templateOptions?.required) {
        return { required: true };
    }
    if (field?.templateOptions?.['datepickerOptions']['endDateRequired']) {
        const endDate = field.form?.get(field?.templateOptions?.['endDateKey']);
        if (field?.templateOptions?.['isRange']) {
            return endDate?.valid ? null : { endDateInvalid: true };
        }
    }
    return null;

};

export const invalidDate = (
    error: any,
    field: FormlyFieldConfig
): string =>
    'Date Format is not Matching with DD/MM/YYYY';
