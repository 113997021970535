<mat-chip-list
  cdkDropList
  cdkDropListOrientation="horizontal"
  (cdkDropListDropped)="drop($event)">
  <ng-container     *ngFor="let addressPart of addressPartList">
    <mat-chip *ngIf="addressPart.value && addressPart.value.trim().length > 0"
    @slideInLeft
    @slideOutRight
    cdkDrag class="gap-1 address-part-chip">

<mat-icon class="icon-size-3" [svgIcon]="'mat_solid:open_with'"></mat-icon>
   <span>{{addressPart.value}}</span>
  </mat-chip>
  </ng-container>
</mat-chip-list>
