import { Component,ChangeDetectionStrategy, ViewEncapsulation, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-button',
  templateUrl: './formly-button.component.html',
  styleUrls: ['./formly-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class FormlyButtonComponent  extends FieldType implements OnInit {
    formControl: any;

    ngOnInit(): void {
        this.field.className = 'button';

      }

      onClick() {
        if (this.to['onClick']) {
          this.to['onClick']();
        }
      }

}
