<hr class="block w-full m-0" />

<div class="flex items-center h-12 gap-4 pb-2" [class.-mb-13]="to['collapseHeader']">
    <div class="flex items-center" skeleton >
        <label *ngIf="to.label" class="text-lg font-bold whitespace-nowrap">{{ to.label }}</label>
        <mat-icon
            *ngIf="to['tooltip']"
            [matTooltip]="to['tooltip']"
            svgIcon="info"
            class="icon-size-4"
        ></mat-icon>
    </div>

    <div class="flex items-center justify-between w-full gap-4" skeleton [class.mr-40]="to['collapseHeader']">
        <label class="text-sm font-bold">Attribute Level: {{to['attributeLevelLabel']}}</label>
        <mat-slide-toggle [matTooltip]="to['setAttributeLevelTooltip']" [formControl]="formControl" class="relative z-10 text-xs font-bold">{{to['setAttributeLevelLabel']}}</mat-slide-toggle>
    </div>
</div>
