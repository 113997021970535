export const adminEndpoints = {
    commercial: {
        // Account
        businessAccountList: 'commercial/account/list',
        businessAccountWithUId: 'commercial/account/{accountUId}',
        businessAccount: 'commercial/account',

        // Agency
        agency: 'commercial/agency',
        agencyList: 'commercial/agency/list',
        agencyWithUId: 'commercial/agency/{businessUId}',
        agencyNew: 'commercial/agency/new',
        agencySearch: 'commercial/agency/search',

        // Agency Relationship. Does not have its own model. Is a subset of the Relationship model

        agencyRelationship: 'commercial/agencyrelationship',
        agencyRelationshipWithUId: 'commercial/agencyrelationship/{relationshipUId}',
        agencyRelationshipList: 'commercial/agencyrelationship/list',

        // Agency Profile Relationship. Does not have its own model. Is a subset of the Relationship model

        agencyProfileRelationship: 'commercial/agencyprofilerelationship',
        agencyProfileRelationshipWithUId: 'commercial/agencyprofilerelationship/{relationshipUId}',
        agencyProfileRelationshipList: 'commercial/agencyprofilerelationship/list',

        // Agreement
        agreement: 'commercial/agreement',
        agreementWithUId: 'commercial/agreement/{agreementUId}',
        agreementNew: 'commercial/agreement/new',
        agreementDuplicate: 'commercial/agreement/{agreementUId}/duplicate',
        agreementList: 'commercial/agreement/list',
        agreementSearch: 'commercial/agreement/search',

        // business
        business: 'commercial/business',
        businessList: 'commercial/business/list',
        businessWithUId: 'commercial/business/{businessUId}',
        businessWithUIdConsultant: 'commercial/business/{businessUId}?incl=consultant',
        businessNew: 'commercial/business/new',
        businessForAccounts: 'commercial/business/{businessUId}/accounts',
        businessSearch: 'commercial/business/search',
        businessSearchByBooking: 'accounts/businesssearchbybooking',
        businessSearchByPurchase: 'accounts/businesssearchbypurchase',
        businessRelationshipList:
            'commercial/business/{businessUId}/relationship/list',
        businessRelationship: 'commercial/business/{businessUId}/relationship',

        // business Email
        businessEmail: 'commercial/businessemail',
        businessEmailWithUId: 'commercial/businessemail/{businessEmailUId}',

        // business phone No
        businessPhoneNo: 'commercial/businessphoneno',
        businessPhoneNoWithUId:
            'commercial/businessphoneno/{businessPhoneNoUId}',

        // Direct Client Profile. Does not have its own model. Is a subset of the Relationship model

        directClientProfileRelationship: 'commercial/directclientprofilerelationship',
        directClientProfileRelationshipWithUId:
            'commercial/directclientprofilerelationship/{relationshipUId}',
        directClientProfileRelationshipList: 'commercial/directclientprofilerelationship/list',

        // exchange rate
        exchangeRate: 'commercial/exchangeRate',
        exchangeRateWithUId: 'commercial/exchangeRate/{exchangeRateUId}',
        exchangeRateList: 'commercial/exchangerate/list',

        // Office
        office: 'commercial/office',
        officeList: 'commercial/office/list',
        officeWithUId: 'commercial/office/{businessUId}',
        officeSearch: 'commercial/office/search',

        dmcNew: 'commercial/dmc/new',
        salesOfficeNew: 'commercial/salesoffice/new',
        dmcSearch: 'commercial/dmc/search',
        salesOfficeSearch: 'commercial/salesoffice/search',

        // payment rule
        paymentRule: 'commercial/paymentrule',
        paymentRuleWithUId: 'commercial/paymentrule/{paymentRuleUId}',
        paymentRuleList: 'commercial/paymentrule/list',

        // profile
        profile: 'commercial/profile',
        profileWithUId: 'commercial/profile/{profileUId}',
        profileSearch: 'commercial/profile/search',

        // relationship
        relationship: 'commercial/relationship',
        relationshipWithUId: 'commercial/relationship/{relationshipUId}',
        relationshipList: 'commercial/relationship/list',

        // Supplier

        supplier: 'commercial/supplier',
        supplierList: 'commercial/supplier/list',
        supplierWithUId: 'commercial/supplier/{businessUId}',
        supplierNew: 'commercial/supplier/new',
        supplierSearch: 'commercial/supplier/search',

        // Supplier Relationship. Does not have its own model. Is a subset of the Relationship model

        supplierRelationship: 'commercial/supplierrelationship',
        supplierRelationshipWithUId: 'commercial/supplierrelationship/{relationshipUId}',
        supplierRelationshipList: 'commercial/supplierrelationship/list',

        // Supplier Profile. Does not have its own model. Is a subset of the Relationship model

        supplierProfileRelationship: 'commercial/supplierprofilerelationship',
        supplierProfileRelationshipWithUId: 'commercial/supplierprofilerelationship/{relationshipUId}',
        supplierProfileRelationshipList: 'commercial/supplierprofilerelationship/list',

        //tax schedule
        taxSchedule: 'commercial/taxschedule',
        taxScheduleWithUId: 'commercial/taxschedule/{taxScheduleUId}',
        taxScheduleNew: 'commercial/taxschedule/new',
        taxScheduleList: 'commercial/taxschedule/list',

        //tax rate
        taxRate: 'commercial/taxrate',
        taxRateWithUId: 'commercial/taxrate/{taxRateUId}',
        taxRateList: 'commercial/taxrate/list',
        taxRateDuplicate: 'commercial/taxrate/{taxRateUId}/duplicate',
    },
    marketing: {
        // Person

        person: 'marketing/person',
        personWithUId: 'marketing/person/{personUId}',
    },
    platform: {
        // Settings
        settings: 'platform/settings',
        userList: 'platform/user/list',
        user: 'platform/user',
        userWithUId: 'platform/user/{userUId}',
    },
    products: {
        // Agreement Item (Surrogate)

        agreementItem: 'product/agreementitem',
        agreementItemWithUId: 'product/agreementitem/{agreementItemUId}',
        agreementItemList: 'product/agreementitem/list',

        // Agreement Product

        agreementProduct: 'product/agreementproduct',
        agreementProductWithUId:
            'product/agreementproduct/{agreementProductUId}',
        agreementProductList: 'product/agreementproduct/list',

        // Agreement Product Type

        agreementProductType: 'product/agreementproducttype',
        agreementProductTypeWithUId:
            'product/agreementproducttype/{agreementProductTypeUId}',
        agreementProductTypeList: 'product/agreementproducttype/list',

        // Agreement Product Series

        agreementProductSeries: 'product/agreementproductseries',
        agreementProductSeriesWithUId:
            'product/agreementproductseries/{agreementProductSeriesUId}',
        agreementProductSeriesList: 'product/agreementproductseries/list',

        // Attribute

        attribute: 'product/attribute',
        attributeWithUId: 'product/attribute/{attributeUId}',
        attributeList: 'product/attribute/list',
        attributeExclude: 'product/attribute/exclude/{attributeUId}',

        // Attribute Choice

        attributeChoice: 'product/attributechoice',
        attributeChoiceWithUId: 'product/attributechoice/{attributeChoiceUId}',
        attributeChoiceList: 'product/attributechoice/list',

        // Attribute Exclusion

        attributeExclusion: 'product/attributeexclusion',
        attributeExclusionWithUId:
            'product/attributeexclusion/{attributeExclusionUId}',

        // Calendar

        calendar: 'product/calendar',
        calendarWithUId: 'product/calendar/{calendarUId}',
        calendarNew: 'product/calendar/new',
        calendarDuplicate: 'product/calendar/{calendarUId}/duplicate',
        calendarResetPrices: 'product/calendar/{calendarUId}/resetprices',
        calendarReset: 'product/calendar/{calendarUId}/reset',
        calendarList: 'product/calendar/list',
        calendarSearch: 'product/calendar/search',

        // Calendar Date

        calendarDate: 'product/calendardate',
        calendarDateWithUId: 'product/calendardate/{calendarDateUId}',
        calendarDateNew: 'product/calendardate/new',
        calendarDateDuplicate:
            'product/calendardate/{calendarDateUId}/duplicate',
        calendarDateList: 'product/calendardate/list',

        // Calendar Item (Surrogate)

        calendarItem: 'product/calendaritem',
        calendarItemWithUId: 'product/calendaritem/{calendarItemUId}',
        calendarItemList: 'product/calendaritem/list',

        // Calendar Package Rate

        calendarPackageRate: 'product/calendarpackagerate',
        calendarPackageRateWithUId:
            'product/calendarpackagerate/{calendarPackageRateUId}',
        calendarPackageRateList: 'product/calendarpackagerate/list',

        // Calendar Period

        calendarPeriod: 'product/calendarperiod',
        calendarPeriodWithUId: 'product/calendarperiod/{calendarPeriodUId}',
        calendarPeriodList: 'product/calendarperiod/list',

        // Calendar Period Day

        calendarPeriodDay: 'product/calendarperiodday',
        calendarPeriodDayWithUId:
            'product/calendarperiodday/{calendarPeriodDayUId}',
        calendarPeriodDayList: 'product/calendarperiodday/list',

        // Calendar Product

        calendarProduct: 'product/calendarproduct',
        calendarProductWithUId: 'product/calendarproduct/{calendarProductUId}',
        calendarProductList: 'product/calendarproduct/list',

        // Calendar Product Type

        calendarProductType: 'product/calendarproducttype',
        calendarProductTypeWithUId:
            'product/calendarproducttype/{calendarProductTypeUId}',
        calendarProductTypeList: 'product/calendarproducttype/list',

        // Calendar Product Series

        calendarProductSeries: 'product/calendarproductseries',
        calendarProductSeriesWithUId:
            'product/calendarproductseries/{calendarProductSeriesUId}',
        calendarProductSeriesList: 'product/calendarproductseries/list',

        // Commission

        commission: 'product/commission',
        commissionWithUId: 'product/commission/{commissionUId}',
        commissionSearch: 'product/commission/search',

        // CommissionProduct

        commissionProduct: 'product/commissionproduct',
        commissionProductWithUId:
            'product/commissionproduct/{commissionProductUId}',
        commissionProductList: 'product/commissionproduct/list',
        commissionProductSearch: 'product/commissionproduct/search',

        // Markup

        markup: 'product/markup',
        markupWithUId: 'product/markup/{markupUId}',
        markupSearch: 'product/markup/search',

        // MarkupProduct

        markupProduct: 'product/markupproduct',
        markupProductWithUId: 'product/markupproduct/{markupProductUId}',
        markupProductList: 'product/markupproduct/list',
        markupProductSearch: 'product/markupproduct/search',

        // Options

        optionGroupList: 'product/optiongroup/list',
        optionList: 'product/option/list',
        optionGroupSearch: 'product/product/optiongroupsearch',
        optionGroupChoice: 'product/optiongroupchoice',
        optionGroupChoiceList: 'product/optiongroupchoice/list',
        optionGroupChoiceWithUId:
            'product/optiongroupchoice/{optionGroupChoiceUId}',
        optionGroupChoiceRate: 'product/optiongroupchoicerate',
        optionGroupChoiceRateWithUId:
            'product/optiongroupchoicerate/{optionGroupChoiceUId}',
        optionGroupChoiceRateList: 'product/optiongroupchoicerate/list',

        // Package Product

        packageProduct: 'product/packageproduct',
        packageProductWithUId: 'product/packageproduct/{packageProductUId}',
        packageProductList: 'product/packageproduct/list',
        packageProductSearch: 'product/packageproduct/search',

        // Package Day

        packageDayList: 'product/packageday/list',
        packageDay: 'product/packageday',
        packageDayWithUId: 'product/packageday/{packageDayUId}',

        // Package Inclusion

        packageInclusion: 'product/packageinclusion',
        packageInclusionWithUId:
            'product/packageinclusion/{packageInclusionUId}',
        packageInclusionList: 'product/packageinclusion/list',

        //package Inclusion Rate

        packageInclusionRate: 'product/packageinclusionrate',
        packageInclusionRateWithUId:
            'product/packageinclusionrate/{packageInclusionRateUId}',
        packageInclusionRateList: 'product/packageinclusionrate/list',

        // Package Rate

        packageRate: 'product/packagerate',
        packageRateWithUId: 'product/packagerate/{packageRateUId}',
        packageRateList: 'product/packagerate/list',

        // Payment Rule

        paymentRule: 'product/paymentrule',
        paymentRuleWithUId: 'product/paymentrule/{paymentRuleUId}',
        paymentRuleNew: 'product/paymentrule/new',
        paymentRuleDuplicate: 'product/paymentrule/{paymentRuleUId}/duplicate',
        paymentRuleList: 'commercial/paymentrule/list',

        // Price

        price: 'product/price',
        priceWithUId: 'product/price/{priceUId}',
        priceList: 'product/price/list',
        priceSearch: 'product/price/search',
        priceSheet: 'product/price/sheet',

        // Price Break

        priceBreak: 'product/pricebreak',
        priceBreakWithUId: 'product/pricebreak/{priceBreakUId}',
        priceBreakList: 'product/pricebreak/list',

        // Price Surrogate

        priceSurrogateList: 'product/price/surrogate/list',
        priceSurrogate: 'product/price/surrogate',
        priceSurrogateWithUId: 'product/price/surrogate/{priceUId}',

        // Product

        product: 'product/product',
        productWithUId: 'product/product/{productUId}',
        productNew: 'product/product/new',
        productDuplicate: 'product/product/{productUId}/duplicate',
        productList: 'product/product/list',
        productSearch: 'product/product/search',
        productPackageSearch: 'product/product/packageproductsearch',
        agreementProductSearch: 'product/agreementproduct/search',
        extraList: 'product/extra/list',
        productExtraList: 'product/productextra/list',
        extraAssociatedProductsList: 'product/extra/{extraUId}/productlist',
        productAssociatedExtrasList: 'product/product/{productUId}/extralist',

        // Product Extra

        productExtra: 'product/productextra',

        // Product Content

        productContent: 'product/productcontent',
        productContentWithUId: 'product/productcontent/{productContentUId}',
        productContentList: 'product/productcontent/list',

        // Product Day Content

        productDayContent: 'product/productdaycontent',
        productDayContentWithUId:
            'product/productdaycontent/{productDayContentUId}',
        productDayContentList: 'product/productdaycontent/list',

        // Product Extra

        productExtraWithUId: 'product/productextra/{productExtraUId}',

        // Product Item

        productItemList: 'product/productitem/list',
        productItemSearch: 'product/productitem/search',

        // Product Series

        productSeries: 'product/productseries',
        productSeriesWithUId: 'product/productseries/{productSeriesUId}',
        productSeriesNew: 'product/productseries/new',
        productSeriesDuplicate:
            'product/productseries/{productSeriesUId}/duplicate',
        productSeriesList: 'product/productseries/list',
        productSeriesSearch: 'product/productseries/search',

        // Product Type

        productType: 'product/producttype',
        productTypeWithUId: 'product/producttype/{productTypeUId}',
        productTypeNew: 'product/producttype/new',
        productTypeList: 'product/producttype/list',
        productTypeSearch: 'product/producttype/search',

        // Product Type Attribute

        productTypeAttribute: 'product/producttypeattribute',
        productTypeAttributeWithUId:
            'product/producttypeattribute/{productTypeAttributeUId}',

        // Rate
        newRate: 'product/rate/new',
        rate: 'product/rate',
        rateWithUId: 'product/rate/{rateUId}',
        rateNew: 'product/rate/new',
        rateDuplicate: 'product/rate/{rateUId}/duplicate',
        rateList: 'product/rate/list',
        rateSearch: 'product/rate/search',

        // Rate Content

        rateContent: 'product/ratecontent',
        rateContentWithUId: 'product/ratecontent/{rateContentUId}',
        rateContentList: 'product/ratecontent/list',

        // Room Bed Config

        roomBedConfig: 'product/roombedconfig',
        roomBedConfigWithUId: 'product/roombedconfig/{roomBedConfigUId}',
        roomBedConfigList: 'product/roombedconfig/list',

        // Season

        season: 'product/season',
        seasonWithUId: 'product/season/{seasonUId}',
        seasonNew: 'product/season/new',
        seasonDuplicate: 'product/season/{seasonUId}/duplicate',
        seasonList: 'product/season/list',
        seasonSearch: 'product/season/search',
    },
    common: {
        // content
        contentList: 'common/content/list',
        content: 'common/content',
        contentWithUId: 'common/content/{contentUId}',
        contentNew: 'common/content/new',
        dayContentList: 'common/daycontent/list',
        dayContent: 'common/daycontent',
        dayContentWithUId: 'common/daycontent/{dayContentUId}',
        note: 'common/note',
        noteWithUId: 'common/note/{noteUId}',
        noteList: 'common/note/list',
        action: 'common/action',
        actionList: 'common/action/list',
        actionWithUId: 'common/action/{actionUId}',
        actionDuplicate: 'common/action/{actionUId}/duplicate',
        attachment: 'common/attachment',
        attachmentWithUId: 'common/attachment/{attachmentUId}',
        uploadAttachment: 'common/upload/attachment',
        attachmentList: 'common/attachment/list',
    },
    wiki: {
        // Point of Interest

        pointOfInterest: 'wiki/pointofinterest',
        pointOfInterestWithUId: 'wiki/pointofinterest/{pointOfInterestUId}',
        pointOfInterestSearch: 'wiki/pointofinterest/search',

        // Visited By

        visitedBy: 'wiki/visitedby',
        visitedByWithUId: 'wiki/visitedby/{visitedByUId}',
        visitedByList: 'wiki/visitedby/list',
    },
    refData: {
        productTypeList: 'refdata/producttype/list',
        productTypeListNested: 'refdata/producttype/list/nested',
        travellerList: 'refdata/traveller/list',
        supplierList: 'refdata/supplier/list',
        optionList: 'refdata/option/list',
        exchangeRateList: 'refdata/exchangeRate/list',
        priceSheet: 'refdata/bundle/pricesheetfilter',
    },
};
