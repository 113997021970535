import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { DialogConfig } from '../../interfaces/dialog-config.type';

@Component({
  selector: 'app-dialog-wrapper',
  templateUrl: './dialog-wrapper.component.html',
  styleUrls: ['./dialog-wrapper.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DialogWrapperComponent implements OnInit
{
    @Input() dialogConfig: DialogConfig | undefined;
    @Input() data: any;
    /**
     * Constructor
     */
    constructor(
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

}

