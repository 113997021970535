<!-- Navigation -->
<fuse-vertical-navigation
    #nav
    class="bg-gray-900 dark print:hidden"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="navigation"
    [opened]="!isScreenSmall"
    [navPosition]="nav.opened"
>
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <!-- User -->
        <div
            class="flex items-center justify-center w-full h-16 border-b border-white border-opacity-50"
        >
            <a [routerLink]="['/']">
                <img
                    class="min-h-8"
                    src="assets/images/logo/logo-text-on-dark-white.svg"
                />
            </a>
        </div>
        <div class="flex flex-col items-center w-full p-4"             *ngIf="user$ | async let user">
            <div
                class="w-full mt-0.5 mb-2 whitespace-nowrap overflow-ellipsis overflow-hidden text-center text-md leading-normal font-bold"
            >
                50 Degrees North Group
            </div>
            <div class="relative flex items-center justify-center w-24 h-24" >
                <img
                    class="w-full h-full rounded-full"
                    *ngIf="user?.avatar"
                    [src]="user?.avatar"
                    alt="User avatar"
                />
                <div
                    *ngIf="!user?.avatar"
                    class="flex items-center justify-center w-20 h-20 text-4xl text-black uppercase bg-green-100 rounded-full"
                >
                    {{ user?.initials }}
                </div>
            </div>
            <div class="flex flex-col items-center justify-center w-full mt-6">
                <div
                    class="w-full overflow-hidden font-medium leading-normal text-center whitespace-nowrap overflow-ellipsis"
                >
                    {{ user?.name }}
                </div>
                <div
                    class="w-full mt-0.5 whitespace-nowrap overflow-ellipsis overflow-hidden text-center text-md leading-normal font-medium text-secondary"
                >
                {{ user?.email }}
                </div>
            </div>
        </div>
    </ng-container>
    <!-- Navigation footer hook -->
    <ng-container fuseVerticalNavigationContentFooter>
        <div
            class="flex items-center justify-center h-16 pl-2 pr-6 mt-2 mb-4 flex-0 opacity-12"
        >
            <img
                class="max-w-40"
                src="assets/images/logo/logo-text-on-dark.svg"
            />
        </div>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">
    <!-- Header -->
    <div
        class="relative flex items-center w-full h-16 px-4 bg-transparent border-b border-white border-opacity-50 shadow-none backdrop-filter backdrop-blur-lg flex-0 md:px-6 z-49 print:hidden"
    >
        <!-- Navigation toggle button -->
        <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
            <mat-icon
                [svgIcon]="'heroicons_outline:menu'"
                class="text-white"
            ></mat-icon>
        </button>
        <div
            class="flex items-center justify-center h-16 pl-2 pr-6 flex-0"
            [matMenuTriggerFor]="organisationOptions"
        >
            <img class="max-w-36" src="assets/images/logo/50DN_logo.svg" />
            <button mat-icon-button>
                <mat-icon
                    [svgIcon]="'mat_solid:keyboard_arrow_down'"
                    class="text-white"
                ></mat-icon>
            </button>

            <mat-menu [xPosition]="'before'" #organisationOptions="matMenu">
                <div class="px-4">
                    <span class="flex flex-col">
                        <label
                            class="text-xs font-bold uppercase whitespace-nowrap"
                            >Organisation Settings</label
                        >
                        <span class="font-bold">50 Degrees North Group</span>
                    </span>
                </div>
                <mat-divider class="my-2"></mat-divider>
                <button mat-menu-item>
                    <mat-icon
                        [svgIcon]="'location_city'"
                    ></mat-icon>
                    <span>Organisation</span>
                </button>
                <button mat-menu-item routerLink="./settings">
                    <mat-icon [svgIcon]="'heroicons_outline:cog'"></mat-icon>
                    <span> Settings</span>
                </button>
            </mat-menu>
        </div>

        <!-- Components -->
        <div class="flex items-center justify-end gap-4 ml-auto">
            <div class="flex items-center justify-center"></div>
            <div
                [matMenuTriggerFor]="officeOptions"
                class="flex flex-col items-center justify-center px-2 font-bold bg-white border-r border-solid rounded-lg min-h-10"
            >
                <label
                    class="absolute text-xs font-bold uppercase transform scale-90 whitespace-nowrap top-3"
                    >Offices</label
                >

                <div *ngIf="selectedBusiness$ | async; let office"
                    class="flex items-center justify-center w-full gap-1 mt-2 text-md"
                >
                    <span>{{ office.nickname ? office.nickname : office.name }}</span>
                    <mat-icon
                        class="icon-size-5"
                        [svgIcon]="'mat_solid:arrow_drop_down'"
                    ></mat-icon>
                </div>

                <mat-menu [xPosition]="'before'" #officeOptions="matMenu" >
                    <div class="px-4 py-2">
                        <span class="flex flex-col">
                            <label
                                class="text-xs font-bold uppercase whitespace-nowrap"
                                >Switch Offices</label
                            >
                            <span class="font-bold"
                                >50 Degrees North Group</span
                            >
                        </span>
                    </div>
                    <mat-divider class="my-2"></mat-divider>
                    <ng-container *ngIf="selectedBusiness$ | async; let office">
                        <button
                        [ngClass]="{
                            'bg-primary-500 text-on-primary-500':
                                option.businessUId === office.businessUId
                        }"
                        (click)="updateSelectedOffice(option)"
                        mat-menu-item
                        *ngFor="let option of userOfficeList$ | async"
                    >
                        <mat-icon
                            [ngClass]="{
                                'text-on-primary-500':
                                    option.businessUId === office.businessUId
                            }"
                            [svgIcon]="'mat_solid:location_city'"
                        ></mat-icon>
                        <span>{{ option.nickname ? option.nickname : option.name }} </span>
                    </button>
                    </ng-container>
                </mat-menu>
            </div>

            <user [user]="user$ | async" [showAvatar]="false"></user>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <!--<div class="relative flex items-center justify-start w-full px-4 border-t flex-0 h-14 md:px-6 z-49 bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">Fuse &copy; {{currentYear}}</span>
    </div>-->
</div>
