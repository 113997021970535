import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageLayoutModule } from './modules/page-layout/page-layout.module';
import { AgGridModule } from '@ag-grid-community/angular';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyFormUtilitiesModule } from './modules/formly-form-utilities/formly-form-utilities.module';
import { AgGridUtilitiesModule } from './modules/ag-grid-utilities/ag-grid-utilities.module';
import { GeneralUtilitiesModule } from './modules/general-utilities/general-utilities.module';
import { RouterAnimationModule } from './modules/router-animation/router-animation.module';
import { AppCurrencyPipe } from './pipes/currency.pipe';
import { HumanizePipe } from './pipes/humanize.pipe';
import { AppPluralPipe } from './pipes/plural.pipe';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { environment } from 'environments/environment';
import { ApiPathConfig } from './config/api-path.config';
import { DeleteConfirmationService } from './services/delete-confirmation/delete-confirmation.service';
@NgModule({
    providers: [
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue:
                { hasBackdrop: true, width: '40vw', minHeight: '20vh', }
        },
        {
            provide: 'SiglaApiPath',
            useValue: new ApiPathConfig(
                environment.api.siglaApi
            ),
        },

    ],
    declarations: [AppCurrencyPipe, AppPluralPipe, HumanizePipe],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PageLayoutModule,
        AgGridModule,
        FormlyFormUtilitiesModule,
        AgGridUtilitiesModule,
        GeneralUtilitiesModule,
        RouterAnimationModule,
        MatButtonModule,
        MatIconModule,
        RouterAnimationModule,
        ClipboardModule,

    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        GeneralUtilitiesModule,
        PageLayoutModule,
        FormlyModule,
        FormlyMaterialModule,
        FormlyFormUtilitiesModule,
        AgGridModule,
        AgGridUtilitiesModule,
        MatButtonModule,
        MatIconModule,
        RouterAnimationModule,
        AppCurrencyPipe, AppPluralPipe, HumanizePipe,
        ClipboardModule,
    ],
})
export class SharedModule { }
