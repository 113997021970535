import { ChangeDetectionStrategy, Component, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
    selector: 'app-currency-input',
    templateUrl: './formly-currency-input.component.html',
    styleUrls: ['./formly-currency-input.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class FormlyCurrencyInputComponent extends FieldType {
  @ViewChild('currencyInput') currencyInput: ElementRef | undefined;
  override formControl: any;
  private regex: RegExp = new RegExp(/^\d*\.?\d{0,2}$/g);
  private specialKeys: Array<string> = [
    'Backspace',
    'Tab',
    'End',
    'Home',
    '-',
    'ArrowLeft',
    'ArrowRight',
    'Del',
    'Delete',
  ];

  constructor() {
    super();
  }


  omitExtraDecimalPlaces(event: KeyboardEvent): void {
    //Check for decimal attribute from formly field configuration.
    if(this.field?.templateOptions?.attributes?.['decimal']) {
      this.regex = new RegExp(`^\\d*\\.?\\d{0,${this.field?.templateOptions?.attributes?.['decimal']}}$`, 'g');
    }
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    const current: string = this.currencyInput?.nativeElement.value;
    const position = this.currencyInput?.nativeElement.selectionStart;
    const next: string = [current.slice(0, position), event.key === 'Decimal' ? '.' : event.key, current.slice(position)].join('');
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }
}
