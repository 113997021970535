import { Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation, AfterViewInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { GroupValueFn } from '@ng-select/ng-select/lib/ng-select.component';
import { FieldType } from '@ngx-formly/core';
import { siglaLog } from 'app/shared/utility/logging-util';

@Component({
    selector: 'app-formly-existing-traveller-autocomplete',
    templateUrl: './formly-existing-traveller-autocomplete.component.html',
    styleUrls: ['../../styles/ng-select.scss', './formly-existing-traveller-autocomplete.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyExistingTravellerAutocompleteComponent extends FieldType implements OnInit {

    @ViewChild('ngSelect') ngSelect!: NgSelectComponent;
    travellers!: any[];
    override formControl: any;

      clearItem(travellerUId: string): void {
        const formControl: FormControl = this.formControl;
        const index = formControl.value.findIndex((item: any) => item === travellerUId);
        if (index > -1) {
            formControl.value.splice(index, 1);
        }
        this.formControl.patchValue(formControl.value);
    }

    ngOnInit(): void {
        this.travellers = this.to.options as [];
        this.formControl.valueChanges.subscribe((value: any) => siglaLog(value));
    }
}
