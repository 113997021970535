<button
    *ngIf="this.inlineEditActions?.onRowEditStart"
    mat-icon-button
    class="action-button edit"
    title="Edit"
    #editButton
    [disabled]="disabled || disableEdit"
    (click)="inlineEditTrigger.openMenu()"
>
    <mat-icon svgIcon="edit"></mat-icon>
</button>

<button
    *ngIf="this.inlineEditActions?.onRowCopy"
    mat-icon-button
    class="action-button copy"
    (click)="rowCopy()"
    [disabled]="editing"
>
    <mat-icon svgIcon="file_copy"></mat-icon>
</button>
<button
    *ngIf="this.inlineEditActions?.onRowDelete && this.showRemoveButton"
    mat-icon-button
    class="action-button delete"
    data-action="delete"
    (click)="rowDelete()"
    [disabled]="editing || disableDelete"    
>
    <mat-icon 
        class="text-warn-500"
        svgIcon="delete"
        [matTooltip]="deleteToolTip"
        [matTooltipDisabled]="!disableDelete"
    ></mat-icon>
</button>
<div
    #triggerElement
    #inlineEditTrigger="matMenuTrigger"
    class="fixed bottom-0 left-0 w-full h-full pointer-events-none -z-1"
    [class.bg-green-100]="editing"
    mat-icon-button
    [matMenuTriggerFor]="menu"
    (menuOpened)="rowEditStart(menuInner)"
    (menuClosed)="rowEditCancel()"
></div>
<mat-menu
    #menu="matMenu"
    [hasBackdrop]="false"
    [class]="'ag-grid-inline-edit-panel'"
>
    <div class="ag-grid-inline-edit-backdrop">
        <svg width="100vw" height="100vh">
            <rect
                class="knockout-text-bg"
                width="100%"
                height="100%"
                fill="rgba(127,127,127,.1)"
                x="0"
                y="0"
                fill-opacity="1"
                mask="url(#knockout)"
            />

            <mask id="knockout">
                <rect width="100vw" height="100vh" fill="#fff" x="0" y="0" />
                <rect
                    [style.width.px]="agGridRowBoundingRect?.width || 0"
                    [style.height.px]="agGridRowBoundingRect?.height || 0"
                    [style.x.px]="agGridRowBoundingRect?.x || 0"
                    [style.y.px]="agGridRowBoundingRect?.y || 0"
                />
            </mask>
        </svg>
    </div>
    <div
        #menuInner
        [style.width.px]="triggerElement.clientWidth + 32"
        [style.maxWidth.px]="triggerElement.clientWidth + 32"
        class="flex flex-row items-center h-full gap-2 px-4 pt-4 pointer-events-auto"
        (click)="$event.stopPropagation(); $event.preventDefault()"
        (keydown)="$event.stopPropagation()"
        [style]="this.getColumnWidthStyles()"
    >
        <ng-container *ngIf="editing">
            <ng-container
                *ngTemplateOutlet="
                    params?.template ||
                        params?.getTemplate(params?.templateName);
                    context: {
                        $implicit: {
                            rowNode: params,
                            inlineEditTrigger: inlineEditTrigger
                        }
                    }
                "
            ></ng-container>
        </ng-container>
    </div>
</mat-menu>
