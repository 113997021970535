<div class="tel-container">
    <input
        type="tel"
        class="tel"
        [required]="to.required"
        [formControl]="formControl"
        [formlyAttributes]="field"
        name="phone-link"
    />

    <a matSuffix [phoneLink]="formControl.value"> <mat-icon>phone</mat-icon></a>
</div>
