import { Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { siglaLog } from 'app/shared/utility/logging-util';
import { ContentBlock } from '../types/editor';

@Component({
  selector: 'app-row',
  templateUrl: './row.component.html',
  styleUrls: ['./row.component.scss']
})
export class RowComponent implements OnInit{
    @Input() block: ContentBlock | undefined;
    @Input() editable: boolean = true;
    @Output() ngModelChange = new EventEmitter();
    constructor() { }

    ngOnInit(): void {
        siglaLog(this.block);
    }

}

