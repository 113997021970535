<angular-editor
    *ngIf="block?.data"
    (input)="ngModelChange.emit(block)"
    [(ngModel)]="block?.data.text"
    [config]="config"
></angular-editor>
<angular-editor
    *ngIf="data"
    (input)="ngModelChange.emit(block)"
    [(ngModel)]="data.text"
    [config]="config"
></angular-editor>
