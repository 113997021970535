import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
    selector: 'app-formly-location-attribute-repeat',
    templateUrl: './formly-location-attribute-repeat.component.html',
    styleUrls: ['./formly-location-attribute-repeat.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class FormlyLocationAttributeRepeatComponent extends FieldArrayType implements OnInit {
    constructor(private cdRef: ChangeDetectorRef) {
        super();
    }
    ngOnInit(): void {
        this.field.className = 'repeat dense';
        this.field?.fieldGroup?.forEach((item, index) => {
            const useOptionList = this.field.model[index].useOptionList ? true: false;
            item.fieldGroup?.forEach((field)=> {
                if (useOptionList){
                    field.hide = !(field.key === 'optionList');
                }
            });
        });
    }
    addLocation(type: 'location' | 'choice'): void {
        if (this.field && this.field.fieldGroup) {
            if (type === 'location') {
                this.add();
            } else {
                this.add();
                const fieldGroup: any = this.field.fieldGroup[this.field.fieldGroup.length - 1].fieldGroup;
                if (fieldGroup) {
                    fieldGroup[2].hide = true;
                    fieldGroup[3].hide = true;
                    fieldGroup[4].hide = true;
                    fieldGroup[5].hide = true;
                    fieldGroup[6].hide = false;
                }
                this.field.model[this.field.model.length -1].useOptionList = true;
            }
            this.cdRef.detectChanges();
        }

    }
    override remove(index: number): void {
        super.remove(index);
        this.field.model.map((item: any, i: number) => (Object.assign(item, {seqNo : i+1})));
        if(this.field.model.length === 0) {
            this.add();
        }
        this.cdRef.detectChanges();
    }

    drop(event: CdkDragDrop<string[]>): void {
        if (this.field.fieldGroup) {
            moveItemInArray(this.field.fieldGroup, event.previousIndex, event.currentIndex);
            moveItemInArray(this.field.model, event.previousIndex, event.currentIndex);
            this.field.model.map((item: any, index: number) => (Object.assign(item, {seqNo : index+1})));
            this.cdRef.detectChanges();
        }
    }
}
