import { ICellRendererParams } from '@ag-grid-community/all-modules';
import { AgRendererComponent } from '@ag-grid-community/angular';
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-ag-grid-button-cell-renderer',
  templateUrl: './ag-grid-button-cell-renderer.component.html',
  styleUrls: ['./ag-grid-button-cell-renderer.component.scss'],
})
export class AgGridButtonCellRendererComponent  implements AgRendererComponent {
    public params: any | undefined;
    refresh(params: ICellRendererParams): boolean {
        return false;
    }
    agInit(params: any): void {
        this.params = params;
    }
}
