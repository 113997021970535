import { ICellEditorAngularComp } from '@ag-grid-community/angular';
import { AfterViewInit, Component, ViewEncapsulation } from '@angular/core';
import { siglaLog } from 'app/shared/utility/logging-util';

@Component({
    selector: 'app-ag-grid-checkbox',
    templateUrl: './ag-grid-checkbox.component.html',
    styleUrls: ['./ag-grid-checkbox.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AgGridCheckboxComponent
    implements ICellEditorAngularComp, AfterViewInit {
    params: any;
    disabled: boolean = false;
    constructor() { }
    agInit(params: any): void {
        this.params = params;
        this.disabled = params.data?.disabled ? params.data?.disabled : false;

    }

    ngAfterViewInit(): void { }

    getValue(): string {
        return this.params.value;
    }

    change(): void {
        siglaLog(this.params);
        if (this.params.eGridHeader) {
            this.params.api.forEachNode((node) => {
                if (!node.data.default) {
                    node.data.selected = this.params.value;
                }
                if (node.data?.childrenList?.length) {
                    node.data?.childrenList.forEach((child) => {
                        if (!child.default) {
                            child.selected = this.params.value;
                        }
                    });
                }
            });
            this.params.api.refreshCells();
        }
    }
}
