<mat-form-field>
    <mat-chip-list #chipList aria-label="Price Break selection">
        <mat-chip
            class="price-break-chip"
            *ngIf="params?.value?.length === 0 || !params?.value"
        >
            Open
        </mat-chip>
        <mat-chip class="price-break-chip" *ngFor="let price of params?.value">
            {{ price.name }}
        </mat-chip>
    </mat-chip-list>
</mat-form-field>
