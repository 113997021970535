import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { AbstractControl, Form, FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDatepickerInputEvent, MatEndDate } from '@angular/material/datepicker';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { siglaLog } from 'app/shared/utility/logging-util';
import { dateRangeRequiredValidator, noEndDateValidationMessage } from '../../validators/validators';

@Component({
    selector: 'app-formly-date-picker-switch',
    templateUrl: './formly-date-picker-switch.component.html',
    styleUrls: ['./formly-date-picker-switch.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormlyDatePickerSwitchComponent extends FieldType implements OnInit {

    override formControl: any;
    override options: any;
    isRange: boolean = true;
    focused: boolean = false;
    override defaultOptions = {
        className: 'date-picker-switch',
        templateOptions: {
            isRange: true,
            addonLeft: {
                icon: 'toggle_on',
                onClick: (to: any, wrapper: any): void => {
                    if (to) {
                        if (to['isRange']) {
                            to['isRange'] = false;
                            to['addonLeft'].icon = 'toggle_off';

                        } else {
                            to['isRange'] = true;
                            to['addonLeft'].icon = 'toggle_on';
                        }
                        this.toggleDatePicker.bind(this)(to, wrapper);
                    }
                },
            },
            datepickerOptions: {
                startView: 'month',
                fields: undefined,
                dateInput: (): void => { },
                dateChange: (): void => { },
                monthSelected: (): void => { },
                yearSelected: (): void => { },
            },
        },
    };
    // startDate: any;
    endDate!: FormControl;
    constructor() {
        super();
    }



    toggleDatePicker(to: any, wrapper: any): void {
        this.field = wrapper.field;
        //    this.field.formControl.setValue(null);
        this.form?.get(to['endDateKey'])?.setValue(null);
        this.model[this.to['endDateKey'] as string] = null;
        if (to['isRange']) {
            if (to['datepickerOptions'].endDateRequired) {
                this.form?.get(to['endDateKey'])?.setValidators(Validators.required);
            } else {
                this.form?.get(to['endDateKey'])?.setValidators(null);
            }
            this.model['type'] = 'Date Range';
        } else {
            this.form?.get(to['endDateKey'])?.setValidators(null);
            this.model['type'] = 'Date';
        }
        this.form?.get(to['endDateKey'])?.updateValueAndValidity();
        this.field.formControl?.updateValueAndValidity();
    }


    ngOnInit(): void {
        this.initStartEndDateFields();
        this.field.formControl?.setValidators([dateRangeRequiredValidator(this.field)]);
    }

    initStartEndDateFields(): void {
        this.form.addControl(this.to['endDateKey'], new FormControl());
        if (this.form.get(this.to['endDateKey'])) {
            this.endDate = this.form.get(this.to['endDateKey']) as FormControl;
            if (this.model[this.to['endDateKey'] as string]) {
                this.endDate.setValue(this.model[this.to['endDateKey'] as string]);
                this.model['type'] = 'Date Range';
            } else {
                this.to['isRange'] = false;
                this.model['type'] = 'Date';
                const options = { ...this.defaultOptions };
                options.templateOptions['isRange'] = false;
                this.defaultOptions = options;
                this.to['addonLeft'].icon = 'toggle_off';
            }
        }
    }

    fromDateChange($event: MatDatepickerInputEvent<Date>): void {
        this.to['datepickerOptions'].dateChange(this.field, $event);

    }



    toDateChange($event: MatDatepickerInputEvent<Date>): void {
        this.to['datepickerOptions'].dateChange(this.field, $event);
        this.model[this.to['endDateKey'] as string] = $event.value;
        this.form.get(this.to['endDateKey'])?.setValue($event.value);
        this.field.formControl?.updateValueAndValidity();
    }


    detectChanges(): void {
        if (this.options) {
            this.options.detectChanges?.(this.field);
        }
    }

    changeToggle(): void {
        this.isRange = !this.isRange;
        siglaLog(this.isRange);
    }

}



