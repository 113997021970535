import { Directive, HostListener, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

export interface AuxiliaryLink {
    l1?: Array<string>;
    l2?: Array<string>;
    l3?: Array<string>;
    l4?: Array<string>;
    l5?: Array<string>;
}
@Directive({
    selector: '[auxiliaryLink]',
})
export class AuxiliaryLinkDirective {
    @Input() auxiliaryLink: AuxiliaryLink | undefined;
    @Input() auxiliaryQueries: any;
    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
    ) { }
    @HostListener('click', ['$event.target'])
    private _onClick(): void {
        this.router.navigate([{ outlets: this.auxiliaryLink }], {
            relativeTo: this.activatedRoute.pathFromRoot[2],
            queryParams: this.auxiliaryQueries,
            queryParamsHandling: 'merge'
        });
    }
}
