<button
 *ngIf="!scrolledToStart && !(this.scrolledToStart && this.scrolledToEnd)"
 (click)="back()"
 mat-icon-button
 class="absolute z-20 self-center shadow-lg bg-opacity-80 bg-card left-2 max-w-40 max-h-40 backdrop-filter backdrop-blur-sm"
>
 <mat-icon class="rounded-full" [svgIcon]="'chevron_left'"></mat-icon>
</button>
<div class="relative flex-grow w-full h-24 bg-default scroll-container"
 [class.scrolled-to-start]="scrolledToStart"
 [class.scrolled-to-end]="scrolledToEnd"
>
<ng-content></ng-content>

</div>
<button
*ngIf="!scrolledToEnd && !(this.scrolledToStart && this.scrolledToEnd)"
    (click)="next()"
    mat-icon-button
    class="absolute z-20 self-center shadow-lg bg-opacity-80 bg-card right-2 max-w-40 max-h-40 backdrop-filter backdrop-blur-sm"
>
    <mat-icon class="rounded-full" [svgIcon]="'chevron_right'"></mat-icon>
</button>
