<div
    class="flex m-1 align-middle"
    *ngFor="let day of dayList"
    (click)="change(!controlValue.includes(day), day)"
>
    <mat-icon
        class="text-slate-900"
        class="align-middle"
        [svgIcon]="
            controlValue.includes(day)
                ? 'mat_solid:check_box'
                : 'mat_solid:check_box_outline_blank'
        "
    ></mat-icon>
    <span class="ml-1 leading-6 align-middle select-none"> {{ day }}</span>
</div>
