import { Inject, Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import {  take } from 'rxjs/operators';
import { User } from 'app/core/user/user.types';
import { ApiPathConfig } from 'app/shared/config/api-path.config';
import { PathUtil } from 'app/shared/utility/path-util';

import { RefDataService } from 'app/shared/services/ref-data/ref-data.service';
import { Business } from 'app/shared/models/commercial/commercial-models';

@Injectable({
    providedIn: 'root',
})
export class UserService  {
    private _user$: BehaviorSubject<User | undefined> = new BehaviorSubject<
        User | undefined
    >(undefined);
    private _selectedBusinessId$: BehaviorSubject<string | undefined> =
        new BehaviorSubject<string | undefined>(undefined);

    private _selectedBusiness$: BehaviorSubject<Business | undefined> =
        new BehaviorSubject<Business | undefined>(undefined);

    constructor(
        private _httpClient: HttpClient,
        @Inject('SiglaApiPath') private siglaApiPath: ApiPathConfig,
        private refDataService: RefDataService
    ) { }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    set user(value: User | undefined) {
        // Store the value
        this._user$.next(value);
    }

    get user$(): Observable<User | undefined> {
        return this._user$.asObservable();
    }

    set selectedBusinessId(value: string | undefined) {
        // Store the value
        this._selectedBusinessId$.next(value);
    }

    get selectedBusinessId$(): Observable<string | undefined> {
        return this._selectedBusinessId$.asObservable();
    }

    set selectedBusiness(value: Business) {
        this._selectedBusiness$.next(value);
    }

    get selectedBusiness$(): Observable<Business | undefined> {
        return this._selectedBusiness$.asObservable();
    }


    get userOfficeList$(): Observable<Business[]> {
        return this.refDataService.userOfficeList$;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    loadUserWithOffices(): void {
        let url = PathUtil.combinePaths(
            this.siglaApiPath.apiPath,
            'platform/user'
        );
        url = PathUtil.addParam(url, 'inclusionList=Businesses');

        this._httpClient
            .get<User>(url)
            .pipe(take(1))
            .subscribe((user: User) => {
                this._user$.next(user);
                if (user.businessUIdList) {
                    this.userOfficeList$.pipe(take(1)).subscribe((officeList) => {
                        this._selectedBusiness$.next(officeList[0]);
                    });
                }

            });
    }

}
