import { Component, EventEmitter, Input, Output, ViewChild, AfterViewInit, OnChanges, SimpleChanges, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { ImageUploaderOptions } from 'app/shared/models/common/image-uploader-models';
import { siglaLog } from 'app/shared/utility/logging-util';
import { environment } from 'environments/environment';

import { of } from 'rxjs';
import { delay, take } from 'rxjs/operators';
import { ContentBlock } from '../../types/editor';
import { ImageUploaderComponent } from '../image-uploader/image-uploader.component';


@Component({
    selector: 'app-image',
    templateUrl: './image.component.html',
    styleUrls: ['./image.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ImageComponent implements AfterViewInit, OnChanges {
    @ViewChild('uploader') uploader!: ImageUploaderComponent;
    @Input() data: any;
    @Input() block: ContentBlock | undefined;
    @Input() editable: boolean = true;
    @Output() ngModelChange = new EventEmitter();
    @Output() removeImage = new EventEmitter();
    @Input() imageOptions: ImageUploaderOptions = {
        uploadUrl: environment.api.siglaApi + '/common/upload/image',
        allowedImageTypes: ['image/jpeg', 'image/jpg', 'image/webp', 'image/png'],
        thumbnailResizeMode: 'fill',
        cropEnabled: false,
        autoUpload: true,
        resizeOnLoad: true,
        thumbnailWidth: 680,
        thumbnailHeight: 280,
        maxImageSize: .8,
    };
    error!: string;
    constructor(public cdRef: ChangeDetectorRef) { }

    ngOnChanges(changes: SimpleChanges): void {
        this.renderImage();
        console.log(changes);
    }
    ngAfterViewInit(): void {
        this.renderImage();
    }

    upload(event: any): void {
        of(true).pipe(delay(100), take(1)).subscribe(() => {
            const data = this.block?.data || this.data;
            siglaLog(data);
            if (data && event?.response?.body?.file?.url) {
                data.file.url = event?.response?.body?.file?.url;
                this.ngModelChange.emit();
            }
            if (event?.response?.errorType && event?.response?.message) {
                this.error = event?.response?.errorType + ' - ' + event?.response?.message;
            }
        });
    }

    remove(event: any): void {
        if (event.target.classList.contains('remove')) {
            const data = this.block?.data || this.data;
            if (data) {
                data.file.url = '';
                this.removeImage.emit();
                this.ngModelChange.emit();
                this.cdRef.detectChanges();
            }
        }
    }

    renderImage(): void {
        const data = this.block?.data || this.data;
        if (data && this.uploader) {
            if (data.file?.url) {
                if (data.file.url !== '') {
                    this.uploader.imageThumbnail = data.file.url;
                    this.cdRef.detectChanges();
                }
            }
        }
    }

}
