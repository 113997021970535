import { Injectable, OnDestroy } from '@angular/core';
import { siglaLog } from 'app/shared/utility/logging-util';
import { environment } from 'environments/environment';
import { merge } from 'lodash';
import { BehaviorSubject, combineLatest, Observable, Subject, throwError } from 'rxjs';
import { catchError, take, takeUntil, tap } from 'rxjs/operators';

/**
 * Ready service represents a finite state machine that accepts inputs which will adjust an internal state,
 * results from the inputs contributing to the state of the service
 * Supported states:
 * - Default. The state the ready service is in before being used
 * - Loading. When any processed action has a 'Waiting' result, the state engine will be set to Loading. Can be set from Default or Ready state
 * - Ready. When all processed actions have a 'Complete' result, the state engine will move to Ready state. Can be set from Default or Loading state, typically loading
 * - Error. If an error occurs at all during loading then the state engine is set to 'Error' state. This can only be reverted via a reset to 'Default' state
 */

@Injectable({
    providedIn: 'any'
})
export class ReadyStateService implements OnDestroy {

    name?: string;

    private readonly _state$ = new BehaviorSubject<ReadyState>({ name: 'Default' });

    private _processingList: IProcessing[] = [];

    private readonly _destroying$ = new Subject<void>();

    constructor() { }

    ngOnDestroy(): void {
        this._destroying$.next(undefined);
        this._destroying$.complete();
    }


    /**
     * Accessors
     */

    get state$(): Observable<ReadyState> {
        return this._state$.asObservable();
    }


    /**
     * State Events
     */

    // Loading. An input that accepts a list of actions that all must successfully complete for the ready state to be set true
    // IMPORTANT. It is critical to understand variable scope to understand how this method works. SomeActions task is linked
    // to someProcessing because when the subscription was created both variables were scoped to the contained function. This
    // is how results for tasks can be correctly applied to the correct processing object

    addLoading(name: string, task: Observable<any[] | any>): Observable<any[] | any> {

        // Cannot move state from 'Error' without reset

        if (this._state$.value && this._state$.value.name === 'Error') {
            return task;
        }

        this._state$.next({ name: 'Loading' });

        // Create and cache processing job

        const processing: Processing = { name: name, task: task, result: 'Waiting' };
        this._processingList.push(processing);

        return task.pipe(
            takeUntil(this._destroying$),
            tap((response: any) => {

                // Only process non-empty results. Behavior subjects will often return an undefined result for object observables
                // Other undefined observables will generally be sent to error handling
                if (!response) {
                    return;
                }

                // if (Array.isArray(response)) {
                //     processing.result = (response as []).length > 0 ? 'Complete' : processing.result;
                // }
                // else {
                //     processing.result = 'Complete';
                // }
                processing.result = 'Complete';
                this.updateState();

            }),
            catchError((error) => {

                processing.result = 'Error';
                processing.resultDetail = error;

                this.updateState();

                throw error;

            })
        );

    }


    // Action. Ready state should switch to loading for actions that occur in a state machine. This action method
    // allows for registration and monitoring of such actions
    /*
        addAction(name: string, task: Observable<any[] | any>): Observable<any[] | any> {
    
            // Cannot move state from 'Error' without reset
    
            if (this._state$.value && this._state$.value.name === 'Error') {
                return task;
            }
    
            this._state$.next({ name: 'Loading' });
    
            // Create and cache processing job
    
            const processing: Processing = { name: name, task: task, result: 'Waiting' };
            this._processingList.push(processing);
    
            return task.pipe(
                takeUntil(this._destroying$),
                tap((response: any) => {
    
                    // Any result on an action is considered a completion
                    processing.result = 'Complete';
    
                    this.updateState();
    
                }),
                catchError((error) => {
    
                    processing.result = 'Error';
                    processing.resultDetail = error;
    
                    this.updateState();
    
                    throw error;
    
                })
            );
    
        }
     */

    // Reset. The only way to move from the error state is to reset the state engine

    reset(): void {

        this._processingList = [];
        this._state$.next({ name: 'Default' });

    }


    /**
     * State Checking
     */

    private updateState(): void {

        const currentState = this._state$.value;

        if (currentState.name !== 'Error') { // State cannot be updated when in error, only reset clears this

            // Error
            if (this._processingList.filter((item: IProcessing) => item.result === 'Error').length > 0) {
                const errorDetailList: ReadyError[] =
                    this._processingList.filter((item) => item.result === 'Error').map((item: IProcessing) =>
                        ({ actionName: item.name, errorDetail: item.resultDetail } as ReadyError)
                    );
                const errorState: ReadyErrorState = { name: 'Error', errorList: errorDetailList };
                this._state$.next(errorState);
            } // Still loading
            else if (this._processingList.filter((item: IProcessing) => item.result === 'Waiting').length > 0) {
                this._state$.next({ name: 'Loading' });
            } // Complete
            else {
                this._state$.next({ name: 'Ready' });
            }

        }

        this.logResult(this._state$.value, this._processingList);

    }


    /**
     * Logging
     */

    private logResult(state: ReadyState, processingList: IProcessing[]): void {

        if (!environment.production) {
            const name = this.name ? '<' + this.name + '> \n' : '';
            const outputList = processingList.map((processing) =>
                ({ name: processing.name, result: processing.result, resultDetail: processing.resultDetail } as Record<any, any>)
            );
            if (state.name === 'Ready') {
                siglaLog(name + 'All tasks complete. State = Ready', outputList);
            }
            else if (state.name === 'Loading') {
                siglaLog(name + 'Task(s) not started or partially complete. State = Waiting', outputList);
            }
            else if (state.name === 'Error') {
                siglaLog(name + 'Processing error found. State = Error', outputList);
            }
        }

    }




}


// State Models

export class ReadyState {
    name!: 'Default' | 'Loading' | 'Ready' | 'Error';
}

export class ReadyErrorState extends ReadyState {
    errorList: ReadyError[] = [];
}

export class ReadyError {
    actionName!: string;
    errorDetail?: any;
}


// Processing Models

export interface IProcessing {
    name: string;
    result: 'Waiting' | 'Complete' | 'Error';
    resultDetail?: any;
}

export class Processing implements IProcessing {
    name!: string;
    task!: Observable<any[] | any>;
    result!: 'Waiting' | 'Complete' | 'Error';
    resultDetail?: any;
}
