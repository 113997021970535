<ng-select
    *ngIf="to.options | formlySelectOptions: field | async"
    [items]="to.options | formlySelectOptions: field | async"
    [bindValue]="to['bindValue'] || 'value'"
    [formControl]="formControl"
    [groupBy]="to['groupBy']"
    [class.is-invalid]="showError"
    [clearable]="true"
    [multiple]="true"
    [closeOnSelect]="false"
    [hideSelected]="true"
    [searchable]="true"
>
</ng-select>
