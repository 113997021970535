import { Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-checkbox',
  templateUrl: './formly-checkbox.component.html',
  styleUrls: ['./formly-checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class FormlyCheckboxComponent extends FieldType {
  override formControl!: FormControl;

  get checkBoxValue(): boolean {
    return this.formControl.value;
  }

  toggle(value: boolean): void {
    if (value) {
      this.model[this.key as string] = false;
      this.formControl.setValue(false);
    } else {
      this.model[this.key as string] = true;
      this.formControl.setValue(true);
    }
  }



}
