import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';
import { ErrorSnackBarComponent } from './error-snack-bar/error-snack-bar-component';
@Injectable()
export class NotificationService {


    constructor(private snackBar: MatSnackBar) {}

    error(message: string, close?: boolean, duration?: number): MatSnackBarRef<TextOnlySnackBar> {
        return this.snackBar.open(message, close ? 'close' : '', {
            panelClass: ['snackbar-error'],
            duration: duration,
        });
    }
    httpError(title: string, error: string, message: string, close?: boolean, duration?: number): MatSnackBarRef<ErrorSnackBarComponent> {
          return this.snackBar.openFromComponent(ErrorSnackBarComponent, {
              panelClass: ['snackbar-error'],
              duration: duration,
              data: {
                title: title,
                error: error,
                message: message
              }
            });
      }

    success(message: string, close?: boolean, duration?: number): MatSnackBarRef<TextOnlySnackBar> {
        return this.snackBar.open(message, close ? 'close' : '', {
            panelClass: ['snackbar-success'],
            duration: duration,
        });
    }

    info(message: string, close?: boolean, duration?: number): MatSnackBarRef<TextOnlySnackBar> {
        return this.snackBar.open(message, close ? 'close' : '', {
            panelClass: ['snackbar-info'],
            duration: duration,
        });
    }
}
