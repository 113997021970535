import { AfterViewInit, Component, ViewEncapsulation } from '@angular/core';
import { ICellEditorAngularComp } from '@ag-grid-community/angular';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-ag-grid-select',
    templateUrl: './ag-grid-select.component.html',
    styleUrls: ['./ag-grid-select.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AgGridSelectComponent
    implements ICellEditorAngularComp, AfterViewInit {
    params: any;
    selected: any;
    options: any;
    options$!: Observable<any>;

    agInit(params: any): void {
        this.params = params;
        this.options$ = this.params?.colDef?.cellEditorParams?.options$;
        this.options = this.params?.colDef?.cellEditorParams?.options;
        if (this.params.value) {
            this.selected = this.params.value;
        }
    }

    // dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
    ngAfterViewInit(): void { }

    valueChange(e: string): void {
        this.params.node.setDataValue(this.params.colDef, e);
    }

    getValue(): string {
        return this.selected;
    }
}
