import { FuseConfirmationConfig } from '@fuse/services/confirmation';

export const confirmationConfig = (): FuseConfirmationConfig => ({
    'title': 'Remove Traveller',
    'message': 'Are you sure you want to remove this traveller permanently? <span class="font-medium">This action cannot be undone!</span>',
    'icon': {
      'show': true,
      'name': 'heroicons_outline:exclamation',
      'color': 'warn'
    },
    'actions': {
      'confirm': {
        'show': true,
        'label': 'Remove',
        'color': 'warn'
      },
      'cancel': {
        'show': true,
        'label': 'Cancel'
      }
    },
    'dismissible': true
  });
