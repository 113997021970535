import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterAnimationComponent } from './router-animation.component';



@NgModule({
  declarations: [RouterAnimationComponent],
  imports: [
    CommonModule
  ],
  exports: [
    RouterAnimationComponent
  ]
})
export class RouterAnimationModule { }
