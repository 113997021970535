import { ICellRendererParams } from '@ag-grid-community/all-modules';
import { AgRendererComponent } from '@ag-grid-community/angular';
import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'ag-grid-row-copy',
  template: `<button *ngIf="!params?.node?.expanded" mat-icon-button>
  <mat-icon svgIcon="file_copy"></mat-icon>
</button>`,
encapsulation: ViewEncapsulation.None,
styles: [
   `
       ag-grid-row-copy {
           width: 40px;
           justify-content: center;
           display: flex;
       }
   `,
],

})
export class AgGridRowCopyRendererComponent implements AgRendererComponent {
    public params: ICellRendererParams | undefined;
    refresh(params: ICellRendererParams): boolean {
        return false;
    }
    agInit(params: ICellRendererParams): void {
        this.params = params;
    }
}
