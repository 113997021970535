<mat-form-field>
    <mat-select
        [(ngModel)]="selected"
        (valueChange)="valueChange($event)"
        panelClass="ag-custom-component-popup"
    >
        <mat-select-trigger>
            {{ selected }}
        </mat-select-trigger>
        <mat-option
            *ngFor="let option of (options$ | async) || options"
            [value]="option.value"
        >
            {{ option.value }}
        </mat-option>
    </mat-select>
</mat-form-field>
