<!-- Classy -->
<classy-layout *ngIf="layout === 'classy'"></classy-layout>

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Settings drawer - Remove this to remove the drawer and its button -->
<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- <div
    class="fixed right-0 flex items-center justify-center w-10 h-10 bg-red-600 rounded-l-lg shadow-lg cursor-pointer z-999 bg-opacity-90 print:hidden"
    style="top: 275px"
    (click)="settingsDrawer.toggle()"
>
    <mat-icon
        class="text-white icon-size-5 animate-spin-slow"
        [svgIcon]="'heroicons_solid:cog'"
    ></mat-icon>
</div>

<fuse-drawer
    class="w-screen min-w-screen sm:w-100 sm:min-w-100"
    fixed
    transparentOverlay
    [mode]="'over'"
    [name]="'settingsDrawer'"
    [position]="'right'"
    #settingsDrawer
>
    <div class="flex flex-col w-full overflow-auto bg-card">
        <div
            class="flex flex-row items-center h-20 px-6 text-white min-h-20 bg-primary"
        >
            <mat-icon
                class="text-current icon-size-7"
                [svgIcon]="'heroicons_solid:cog'"
            ></mat-icon>
            <div class="ml-3 text-2xl font-semibold tracking-tight">
                Settings
            </div>
        </div>

        <div class="flex flex-col p-6">
            <!-- Theme
            <div class="font-semibold text-md text-secondary">THEME</div>
            <div class="grid grid-cols-2 gap-3 mt-6 sm:grid-cols-3">
                <ng-container *ngFor="let theme of themes">
                    <div
                        class="flex items-center justify-center px-4 py-3 rounded-full cursor-pointer ring-inset ring-primary bg-hover"
                        [class.ring-2]="config?.theme === theme[0]"
                        (click)="setTheme(theme[0])"
                    >
                        <div
                            class="w-3 h-3 rounded-full flex-0"
                            [style.background-color]="theme[1].primary"
                        ></div>
                        <div
                            class="ml-2.5 font-medium leading-5 truncate"
                            [class.text-secondary]="config?.theme !== theme[0]"
                        >
                            {{ theme[0] | titlecase }}
                        </div>
                    </div>
                </ng-container>
            </div>

            <hr class="my-8" />

            <!-- Scheme
            <div class="font-semibold text-md text-secondary">SCHEME</div>
            <div class="grid grid-cols-3 gap-3 mt-6 justify-items-start">
                <!-- Auto
                <div
                    class="flex items-center py-3 pl-5 pr-6 rounded-full cursor-pointer ring-inset ring-primary bg-hover"
                    [class.ring-2]="config?.scheme === 'auto'"
                    [matTooltip]="
                        'Automatically sets the scheme based on user\'s operating system\'s color scheme preference using \'prefer-color-scheme\' media query.'
                    "
                    (click)="setScheme('auto')"
                >
                    <div class="flex items-center overflow-hidden rounded-full">
                        <mat-icon
                            class="icon-size-5"
                            [svgIcon]="'heroicons_solid:lightning-bolt'"
                        ></mat-icon>
                    </div>
                    <div
                        class="flex items-center ml-2 font-medium leading-5"
                        [class.text-secondary]="config?.scheme !== 'auto'"
                    >
                        Auto
                    </div>
                </div>
                <!-- Dark
                <div
                    class="flex items-center py-3 pl-5 pr-6 rounded-full cursor-pointer ring-inset ring-primary bg-hover"
                    [class.ring-2]="config?.scheme === 'dark'"
                    (click)="setScheme('dark')"
                >
                    <div class="flex items-center overflow-hidden rounded-full">
                        <mat-icon
                            class="icon-size-5"
                            [svgIcon]="'heroicons_solid:moon'"
                        ></mat-icon>
                    </div>
                    <div
                        class="flex items-center ml-2 font-medium leading-5"
                        [class.text-secondary]="config?.scheme !== 'dark'"
                    >
                        Dark
                    </div>
                </div>
                <!-- Light
                <div
                    class="flex items-center py-3 pl-5 pr-6 rounded-full cursor-pointer ring-inset ring-primary bg-hover"
                    [class.ring-2]="config?.scheme === 'light'"
                    (click)="setScheme('light')"
                >
                    <div class="flex items-center overflow-hidden rounded-full">
                        <mat-icon
                            class="icon-size-5"
                            [svgIcon]="'heroicons_solid:sun'"
                        ></mat-icon>
                    </div>
                    <div
                        class="flex items-center ml-2 font-medium leading-5"
                        [class.text-secondary]="config?.scheme !== 'light'"
                    >
                        Light
                    </div>
                </div>
            </div>

            <hr class="my-8" />
        </div>
    </div>
</fuse-drawer> -->
