<mat-form-field>
    <input
        matInput
        [matDatepicker]="picker"
        [(ngModel)]="inputValue"
        [min]="minDate"
        [max]="maxDate"
        (ngModelChange)="updateDateRange()"
    />
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker
        #picker
        panelClass="ag-custom-component-popup"
    ></mat-datepicker>
</mat-form-field>
