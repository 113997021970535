import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'humanize',
})
export class HumanizePipe implements PipeTransform {
  transform(value: string): string {
    return this.humanize(value);
  }

  humanize(str: string): string {
    str = str || '';
    str = str.toString(); // might be a number
    str = str.trim();
    str = str.replace(this.extname(str), '');
    str = this.underscore(str);
    str = str.replace(/[\W_]+/g, ' ');

    return this.capitalize(str);
  }

  underscore(str: string): string {
    str = str || '';
    str = str.toString(); // might be a number
    str = str.trim();
    str = str.replace(/([a-z\d])([A-Z]+)/g, '$1_$2');
    str = str.replace(/[-\s]+/g, '_').toLowerCase();

    return str;
  }

  extname(str: string): string {
    const index = str.lastIndexOf('.');
    const ext = str.substr(index, str.length);

    return index === -1 ? '' : ext;
  }

  capitalize(str: string): string {
    str = str || '';
    str = str.trim();

    if (str[0]) {
      str = str[0].toUpperCase() + str.substr(1).toLowerCase();
    }

    return str;
  }
}
