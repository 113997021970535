<mat-chip-list #chipList aria-label="Price Break selection">
    <mat-chip
        class="price-break-chip"
        *ngFor="let price of priceBreakItems"
        (removed)="remove(price)"
    >
        <button
            *ngIf="priceBreakItems.length > 1"
            matChipRemove
            class="mb-3 mr-2 text-white"
        >
            <mat-icon class="text-white">&times;</mat-icon>
        </button>
        {{ price.name }}
    </mat-chip>
    <mat-icon
        class="icon-size-5"
        #priceBreakMenuTrigger="matMenuTrigger"
        [matMenuTriggerFor]="priceBreakMenu"
        [svgIcon]="'add'"
    ></mat-icon>
</mat-chip-list>
<mat-menu class="price-break-menu" #priceBreakMenu="matMenu">
    <div
        class="flex flex-wrap p-4"
        (click)="$event.stopPropagation(); $event.preventDefault()"
    >
        <h2 class="flex w-full">{{ priceByLabel }} Breaks</h2>
        <div class="flex justify-between w-full mt-3 price-break-fields">
            <mat-form-field>
                <mat-label>Min {{ priceByLabel + "s" }}</mat-label>
                <input
                    *ngIf="minPriceByChecked"
                    matInput
                    name="minPriceBy"
                    [value]="'Open'"
                    [disabled]="true"
                    type="text"
                />

                <input
                    *ngIf="!minPriceByChecked"
                    matInput
                    name="minPriceBy"
                    [(ngModel)]="minPriceBy"
                    (ngModelChange)="checkValidation()"
                    type="number"
                    [min]="1"
                />
                <mat-icon
                    matSuffix
                    class="icon-size-6"
                    (click)="checkMinBy()"
                    [svgIcon]="
                        minPriceByChecked
                            ? 'mat_solid:check_box'
                            : 'mat_solid:check_box_outline_blank'
                    "
                ></mat-icon>
            </mat-form-field>
            <mat-form-field fxFlex="100">
                <mat-label>Max {{ priceByLabel + "s" }}</mat-label>

                <input
                    *ngIf="maxPriceByChecked"
                    matInput
                    name="maxPriceBy"
                    [value]="'Open'"
                    [disabled]="true"
                    type="text"
                />

                <input
                    *ngIf="!maxPriceByChecked"
                    matInput
                    name="maxPriceBy"
                    [(ngModel)]="maxPriceBy"
                    (ngModelChange)="checkValidation()"
                    type="number"
                    [min]="minPriceBy || 1"
                />

                <mat-icon
                    matSuffix
                    (click)="checkMaxBy()"
                    class="icon-size-6"
                    [svgIcon]="
                        maxPriceByChecked
                            ? 'mat_solid:check_box'
                            : 'mat_solid:check_box_outline_blank'
                    "
                ></mat-icon>
                <mat-error *ngIf="maxPriceBy < minPriceBy"
                    >Value Should be greater than {{ minPriceBy }}</mat-error
                >
            </mat-form-field>
        </div>
        <p *ngIf="hasError" class="text-sm text-red-700">
            Price Breaks cannot overlap
        </p>
        <div class="flex justify-end w-full">
            <button
                type="button"
                class="mr-2"
                color="warn"
                (click)="close()"
                mat-stroked-button
            >
                Cancel
            </button>
            <button
                (click)="submit()"
                type="submit"
                color="primary"
                mat-raised-button
            >
                Add
                <mat-icon class="icon-size-5" [svgIcon]="'add'"></mat-icon>
            </button>
        </div>
    </div>
</mat-menu>
