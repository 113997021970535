<div class="image-container">
    <div class="match-parent" [ngSwitch]="status">
        <div class="match-parent" *ngSwitchCase="statusEnum.NotSelected">
            <button
                type="button"
                class="add-image-btn"
                (click)="onImageClicked()"
            >
                <div>
                    <p class="plus">+</p>
                    <p>Click here to add image</p>
                    <p>Or drop image here</p>
                </div>
            </button>
        </div>

        <div
            class="selected-status-wrapper match-parent"
            *ngSwitchCase="statusEnum.Loaded"
        >
            <img [src]="imageThumbnail" #imageElement />

            <button type="button" class="remove" (click)="removeImage()">
                ×
            </button>
        </div>

        <div
            class="selected-status-wrapper match-parent"
            *ngSwitchCase="statusEnum.Selected"
        >
            <img [src]="imageThumbnail" #imageElement />

            <button type="button" class="remove" (click)="removeImage()">
                ×
            </button>
        </div>

        <div *ngSwitchCase="statusEnum.Uploading">
            <img
                [attr.src]="imageThumbnail ? imageThumbnail : null"
                (click)="onImageClicked()"
            />

            <div class="progress-bar">
                <div class="bar" [style.width]="progress + '%'"></div>
            </div>
        </div>

        <div class="match-parent" *ngSwitchCase="statusEnum.Loading">
            <div class="sk-fading-circle">
                <div class="sk-circle1 sk-circle"></div>
                <div class="sk-circle2 sk-circle"></div>
                <div class="sk-circle3 sk-circle"></div>
                <div class="sk-circle4 sk-circle"></div>
                <div class="sk-circle5 sk-circle"></div>
                <div class="sk-circle6 sk-circle"></div>
                <div class="sk-circle7 sk-circle"></div>
                <div class="sk-circle8 sk-circle"></div>
                <div class="sk-circle9 sk-circle"></div>
                <div class="sk-circle10 sk-circle"></div>
                <div class="sk-circle11 sk-circle"></div>
                <div class="sk-circle12 sk-circle"></div>
            </div>
        </div>

        <div class="match-parent" *ngSwitchCase="statusEnum.Error">
            <div class="error" (click)="dismissError()">
                <div class="error-message">
                    <p>{{ errorMessage }}</p>
                </div>
                <button type="button" class="remove">×</button>
            </div>
        </div>
    </div>

    <input type="file" #fileInput (change)="onFileChanged()" />
    <div class="drag-overlay" [hidden]="true" #dragOverlay></div>
</div>
