import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    ContentChild,
    ViewEncapsulation,
    Input,
    ElementRef,
    ViewChild,
    TemplateRef,
    AfterContentInit,
    OnDestroy,
    Renderer2,
    Output,
    EventEmitter,
} from '@angular/core';
import { AgGridAngular } from '@ag-grid-community/angular';
import { AllEnterpriseModules } from '@ag-grid-enterprise/all-modules';
import { AllCommunityModules, ColDef, ColGroupDef } from '@ag-grid-community/all-modules';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Subscription } from 'rxjs';
import { DetailTemplateData } from '../../interfaces/detail-template-data';
import { AgGridTemplateRendererComponent } from '../ag-grid-template-renderer/ag-grid-template-renderer.component';
import { AuxiliaryLink } from '../../../general-utilities/directives/auxiliary-link/auxiliary-link.directive';
import { AgGridNewTabCellRendererComponent } from '../ag-grid-new-tab-cell-renderer/ag-grid-new-tab-cell-renderer.component';
import { AgGridBooleanCellRendererComponent } from '../ag-grid-bool-cell-renderer/ag-grid-bool-cell-renderer.component';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-ag-grid-wrapper',
    templateUrl: './ag-grid-wrapper.component.html',
    styleUrls: ['./ag-grid-wrapper.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AgGridWrapperComponent implements OnInit, AfterContentInit, OnDestroy {
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('visible') set tableVisible(value: boolean) {
        this.visible = value;
        this.setVisibilityStatus(value);
    }
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('loading') set setLoading(value: boolean) {
        this.loading(value);
    }
    @Input() heading: string = 'heading';
    @Input() subHeading: string | undefined;
    @Input() addButtonLabel: string = 'Add Item';
    @Input() addButtonLink: AuxiliaryLink | undefined;
    @Input() auxiliaryQueries: any | undefined;
    @Input() masterDetailTemplate: TemplateRef<any> | undefined;
    @Input() actionTemplate: TemplateRef<any> | undefined;
    @Input() addTemplate: TemplateRef<any> | undefined;
    @Input() inlineAdd: boolean = false;
    @Output() inlineAddAction = new EventEmitter();
    @Input() autoHeight: boolean = false;
    @Input() detailFixedHeight: number | undefined;
    @Input() set disableAddButton(value: boolean) {
        this.disableAdd = value;
    }
    @Input() isAddAvailable = true;
    @Input() isAgGridActions: boolean = false;
    @Input() isAgGridAdd: boolean = true;
    @Input() agGridSaveLabel = 'Save';
    @Input() agGridCancelLabel = 'Cancel';
    @Output() agGridSubmit = new EventEmitter();
    @Output() agGridCancel = new EventEmitter();
    @Output() agGridAdd = new EventEmitter();
    @ContentChild(AgGridAngular) agGrid: AgGridAngular | undefined;
    @ViewChild('gridWrapper') gridWrapperElement: ElementRef | undefined;
    disableAdd: boolean = false;
    visible: boolean | undefined;
    agGridSubscriptions: Subscription[] = [];
    headerTemplate = `<div class="ag-cell-label-container" role="presentation" headerTooltip="tooltip">
      <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
      <div ref="eLabel" class="ag-header-cell-label label-pinned" role="presentation">
        <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>
      <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>
       <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>
       <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>
       <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
       <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
      </div>
    </div>`;
    constructor(private bottomSheet: MatBottomSheet, private elRef: ElementRef, private renderer: Renderer2) { }

    get showAddButton(): boolean {
        return this.isAddAvailable &&
            ((this.masterDetailTemplate || this.addTemplate) && !this.addButtonLink && !this.actionTemplate) ? true : false;
    }
    ngOnInit(): void { }

    ngAfterContentInit(): void {
        this.gridInit();
    }

    ngOnDestroy(): void {
        this.agGridSubscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    gridInit(): void {
        if (this.agGrid) {
            this.renderer.addClass(this.elRef.nativeElement, 'loading');
            this.agGrid.modules = [...AllEnterpriseModules, ...AllCommunityModules];
            this.configureMasterDetail();
            this.configureDefaultColDef();
            this.configureNewTabRenderer();
            this.configureBooleanRenderer();

            if (this.autoHeight) {
                this.agGrid.gridOptions.domLayout = 'autoHeight';
                this.renderer.addClass(this.elRef.nativeElement, 'auto-height');
            } else {
                this.agGrid.gridOptions.domLayout = 'normal';
            }
            const readySubscription = this.agGrid.gridReady.subscribe((event) => {
                if (this.visible === undefined) {
                    this.renderer.addClass(this.elRef.nativeElement, 'visible');
                }
                const sizeChangedSubscription = this.agGrid?.gridSizeChanged?.subscribe(() => this.configureGrid());
                const dataChangedSubscription = this.agGrid?.rowDataChanged?.subscribe(() => this.configureGrid());

                if (sizeChangedSubscription) {
                    this.agGridSubscriptions.push(sizeChangedSubscription);
                }
                if (dataChangedSubscription) {
                    this.agGridSubscriptions.push(dataChangedSubscription);
                }
            });


            this.agGridSubscriptions.push(readySubscription);
        }
    }

    configureMasterDetail(): void {
        if (this.agGrid && this.masterDetailTemplate) {
            this.agGrid.gridOptions.masterDetail = true;
            this.agGrid.gridOptions.detailCellRendererFramework = AgGridTemplateRendererComponent;
            this.agGrid.gridOptions.detailCellRendererParams = {
                template: this.masterDetailTemplate,
                agGrid: this.agGrid,
            };
            this.agGrid.gridOptions.detailRowAutoHeight = true;
        } else if (this.agGrid) {
            this.agGrid.gridOptions.masterDetail = false;
        }
    }

    configureDefaultColDef(): void {
        if (this.agGrid) {
            this.agGrid.gridOptions.defaultColDef = {
                sortable: true,
                wrapText: true,
                resizable: true
            };
        }
    }

    configureNewTabRenderer(): void {
        if (this.agGrid) {
            if (this.agGrid.frameworkComponents === undefined) {
                this.agGrid.frameworkComponents = {};
            }
            this.agGrid.frameworkComponents.openInNewTabRenderer = AgGridNewTabCellRendererComponent;
        }

    }

    configureBooleanRenderer(): void {
        if (this.agGrid) {
            if (this.agGrid.frameworkComponents === undefined) {
                this.agGrid.frameworkComponents = {};
            }
            this.agGrid.frameworkComponents.boolCellRenderer = AgGridBooleanCellRendererComponent;
        }

    }



    configureGrid(): void {
        if (this.agGrid) {
            const columnDefs: (ColDef | ColGroupDef)[] | null | undefined = this.agGrid.columnDefs;
            if (columnDefs) {
                columnDefs?.forEach((colDef: ColDef, index) => {
                    colDef.minWidth = 100;
                    colDef.flex = 1;

                    if (colDef?.headerTooltip) {
                        const headerTooltip = colDef?.headerTooltip;
                        let headerTemplate = this.headerTemplate;
                        headerTemplate = headerTemplate.replace('headerTooltip="tooltip"', 'headerTooltip="' + headerTooltip + '"');
                        colDef.headerComponentParams = {};
                        colDef.headerComponentParams.template = headerTemplate;
                    }


                    if (colDef?.cellRenderer === 'actionRenderer') {
                        colDef.minWidth = 130;
                        colDef.maxWidth = 130;
                        colDef.cellClass = 'flex-nowrap';
                    }

                    if (colDef?.cellRenderer === 'agGroupCellRenderer') {
                        colDef.wrapText = false;
                    }
                    if (colDef?.cellRenderer === 'deleteRowRenderer' || colDef?.cellRenderer === 'copyRowRenderer') {
                        colDef.wrapText = false;
                        colDef.minWidth = 56;
                        colDef.maxWidth = 56;
                    }
                    if (colDef?.headerName?.toLowerCase()?.includes('name')) {
                        colDef.minWidth = 200;
                        colDef.flex = 4;
                    }
                    if (colDef?.headerName?.toLowerCase()?.includes('address')) {
                        colDef.minWidth = 200;
                        colDef.flex = 4;
                    }
                    if (colDef?.headerName?.toLowerCase()?.includes('email')) {
                        colDef.cellClass = 'break-all';
                    }
                });
                this.agGrid.api.setColumnDefs(columnDefs);
                this.agGrid.api.sizeColumnsToFit();
                if (!this.visible) {
                    this.dataLoaded();
                }

                this.agGrid.rowDataUpdated.pipe(take(1)).subscribe(() => {
                    this.dataLoaded();
                });
                this.agGrid.rowDataChanged.pipe(take(1)).subscribe(() => {
                    this.dataLoaded();
                });
                if (this.agGrid.rowData) { // Data may have been loaded prior to ag grid initialization completing. If data is not loaded rowdata will be undefined
                    this.dataLoaded();
                }

            }

        }
    }

    addItemBottomSheet(): void {
        const data: DetailTemplateData = {
            newItem: true,
            bottomSheet: this.bottomSheet,
        };
        if (this.masterDetailTemplate) {
            this.bottomSheet.open(this.masterDetailTemplate,
                { data: data, closeOnNavigation: true, backdropClass: 'add-item-backdrop', panelClass: 'add-item-bottom-sheet' });

        } else if (this.addTemplate) {
            this.bottomSheet.open(this.addTemplate,
                { data: data, closeOnNavigation: true, backdropClass: 'add-item-backdrop', panelClass: 'add-item-bottom-sheet' });

        }
    }

    dataLoaded(): void {
        this.renderer.removeClass(this.elRef.nativeElement, 'loading');
    }

    loading(status: boolean): void {
        if (status) {
            this.renderer.addClass(this.elRef.nativeElement, 'loading');
        } else {
            this.renderer.removeClass(this.elRef.nativeElement, 'loading');
        }
    }

    setVisibilityStatus(status: boolean): void {
        if (status) {
            this.renderer.addClass(this.elRef.nativeElement, 'visible');
        } else {
            this.renderer.removeClass(this.elRef.nativeElement, 'visible');
        }
    }
}
