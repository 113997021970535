import { FormlyFieldConfig, FormlyTemplateOptions } from '@ngx-formly/core';
import { FormlyAddressDialogComponent } from '../formly-address-dialog.component';
export const fields = (component: FormlyAddressDialogComponent): FormlyFieldConfig[] => [
    {
        key: 'addressLine',
        type: 'textarea',
        templateOptions: {
            label: 'Address Line',
            maxLength: 300
        },
    },
    {
        key: 'town',
        type: 'input',
        templateOptions: {
            label: 'Suburb / City',
            maxLength: 50
        },
    },
    {
        key: 'state',
        type: 'input',
        templateOptions: {
            label: 'State',
            maxLength: 50
        },
    },
    {
        key: 'postalCode',
        type: 'input',
        templateOptions: {
            label: 'Postcode',
            maxLength: 20
        },
    },
    {
        key: 'country',
        type: 'select',
        templateOptions: {
            label: 'Country',
            required: true,
            validation: ['required'],
            options: component.countryList$
        },
    },
    {
        key: 'latitude',
        type: 'input',
        templateOptions: {
            label: 'Latitude',
            type: 'number',
        },
        expressionProperties: {
            'templateOptions.required': (model): boolean =>
                model.longitude,
        },
        validators: {
            validation: ['latitude'],
        },
    },
    {
        key: 'longitude',
        type: 'input',
        templateOptions: {
            label: 'Longitude',
            type: 'number',
        },
        expressionProperties: {
            'templateOptions.required': (model): boolean =>
                model.latitude,
        },
        validators: {
            validation: ['longitude'],
        },
    },
    {
        key: 'display',
        type: 'address-part-chip-list-component',
        templateOptions: {
            label: 'International Address Format',
            maxLength: 300,
            addonRight: {
                icon: 'help',
                tooltip: 'Order the address parts to reflect the correct international address formatting',
            },
        },
    }
];
