<div class="wrapper">
    <div *ngIf="content" class="scroll">
        <div
            cdkDropList
            (cdkDropListDropped)="drop($event)"
            class="flex flex-col flex-wrap shadow-2xl page"
        >
            <ng-container *ngFor="let block of content.blocks">
                <div
                    cdkDrag
                    class="relative flex items-center content-block"
                    [ngSwitch]="block.type"
                >
                    <div *cdkDragPreview></div>

                    <div cdkDragHandle class="placeholder"></div>
                    <a
                        mat-icon-button
                        class="absolute left-0 flex-grow-0 -ml-12 shadow-sm pointer-events-none"
                    >
                        <mat-icon svgIcon="drag_indicator"></mat-icon>
                    </a>
                    <app-text
                        (ngModelChange)="editorChange()"
                        [block]="block"
                        *ngSwitchCase="'paragraph'"
                    >
                    </app-text>

                    <app-text
                        (ngModelChange)="editorChange()"
                        [block]="block"
                        *ngSwitchCase="'header'"
                    >
                    </app-text>

                    <app-row
                        (ngModelChange)="editorChange()"
                        [block]="block"
                        *ngSwitchCase="'twoColumnText'"
                    ></app-row>
                    <app-row
                        (ngModelChange)="editorChange()"
                        [block]="block"
                        *ngSwitchCase="'threeColumnText'"
                    ></app-row>
                    <app-row
                        (ngModelChange)="editorChange()"
                        [block]="block"
                        *ngSwitchCase="'twoColumnImageText'"
                        class="items-center"
                    ></app-row>
                    <app-row
                        (ngModelChange)="editorChange()"
                        [block]="block"
                        *ngSwitchCase="'twoColumnTextImage'"
                        class="items-center"
                    ></app-row>
                    <app-image
                        (ngModelChange)="editorChange()"
                        [block]="block"
                        [imageOptions]="imageOptions"
                        *ngSwitchCase="'image'"
                    ></app-image>
                    <app-carousel
                        (ngModelChange)="editorChange()"
                        [block]="block"
                        *ngSwitchCase="'carousel'"
                    ></app-carousel>
                    <a
                        mat-mini-fab
                        [color]="deleteMode ? 'warn' : ''"
                        class="absolute right-0 flex-grow-0 -mr-12 shadow-sm center"
                        (click)="remove(block)"
                        (mouseenter)="deleteMode = false"
                        (mouseleave)="deleteMode = false"
                    >
                        <mat-icon svgIcon="delete"></mat-icon>
                    </a>
                </div>
            </ng-container>
            <div
                *ngIf="content?.blocks?.length === 0"
                class="flex flex-col items-center justify-center w-full gap-1 bg-default"
                style="height: 12cm !important"
            >
                <mat-icon
                    class="cursor-pointer icon-size-20 text-disabled"
                    svgIcon="image"
                ></mat-icon>

                <h3 class="text-2xl font-bold text-disabled">
                    No Content Loaded
                </h3>
                <span class="text-disabled"
                    >Select a layout below to get started</span
                >
            </div>
        </div>
    </div>
</div>
<app-toolbar
    [content]="content"
    (ngModelChange)="editorChange()"
    class="sticky bottom-0 z-10 flex items-center h-10 px-4 mb-4 bg-current border border-solid rounded-lg shadow-xl"
></app-toolbar>
