import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Router, ActivatedRoute, RouterOutlet } from '@angular/router';

@Component({
    selector: 'app-auxiliary-wrapper',
    templateUrl: './auxiliary-wrapper.component.html',
    styleUrls: ['./auxiliary-wrapper.component.scss'],
  //  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuxiliaryWrapperComponent implements OnInit {
    @Input() inline: boolean = false;
    constructor(private router: Router, public outlet: RouterOutlet, public activatedRoute: ActivatedRoute) { }

    ngOnInit(): void {
    }

    public toggleMinimisePage(): void {
        const outlet: any = this.outlet;
        outlet.minimised = !outlet.minimised;
    }

    public navigateAway(): void {
        // tslint:disable-next-line: no-string-literal
        const outlet = this.outlet['name'];
        this.router.navigate([{ outlets: { [outlet]: null } }], {
            relativeTo: this.activatedRoute?.parent?.parent,
            queryParamsHandling: 'merge'
        });
    }

}


