<div
    bottom-content
    class="flex items-center justify-start w-full"
    (click)="openDialog()"
>
    <ul class="flex flex-wrap items-center justify-start w-full gap-2">
        <li *ngFor="let room of formControl.value; let i = index;"
            class="flex items-center justify-center h-6 gap-1 pr-2 font-bold border border-gray-300 border-solid rounded-full bg-default"
            [matTooltip]="'Room ' + i + 1"
        >
            <span
                style="margin-left: -1px"
                class="flex items-center justify-center w-6 h-6 text-sm border-2 border-gray-300 border-solid rounded-full"
                >R{{i + 1}}</span
            >
            <span class="text-sm">

                <ng-container *ngFor="let traveller of room.travellerList; let i = index;">
                    <ng-container *ngIf="traveller.firstName">
                        {{ traveller.firstName }} {{ traveller.lastName }}
                    </ng-container>
                    <ng-container *ngIf="!traveller.firstName">
                        {{ traveller.passengerType }}
                    </ng-container>
                </ng-container>

            </span>
        </li>
    </ul>
    <a mat-icon-button>
        <mat-icon class="" svgIcon="edit"></mat-icon>
    </a>
</div>
