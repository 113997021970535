import { CalendarNature, CalendarStatus, PriceBy, PriceStatus } from 'app/shared/types/types';
import { PresentationUtil } from 'app/shared/utility/presentation-util';
import { Agreement } from '../commercial/commercial-models';
import { Content } from '../common/common-models';
import { UserInterfaceVariables } from '../core/core-models';
import {
    ProductMeta,
    ProductSeriesMeta,
    RateMeta,
    PriceBreakMeta,
    ExtraMeta,
    CalendarDateMeta,
    SeasonMeta,
} from '../meta/product-meta-models';
import { VisitedBy } from '../wiki/wiki-models';
import {
    DifficultyAttribute,
    ItineraryAttribute,
    LocationAttribute,
    ProductDurationAttribute,
    RoomAttribute,
    SessionAttribute,
} from './attribute-models';

export class AgreementItem {
    calendarList!: Calendar[];

    productLevel!: string;
    agreementItemUId!: string;
    relationshipUId!: string;
    relationshipName!: string;
    agreementUId!: string;
    agreementName!: string;
    calendarListDetail!: string;
    productItemUId!: string;
    name!: string;
    inactive!: boolean;
    inactiveNote!: string;
}

export class AgreementProduct {
    agreementProductUId!: string;
    who!: string;
    createdDate!: Date;
    agreementUId!: string;
    agreementName!: string;
    sourceAgreementUId!: string;
    sourceAgreementName!: string;
    productUId!: string;
    productName!: string;
    inactive!: boolean;
    inactiveNote!: string;
}

export class AgreementProductSeries {
    agreementProductSeriesUId!: string;
    who!: string;
    createdDate!: Date;
    agreementUId!: string;
    agreementName!: string;
    sourceAgreementUId!: string;
    sourceAgreementName!: string;
    productSeriesUId!: string;
    productSeriesName!: string;
    inactive!: boolean;
    inactiveNote!: string;
}

export class AgreementProductType {
    agreementProductTypeUId!: string;
    who!: string;
    createdDate!: Date;
    agreementUId!: string;
    agreementName!: string;
    sourceAgreementUId!: string;
    sourceAgreementName!: string;
    productTypeUId!: string;
    productTypeName!: string;
    inactive!: boolean;
    inactiveNote!: string;
}

export class CalendarProductItem {
    productLevel!: string;
    calendarItemUId!: string;
    seasonUId!: string;
    seasonName!: string;
    calendarUId!: string;
    calendarName!: string;
    productItemUId!: string;
    productItemName!: string;
    name!: string;
    inactive!: boolean;
    inactiveNote!: string;
    calendarDates!: string;
}

export class Calendar {
    calendarUId: string | undefined;
    calendarCode: string | undefined;
    createdDate!: Date;
    updatedDate!: Date;
    changeUserUId!: string;
    inactive: boolean | undefined;
    inactiveNote: string | undefined;
    status!: CalendarStatus;
    businessUId!: string;
    businessName: string | undefined;
    agreementUId!: string | undefined;
    agreementName!: string | undefined;
    name!: string;
    useSeasons!: boolean;
    priceBySeason!: boolean;
    hasPrices!: boolean;
    fromDate?: Date;
    toDate?: Date;
    agreementStartDate?: string;
    agreementFinishDate?: string;
    seasonList?: Season[];
    calendarDateList?: CalendarDate[];
    priceList?: Price[];
}

export class CalendarDate {
    gridId?: string;
    calendarDateUId?: string;
    calendarDateCode?: string;
    createdDate?: Date;
    updatedDate?: Date;
    changeUserUId?: string;
    changeUserName?: string;
    inactive?: boolean;
    inactiveNote?: string;
    status?: CalendarStatus;
    calendarUId?: string;
    calendarName?: string;
    seasonUId?: string;
    seasonName?: string;
    type?: string;
    nature?: CalendarNature;
    fromDate?: string;
    toDate?: string;
    dayList: string[] = [];
    color?: string;

    get name(): string | undefined {
        return (
            this.fromDate?.toString() +
            (this.fromDate && this.toDate ? ' - ' : '') +
            this.toDate?.toString()
        );
    }

    meta?: CalendarDateMeta;
    priceList?: Price[];
}

export class CalendarItem {
    productLevel!: string;
    calendarItemUId!: string;
    seasonUId!: string;
    seasonName!: string;
    calendarUId!: string;
    calendarDates!: string;
    calendarName!: string;
    productItemUId!: string;
    productItemName!: string;
    name!: string;
    inactive!: boolean;
    inactiveNote!: string;
}

export class CalendarPackageRate {
    calendar!: Calendar;
    packageRate!: PackageRate;

    calendarPackageRateUId!: string;
    who!: string;
    createdDate!: Date;
    calendarUId!: string;
    packageRateUId!: string;
}

export class CalendarPeriod {
    dayList!: CalendarPeriodDay[];

    calendarPeriodUId!: string;
    calendarPeriodCode!: string;
    who!: string;
    createdDate!: Date;
    updatedDate!: Date;
    calendarUId!: string;
    calendarName!: string;
    type!: string;
    name!: string;
    status!: string;
    inactive!: boolean;
    inactiveNote!: string;
    fromDate!: Date;
    toDate!: Date;
    static isCalendarPeriodModel(object: any): boolean {
        return object.hasOwnProperty('calendarPeriodCode');
    }
}

export class CalendarPeriodDay {
    priceList!: Price[];

    calendarPeriodDayUId!: string;
    calendarPeriodDayCode!: string;
    who!: string;
    createdDate!: Date;
    updatedDate!: Date;
    calendarPeriodUId!: string;
    calendarPeriodName!: string;
    name!: string;
    status!: string;
    inactive!: boolean;
    inactiveNote!: string;
    dayList!: string;
    dayListBinary!: number;
    static isCalendarPeriodDayModel(object: any): boolean {
        return object.hasOwnProperty('calendarPeriodDayCode');
    }
}

export class CalendarProduct {
    calendarProductUId!: string;
    who!: string;
    createdDate!: Date;
    calendarUId!: string;
    calendarName!: string;
    productUId!: string;
    productName!: string;
    inactive!: boolean;
    inactiveNote!: string;
}

export class CalendarProductSeries {
    calendarProductSeriesUId!: string;
    who!: string;
    createdDate!: Date;
    calendarUId!: string;
    calendarName!: string;
    productSeriesUId!: string;
    productSeriesName!: string;
    inactive!: boolean;
    inactiveNote!: string;
}

export class CalendarProductType {
    calendarProductTypeUId!: string;
    who!: string;
    createdDate!: Date;
    calendarUId!: string;
    calendarName!: string;
    productTypeUId!: string;
    productTypeName!: string;
    inactive!: boolean;
    inactiveNote!: string;
}

export class Commission {
    productList!: CommissionProduct[];

    commissionUId!: string;
    commissionCode!: string;
    who!: string;
    createdDate!: Date;
    updatedDate!: Date;
    relationshipUId!: string;
    relationshipName!: string;
    agreementUId!: string;
    agreementName!: string;
    valueOrPercent!: string;
    value!: number;
    percent!: number;
    applyPer!: string;
    fromDate!: Date;
    toDate!: Date;
    commission!: number;
    relationship!: any;
    static isCommissionModel(object: any): boolean {
        return object.hasOwnProperty('commissionCode');
    }
}

export class CommissionProduct {
    commissionProductUId!: string;
    commissionProductCode!: string;
    who!: string;
    createdDate!: Date;
    updatedDate!: Date;
    commissionUId!: string;
    commissionName!: string;
    productTypeUId!: string;
    productTypeName!: string;
    productSeriesUId!: string;
    productSeriesName!: string;
    productUId!: string;
    productName!: string;
}

export class Markup {
    productList!: MarkupProduct[];

    markupUId!: string;
    markupCode!: string;
    who!: string;
    createdDate!: Date;
    updatedDate!: Date;
    relationshipUId!: string;
    relationshipName!: string;
    agreementUId!: string;
    agreementName!: string;
    valueOrPercent!: string;
    value!: number;
    percent!: number;
    applyPer!: string;
    fromDate!: Date;
    toDate!: Date;
    markup!: number;
    relationship!: any;
    static isMarkupModel(object: any): boolean {
        return object.hasOwnProperty('markupCode');
    }
}

export class MarkupProduct {
    markupProductUId!: string;
    markupProductCode!: string;
    who!: string;
    createdDate!: Date;
    updatedDate!: Date;
    markupUId!: string;
    markupName!: string;
    productTypeUId!: string;
    productTypeName!: string;
    productSeriesUId!: string;
    productSeriesName!: string;
    productUId!: string;
    productName!: string;
}

export class OptionalProductChoice {
    optionalProductChoiceUId!: string;
    who!: string;
    createdDate!: Date;
    updatedDate!: Date;
    productUId!: string;
    rateUId!: string;
    seqNo!: number;
}

export class PackageDay {
    packageDayUId!: string;
    packageDayCode!: string;
    who!: string;
    createdDate!: Date;
    updatedDate!: Date;
    inactive!: boolean;
    inactiveNote!: string;
    status!: string;
    packageProductUId!: string;
    dayNo!: number;
    packageProduct?: PackageProduct;
    packageInclusionList!: PackageInclusion[];
    name!: string;
    editable!: boolean;
    expanded!: boolean;
}

export class PackageRate {
    packageRateUId!: string;
    packageRateCode!: string;
    who!: string;
    createdDate!: Date;
    updatedDate!: Date;
    packageProductUId!: string;
    parentRateUId!: string;
    parentRateName!: string;
    childRateUId!: string;
    childRateName!: string;
    status!: string;
    inactive!: boolean;
    inactiveNote!: string;
}

export class Price {
    [key: string]: any;
    priceUId?: string;
    priceCode?: string;
    createdDate?: Date;
    updatedDate?: Date;
    changeUserUId?: string;
    changeUserName?: string;
    inactive?: boolean;
    inactiveNote?: string;
    status!: PriceStatus;
    calendarUId?: string;
    seasonUId?: string;
    calendarDateUId?: string;
    priceBreakUId?: string;
    currency!: string;
    unitPrice?: number;
    adultPrice?: number;
    childPrice?: number;
    infantPrice?: number;
    extraAdultPrice?: number;
    extraChildPrice?: number;
    extraInfantPrice?: number;

    // Meta
    priceBreakName?: string;
    calendarName?: string;
    seasonName?: string;
    calendarDateName?: string;
    businessUId?: string;
    businessName?: string;
    productTypeUId?: string;
    productTypeName?: string;
    productSeriesUId?: string;
    productSeriesName?: string;
    productUId?: string;
    productName?: string;
    rateUId?: string;
    rateName?: string;
    pricePer?: string;
    priceBy?: string;
    pricePerLabel?: string;
    priceByLabel?: string;
    priceFormat!: string;

    get name(): string | undefined {
        return this.priceBreakName;
    }
}

export class PriceBreakCompact {
    priceBreakUId!: string;
    priceBreakCode!: string;
    name!: string;
    minPriceBy?: number;
    maxPriceBy?: number;
}

export class PriceBreak {
    priceBreakUId?: string;
    priceBreakCode?: string;
    createdDate?: Date;
    updatedDate?: Date;
    changeUserUId?: string;
    changeUserName?: string;
    inactive?: boolean;
    inactiveNote?: string;
    status?: string;
    rateUId?: string;
    name?: string;
    minPriceBy?: number;
    maxPriceBy?: number;

    meta?: PriceBreakMeta;
    priceList?: Price[];
}

export class Product {
    selected!: boolean;
    productUId!: string;
    productCode?: string;
    createdDate?: Date;
    updatedDate?: Date;
    changeUserUId?: string;
    changeUserName?: string;
    inactive?: boolean;
    inactiveNote?: string;
    status?: string;
    inclusionType?: string;
    businessUId?: string;
    businessName?: string;
    productTypeUId?: string;
    productTypeName?: string;
    productSeriesUId?: string;
    productSeriesName?: string;
    type?: string;
    name!: string;
    pricingSet: boolean = false;
    pricePer?: string;
    priceBy?: string;
    pricePerLabel?: string;
    priceByLabel?: string;
    peopleSet: boolean = false;
    infantMaxAge?: number;
    childMaxAge?: number;
    adultMaxAge?: number;
    infantsPermitted?: boolean;
    childrenPermitted?: boolean;
    difficultyAttributeSet?: boolean = false;
    itineraryAttributeSet?: boolean = false;
    productDurationAttributeSet?: boolean = false;
    locationAttributeSet?: boolean = false;
    roomAttributeSet?: boolean = false;
    sessionAttributeSet?: boolean = false;

    difficultyAttribute?: DifficultyAttribute;
    itineraryAttribute?: ItineraryAttribute;
    locationAttribute?: LocationAttribute;
    productDurationAttribute?: ProductDurationAttribute;
    roomAttribute?: RoomAttribute;
    sessionAttribute?: SessionAttribute;
    supplierProductName?: string;
    meta?: ProductMeta;
    contentList?: Content[];
    rateList?: Rate[];
    visitedByList?: VisitedBy[];
    dayList?: PackageDay[];
    usable!: boolean;
    //productExtraList!: ProductExtra[];
}

export class Package extends Product {
    override dayList!: PackageDay[]; // @todo. Day list was incorrectly put in product. It has to be moved here. Override is to allow code to support move now
}

export class PackageProduct extends Product {
    // @todo this is named incorrectly. Using Package class above. Remove this when possible
    packageProductUId!: string;
    packageProductCode!: string;
    parentProductUId!: string;
    parentProductName!: string;
    childAgreementUId!: string;
    childAgreementName!: string;
    childProductUId!: string;
    childProductName!: string;
    pricingType!: string;
    dayNo!: number;
    seqNo!: number;
    override dayList!: PackageDay[];
}

export class ProductsExtrasAssociation {
    extraInclusionType?: string;
    extraName?: string;
    extraUId?: string;
    inclusionType?: string;
    productName?: string;
    productUId?: string;
    readOnly!: true;
    selected!: true;
}
export class CatalogProduct extends Product {
    package!: boolean;
    scope!: string;
    extraList!: ProductExtra[];
}

export class Extra extends Product {
    availableOnAllProduct?: boolean;
    productList?: ProductExtra[];
    override meta?: ExtraMeta;
}

export class ProductExtra {
    productExtraUId?: string;
    createdDate?: Date;
    updatedDate?: Date;
    inactive?: boolean;
    inactiveNote?: string;
    status?: string;
    extraInclusionType?: string;
    inclusionType?: string;
    changeUserUId?: string;
    changeUserName?: string;
    productSeriesUId?: string;
    productSeriesName?: string;
    productUId!: string;
    productName!: string;
    extraUId?: string;
    extraName?: string;
    availableOnAllProduct?: boolean;

    constructor(product?: Product, extra?: Extra) {
        if (product) {
            this.status = product.status;
            this.inclusionType = product.inclusionType;
            this.productUId = product.productUId;
            this.productName = product.name;
            this.extraUId = undefined;
            this.extraName = undefined;
            this.productSeriesUId = product.productSeriesUId;
            this.productSeriesName = product.productSeriesName;
            this.productExtraUId = undefined;
        }

        if (extra) {
            this.extraUId = extra.productUId;
            this.extraInclusionType = extra.inclusionType;
            this.extraName = extra.name;
            this.status = extra.status;
            this.availableOnAllProduct = extra.availableOnAllProduct;
            this.productExtraUId = undefined;
        }
    }
}

export class OptionGroup extends Product {
    expanded!: boolean;
    editable!: boolean;
    selectionType!: string;
    choiceList!: OptionGroupChoice[];
}

export class PackageInclusion {
    editable!: boolean;
    expanded!: boolean;
    packageInclusionUId!: string;
    packageInclusionCode!: string;
    ownerCode!: string;
    who!: string;
    createdDate!: string;
    updatedDate!: string;
    inactive!: boolean;
    inactiveNote!: string;
    searchable!: boolean;
    packageProductUId!: string;
    includedProductName!: string;
    includedProductType!: string;
    includedAgreementUId!: string;
    includedAgreementName!: string;
    includedProductUId!: string;
    status!: string;
    pricingType!: string;
    inclusionType!: string;
    packageDayNo!: number;
    seqNo!: number;
    time!: string;
    includedAgreement?: Agreement;
    includedProduct!: Product | PackageProduct | OptionGroup;
    includedRateList?: PackageInclusionRate[];
    choiceList!: OptionGroupChoice[];
    dayList!: PackageDay[];
}

export class PackageInclusionRate {
    packageInclusionRateUId!: string;
    packageInclusionCode!: string;
    ownerCode!: string;
    who!: string;
    createdDate!: string;
    updatedDate!: string;
    inactive!: boolean;
    inactiveNote!: string;
    searchable!: boolean;
    packageInclusionUId!: string;
    packageRateId!: string;
    includedRateUId!: string;
    packageInclusion!: PackageInclusion;
    packageRate!: Rate;
    includedRate!: Rate;
}

export class OptionGroupChoice {
    editable!: boolean;
    expanded!: boolean;
    optionChoiceUId!: string;
    optionChoiceCode!: string;
    ownerCode!: string;
    who!: string;
    createdDate!: string;
    updatedDate!: string;
    inactive!: boolean;
    inactiveNote!: string;
    searchable!: boolean;
    optionGroupProductUId!: string;
    includedAgreementUId!: string;
    includedProductUId!: string;
    default!: boolean;
    status!: string;
    seqNo!: number;
    agreement?: Agreement;
    optionGroup?: OptionGroup;
    agreementName?: string;
    includedProductTypeName?: string;
    includedProduct?: Product;
    includedProductName!: string;
    includedAgreementName!: string;
    includedAgreementType!: string;
    includedRateList?: OptionGroupChoiceRate[];
}

export class OptionGroupChoiceRate {
    optionGroupChoiceRateUId!: string;
    optionGroupRateName!: string;
    optionGroupRateUId!: string;
    optionGroupRateCode!: string;
    optionGroupRateStatus!: string;
    ownerCode!: string;
    who!: string;
    createdDate!: string;
    updatedDate!: string;
    inactive!: boolean;
    inactiveNote!: string;
    status!: string;
    searchable!: boolean;
    optionGroupChoiceUId!: string;
    optionGroupChoice!: OptionGroupChoice;
    optionGroupChoiceRate!: Rate;
    includedProductRateName!: string;
    includedProductRateStatus!: string;
    includedProductRate!: Rate;
    includedProductRateUId!: string;
    optionGroupChoiceName!: string;
}

export class OptionalProduct extends Product {
    override businessUId!: string;
    optionType!: string;
    optional!: boolean;
    leadInChoiceUId!: string;
}

export class ProductContent {
    productContentUId!: string;
    who!: string;
    createdDate!: Date;
    updatedDate!: Date;
    productSeriesUId!: string;
    productSeriesName!: string;
    productUId!: string;
    productName!: string;
    type!: string;
    status!: string;
    inactive!: boolean;
    inactiveNote!: string;
    contentFormat!: string;
    content!: string;
}

export class ProductDayContent {
    productDayContentUId!: string;
    who!: string;
    createdDate!: Date;
    updatedDate!: Date;
    productSeriesUId!: string;
    productSeriesName!: string;
    productUId!: string;
    productName!: string;
    type!: string;
    status!: string;
    inactive!: boolean;
    inactiveNote!: string;
    dayNo!: number;
    contentFormat!: string;
    content!: string;
}

export class ProductItem {
    productLevel!: string;
    productItemUId!: string;
    name!: string;
    status!: string;
    inactive!: boolean;
    inactiveNote!: string;
    productItemCode!: string;
    supplierName!: string;
    sourceRelationshipUId!: any;
    sourceRelationshipName!: any;
    sourceAgreementUId!: any;
    sourceAgreementName!: any;
}

export class ProductOption {
    productOptionUId!: string;
    who!: string;
    createdDate!: Date;
    updatedDate!: Date;
    productUId!: string;
    optionUId!: string;
    suitability!: string;
}

export class ProductSeries {
    productSeriesUId!: string;
    productSeriesCode?: string;
    createdDate?: Date;
    updatedDate?: Date;
    changeUserUId?: string;
    changeUserName?: string;
    inactive?: boolean;
    inactiveNote?: string;
    status?: string;
    businessUId?: string;
    businessName?: string;
    productTypeUId?: string;
    productTypeName?: string;
    type?: string;
    name!: string;
    pricingSet: boolean = false;
    pricePer?: string;
    priceBy?: string;
    pricePerLabel?: string;
    priceByLabel?: string;
    peopleSet: boolean = false;
    infantMaxAge?: number;
    childMaxAge?: number;
    adultMaxAge?: number;
    infantsPermitted?: boolean;
    childrenPermitted?: boolean;
    difficultyAttributeSet: boolean = false;
    itineraryAttributeSet: boolean = false;
    productDurationAttributeSet: boolean = false;
    locationAttributeSet: boolean = false;
    roomAttributeSet: boolean = false;
    sessionAttributeSet: boolean = false;

    difficultyAttribute?: DifficultyAttribute;
    itineraryAttribute?: ItineraryAttribute;
    locationAttribute?: LocationAttribute;
    productDurationAttribute?: ProductDurationAttribute;
    roomAttribute?: RoomAttribute;
    sessionAttribute?: SessionAttribute;

    meta?: ProductSeriesMeta;
    agreementList?: AgreementProductSeries[];
    calendarList?: CalendarProductSeries[];
    productList?: Product[];
    contentList?: ProductContent[];
    usable!: boolean;
}

export class ProductSeriesOption {
    productSeriesOptionUId!: string;
    who!: string;
    createdDate!: Date;
    updatedDate!: Date;
    productSeriesUId!: string;
    optionUId!: string;
    suitability!: string;
}

export class ProductType {
    productTypeUId!: string;
    productTypeCode?: string;
    who!: string;
    createdDate!: Date;
    updatedDate!: Date;
    inactive!: boolean;
    inactiveNote!: string;
    hasProducts!: string;
    status!: string;
    name!: string;
    attributeTypeList: string[] = [];
    pricePer!: string;
    priceBy!: PriceBy;
    pricePerLabel!: string;
    priceByLabel!: string;
    iconName!: any;
    subProductTypeList?: ProductType[];
    durationLabel: undefined | string;
    startDateLabel: undefined | string;
    packagesOnly: undefined | boolean;
    usable!: boolean;
}

export class RateCompact {
    rateUId!: string;
    name!: string;
    noPeople?: number;
    minNoPeople?: number;
    maxNoPeople?: number;
    maxNoAdults?: number;

    assignmentSlotList: AssignmentSlot[] = [];
}

export class AssignmentSlot {
    assignmentNo!: number;
    passengerTypeList: string[] = [];
    required!: boolean;
    minAge?: number;
    maxAge?: number;
    extraPerson!: boolean;
    adultPermitted!: boolean;
}

export class Rate {
    selected!: boolean;
    rateUId!: string;
    rateCode!: string;
    meta?: RateMeta;
    who!: string;
    createdDate!: Date;
    updatedDate!: Date;
    changeUserUId?: string;
    changeUserName?: string;
    inactive?: boolean;
    inactiveNote?: string;
    status?: string;
    productUId!: string;
    name?: string;
    leadIn?: boolean;
    noPeople?: number;
    minNoPeople?: number;
    maxNoPeople?: number;
    maxNoAdults?: number;
    pricingSet: boolean = false;
    pricePer?: string;
    priceBy?: string;
    pricePerLabel?: string;
    priceByLabel?: string;
    peopleSet: boolean = false;
    infantMaxAge?: number;
    childMaxAge?: number;
    adultMaxAge?: number;
    infantsPermitted?: boolean;
    childrenPermitted?: boolean;
    difficultyAttributeSet?: boolean = false;
    itineraryAttributeSet?: boolean = false;
    productDurationAttributeSet?: boolean = false;
    locationAttributeSet?: boolean = false;
    roomAttributeSet?: boolean = false;
    sessionAttributeSet?: boolean = false;

    currency!: string;
    priceBreakList: PriceBreak[] | undefined;

    difficultyAttribute?: DifficultyAttribute;
    itineraryAttribute?: ItineraryAttribute;
    locationAttribute?: LocationAttribute;
    productDurationAttribute?: ProductDurationAttribute;
    roomAttribute?: RoomAttribute;
    sessionAttribute?: SessionAttribute;
}

export class RateContent {
    rateContentUId!: string;
    who!: string;
    createdDate!: Date;
    updatedDate!: Date;
    rateUId!: string;
    rateName!: string;
    type!: string;
    status!: string;
    inactive!: boolean;
    inactiveNote!: string;
    contentFormat!: string;
    content!: string;
}

export class RateInclusion {
    rateInclusionUId!: string;
    who!: string;
    createdDate!: Date;
    updatedDate!: Date;
    rateUId!: string;
    includedRateUId!: string;
}

export class Season {
    loading!: boolean;
    gridId?: string;
    seasonUId?: string;
    seasonCode?: string;
    createdDate?: Date;
    updatedDate?: Date;
    changeUserUId?: string;
    changeUserName?: string;
    inactive?: boolean;
    inactiveNote?: string;
    status?: string;
    calendarUId?: string;
    calendarName?: string;
    name?: string;
    color?: string;

    uiVariables = new UserInterfaceVariables();

    meta?: SeasonMeta;
    calendarDateList?: CalendarDate[];
    priceList?: Price[];
}

export class PriceSheet {
    supplierUId!: string;
    supplierName!: string;
    supplierCurrency!: string;
    productItemList!: PriceSheetProductItem[];
}
export class PriceSheetProductItem {
    productTypeUId!: string;
    productSeriesUId!: string;
    productUId!: string;
    rateUId!: string;
    productTypeName!: string;
    productSeriesName!: string;
    productName!: string;
    rateName!: string;
    rateLimit!: RateLimit;
    priceBreakList!: PriceSheetPriceBreak[];
}

export class RateLimit {
    noPeople!: number;
    minNoPeople!: number;
    maxNoPeople!: number;
    maxNoAdults!: number;
}

export class PriceSheetPriceBreak {
    priceBreakUId!: string;
    priceBreakName!: string;
    priceBreakLimits!: string;
    agreementList!: PriceSheetAgreement[];
    calendarList!: PriceSheetCalendar[];
}

export class PriceSheetAgreement {
    agreementUId!: string;
    agreementName!: string;
    calendarList!: PriceSheetCalendar[];
}

export class PriceSheetCalendar {
    calendarUId!: string;
    calendarName!: string;
    calendarDateGrouping!: string;
    dateItemList!: PriceSheetDateItem[];
}

export class PriceSheetDateItem {
    dateItemName!: string;
    dateItemColor!: string;
    dateItemCurrency!: string;
    dateItemPriceBy!: string;
    dateList!: PriceSheetDateItemDate;
    price: undefined | null | Price;
    dateItemPriceId!: PriceSheetDateItemId;
    dateItemUId!: string;
}

export class PriceSheetDateItemDate {
    date!: string;
}

export class PriceSheetDateItemId {
    priceBreakUId!: string;
    calendarUId!: string;
    seasonUId!: string;
    calendarDateUId!: string;
}
