import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-panel-wrapper',
  templateUrl: './formly-panel-wrapper.component.html',
  styleUrls: ['./formly-panel-wrapper.component.scss']
})
export class FormlyPanelWrapperComponent extends FieldWrapper{
  @ViewChild('fieldComponent', {read: ViewContainerRef}) override fieldComponent!: ViewContainerRef;
}
