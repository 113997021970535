<ng-select
    #ngSelect
    *ngIf="this.to['travellerList']"
    [items]="this.to['travellerList']"
    [formControl]="formControl"
    [class.is-invalid]="showError"
    [clearable]="false"
    [multiple]="true"
    [closeOnSelect]="false"
    [searchable]="true"
    (add)="add($event.travellerUId)"
>
    <ng-template ng-label-tmp let-item="item">
        <div class="flex flex-row items-center justify-center gap-1 text-md">
            <span
                (click)="remove(item.travellerUId)"
                aria-hidden="true"
                class="ng-value-icon left"
                >×</span
            >
            <span class="ng-value-label">
                <ng-container *ngIf="item.firstName">
                    {{ item.firstName }} {{ item.lastName }}
                </ng-container>
                <ng-container *ngIf="!item.firstName">
                    {{ item.passengerType }}
                </ng-container>
            </span>
        </div>
    </ng-template>
    <ng-template ng-option-tmp let-item="item">
        <div
            class="flex flex-row items-center justify-center gap-1 text-md"
            title="{{ item.firstName? item.firstName : item.passengerType }}"
        >
        <ng-container *ngIf="item.firstName">
            {{ item.firstName }} {{ item.lastName }}
        </ng-container>
        <ng-container *ngIf="!item.firstName">
            {{ item.passengerType }}
        </ng-container>
        </div>
    </ng-template>
</ng-select>
