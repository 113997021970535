import {
    Directive,
    ElementRef,
    HostListener,
    Input,
    OnChanges,
    Renderer2,
    SimpleChange,
    SimpleChanges,
} from '@angular/core';
@Directive({
    selector: '[phoneLink]',
})
export class FormlyPhoneLinkDirective implements OnChanges {
    @Input() phoneLink!: any;
    telephoneLink!: string;
    constructor(public renderer: Renderer2, public hostElement: ElementRef) { }

    @HostListener('click', ['$event']) onClick($event: Event): void {
        if (this.telephoneLink) {
            document.location.href = this.telephoneLink;
        }
    }



    ngOnChanges(changes: SimpleChanges): void {
        if (changes['phoneLink']) {
            this.validate();
        }
    }


    validate(): void {
        if (this.phoneLink) {
            // const regex = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
            const numbers = this.phoneLink.replace(/[^\d+]/g, ''); // removes all chars and special chars except '+';
            if (numbers.length >= 10) {
                this.telephoneLink = 'tel:' + numbers;
                this.renderer.setAttribute(
                    this.hostElement.nativeElement,
                    'href',
                    this.telephoneLink
                );
            } else {
                this.telephoneLink = '';
                this.renderer.removeAttribute(
                    this.hostElement.nativeElement,
                    'href'
                );
            }
        }

    }
}
