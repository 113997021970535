<mat-form-field>
    <div
        class="flex items-center"
        [ngClass]="{
            'self-center w-6 h-6 mr-2 overflow-hidden rounded-full':
                type === 'color'
        }"
    >
        <input
            [ngClass]="{
                'w-8 h-8 origin-center transform scale-150': type === 'color'
            }"
            matInput
            #gridInput
            (change)="change()"
            (keypress)="omitExtraDecimalPlaces($event)"
            [(ngModel)]="params.value"
            [readonly]="readonly || params.colDef.editable === false"
            [disabled]="disabled"
            [type]="type"
        />
    </div>
</mat-form-field>
