import { AfterViewInit, Directive, ElementRef, EventEmitter, HostBinding, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';

@Directive({
    selector: '[carouselNavItem]'
})
export class CarouselNavItemDirective implements AfterViewInit, OnChanges, OnDestroy {

    @HostBinding('class.visible') isVisibleInView = false;
    @HostBinding('class.selected') @Input() selected = false;
    @Input() firstNavItem = false;
    @Input() lastNavItem = false;
    @Output() scrolledToStart: EventEmitter<boolean> = new EventEmitter();
    @Output() scrolledToEnd: EventEmitter<boolean> = new EventEmitter();
    @Output() selectedItemPosition: EventEmitter<number> = new EventEmitter();
    private observer: undefined | IntersectionObserver;

    constructor(private el: ElementRef) {
    }
    ngOnChanges(changes: SimpleChanges): void {
     if(this.selected) {
        this.selectedItemPosition.emit(this.el.nativeElement.getBoundingClientRect().left - this.el.nativeElement.parentElement.getBoundingClientRect().left);
     }
    }

    ngAfterViewInit(): void {
        this.observer = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
            entries.forEach((entry: IntersectionObserverEntry) => {
                const isIntersecting = entry.isIntersecting;
                this.isCarouselNavScrolledToStart(isIntersecting);
                this.isCarouselNavScrolledToEnd(isIntersecting);
                this.isVisibleInView = isIntersecting;
            });
        }, {
            threshold: 0.75
        });
        this.observer.observe(this.el.nativeElement as HTMLElement);
    }

    ngOnDestroy(): void {
        this.observer?.disconnect();
    }

    isCarouselNavScrolledToStart(isIntersecting: boolean): void {
        if (this.firstNavItem) {
            this.scrolledToStart.emit(isIntersecting);
        }
    }

    isCarouselNavScrolledToEnd(isIntersecting: boolean): void {
        if (this.lastNavItem) {
            this.scrolledToEnd.emit(isIntersecting);
        }
    }
    scrollIntoView(): void {
        this.el.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' });
    }

}
