import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { dialogConfig } from './config/dialog.config';
import { fields } from './config/formly-form.config';
import { NgForm, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { defaultPlaceDisplayValue, Place } from 'app/shared/models/shared/place-model';
import { ReadyStateService, ReadyState } from 'app/core/state/ready-state.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormlyOption } from '../../types/formly';
import { mapToFormlyOptionList } from 'app/shared/utility/form-utils';
import { RefDataService } from 'app/shared/services/ref-data/ref-data.service';

@Component({
    selector: 'app-formly-address-dialog',
    templateUrl: './formly-address-dialog.component.html',
    providers: [ReadyStateService]
})
export class FormlyAddressDialogComponent implements OnDestroy {
    dialogConfig = dialogConfig;
    form = new FormGroup({});
    fields!: FormlyFieldConfig[];
    ready: boolean = false;
    place: undefined | Place;
    countryList$?: Observable<FormlyOption[]>;
    private readonly _destroying$ = new Subject<void>();
    constructor(public dialogRef: MatDialogRef<FormlyAddressDialogComponent>,
        private refDataService: RefDataService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private readyService: ReadyStateService) {
        this.place = this.data;
        this.componentInit();
    }

    ngOnDestroy(): void {
        this._destroying$.next(undefined);
        this._destroying$.complete();
    }

    componentInit = (): void => {
        this.readyService.name = this.constructor.name;
        this.readyService.state$.pipe(takeUntil(this._destroying$)).subscribe((state: ReadyState) => {
            this.ready = (state.name === 'Ready');
        });
        this.configureFormFields();
    };

    configureFormFields = (): void => {
        this.countryList$ = mapToFormlyOptionList(this.readyService.addLoading('countryList$', this.refDataService.countryList$));
        this.fields = fields(this);
    };

    reset(): void {
        this.form.reset();
        this.place = {
            display: defaultPlaceDisplayValue,
            addressLine: undefined,
            town: undefined,
            state: undefined,
            postalCode: undefined,
            country: undefined,
            latitude: undefined,
            longitude: undefined,
            provider: undefined,
        };
    }

    submitAction(): void {
        if (this.form.valid) {
            this.dialogRef.close(this.form.value);
        }
    }
}
