import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { BehaviorSubject } from 'rxjs';
import { WizardState } from '../../interfaces/wizard-state';

@Component({
    selector: 'app-wizard-footer',
    templateUrl: './wizard-footer.component.html',
    styleUrls: ['./wizard-footer.component.scss'],
    animations: fuseAnimations
})
export class WizardFooterComponent implements OnInit {
    @Input() wizardState$: undefined | BehaviorSubject<WizardState>;
    @Output() stepAction: EventEmitter<number> = new EventEmitter();
    constructor() { }

    ngOnInit(): void {
    }

    getStepNumber(i: number | undefined): number | void {
        if (i !== undefined) {
            return i + 1;
        }
    }

    back(): void {
        const state = this.wizardState$?.getValue();
        if(state) {
            this.stepAction.emit(state.currentStepIndex - 1);
        }
    }

    next(): void {
        const state = this.wizardState$?.getValue();
        if(state) {
            this.stepAction.emit(state.currentStepIndex + 1);
        }
    }

}
