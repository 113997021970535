export class PathUtil {
    static combinePaths(...paths: string[]): string {
        if (paths.length === 0) {
            return '';
        }

        let path: string = '';

        for (const pathPart of paths) {
            if (
                path &&
                path.length > 0 &&
                path.charAt(path.length - 1) !== '/'
            ) {
                path += '/';
            }
            if (path == null) {
                path = pathPart;
            } else {
                path += pathPart;
            }
        }

        return path;
    }

    static addParam(path: string, ...params: string[]): string {
        if (params.length === 0) {
            return path;
        }
        for (const pathPart of params) {
            if (path != null && path.length > 0 && path.indexOf('?') === -1) {
                path += '?' + pathPart;
            } else {
                path += '&' + pathPart;
            }
        }

        return path;
    }
}
