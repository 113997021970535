<div class="border-t border-solid"></div>
<app-section-heading class="mb-2"  [size]="'small'" [heading]="to.label || ''" [tooltip]="to['tooltip']"
    >

    <button mat-raised-button color="primary" [disabled]="to['disableAdd']" (click)="add()" class="transform scale-90">
        <mat-icon class="mr-2 icon-size-5" [svgIcon]="'add'"></mat-icon>
        {{ to["addLabel"] }}
    </button></app-section-heading
>

<div *ngFor="let field of field.fieldGroup; let i = index" class="flex flex-col justify-center field-group">
    <label class="font-extrabold leading-7 tracking-tight truncate text-md"
        >{{to['listItemPrefixLabel']}} <ng-container *ngIf="to['listItemNumbering']">{{ i + 1 }}</ng-container> {{to['listItemSuffixLabel']}}
    </label>
    <div class="flex flex-row items-center">
        <formly-field class="flex-grow" [field]="field"></formly-field>

        <button mat-icon-button class="flex-grow-0" (click)="remove(i)">
            <mat-icon color="warn" [svgIcon]="'delete'"></mat-icon>
        </button>
    </div>
</div>
