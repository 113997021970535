<div
    [style.--page-theme-color]="pageThemeColor"
    class="absolute flex flex-col min-w-0 overflow-y-auto border rounded-lg shadow-xl page-outline inset-8 bottom-2 bg-default"
    cdkScrollable
    page
    [class.scrolled-to-bottom]="scrolledToBottom"
>
    <!-- Header -->
    <div
        class="relative flex flex-col flex-wrap gap-4 p-6 header z-999 sm:flex-row flex-0 sm:items-center sm:justify-between sm:py-8 sm:px-10 bg-card"
    >
        <div class="flex-1 min-w-0">
            <!-- Breadcrumbs -->
            <div>
                <div class="flex-wrap items-center font-medium sm:flex">
                    <span
                        skeleton
                        class="text-sm font-bold uppercase page-prefix-text"
                    >
                    {{
                        ((pageHeaderConfig$ | async)?.headingPrefix$ | async) ||
                            headingPrefix
                    }}
                    </span>
                </div>
            </div>
            <!-- Title -->
            <div skeleton class="mt-2">
                <h2
                    class="text-3xl font-extrabold leading-7 tracking-tight truncate md:text-4xl sm:leading-10"
                >
                {{
                    ((pageHeaderConfig$ | async)?.heading$ | async) ||
                        heading
                }}
                </h2>
                <div class="small max-w-120">
                    {{
                        ((pageHeaderConfig$ | async)?.description$ | async) ||
                            description
                    }}
                </div>
            </div>
        </div>
        <!-- Actions -->
        <div
            skeleton
            class="flex items-center flex-shrink-0 mt-6 sm:mt-0 sm:ml-4 actions"
        ></div>
    </div>
    <!-- Fixed Header -->
    <div
        class="sticky top-0 flex flex-row items-center w-full px-10 py-2 border-b shadow-sm sticky-header min-h-10 max-h-10 z-99 bg-card"
        style="margin-top: -1.75rem"
    >
        <h5 class="flex gap-2 text-sm font-bold uppercase">
            <span class="page-prefix-text">
                {{
                    ((pageHeaderConfig$ | async)?.headingPrefix$ | async) ||
                        headingPrefix
                }}</span
            >
            {{
                ((pageHeaderConfig$ | async)?.heading$ | async) ||
                    heading
            }}
        </h5>
        <div
            class="absolute bottom-0 left-0 w-full h-1 progress-bar-background"
        >
            <mat-progress-bar
                *ngIf="loading || progressLoading"
                [mode]="progressMode"
            ></mat-progress-bar>
        </div>
    </div>

    <!-- Main -->
    <div page-content class="flex flex-col gap-4">
        <div class="p-10 content-wrapper">
            <ng-content></ng-content>
        </div>
        <app-router-animation *ngIf="childRoute">
            <router-outlet
            (activate)="onActivate($event)"
                #outlet="outlet"
            ></router-outlet>
        </app-router-animation>
    </div>
</div>
