import { Component,ChangeDetectionStrategy, ViewEncapsulation} from '@angular/core';
import { FieldType } from '@ngx-formly/core';
@Component({
  selector: 'app-formly-multi-select-autocomplete',
  templateUrl: './formly-multi-select-autocomplete.component.html',
  styleUrls: ['../../styles/ng-select.scss','./formly-multi-select-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class FormlyMultiSelectAutocompleteComponent extends FieldType {
    override formControl: any;

}
