<ng-container *ngIf="!masterDetailTemplate || !addTemplate || !addButtonLink">
    <app-section-heading
        *ngIf="heading !== 'heading'"
        [heading]="heading"
        [subHeading]="subHeading"
    >
        <button
            *ngIf="showAddButton"
            mat-stroked-button
            class="transform scale-90"
            [disabled]="disableAdd"
            (click)="addItemBottomSheet()"
        >
            <mat-icon class="mr-2 icon-size-5" [svgIcon]="'add'"></mat-icon>
            {{ addButtonLabel }}
        </button>
        <button
            *ngIf="!masterDetailTemplate && !addTemplate && addButtonLink"
            mat-stroked-button
            class="transform scale-90"
            [disabled]="disableAdd"
            [auxiliaryQueries]="auxiliaryQueries"
            [auxiliaryLink]="addButtonLink"
        >
            <mat-icon class="mr-2 icon-size-5" [svgIcon]="'add'"></mat-icon>
            {{ addButtonLabel }}
        </button>
        <button
            *ngIf="
                inlineAdd &&
                !masterDetailTemplate &&
                !addTemplate &&
                !addButtonLink
            "
            mat-stroked-button
            class="transform scale-90"
            (click)="inlineAddAction.next()"
        >
            <mat-icon class="mr-2 icon-size-5" [svgIcon]="'add'"></mat-icon>
            {{ addButtonLabel }}
        </button>
        <button
            *ngIf="
                !masterDetailTemplate &&
                !inlineAdd &&
                !addTemplate &&
                !addButtonLink &&
                isAgGridAdd
            "
            mat-raised-button
            [color]="'primary'"
            [disabled]="disableAdd"
            (click)="agGridAdd.next()"
        >
            <mat-icon class="mr-2 icon-size-5" [svgIcon]="'add'"></mat-icon>
            {{ addButtonLabel }}
        </button>
        <ng-container *ngIf="actionTemplate">
            <ng-container *ngTemplateOutlet="actionTemplate"></ng-container>
        </ng-container>

        <ng-container *ngIf="isAgGridActions">
            <button
                type="button"
                color="warn"
                (click)="agGridCancel.emit()"
                mat-stroked-button
                class="transform scale-90"
            >
                {{ agGridCancelLabel }}
            </button>

            <button
                (click)="agGridSubmit.emit()"
                type="submit"
                class="transform scale-90"
                mat-stroked-button
            >
                {{ agGridSaveLabel }}
            </button>
        </ng-container>
    </app-section-heading>
    <div
        class="grid-wrapper ag-theme-alpine"
        [class.auto-height]="autoHeight"
        #gridWrapper
    >
        <ng-content select="ag-grid-angular"></ng-content>
        <div
            [class.flex]="loading"
            class="flex-row items-center justify-center hidden loader"
        ></div>
    </div>
</ng-container>
<ng-container *ngIf="masterDetailTemplate && addButtonLink">
    <span class="text-lg text-warn-500"
        >Use a "masterDetailTemplate" or a "addButtonLink" Input() on this
        component. You cannot use both.</span
    >
</ng-container>
