<textarea
matInput
[id]="id"
readonly
[required]="to.required || false"
[formControl]="formControl"
[cols]="1"
[formlyAttributes]="field"
[tabindex]="to.tabindex"
[cdkTextareaAutosize]="true"
[cdkAutosizeMinRows]="1"
[cdkAutosizeMaxRows]="3"
[class.cdk-textarea-autosize]="true"
>
</textarea>
