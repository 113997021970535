<ng-select
    #select
    [items]="options$ | async"
    [typeahead]="search$"
    [formControl]="formControl"
    [loading]="loading"
    loadingText="Loading..."
    [editableSearchTerm]="true"
    (change)="changeSelection($event)"
    [class.is-invalid]="showError"
    [clearable]="to['clearable']"
    (close)="close()"
    (clear)="clear()"
>
</ng-select>
