import { ICellRendererParams } from '@ag-grid-community/all-modules';
import { AgRendererComponent } from '@ag-grid-community/angular';
import { Component } from '@angular/core';
import { siglaLog } from 'app/shared/utility/logging-util';

@Component({
    selector: 'app-ag-grid-new-tab-cell-renderer',
    templateUrl: './ag-grid-new-tab-cell-renderer.component.html',
    styleUrls: ['./ag-grid-new-tab-cell-renderer.component.scss'],
})
export class AgGridNewTabCellRendererComponent implements AgRendererComponent {
    public params: any | undefined;

    constructor() {}

    refresh(params: ICellRendererParams): boolean {
        return false;
    }
    agInit(params: any): void {
        this.params = params;
    }

    openInNewTab(event: any): void {
        event.stopPropagation();
        let uIdKey;
        if (this.params['uIdField']) {
            uIdKey = Object.keys(this.params.data).find(
                (key) => key === this.params?.['uIdField']
            );
        } else {
            uIdKey = Object.keys(this.params.data).find((key) =>
                key.includes('UId')
            );
        }

        const staticLink = this.params.staticLink;
        if (uIdKey && staticLink) {
            const uId = this.params.data[uIdKey];
            const url =
                window.location.origin + staticLink.replace('[[uId]]', uId);
            siglaLog(this.params);
            if (!url.includes('[[uId]]')) {
                window.open(url, '_blank');
            }
        }
    }
}
