import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MsalService } from '@azure/msal-angular';
import { NotificationService } from '../notification/notification.service';
import { Error } from 'app/shared/models/core/core-models';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private snackBar: NotificationService,
        private msalService: MsalService,
    ) { }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((httpError: any) => {

                console.error(httpError);
                let error: Error;

                // Handle access errors

                if ([400].includes(httpError.status)) {
                    const errors = httpError?.error?.errors??httpError?.error?.errorList;
                    error = {
                        errorType: 'Validation',
                        code: httpError.status,
                        message: 'API request failed validation',
                        detail: errors ?? httpError.message
                    } as Error;
                }
                else if ([401,402,403,405,407,511].includes(httpError.status)) {
                    error = {
                        errorType: 'Access',
                        code: httpError.status,
                        message: 'User does not have access to requested operation',
                        detail: httpError.message
                    } as Error;
                }
                else if ([404,501].includes(httpError.status)) {
                    error = {
                        errorType: 'Application Error',
                        code: httpError.status,
                        message: 'Requested API does not exist',
                        detail: httpError.message
                    } as Error;
                }
                else if ([505].includes(httpError.status)) {
                    error = {
                        errorType: 'Application Error',
                        code: httpError.status,
                        message: 'Requested API version not supported',
                        detail: httpError.message
                    } as Error;
                }
                else if ([408,504].includes(httpError.status)) {
                    error = {
                        errorType: 'Timeout',
                        code: httpError.status,
                        message: 'API request timed out',
                        detail: httpError.message
                    } as Error;
                }
                else if (httpError.status >= 400 && httpError.status <= 499) {
                    error = {
                        errorType: 'Application Error',
                        code: httpError.status,
                        message: 'An unknown error occurred with the application setup',
                        detail: httpError.message
                    } as Error;
                }
                else if ([503].includes(httpError.status)) {
                    error = {
                        errorType: 'Unavailable',
                        code: httpError.status,
                        message: 'The API is currently unavailable',
                        detail: httpError.message
                    } as Error;
                }
                else if (httpError.status >= 500 && httpError.status <= 599) {
                    error = {
                        errorType: 'API Error',
                        code: httpError.status,
                        message: 'An unknown error occurred on the server during API request',
                        detail: httpError.message
                    } as Error;
                }
                else {
                    error = {
                        errorType: 'Unknown',
                        code: httpError.status,
                        message: 'An unknown error occurred',
                        detail: httpError.message
                    } as Error;
                }
                this.showError(error);
                return throwError(error);

            })
        );
    }

    showError(error: Error): void {
        let msg = '';
        if( error.errorType === 'Validation') {
            const errors = (Object as any).values(error.detail).join(',');
            msg = `${errors}. Request failed, Please check the form and submit again`;
        } else if(error.errorType === 'Access'){
            msg = `${error.message}. ${error.detail}`;
        } else {
            msg = `${error.message}. Sorry, the application has encountered an unknown error. We are working on getting this resolved as soon as we can.`;
        }
       this.snackBar.httpError(error.errorType, msg,
            `You can help us improve your experience by sending us your user login, date, time and page that the error was encountered on
            through to technology@fiftydegreesnorth.com`,
            true,
        );
    }
}
