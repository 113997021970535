import { HttpClient } from '@angular/common/http';
import { EventEmitter, Inject, Injectable, OnDestroy } from '@angular/core';
import { ApiPathConfig } from 'app/shared/config/api-path.config';
import { adminEndpoints } from 'app/shared/config/endpoints/admin-endpoints';
import { Business } from 'app/shared/models/commercial/commercial-models';
import { SearchBusinessCriteria } from 'app/shared/models/commercial/search-models';
import { Error } from 'app/shared/models/core/core-models';
import { Status } from 'app/shared/models/ref-data/ref-data-models';
import { Observable, Subject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { RefDataService } from '../../ref-data/ref-data.service';
import { PathUtil } from '../../../utility/path-util';
import { ActivityService } from 'app/core/activity/activity.service';
import { ReadyStateService } from 'app/core/state/ready-state.service';
import { BusinessSearchResult } from 'app/shared/models/commercial/search-result-models';

@Injectable()
export class BusinessSearchService implements OnDestroy {
    deletedAction = new EventEmitter<Business>();

    searchReadyService?: ReadyStateService;

    private readonly _destroying$ = new Subject<void>();

    constructor(
        private httpClient: HttpClient,
        private activityService: ActivityService,
        private refDataService: RefDataService,
        @Inject('SiglaApiPath') private siglaApiPath: ApiPathConfig
    ) {}

    ngOnDestroy(): void {
        this._destroying$.next(undefined);
        this._destroying$.complete();
    }

    /**
     * Business methods
     * Note. Search API calls that rely on pagination do not use a behavior subject to hold results
     */

    search(
        searchCriteria: SearchBusinessCriteria
    ): Observable<BusinessSearchResult[]> {
        const url = PathUtil.combinePaths(
            this.siglaApiPath.apiPath,
            adminEndpoints.commercial.businessSearch
        );

        let searchRequest = this.httpClient.post<BusinessSearchResult[]>(
            url,
            searchCriteria
        );

        if (this.searchReadyService) {
            searchRequest = this.searchReadyService.addLoading(
                'searchBusiness',
                searchRequest
            );
        }

        return searchRequest;
    }

    checkBusinessNameExists( searchCriteria: SearchBusinessCriteria, businessUId: string): Observable<boolean | undefined>  {
        const url = PathUtil.combinePaths(
            this.siglaApiPath.apiPath,
            adminEndpoints.commercial.businessSearch
        );

        return this.httpClient.post<BusinessSearchResult[]>( url, searchCriteria)
            .pipe(map((businessList) => ((businessList.length > 0) && businessList.findIndex((item) => item.businessUId === businessUId) === -1)));
    }

    newBusiness(): void {
        const url = PathUtil.combinePaths(
            this.siglaApiPath.apiPath,
            adminEndpoints.commercial.businessNew
        );

        const newRequest = this.httpClient.get<Business>(url);

        const activity = this.activityService?.newActivity(
            'Adding',
            'Business'
        );

        newRequest.pipe(take(1)).subscribe(
            (business: Business) => {
                if (activity) {
                    this.activityService?.completeActivity(activity, business);
                }
            },
            (error: Error) => {
                if (activity) {
                    this.activityService?.failActivity(activity, error);
                }
            }
        );
    }


    /**
     * Office methods
     */

    officeSearch(
        searchCriteria: SearchBusinessCriteria
    ): Observable<BusinessSearchResult[]> {
        const url = PathUtil.combinePaths(
            this.siglaApiPath.apiPath,
            adminEndpoints.commercial.officeSearch
        );

        let searchRequest = this.httpClient.post<BusinessSearchResult[]>(
            url,
            searchCriteria
        );

        if (this.searchReadyService) {
            searchRequest = this.searchReadyService.addLoading(
                'searchBusiness',
                searchRequest
            );
        }

        return searchRequest;
    }

    /**
     * Agency methods
     */

    agencySearch(
        searchCriteria: SearchBusinessCriteria
    ): Observable<BusinessSearchResult[]> {
        const url = PathUtil.combinePaths(
            this.siglaApiPath.apiPath,
            adminEndpoints.commercial.agencySearch
        );

        let searchRequest = this.httpClient.post<BusinessSearchResult[]>(
            url,
            searchCriteria
        );

        if (this.searchReadyService) {
            searchRequest = this.searchReadyService.addLoading(
                'searchBusiness',
                searchRequest
            );
        }

        return searchRequest;
    }


    newAgency(params: {officeBusinessUId: string}): void {
        const url = PathUtil.combinePaths(
            this.siglaApiPath.apiPath,
            adminEndpoints.commercial.agencyNew
        );

        const newRequest = this.httpClient.get<Business>(url, {params:params});

        const activity = this.activityService?.newActivity(
            'Adding',
            'Business'
        );

        newRequest.pipe(take(1)).subscribe(
            (business: Business) => {
                if (activity) {
                    this.activityService?.completeActivity(activity, business);
                }
            },
            (error: Error) => {
                if (activity) {
                    this.activityService?.failActivity(activity, error);
                }
            }
        );
    }

    /**
     * DMC methods
     */

    dmcSearch(
        searchCriteria: SearchBusinessCriteria
    ): Observable<BusinessSearchResult[]> {
        const url = PathUtil.combinePaths(
            this.siglaApiPath.apiPath,
            adminEndpoints.commercial.dmcSearch
        );

        let searchRequest = this.httpClient.post<BusinessSearchResult[]>(
            url,
            searchCriteria
        );

        if (this.searchReadyService) {
            searchRequest = this.searchReadyService.addLoading(
                'searchBusiness',
                searchRequest
            );
        }

        return searchRequest;
    }


    newDMC(): void {
        const url = PathUtil.combinePaths(
            this.siglaApiPath.apiPath,
            adminEndpoints.commercial.dmcNew
        );

        const newRequest = this.httpClient.get<Business>(url);

        const activity = this.activityService?.newActivity(
            'Adding',
            'Business'
        );

        newRequest.pipe(take(1)).subscribe(
            (business: Business) => {
                if (activity) {
                    this.activityService?.completeActivity(activity, business);
                }
            },
            (error: Error) => {
                if (activity) {
                    this.activityService?.failActivity(activity, error);
                }
            }
        );
    }

    /**
     * Sales office methods
     */

    salesOfficeSearch(
        searchCriteria: SearchBusinessCriteria
    ): Observable<BusinessSearchResult[]> {
        const url = PathUtil.combinePaths(
            this.siglaApiPath.apiPath,
            adminEndpoints.commercial.salesOfficeSearch
        );

        let searchRequest = this.httpClient.post<BusinessSearchResult[]>(
            url,
            searchCriteria
        );

        if (this.searchReadyService) {
            searchRequest = this.searchReadyService.addLoading(
                'searchBusiness',
                searchRequest
            );
        }

        return searchRequest;
    }


    newSalesOffice(): void {
        const url = PathUtil.combinePaths(
            this.siglaApiPath.apiPath,
            adminEndpoints.commercial.salesOfficeNew
        );

        const newRequest = this.httpClient.get<Business>(url);

        const activity = this.activityService?.newActivity(
            'Adding',
            'Business'
        );

        newRequest.pipe(take(1)).subscribe(
            (business: Business) => {
                if (activity) {
                    this.activityService?.completeActivity(activity, business);
                }
            },
            (error: Error) => {
                if (activity) {
                    this.activityService?.failActivity(activity, error);
                }
            }
        );
    }


    /**
     * Supplier methods
     */

    supplierSearch(
        searchCriteria: SearchBusinessCriteria
    ): Observable<BusinessSearchResult[]> {
        const url = PathUtil.combinePaths(
            this.siglaApiPath.apiPath,
            adminEndpoints.commercial.supplierSearch
        );

        let searchRequest = this.httpClient.post<BusinessSearchResult[]>(
            url,
            searchCriteria
        );

        if (this.searchReadyService) {
            searchRequest = this.searchReadyService.addLoading(
                'searchBusiness',
                searchRequest
            );
        }

        return searchRequest;
    }


    newSupplier(params: {officeBusinessUId: string}): void {
        const url = PathUtil.combinePaths(
            this.siglaApiPath.apiPath,
            adminEndpoints.commercial.supplierNew
        );

        const newRequest = this.httpClient.get<Business>(url, {params:params});

        const activity = this.activityService?.newActivity(
            'Adding',
            'Business'
        );

        newRequest.pipe(take(1)).subscribe(
            (business: Business) => {
                if (activity) {
                    this.activityService?.completeActivity(activity, business);
                }
            },
            (error: Error) => {
                if (activity) {
                    this.activityService?.failActivity(activity, error);
                }
            }
        );
    }


    /**
     * Ref data
     */

    getStatusList(): Observable<Status[]> {
        // Get business statuses
        return this.refDataService.getStatusForModel('Business');
    }
}
