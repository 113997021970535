<span *ngIf="productName" [class]="productTypeNameStyles">{{ productName }}&nbsp;</span>

<span *ngIf="rateName" [class]="rateNameStyles">{{ rateName }}&nbsp;</span>

<span *ngIf="productTypeOrGroupLabel"
    [class]="productGroupName ? productGroupNameStyles : productTypeNameStyles"
    >{{ productTypeOrGroupLabel }}&nbsp;</span
>

<span class="text-sm italic font-bold">by&nbsp;</span>

<div
*ngIf="supplierMenuActivated"
    [class.preferred]="preferredSupplier"
    class="flex flex-row items-center gap-1"
    #trigger="matMenuTrigger"
    [matMenuTriggerFor]="menu"
    (mouseenter)="trigger.openMenu()"
>
    <span [class]="supplierNameStyles">{{ supplierName }}</span>
    <mat-icon
        *ngIf="preferredSupplier"
        [class.preferred]="preferredSupplier"
        [svgIcon]="'star_rate'"
    >
    </mat-icon>
    <mat-menu
        #menu="matMenu"
        backdropClass="attribute-menu-backdrop"
        class="attribute-menu"
    >
        <div
            (mouseenter)="trigger.openMenu()"
            (mouseleave)="trigger.closeMenu()"
            class="flex flex-col items-start justify-center gap-2 px-3 py-2 -my-2 border-t border-b border-solid bg-default bg-opacity-20"
        >
            <a
                class="flex items-center justify-center gap-2"
                [href]="'https://www.google.com/maps/place/' + 'location'"
                target="blank"
            >
                <mat-icon
                    class="text-current icon-size-5"
                    svgIcon="location_on"
                ></mat-icon>
                <span class="text-xs font-bold">12 Sunshine Street</span>
            </a>

            <a
                class="flex items-center justify-center gap-2"
                [href]="'tel:+6147001470'"
            >
                <mat-icon
                    class="text-current icon-size-5"
                    svgIcon="phone_iphone"
                ></mat-icon>
                <span class="text-xs font-bold">+61 4700 1470</span>
            </a>

            <a
                class="flex items-center justify-center gap-2"
                [href]="'mailto:abc@mail.com'"
            >
                <mat-icon
                    class="text-current icon-size-5"
                    svgIcon="email"
                ></mat-icon>
                <span class="text-xs font-bold">abc@mail.com</span>
            </a>

            <a
                class="flex items-center justify-center gap-2"
                [href]="'www.abc.com'"
                target="blank"
            >
                <mat-icon
                    class="text-current icon-size-5"
                    svgIcon="web_asset"
                ></mat-icon>
                <span class="text-xs font-bold">www.abc.com</span>
            </a>
        </div>
    </mat-menu>
</div>


<div
*ngIf="!supplierMenuActivated"
    [class.preferred]="preferredSupplier"
    class="flex flex-row items-center gap-1"
>
    <span [class]="supplierNameStyles">{{ supplierName }}</span>
    <mat-icon
        *ngIf="preferredSupplier"
        [class.preferred]="preferredSupplier"
        [svgIcon]="'star_rate'"
    >
    </mat-icon>
</div>
