import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { ChangeDetectorRef, Component, OnInit, Self, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { ImageUploaderOptions } from 'app/shared/models/common/image-uploader-models';
import { toCamelCase } from 'app/shared/utility/formatter-utils';
import { siglaLog } from 'app/shared/utility/logging-util';
import { environment } from 'environments/environment';

import { Content, ContentBlock } from '../../types/editor';

@Component({
    selector: 'app-editor',
    templateUrl: './editor.component.html',
    styleUrls: ['./editor.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class EditorComponent implements ControlValueAccessor {
    content: Content = { time: 0, blocks: [] };
    deleteMode = false;
    imageOptions: ImageUploaderOptions = {
        uploadUrl: environment.api.siglaApi + '/common/upload/image',
        allowedImageTypes: ['image/jpeg', 'image/jpg', 'image/webp'],
        thumbnailResizeMode: 'fill',
        cropEnabled: false,
        autoUpload: true,
        resizeOnLoad: true,
        thumbnailWidth: 680,
        thumbnailHeight: 380,
        maxImageSize: .8,
    };

    onTouched!: () => void;
    constructor(@Self() public controlDir: NgControl, public cdRef: ChangeDetectorRef) {
        controlDir.valueAccessor = this;
    }
    writeValue(json: string | null): void {
        if (json != null) {
            this.content = toCamelCase(JSON.parse(json));
            this.cdRef.detectChanges();
        }
    }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    editorChange(): void {
        this.content.time = Date.now();
        siglaLog(this.content);
        const json: string | null = JSON.stringify(this.content);
        this.onChange(json);
        this.cdRef.detectChanges();
    }

    drop(event: CdkDragDrop<string[]>): void {
        moveItemInArray(this.content.blocks, event.previousIndex, event.currentIndex);
        this.editorChange();
    }

    remove(block: ContentBlock): void {
        if (this.deleteMode === false) {
            this.deleteMode = true;
        } else {
            const id = block.id;
            const index = this.content.blocks.findIndex((contentBlock: ContentBlock) => contentBlock.id === id);
            this.content.blocks.splice(index, 1);
            this.editorChange();
            this.deleteMode = false;
        }

    }

    onChange = (val: string): void => { };
}

