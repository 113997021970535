import { Injectable, OnDestroy } from '@angular/core';
import { ChangeItem, ChangeTicket } from 'app/shared/models/core/core-models';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
    providedIn: 'root',
})
export class ChangeService implements OnDestroy {

    private readonly _change$ = new Subject<ChangeItem>();

    /**
     * Deprecated properties. To be coded out of the app
     */
    private readonly _newObject$ = new Subject<ChangeTicket>();
    private readonly _changedObject$ = new Subject<ChangeTicket>();
    private readonly _deletedObject$ = new Subject<ChangeTicket>();
    private readonly _clonedObject$ = new Subject<ChangeTicket>();

    // Deprecated end

    private readonly _destroying$ = new Subject<void>();

    constructor() {
     }

    ngOnDestroy(): void {
        this._destroying$.next(undefined);
        this._destroying$.complete();
    }

    get change$(): Observable<ChangeItem> {
        return this._change$.asObservable().pipe(takeUntil(this._destroying$));
    }
    /**
     * Deprecated accessors. To be coded out of the app
     */

    get newObject$(): Observable<ChangeTicket> {
        return this._newObject$
            .asObservable()
            .pipe(takeUntil(this._destroying$));
    }

    get changedObject$(): Observable<ChangeTicket> {
        return this._changedObject$
            .asObservable()
            .pipe(takeUntil(this._destroying$));
    }

    get deletedObject$(): Observable<ChangeTicket> {
        return this._deletedObject$
            .asObservable()
            .pipe(takeUntil(this._destroying$));
    }

    get clonedObject$(): Observable<ChangeTicket> {
        return this._clonedObject$
            .asObservable()
            .pipe(takeUntil(this._destroying$));
    }

    // Deprecated end

    notify(changedItem: ChangeItem): void {
        // change type :
        this._change$.next(changedItem);
    }


    /**
     * Deprecated methods. To be coded out of app
     */

    notifyNew(newObject: ChangeTicket): void {
        this._newObject$.next(newObject);
        if (newObject.messageName) {
            const messageName = newObject.messageName
                .replace(/([A-Z])/g, ' $1')
                .trim();
            const message = messageName + ' ' + 'Added Successfully';
        }
    }

    notifyChange(changedObject: ChangeTicket): void {
        this._changedObject$.next(changedObject);
        if (changedObject.messageName) {
            const messageName = changedObject.messageName
                .replace(/([A-Z])/g, ' $1')
                .trim();
            const message = messageName + ' ' + 'Updated Successfully';
        }
    }

    notifyDelete(changedObject: ChangeTicket): void {
        if (changedObject.messageName) {
            const messageName = changedObject.messageName
                .replace(/([A-Z])/g, ' $1')
                .trim();
            const message = messageName + ' ' + 'Deleted Successfully';
        }
        this._deletedObject$.next(changedObject);
    }

    notifyClone(changedObject: ChangeTicket): void {
        if (changedObject.messageName) {
            const messageName = changedObject.messageName
                .replace(/([A-Z])/g, ' $1')
                .trim();
            const message = messageName + ' ' + 'Copied Successfully';
        }
        this._clonedObject$.next(changedObject);
    }

    // Deprecated end

    generateChangeUID(): string {
        return uuidv4();
    }

}
