export class Place {
    display: FormattedAddressPart[] = defaultPlaceDisplayValue;
    addressLine: string | undefined;
    town: string | undefined;
    state: string | undefined;
    postalCode: string | undefined;
    country: string | undefined;
    latitude: number | undefined;
    longitude: number | undefined;
    provider?: {
        name: 'google';
        uId: string | undefined;
    };
    hasLocation?: undefined | boolean;
    hasCoordinate?: undefined | boolean;
}
export interface FormattedAddressPart { type: string; value: string };

export const defaultPlaceDisplayValue = [
    { type: 'addressLine', value: '' },
    { type: 'town', value: '' },
    { type: 'state', value: '' },
    { type: 'postalCode', value: '' },
    { type: 'country', value: '' }
];

export const defaultPlaceValue: Place = {
    display: defaultPlaceDisplayValue,
    addressLine: '',
    town: '',
    state: '',
    postalCode: '',
    country: '',
    latitude: undefined,
    longitude: undefined,
};


