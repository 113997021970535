/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { LayoutComponent } from 'app/layout/layout.component';
import { PageRoute } from './shared/modules/page-layout/interfaces/page-data';
import { MsalAuthGuard } from './core/auth/guards/msal-auth.guard';


// @formatter:off
// tslint:disable:max-line-length
export const appRoutes: PageRoute[] = [



    // Redirect empty path to '/example'
    { path: '', pathMatch: 'full', redirectTo: 'example' },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [MsalAuthGuard],
        canActivateChild: [MsalAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'sign-out',
                loadChildren: () =>
                    import('app/modules/auth/sign-out/sign-out.module').then(
                        m => m.AuthSignOutModule
                    ),
            },
        ],
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'home',
                loadChildren: () =>
                    import('app/modules/landing/home/home.module').then(
                        m => m.LandingHomeModule
                    ),
            },
        ],
    },


    // Admin routes
    {
        path: '',
        canActivate: [MsalAuthGuard],
        canActivateChild: [MsalAuthGuard],
        component: LayoutComponent,
        children: [

            {
                path: 'admin',
                loadChildren: () =>
                    import(
                        'app/modules/admin/administration.module'
                    ).then(m => m.AdministrationModule),
            },
            {
                path: 'app',
                loadChildren: () =>
                    import(
                        'app/modules/base/base.module'
                    ).then(m => m.BaseModule),
            },
            {
                path: 'settings',
                loadChildren: () =>
                    import(
                        'app/modules/settings/settings.module'
                    ).then(m => m.SettingsModule),
            },
            {
                path: 'knowledge-admin',
                loadChildren: () =>
                    import(
                        'app/modules/knowledge-admin/knowledge-admin.module'
                    ).then(m => m.KnowledgeAdminModule),
            },
            {
                path: 'reservations',
                loadChildren: () =>
                    import(
                        'app/modules/reservations/reservations.module'
                    ).then(m => m.ReservationsModule),
            },
            {
                path: 'knowledge',
                loadChildren: () =>
                    import(
                        'app/modules/knowledge/knowledge.module'
                    ).then(m => m.KnowledgeModule),
            },
            {
                path: 'style-guide',
                loadChildren: () =>
                    import('app/modules/style-guide/style-guide.module').then(
                        m => m.StyleGuideModule
                    ),
            },
            {
                path: '**',
                pathMatch: 'full',
                redirectTo: 'admin'
            }
        ],
    },
];
