import { Component,ChangeDetectionStrategy, ViewEncapsulation} from '@angular/core';
import { FieldType } from '@ngx-formly/core';
@Component({
  selector: 'app-formly-autocomplete',
  templateUrl: './formly-autocomplete.component.html',
  styleUrls: ['../../styles/ng-select.scss','./formly-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class FormlyAutocompleteComponent extends FieldType {
    override formControl: any;

}
