import { Component,ChangeDetectionStrategy, ViewEncapsulation, OnInit} from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-separator',
  templateUrl: './formly-separator.component.html',
  styleUrls: ['./formly-separator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class FormlySeparatorComponent extends FieldType implements OnInit  {
    override formControl: any;

    ngOnInit(): void {
        this.field.className = this.field.className + ' separator';
     }
}
