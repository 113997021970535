/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';
import { environment } from 'environments/environment';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'reservations',
        title: 'Bookings',
        type: 'group',
        icon: 'bookmark',
        /*         disabled: environment.disableMenu, */
        //    subtitle:'Description goes here.',
        children: [
            {
                id: 'bookingDashboard',
                title: 'Booking Dashboard',
                type: 'basic',
                icon: 'dashboard',
                link: '/reservations/booking-dashboard',
                newTabLink: true,
                exactMatch: true
            },
            {
                id: 'direct-client',
                title: 'New Direct Booking ',
                subtitle: 'Shift + D',
                type: 'basic',
                icon: 'person',
                link: '/reservations/booking-dashboard/direct-client',
                newTabLink: true,

            },
            {
                id: 'agent',
                title: 'New Agency Booking ',
                subtitle: 'Shift + A',
                type: 'basic',
                icon: 'business',
                link: '/reservations/booking-dashboard/agent',
                newTabLink: true,
            },
            // ,
            // {
            //     id: 'bookingSearch',
            //     title: 'Booking Search',
            //     type: 'basic',
            //     icon: 'flight_takeoff',
            //     link: '/reservations/booking-search',
            //     newTabLink:true
            // }
        ],
    },
    {
        type: 'divider',
    },
    {
        id: 'admin',
        title: 'Products & Business',
        type: 'collapsable',
        icon: 'admin_panel_settings',
        //  subtitle:'Description goes here.',
        children: [
            {
                id: 'businessSearch',
                title: 'Business Search',
                type: 'basic',
                icon: 'business_center',
                link: '/admin/business-search',
                newTabLink: true,
            },
            {
                id: 'productSearch',
                title: 'Product Search',
                type: 'basic',
                icon: 'shopping_cart',
                link: '/admin/product-search',
                newTabLink: true,
            },
            {
                id: 'packageSearch',
                title: 'Package Search',
                type: 'basic',
                icon: 'shopping_cart',
                link: '/admin/package-search',
                newTabLink: true,
            },
            /*             {
                id: 'bookingSearch',
                title: 'Booking Search',
                type: 'basic',
                disabled: environment.disableMenu,
                icon: 'flight_takeoff',
                link: '/admin/booking-search'
            } */
        ],
    },
    {
        id: 'knowledgeAdmin',
        title: 'Knowledge Admin',
        type: 'collapsable',
        icon: 'map',
        /*         disabled: environment.disableMenu, */
        //  subtitle:'Description goes here.',
        children: [
            {
                id: 'details',
                title: 'Wiki',
                type: 'basic',
                icon: 'map',
                link: '/knowledge',
                newTabLink: true,
            },
            {
                id: 'poiSearch',
                title: 'Point of Interest Search',
                type: 'basic',
                icon: 'location_on',
                link: '/knowledge-admin/poi-search',
                newTabLink: true,
            },
            {
                id: 'experienceSearch',
                title: 'Experience Search',
                type: 'basic',
                icon: 'insights',
                link: '/knowledge-admin/experience-search',
                newTabLink: true,
            },
            {
                id: 'highlightSearch',
                title: 'Highlight Search',
                type: 'basic',
                icon: 'star',
                link: '/knowledge-admin/highlight-search',
                newTabLink: true,
            },
        ],
    },

    {
        id: 'settings',
        title: 'Settings',
        type: 'basic',
        icon: 'settings',
        //    subtitle:'Description goes here.',
        link: '/settings',
        newTabLink: true,
    },
];
export const compactNavigation: FuseNavigationItem[] = defaultNavigation;
export const futuristicNavigation: FuseNavigationItem[] = defaultNavigation;
export const horizontalNavigation: FuseNavigationItem[] = defaultNavigation;
