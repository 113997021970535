import { ICellRendererParams } from '@ag-grid-community/all-modules';
import { AgRendererComponent } from '@ag-grid-community/angular';
import { Component, ViewEncapsulation } from '@angular/core';

@Component({
    selector:'ag-grid-row-delete',
    template: `<button *ngIf="!params?.node?.expanded" mat-icon-button color="warn">
       <mat-icon svgIcon="delete"></mat-icon>
    </button>`,
     encapsulation: ViewEncapsulation.None,
     styles: [
        `
            ag-grid-row-delete {
                width: 40px;
                justify-content: center;
                display: flex;
            }
        `,
    ],

})
export class AgGridRowDeleteRendererComponent implements AgRendererComponent {
    public params: ICellRendererParams | undefined;
    refresh(params: ICellRendererParams): boolean {
        return false;
    }
    agInit(params: ICellRendererParams): void {
        this.params = params;
    }
}
