import {
    Component,
    OnDestroy,
    ViewEncapsulation,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import {  takeUntil, withLatestFrom } from 'rxjs/operators';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import {
    FuseNavigationItem,
    FuseNavigationService,
    FuseVerticalNavigationComponent,
} from '@fuse/components/navigation';
import { User } from 'app/core/user/user.types';
import { UserService } from 'app/core/user/user.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen';
import { Business } from 'app/shared/models/commercial/commercial-models';
import { getNavigation } from '../../data';



@Component({
    selector: 'classy-layout',
    templateUrl: './classy.component.html',
    styleUrls: ['./classy.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ClassyLayoutComponent implements OnDestroy {
    isScreenSmall: boolean | undefined;
    navigation: FuseNavigationItem[] = [];
    userOfficeList$: Observable<Business[]> = this._userService.userOfficeList$;
    selectedBusiness$: Observable<Business | undefined> = this._userService.selectedBusiness$;
    user$: Observable<User | undefined> = this._userService.user$;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _userService: UserService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSplashScreenService: FuseSplashScreenService,
    ) {
        this.selectedBusiness$.pipe(
            withLatestFrom(this.user$),

        ).subscribe(([selectedOffice, user]) => {

            if (
                (
                    user &&
                    Object.keys(user).length > 0 &&
                    selectedOffice &&
                    Object.keys(selectedOffice).length > 0
                )
            ) {
                this.navigation = getNavigation(selectedOffice.nickname ? selectedOffice.nickname : selectedOffice.name);
                this._fuseSplashScreenService.hide();
            }
        });


        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {
                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('xxl');
            });

    }


    updateSelectedOffice(selectedOffice: Business): void {
        this._userService.selectedBusiness = selectedOffice;
        this.navigation = getNavigation(selectedOffice.nickname ? selectedOffice.nickname : selectedOffice.name);
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation =
            this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
                name
            );

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }
}

