/* eslint-disable @typescript-eslint/naming-convention */
import { defaultPlaceDisplayValue, FormattedAddressPart, Place } from 'app/shared/models/shared/place-model';
import { siglaLog } from 'app/shared/utility/logging-util';

export const mapGoogleAddress = (googlePlace: google.maps.places.PlaceResult): Place => {
    const address_components = googlePlace.address_components || [];
    const formatted_address = googlePlace.formatted_address || '';
    const place: Place = {
        display: defaultPlaceDisplayValue,
        addressLine: getPlaceAddressLineValue(address_components, formatted_address),
        town: getPlaceFieldValue(address_components, ['locality'], formatted_address),
        state: getPlaceFieldValue(address_components, ['postal_town', 'administrative_area_level_1', 'administrative_area_level_2'], formatted_address),
        postalCode: getPlaceFieldValue(address_components, ['postal_code'], formatted_address),
        country: getPlaceFieldValue(address_components, ['country'], formatted_address),
        latitude: googlePlace.geometry?.location.lat(),
        longitude: googlePlace.geometry?.location.lng(),
        provider: {
            name: 'google',
            uId: googlePlace.place_id
        },
    };
    place.display = getPlaceDisplayValue(place, formatted_address);
    siglaLog(formatted_address);
    siglaLog(place.display);
    return place;
};


const getPlaceFieldValue = (components: google.maps.GeocoderAddressComponent[], types: string[], formattedAddress: string): undefined | string => {
    if (types.length === 1) {
        return components.find((component) => component.types.indexOf(types[0]) > -1)?.long_name;
    } else {
        const componentTypeMatches: string[] = [];
        types.forEach((type) => {
            const component = components.find((comp) => comp.types.indexOf(type) > -1);
            if (component) {
                componentTypeMatches.push(component.short_name);
                componentTypeMatches.push(component.long_name);
            }
        });
        return componentTypeMatches.find((match) => formattedAddress?.includes(match));
    }
};


const getPlaceAddressLineValue = (components: google.maps.GeocoderAddressComponent[], formattedAddress: string): string => {
    const filteredComponents = components.filter((comp) => comp.types.includes('subpremise') || comp.types.includes('street_number') || comp.types.includes('route'));
    const allAddressPartsIncludedInFormattedAddress = filteredComponents.every((c) =>
        formattedAddress.toLowerCase()
            .includes(
                c.long_name.toLowerCase() ||
                c.short_name.toLowerCase()
            ));
    if (allAddressPartsIncludedInFormattedAddress) {
        filteredComponents.sort((a: google.maps.GeocoderAddressComponent, b: google.maps.GeocoderAddressComponent,): number => {
            const aIndex = getFormattedStringIndex(a, formattedAddress as string);
            const bIndex = getFormattedStringIndex(b, formattedAddress as string);
            return aIndex - bIndex;
        });
    }
    const addressLine = filteredComponents.map((c) => c.long_name).join(' ');
    return addressLine;

};

const getPlaceDisplayValue = (place: Place, formattedAddress: string): FormattedAddressPart[] => {
    const placePartList = [
        { type: 'addressLine', value: place.addressLine },
        { type: 'town', value: place.town },
        { type: 'state', value: place.state },
        { type: 'postalCode', value: place.postalCode },
        { type: 'country', value: place.country }].
        filter((placePart) => placePart.value !== undefined && placePart.value !== '') as FormattedAddressPart[];

    const allAddressPartsIncludedInFormattedAddress = placePartList.every((placePart) => formattedAddress.toLowerCase().includes(placePart.value.toLowerCase()));

    if (allAddressPartsIncludedInFormattedAddress) {
        placePartList.sort((a: FormattedAddressPart, b: FormattedAddressPart): number => {
            const aIndex = formattedAddress.indexOf(a.value);
            const bIndex = formattedAddress.indexOf(b.value);
            return aIndex - bIndex;
        });
    }
    return placePartList;
};

const getFormattedStringIndex = (component: google.maps.GeocoderAddressComponent, formattedAddress: string): number => {
    const shortNameIndex = formattedAddress.indexOf(component.short_name);
    const longNameIndex = formattedAddress.indexOf(component.long_name);
    return Math.max(shortNameIndex, longNameIndex);

};



