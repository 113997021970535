import { Component, ChangeDetectionStrategy, Input, OnChanges } from '@angular/core';

@Component({
    selector: 'app-human-readable-product-name',
    templateUrl: './human-readable-product-name.component.html',
    styleUrls: ['./human-readable-product-name.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HumanReadableProductNameComponent implements OnChanges {
    @Input() preferredSupplier: boolean = false;
    @Input() supplierName: string | undefined = undefined;
    @Input() supplierNameStyles: string = 'font-bold underline';
    @Input() supplierMenuActivated: boolean  = true;
    @Input() productTypeName: string = '';
    @Input() productTypeNameStyles: string = 'font-bold underline';
    @Input() productGroupName: string | undefined = undefined;
    @Input() productGroupNameStyles: string = 'font-bold underline';
    @Input() productName: string | undefined = undefined;
    @Input() productNameStyles: string = 'font-bold underline';
    @Input() rateName: string | undefined = undefined;
    @Input() rateNameStyles: string = 'font-bold underline';
    productTypeOrGroupLabel: string | undefined = undefined;
    constructor() { }

    ngOnChanges(): void {
        this.configureProductName();
    }

    configureProductName(): void {
        if (this.supplierName && this.productTypeName && this.productName) {
            const productTypeOrGroupLabel = this.productGroupName !== undefined ? this.productGroupName : this.productTypeName;
            const productNameLowercase = this.productName.toLowerCase();
            const productTypeOrGroupLabelLowercase = productTypeOrGroupLabel?.toLowerCase();
            if (productNameLowercase.includes(productTypeOrGroupLabelLowercase) === false) {
                this.productTypeOrGroupLabel = productTypeOrGroupLabel;
            }
        } else {
            console.error('supplierName, productTypeName & productName is required for the HumanReadableProductNameComponent');
        }
    }

}
