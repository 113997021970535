<ng-select
    [items]="null"
    [searchable]="false"
    [formControl]="formControl"
    [class.is-invalid]="showError"
    [clearable]="false"
>
    <ng-template ng-label-tmp let-item="item">
        <div class="flex flex-row items-center justify-between w-full gap-4 font-bold text-md">
            <mat-icon
                class="text-current"
                [svgIcon]="item"
            ></mat-icon>
            <span class="ng-value-label">{{ item }} </span>
        </div>
    </ng-template>

    <ng-template ng-header-tmp>
        <div class="flex flex-row flex-wrap justify-center w-full">
            <div class="px-2 py-4 w-80">
                <input class="w-full h-8 px-2 border border-solid rounded-sm bg-default" placeholder="Search For Icons" type="text" (input)="autocompleteChange($event)" />
            </div>

            <div class="relative mb-4 w-80">
                <cdk-virtual-scroll-viewport appendOnly itemSize="5" class="w-full min-h-80" orientation="vertical">
                    <div *cdkVirtualFor="let option of options$ | async" (click)="selectIcon(option)" class="flex items-center justify-center cursor-pointer hover:bg-default">
                        <mat-icon
                        class="text-current icon-size-8"
                        [svgIcon]="option"
                    ></mat-icon>
                    </div>
                  </cdk-virtual-scroll-viewport>

            </div>
        </div>


    </ng-template>

<!--     <ng-template ng-option-tmp let-item="item">
<!--         <div
            class="flex flex-row items-center justify-center gap-1 text-md"
            title="{{ item }}"
        >
     <!--        <mat-icon
                class="text-current icon-size-4"
                [svgIcon]="item"
            ></mat-icon>
            {{ item }}
        </div> -
    </ng-template> -->
</ng-select>
