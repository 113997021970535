import { HttpClient } from '@angular/common/http';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Inject, OnDestroy, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { FieldType } from '@ngx-formly/core';
import { ApiPathConfig } from 'app/shared/config/api-path.config';
import { PathUtil } from 'app/shared/utility/path-util';
import { Observable, of, Subject } from 'rxjs';
import { takeUntil, startWith, debounceTime, distinctUntilChanged, switchMap, filter, map, tap, catchError } from 'rxjs/operators';

@Component({
  selector: 'app-formly-type-ahead',
  templateUrl: './formly-type-ahead.component.html',
  styleUrls: ['../../styles/ng-select.scss', './formly-type-ahead.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class FormlyTypeAheadComponent extends FieldType implements OnDestroy, OnInit, AfterViewInit {
  @ViewChild('select', { static: true }) select!: NgSelectComponent;
  override formControl: any;
  onDestroy$ = new Subject<void>();
  search$ = new EventEmitter();
  options$!: Observable<any>;
  loading: boolean = false;
  searchTerm!: string;
  inputElement!: any;
  constructor(private httpClient: HttpClient, @Inject('SiglaApiPath') private siglaApiPath: ApiPathConfig,
    private renderer2: Renderer2) {
    super();
  }

  ngAfterViewInit(): void {

    this.inputElement = this.select.element.querySelector('input');

    this.renderer2.listen(this.inputElement, 'input', (event) => {
      this.searchTerm = event.target.value;
    });
  }

  ngOnInit(): void {

    this.options$ = this.search$.pipe(
      takeUntil(this.onDestroy$),
      startWith(''),
      filter(v => v !== null),
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((term: string): Observable<any> => {
        if ((!term || term === '')) {
          return of([]);
        }
        let url = PathUtil.combinePaths(
          this.siglaApiPath.apiPath,
          this.to['url']
        );
        if (term) {
          url = PathUtil.addParam(url, 'name=' + term);
        }
        this.loading = true;
        return this.httpClient
          .get<any[]>(url).pipe(
            map((list: any[]) => {
              if (list.length) {
                return list.map((r: any) =>
                  ({ label: r.name, value: r.uId })
                );
              } else {
                return [{ label: term, value: '' }];
              }
            }

            ),
            catchError(() => of([{ label: term, value: '' }])), // empty list on error
            tap(() => this.loading = false)
          );
      }),
    );

    if (this.to['uId']) {
      this.formControl._parent.addControl(this.to['uId'], new FormControl(''));
    }
    if (this.to['name'] && !this.formControl._parent.get(this.to['name'])) {
      this.formControl._parent.addControl(this.to['name'], new FormControl(''));
    }
    this.options$.subscribe();
  }

  changeSelection(e: any): void {
    if (e) {
      if (this.formControl._parent) {
        this.searchTerm = e.label;
        this.formControl._parent.get(this.to['uId'])?.setValue(e.value);
        this.model[this.to['uId']] = e.value;
        this.model[this.to['name']] = e.label;
        this.formControl._parent.get(this.to['name'])?.setValue(e.label);
      }
    } else {
      this.formControl._parent.get(this.to['uId'])?.setValue(null);
      this.formControl._parent.get(this.to['name'])?.setValue(null);
    }
  }


  clear(): void {
    this.searchTerm = '';
  }

  close(): void {
    if (this.searchTerm !== this.formControl._parent.get(this.to['name']).value) {
      this.formControl._parent.get(this.to['name']).setValue(this.searchTerm);
      this.inputElement.value = this.searchTerm;
      this.model[this.to['name']] = this.searchTerm;
      this.formControl.setValue(this.searchTerm);
    }
  }



  ngOnDestroy(): void {
    this.onDestroy$.complete();
  }
}


