import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgGridWrapperComponent } from './components/ag-grid-wrapper/ag-grid-wrapper.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { GeneralUtilitiesModule } from '../general-utilities/general-utilities.module';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AgGridDetailComponent } from './components/ag-grid-detail/ag-grid-detail.component';
import { AgGridTemplateRendererComponent } from './components/ag-grid-template-renderer/ag-grid-template-renderer.component';
import { AgGridRowDeleteRendererComponent } from './components/ag-grid-row-delete-renderer/ag-grid-row-delete-rendere.component';
import { AgGridModule } from '@ag-grid-community/angular';
import { AgGridButtonCellRendererComponent } from './components/ag-grid-button-cell-renderer/ag-grid-button-cell-renderer.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AgGridCalendarDatePickerComponent } from './fields/ag-grid-calendar-date-picker/ag-grid-calendar-date-picker.component';
import { AgGridCalendarWeekdayPickerComponent } from './fields/ag-grid-calendar-weekday-picker/ag-grid-calendar-weekday-picker.component';
import { AgGridSelectComponent } from './fields/ag-grid-select/ag-grid-select.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AgGridNewTabCellRendererComponent } from './components/ag-grid-new-tab-cell-renderer/ag-grid-new-tab-cell-renderer.component';
import { AgGridRowCopyRendererComponent } from './components/ag-grid-row-copy-renderer/ag-grid-row-copy-renderer.component';
import { AgGridBooleanCellRendererComponent } from './components/ag-grid-bool-cell-renderer/ag-grid-bool-cell-renderer.component';
import { AgGridInputComponent } from './fields/ag-grid-input/ag-grid-input.component';
import { AgGridDatepickerComponent } from './fields/ag-grid-datepicker/ag-grid-datepicker.component';

import { AgGridActionCellRendererComponent } from './components/ag-grid-action-cell-renderer/ag-grid-action-cell-renderer.component';
import { AgGridCheckboxComponent } from './fields/ag-grid-checkbox/ag-grid-checkbox.component';
import { AgGridCheckboxCellRendererComponent } from './components/ag-grid-checkbox-cell-renderer/ag-grid-checkbox-cell-renderer.component';
import { AgGridSeriesGroupRendererComponent } from './components/ag-grid-series-group-renderer/ag-grid-series-group-renderer.component';
import { MatMenuModule } from '@angular/material/menu';
import { AgGridTypeAheadCellRendererComponent } from './fields/ag-grid-type-ahead-cell-renderer/ag-grid-type-ahead-cell-renderer.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { AgGridPriceBreakTypeaheadComponent } from './fields/ag-grid-price-break-typeahead/ag-grid-price-break-typeahead.component';
import { MatChipsModule } from '@angular/material/chips';
import { AgGridPriceBreakRendererComponent } from './fields/ag-grid-price-break-renderer/ag-grid-price-break-renderer.component';
import { AgGridLinkCellRendererComponent } from './components/ag-grid-link-cell-renderer/ag-grid-link-cell-renderer.component';
import { AgGridTemplateCellRendererComponent } from './components/ag-grid-template-cell-renderer/ag-grid-template-cell-renderer.component';
import { AgGridParentNameRendererComponent } from './components/ag-grid-parent-name-renderer/ag-grid-parent-name-renderer.component';
import { AgGridAttributeInfoRendererComponent } from './components/ag-grid-attribute-info-renderer/ag-grid-attribute-info-renderer.component';

@NgModule({
    declarations: [
        AgGridWrapperComponent,
        AgGridDetailComponent,
        AgGridTemplateRendererComponent,
        AgGridRowDeleteRendererComponent,
        AgGridButtonCellRendererComponent,
        AgGridCalendarDatePickerComponent,
        AgGridCalendarWeekdayPickerComponent,
        AgGridSelectComponent,
        AgGridNewTabCellRendererComponent,
        AgGridRowCopyRendererComponent,
        AgGridBooleanCellRendererComponent,
        AgGridDatepickerComponent,
        AgGridInputComponent,
        AgGridActionCellRendererComponent,
        AgGridCheckboxComponent,
        AgGridCheckboxCellRendererComponent,
        AgGridSeriesGroupRendererComponent,
        AgGridTypeAheadCellRendererComponent,
        AgGridPriceBreakTypeaheadComponent,
        AgGridPriceBreakRendererComponent,
        AgGridLinkCellRendererComponent,
        AgGridTemplateCellRendererComponent,
        AgGridParentNameRendererComponent,
        AgGridAttributeInfoRendererComponent
    ],
    imports: [
        CommonModule,
        MatBottomSheetModule,
        GeneralUtilitiesModule,
        MatButtonModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatCheckboxModule,
        MatSelectModule,
        MatDatepickerModule,
        MatInputModule,
        MatSlideToggleModule,
        MatTooltipModule,
        MatMenuModule,
        FormsModule,
        AgGridModule,
        MatChipsModule,
        NgSelectModule
    ],
    exports: [
        AgGridWrapperComponent,
        AgGridDetailComponent,
        AgGridRowDeleteRendererComponent,
        AgGridModule,
        AgGridButtonCellRendererComponent,
        AgGridCalendarDatePickerComponent,
        AgGridCalendarWeekdayPickerComponent,
        AgGridNewTabCellRendererComponent,
        AgGridRowCopyRendererComponent,
        AgGridBooleanCellRendererComponent,
        AgGridDatepickerComponent,
        AgGridInputComponent,
        AgGridSelectComponent,
        AgGridLinkCellRendererComponent,
        AgGridTemplateCellRendererComponent
    ],
})
export class AgGridUtilitiesModule { }
