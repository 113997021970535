<ng-select
    #ngSelect
    *ngIf="to.options | formlySelectOptions: field | async"
    [items]="travellerAssignments"
    [formControl]="formControl"
    [groupBy]="to['groupBy']"
    [class.is-invalid]="showError"
    [clearable]="false"
    [multiple]="true"
    [closeOnSelect]="false"
    [hideSelected]="true"
    [searchable]="true"
    [selectableGroupAsModel]="false"
>
    <ng-template ng-label-tmp let-item="item">
        <div class="flex flex-row items-center justify-center gap-1 text-md">
            <span
                (click)="clearItem(item.value)"
                aria-hidden="true"
                class="ng-value-icon left"
                >×</span
            >
            <span class="ng-value-label">{{ item.label }} </span>
        </div>
    </ng-template>
    <ng-template ng-option-tmp let-item="item">
        <div
            class="flex flex-row items-center justify-center gap-1 text-md"
            title="{{ item.label }}"
        >
            {{ item.label }}
        </div>
    </ng-template>
</ng-select>
