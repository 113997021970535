import { Directive, Input, OnChanges, OnDestroy, OnInit, Renderer2 } from '@angular/core';
let  sidenavCount = 0;
const  sidenavList: boolean[] = [];
@Directive({
    selector: '[navPosition]',
    exportAs: 'navPosition',
})
export class NavPositionDirective implements OnInit, OnChanges, OnDestroy {
    @Input()
    navPosition!: boolean;
    openSidenavCount = 0;
    sidenavIndex = 0;
    constructor(private renderer: Renderer2) { }
    ngOnInit(): void {
        this.sidenavIndex = sidenavCount;
        sidenavList[this.sidenavIndex] = this.navPosition;
        this.adjustStyles();
        sidenavCount = sidenavCount + 1;
    }
    ngOnChanges(): void {
        sidenavList[this.sidenavIndex] = this.navPosition;
        this.adjustStyles();
    }

    ngOnDestroy(): void {
        sidenavList.splice(this.sidenavIndex,1);
        this.adjustStyles();
        sidenavCount = sidenavCount - 1;
    }

    adjustStyles(): void {
        const body = document.body;
        const openSidenavCount = sidenavList.filter(sidenav => sidenav === true)?.length;
        if(openSidenavCount !== undefined) {
            this.renderer.setProperty(body,'style', '--drawer-offset:' + (280 * openSidenavCount) + ';');
        }
    }

}
