/* eslint-disable @typescript-eslint/naming-convention */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormlyFormWrapperComponent } from './components/formly-form-wrapper/formly-form-wrapper.component';
import { FormActionsComponent } from './components/form-actions/form-actions.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FormlyModule, FORMLY_CONFIG } from '@ngx-formly/core';
import { FormlyWrapperAddons } from './addons/addons.wrapper';
import { addonsExtension } from './addons/addons.extension';
import { FormlyMaterialModule } from '@ngx-formly/material';
import {
    DateAdapter,
    MatNativeDateModule,
    MAT_DATE_FORMATS,
    NativeDateAdapter,
} from '@angular/material/core';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { NgSelectModule } from '@ng-select/ng-select';
import {
    EmailValidator,
    FormsModule,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { FormlyAddressComponent } from './fields/formly-address/formly-address.component';
import { FormlyStandardAddressComponent } from './fields/formly-address/formly-standard-address.component';
import { FormlyAutocompleteComponent } from './fields/formly-autocomplete/formly-autocomplete.component';
import { FormlySeparatorComponent } from './fields/formly-separator/formly-separator.component';
import { FormlyButtonComponent } from './fields/formly-button/formly-button.component';
import { FormlyDialogPickerComponent } from './fields/formly-dialog-picker/formly-dialog-picker.component';
import { TextFieldModule } from '@angular/cdk/text-field';
import { FormlyFileUploadComponent } from './fields/formly-file-upload/formly-file-upload.component';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { FormlyRichContentComponent } from './fields/formly-rich-content/formly-rich-content.component';
import { MatMenuModule } from '@angular/material/menu';
import { FormlyMultiSelectAutocompleteComponent } from './fields/formly-multi-select-autocomplete/formly-multi-select-autocomplete.component';
import { FormlyDateRangePickerComponent } from './fields/formly-date-range-picker/formly-date-range-picker.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
    emailValidationMessage,
    fieldExistsValidationConfig,
    fieldMatchValidator,
    dateRangeValidator,
    latitudeValidationMessage,
    latitudeValidator,
    longitudeValidationMessage,
    longitudeValidator,
    maxDateValidationMessage,
    maxDateValidator,
    maxLengthValidationMessage,
    minDateValidationMessage,
    minDateValidator,
    minLengthValidation,
    requiredValidationMessage,
    inValidPhoneNumberMessage,
    minValidation,
    maxValidationMessage,
    noEndDateValidationMessage,
    invalidDate,
} from './validators/validators';
import {
    MatMomentDateModule,
    MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { FormlyTypeaheadComponent } from './fields/formly-typeahead/formly-typeahead.component';
import { FormlyDatePickerSwitchComponent } from './fields/formly-date-picker-switch/formly-date-picker-switch.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormlyTravellerAutocompleteComponent } from './fields/formly-traveller-autocomplete/formly-traveller-autocomplete.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { FormlyRepeatComponent } from './fields/formly-repeat/formly-repeat.component';
import { GeneralUtilitiesModule } from '../general-utilities/general-utilities.module';
import { FormlyPanelWrapperComponent } from './components/formly-panel-wrapper/formly-panel-wrapper.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { FormlyExistingTravellerAutocompleteComponent } from './fields/formly-existing-traveller-autocomplete/formly-existing-traveller-autocomplete.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { FormlyTravellerAssignmentAutocompleteComponent } from './fields/formly-traveller-assignment-autocomplete/formly-traveller-assignment-autocomplete.component';
import { FormlyIconsAutoCompleteComponent } from './fields/formly-icons-auto-complete/formly-icons-auto-complete.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { FormlyCurrencyInputComponent } from './fields/formly-currency-input/formly-currency-input.component';
import { FormlyWholeNumberInputComponent } from './fields/formly-whole-number-input/formly-whole-number-input.component';
import { FormlyPercentInputComponent } from './fields/formly-percent-input/formly-percent-input.component';
import { FormlyTypeAheadComponent } from './fields/formly-type-ahead/formly-type-ahead.component';
import { FormlyPhoneLinkComponent } from './fields/formly-phone-link/formly-phone-link.component';
import { FormlyPhoneLinkDirective } from './fields/formly-phone-link/formly-phone-link.directive';
import { FormlyTimePickerComponent } from './fields/formly-time-picker/formly-time-picker.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { EditorModule } from '../editor/editor.module';
import { FormlyAttributeRepeatComponent } from './fields/formly-attribute-repeat/formly-attribute-repeat.component';
import { FormlyLocationAttributeRepeatComponent } from './formly-location-attribute-repeat/formly-location-attribute-repeat.component';
import { FormlyAttributeSeparatorComponent } from './fields/formly-attribute-separator/formly-attribute-separator.component';
import { MatSelectModule } from '@angular/material/select';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ExchangeRateService } from 'app/shared/services/commercial/exchange-rate/exchange-rate.service';
import { FormlyAddressDialogComponent } from './fields/formly-address/formly-address-dialog.component';
import { FormlyNestedAutocompleteComponent } from './fields/formly-nested-autocomplete/formly-nested-autocomplete.component';
import { MatInputModule } from '@angular/material/input';
import { FormlyPriceBreakTypeaheadComponent } from './fields/formly-price-break-typeahead/formly-rate-price-break.component';
import { FormlyColorPickerComponent } from './fields/formly-color-picker/formly-color-picker.component';
import { FormlyWeekdayPickerComponent } from './fields/formly-weekday-picker/formly-weekday-picker.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormlyCheckboxComponent } from './fields/formly-checkbox/formly-checkbox.component';
import { FormlyAddressPartChipListComponent } from './fields/formly-address-part-chip-list/formly-address-part-chip-list.component';
import { HumanizePipe } from './pipes/humanize.pipe';
import { FormlyPriceSheetCurrencyInputComponent } from './fields/formly-price-sheet-currency-input/formly-price-sheet-currency-input.component';
import { FormlyIntlPhoneComponent } from './fields/formly-intl-phone/formly-intl-phone.component';
import { NgxMatIntlTelInputModule } from 'ngx-mat-intl-tel-input';
import { FormlyLookupTravellerAssignmentAutocompleteComponent } from './fields/formly-lookup-traveller-assignment-autocomplete/formly-lookup-traveller-assignment-autocomplete.component';
import { FormlyLookupRoomAssignmentAutocompleteComponent } from './fields/formly-lookup-room-assignment-autocomplete/formly-lookup-room-assignment-autocomplete.component';
import { FormlyLookupRoomsDialogComponent } from './fields/formly-lookup-room-assignment-autocomplete/formly-lookup-rooms-dialog/formly-lookup-rooms-dialog.component';
import { BusinessSearchService } from 'app/shared/services/commercial/business/business-search.service';
import { AutocompleteOffDirective } from './directives/autocomplete-directive';

@NgModule({
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'en-AU' },
        {
            provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
            useValue: { useUtc: true },
        },
        ExchangeRateService,
        BusinessSearchService,
        {
            provide: FORMLY_CONFIG,
            multi: true,
            useFactory: fieldExistsValidationConfig,
            deps: [ExchangeRateService, BusinessSearchService],
        },
    ],
    declarations: [
        FormlyCurrencyInputComponent,
        FormlyFormWrapperComponent,
        FormActionsComponent,
        FormlyWrapperAddons,
        FormlyStandardAddressComponent,
        FormlyAddressDialogComponent,
        FormlyAutocompleteComponent,
        FormlySeparatorComponent,
        FormlyButtonComponent,
        FormlyDialogPickerComponent,
        FormlyFileUploadComponent,
        FormlyRichContentComponent,
        FormlyMultiSelectAutocompleteComponent,
        FormlyDateRangePickerComponent,
        FormlyTypeaheadComponent,
        FormlyDatePickerSwitchComponent,
        FormlyTravellerAutocompleteComponent,
        FormlyRepeatComponent,
        FormlyPanelWrapperComponent,
        FormlyExistingTravellerAutocompleteComponent,
        FormlyTravellerAssignmentAutocompleteComponent,
        FormlyIconsAutoCompleteComponent,
        FormlyWholeNumberInputComponent,
        FormlyPercentInputComponent,
        FormlyTypeAheadComponent,
        FormlyPhoneLinkComponent,
        FormlyPhoneLinkDirective,
        FormlyTimePickerComponent,
        FormlyAttributeRepeatComponent,
        FormlyLocationAttributeRepeatComponent,
        FormlyAttributeSeparatorComponent,
        FormlyNestedAutocompleteComponent,
        FormlyPriceBreakTypeaheadComponent,
        FormlyColorPickerComponent,
        FormlyWeekdayPickerComponent,
        FormlyAddressPartChipListComponent,
        HumanizePipe,
        FormlyCheckboxComponent,
        FormlyPriceSheetCurrencyInputComponent,
        FormlyIntlPhoneComponent,
        FormlyLookupTravellerAssignmentAutocompleteComponent,
        FormlyLookupRoomAssignmentAutocompleteComponent,
        FormlyLookupRoomsDialogComponent,
        AutocompleteOffDirective

    ],
    imports: [
        GeneralUtilitiesModule,
        EditorModule,
        MatButtonToggleModule,
        ReactiveFormsModule,
        FormlyMaterialModule,
        FormsModule,
        FormlyMatDatepickerModule,
        MatDatepickerModule,
        MatButtonModule,
        MatNativeDateModule,
        MatAutocompleteModule,
        MatMenuModule,
        MatChipsModule,
        MatSelectModule,
        MatFormFieldModule,
        MatGoogleMapsAutocompleteModule,
        MatTooltipModule,
        FormlySelectModule,
        NgSelectModule,
        FileUploadModule,
        TextFieldModule,
        MatTooltipModule,
        ScrollingModule,
        MatInputModule,
        DragDropModule,
        MatCheckboxModule,
        NgxMatIntlTelInputModule,
        FormlyModule.forRoot({
            types: [
                {
                    name: 'percent-input',
                    component: FormlyPercentInputComponent,
                    wrappers: ['form-field'],
                },
                {
                    name: 'whole-number-input',
                    component: FormlyWholeNumberInputComponent,
                    wrappers: ['form-field'],
                },
                {
                    name: 'currency-input',
                    component: FormlyCurrencyInputComponent,
                    wrappers: ['form-field'],
                },
                {
                    name: 'price-sheet-currency-input',
                    component: FormlyPriceSheetCurrencyInputComponent,
                    wrappers: ['form-field'],
                },
                {
                    name: 'autocomplete',
                    component: FormlyAutocompleteComponent,
                    wrappers: ['form-field'],
                },
                {
                    name: 'multi-select-autocomplete',
                    component: FormlyMultiSelectAutocompleteComponent,
                    wrappers: ['form-field'],
                },
                {
                    name: 'traveller-assignment-autocomplete',
                    component: FormlyTravellerAssignmentAutocompleteComponent,
                    wrappers: ['form-field'],
                },
                {
                    name: 'traveller-autocomplete',
                    component: FormlyTravellerAutocompleteComponent,
                    wrappers: ['form-field'],
                },
                {
                    name: 'existing-traveller-autocomplete',
                    component: FormlyExistingTravellerAutocompleteComponent,
                    wrappers: ['form-field'],
                },
                {
                    name: 'standard-address',
                    component: FormlyStandardAddressComponent,
                    wrappers: ['form-field'],
                },
                {
                    name: 'address-part-chip-list-component',
                    component: FormlyAddressPartChipListComponent,
                    wrappers: ['form-field'],
                },
                {
                    name: 'separator',
                    component: FormlySeparatorComponent,
                },
                {
                    name: 'button',
                    component: FormlyButtonComponent,
                },
                {
                    name: 'dialog-picker',
                    component: FormlyDialogPickerComponent,
                    wrappers: ['form-field'],
                },
                {
                    name: 'file-upload',
                    component: FormlyFileUploadComponent,
                    wrappers: ['form-field'],
                },
                {
                    name: 'rich-content',
                    component: FormlyRichContentComponent,
                    wrappers: ['form-field'],
                },
                {
                    name: 'date-range-picker',
                    component: FormlyDateRangePickerComponent,
                    wrappers: ['form-field'],
                },
                {
                    name: 'typeahead',
                    component: FormlyTypeaheadComponent,
                    wrappers: ['form-field'],
                },
                {
                    name: 'date-picker-switch',
                    component: FormlyDatePickerSwitchComponent,
                    wrappers: ['form-field'],
                },

                { name: 'repeat', component: FormlyRepeatComponent },
                {
                    name: 'location-attribute-repeat',
                    component: FormlyLocationAttributeRepeatComponent,
                },
                {
                    name: 'attribute-repeat',
                    component: FormlyAttributeRepeatComponent,
                },
                {
                    name: 'attribute-separator',
                    component: FormlyAttributeSeparatorComponent,
                },
                {
                    name: 'icons-autocomplete',
                    component: FormlyIconsAutoCompleteComponent,
                    wrappers: ['form-field'],
                },
                {
                    name: 'type-ahead',
                    component: FormlyTypeAheadComponent,
                    wrappers: ['form-field'],
                },
                {
                    name: 'phone-link',
                    component: FormlyPhoneLinkComponent,
                    wrappers: ['form-field'],
                },
                {
                    name: 'time-picker',
                    component: FormlyTimePickerComponent,
                    wrappers: ['form-field'],
                },
                {
                    name: 'nested-option-autocomplete',
                    component: FormlyNestedAutocompleteComponent,
                    wrappers: ['form-field'],
                },
                {
                    name: 'price-break-typeahead',
                    component: FormlyPriceBreakTypeaheadComponent,
                    wrappers: ['form-field'],
                },
                {
                    name: 'color',
                    component: FormlyColorPickerComponent,
                },
                {
                    name: 'weekday-picker',
                    component: FormlyWeekdayPickerComponent,
                    wrappers: ['form-field'],
                },
                {
                    name: 'sigla-checkbox',
                    component: FormlyCheckboxComponent,
                    wrappers: ['form-field'],
                },
                {
                    name: 'intl-phone',
                    component: FormlyIntlPhoneComponent,
                    wrappers: ['form-field'],
                },
                {
                    name: 'lookup-traveller-assignment-autocomplete',
                    component: FormlyLookupTravellerAssignmentAutocompleteComponent,
                    wrappers: ['form-field'],
                },
                {
                    name: 'lookup-room-assignment-autocomplete',
                    component: FormlyLookupRoomAssignmentAutocompleteComponent,
                    wrappers: ['form-field'],
                },


            ],
            wrappers: [
                { name: 'addons', component: FormlyWrapperAddons },
                { name: 'panel', component: FormlyPanelWrapperComponent },
            ],
            extensions: [
                { name: 'addons', extension: { onPopulate: addonsExtension } },
            ],
            validators: [
                { name: 'email', validation: Validators.email },
                { name: 'longitude', validation: longitudeValidator },
                { name: 'latitude', validation: latitudeValidator },
                { name: 'fieldMatch', validation: fieldMatchValidator },
                { name: 'dateRange', validation: dateRangeValidator },
                {
                    name: 'minDate',
                    validation: minDateValidator,
                },
                {
                    name: 'maxDate',
                    validation: maxDateValidator,
                },

            ],
            validationMessages: [
                { name: 'required', message: requiredValidationMessage },
                { name: 'minlength', message: minLengthValidation },
                { name: 'maxlength', message: maxLengthValidationMessage },
                { name: 'min', message: minValidation },
                { name: 'max', message: maxValidationMessage },
                { name: 'email', message: emailValidationMessage },
                { name: 'longitude', message: longitudeValidationMessage },
                { name: 'latitude', message: latitudeValidationMessage },
                {
                    name: 'minDate',
                    message: minDateValidationMessage,
                },
                {
                    name: 'maxDate',
                    message: maxDateValidationMessage,
                },

                {
                    name: 'matDatepickerMax',
                    message: maxDateValidationMessage,
                },
                {
                    name: 'matDatepickerMin',
                    message: minDateValidationMessage,
                },
                {
                    name: 'validatePhoneNumber',
                    message: inValidPhoneNumberMessage,
                },
                {
                    name: 'endDateInvalid',
                    message: noEndDateValidationMessage
                },
                {
                    name: 'matDatepickerParse',
                    message: invalidDate
                }

            ],
        }),
        CommonModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatMomentDateModule,
        MatSlideToggleModule,
        MatFormFieldModule,
        MatExpansionModule,
        NgxMaterialTimepickerModule,
    ],
    exports: [FormlyFormWrapperComponent, FormActionsComponent],
})
export class FormlyFormUtilitiesModule { }
