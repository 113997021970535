import { Component, OnInit, ChangeDetectionStrategy, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-section-heading',
  templateUrl: './section-heading.component.html',
  styleUrls: ['./section-heading.component.scss']
})
export class SectionHeadingComponent implements OnInit {
    @Input() heading: string = 'Heading Goes Here';
    @Input() subHeading: string | undefined;
    @Input() tooltip: string | undefined;
    @Input() size: 'extra-small'|'small' | 'large' | undefined;
  constructor() { }

  ngOnInit(): void {
  }

}
