import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-color-picker',
  templateUrl: './formly-color-picker.component.html',
  styleUrls: ['./formly-color-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class FormlyColorPickerComponent extends FieldType implements OnInit {

  defaultColor = '#7f7f7f';
  override formControl!: FormControl;

  ngOnInit(): void {
    if (this.formControl && !this.formControl.value) {
      this.formControl.setValue(this.defaultColor);
    }
  }
}
