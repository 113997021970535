<ng-container *ngIf="to['listType'] === 'location'">
    <div class="flex items-center justify-end">
        <button
            mat-raised-button
            color="primary"
            [matMenuTriggerFor]="menu"
            [disabled]="to['disableAdd']"
            class="origin-right transform scale-75 w-50"
            #trigger="matMenuTrigger"
            (mouseenter)="trigger.openMenu()"
        >
            <mat-icon class="mr-2 icon-size-5" [svgIcon]="'add'"></mat-icon>
            {{ to["addLabel"] }}
        </button>
        <mat-menu #menu="matMenu">
            <div (mouseleave)="trigger.closeMenu()">
                <a (click)="addLocation('location')" mat-menu-item
                    >Add Location</a
                >
                <a (click)="addLocation('choice')" mat-menu-item>
                    Add a list of Location Options
                </a>
            </div>
        </mat-menu>
    </div>
    <div cdkDropList (cdkDropListDropped)="drop($event)">
        <div 
            skeleton
            *ngFor="
                let field of field.fieldGroup;
                let i = index;
                let first = first
            "
            cdkDrag
            class="flex flex-col justify-center field-group"
            [class.first-row]="first"
        >
            <div *cdkDragPreview></div>
            <div class="relative flex flex-row items-center">
                <button cdkDragHandle mat-icon-button class="-mt-4">
                    <mat-icon svgIcon="drag_indicator"></mat-icon>
                </button>
                <div
                    class="absolute flex justify-center w-12 text-xs font-bold origin-center transform -rotate-90 translate-x-2 -translate-y-2"
                >
                    Loc 
                    {{field.fieldGroup?.[0]?.formControl?.value}}
                </div>
                <formly-field class="flex-grow" [field]="field"></formly-field>

                <button mat-icon-button class="flex-grow-0" (click)="remove(i)">
                    <mat-icon color="warn" [svgIcon]="'delete'"></mat-icon>
                </button>
            </div>
        </div>
    </div>
</ng-container>

<div
    *ngIf="to['listType'] === 'choice'"
    class="px-4 py-2 origin-right transform scale-90 rounded-md bg-card"
>
    <div class="flex items-center justify-between">
        <span class="font-bold">List of location options</span>
        <button
            class="origin-right transform scale-90"
            mat-raised-button
            color="primary"
            [disabled]="to['disableAdd']"
            (click)="addLocation('location')"
        >
            <mat-icon class="mr-2 icon-size-5" [svgIcon]="'add'"></mat-icon>
            {{ to["addLabel"] }}
        </button>
    </div>

    <ng-container
        *ngFor="let field of field.fieldGroup; let i = index; let first = first"
    >
        <div
            class="flex flex-col justify-center field-group"
            [class.first-row]="first"
        >
            <div class="flex flex-row items-center">
                <formly-field class="flex-grow" [field]="field"></formly-field>

                <button mat-icon-button class="flex-grow-0" (click)="remove(i)">
                    <mat-icon color="warn" [svgIcon]="'delete'"></mat-icon>
                </button>
            </div>
        </div>
    </ng-container>
</div>
