import { ActivityState, ActivityType, ChangeType, DataEventType, ErrorType } from 'app/shared/types/types';
import { ModelName } from '../model-name/model-name';

export class ListCriteria {
    sortBy: string | undefined;
    sortDirection: string | undefined;
    skip: number | undefined;
    take: number | undefined;
}

export class ActivityTracking {
    modelName!: ModelName;
    activityList: Activity[] = [];
}

export class Activity {
    activityUId!: string;
    thisInstance: boolean = true;
    modelName!: ModelName;
    modelUId!: string;
    modelObject?: any;
    activityType!: ActivityType;
    state!: ActivityState;
    updatedDate!: Date;
    error?: Error;
}

export class ActivityMemo {
    activityUId!: string;
}

export class ChangeTicket {
    changeUId?: string;
    modelName!: string;
    modelObject!: any;
    messageName?: string;
}

export class ChangeItem {
    changeUId?: string;
    changeType!: ChangeType;
    modelName!: string;
    modelUId?: string;
    modelObject!: any;
    memo?: any;
    notificationMessage?: string;
}

export const changeTicketModelNames = {
    business: 'Business',
    product: 'Product',
    extra: 'Extra',
    productSeries: 'ProductSeries',
    agreementItem: 'AgreementItem',
    agreement: 'Agreement',
    agreementPaymentRule: 'AgreementPaymentRule',
    attribute: 'Attribute',
    attributeChoice: 'AttributeChoice',
    fromLocationChoice: 'FromLocation',
    toLocationChoice: 'ToLocation',
    locationChoice: 'Location',
    businessPhone: 'BusinessPhone',
    businessEmail: 'BusinessEmail',
    businessRelationship: 'BusinessRelationship',
    season: 'Season',
    calendarDate: 'CalendarDate',
    calendar: 'Calendar',
    calendarPeriod: 'CalendarPeriod',
    calendarPeriodDay: 'CalendarPeriodDay',
    dayContent: 'DayContent',
    pointOfInterest: 'PointOfInterest',
    markup: 'Markup',
    commission: 'Commission',
    productType: 'ProductType',
    content: 'Content',
    productPrice: 'ProductPrice',
    directContact: 'DirectContact',
    rate: 'Rate',
    tradeContact: 'TradeContact',
    optionGroup: 'OptionGroup',
    optionGroupChoice: 'OptionGroupChoice',
    optionGroupChoiceRate: 'OptionGroupChoiceRate',
    periodPrice: 'PeriodPrice',
    productExtra: 'ProductExtra',
    datePrice: 'DatePrice',
    note: 'Note',
    action: 'Action',
    attachment: 'Attachment',
    booking: 'Booking',
    travelParty: 'TravelParty',
    travelPartyList: 'TravelPartyList',
    room: 'Room',
    businessAccount: 'Account',
    bookingSummary: 'BookingSummary',
    payment: 'Payment',
    transaction: 'Transaction',
    calendarItem: 'CalendarItem',
    exchangeRate: 'ExchangeRate',
    taxSchedule: 'TaxSchedule',
    taxRate: 'TaxRate',
    priceBreak: 'PriceBreak'
};

export class DataEvent {
    eventType!: DataEventType;
    dataUId!: string | undefined;
}

export class ValidationData {
    key!: string;
    value?: any;
}

export class Error {
    errorType!: ErrorType;
    code?: number;
    message!: string;
    detail?: any;
}

export class UserInterfaceVariables {
    expanded?: boolean = false;
    loaded?: boolean = false;
    selected?: boolean = false;
    disabled?: boolean = false;
    visible?: boolean = false;
}
