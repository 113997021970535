import { environment } from 'environments/environment';

export const siglaLog = (log1: any, log2?: any, log3?: any): void => {
    if (environment.logging ?? false) {
        if (log1 && log2 && log3) {
            // eslint-disable-next-line no-console
            console.info(log1, log2, log3);
        } else if (log1 && log2) {
            // eslint-disable-next-line no-console
            console.info(log1, log2);
        } else {
            // eslint-disable-next-line no-console
            console.info(log1);
        }

    }
};
