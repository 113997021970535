<ng-container *ngIf="controlValid">
    <ng-template
        #optionTemplate
        let-option="option"
        let-index="index"
        let-level="level"
        let-name="name"
    >
        <mat-option
            class="h-8 text-sm font-bold leading-8 border-b border-l border-solid bg-default"
            [ngClass]="{
                'bg-opacity-10 shadow-xs text-md': level === 0,
                'bg-opacity-30 text-sm': level > 0
            }"
            [style.margin-left.px]="level * 32"
            (click)="selectOption(option); trigger.closePanel()"
        >
            <div class="flex items-center justify-start w-full gap-2">
                <span>{{ option[this.optionLabelKey]}}</span>
            </div>
        </mat-option>
        <ng-container
            *ngFor="let option of option[this.optionListKey]; let i = index"
        >
            <ng-container
                *ngTemplateOutlet="
                    optionTemplate;
                    context: {
                        option: option,
                        index: i,
                        level: level + 1
                    }
                "
            ></ng-container>
        </ng-container>
    </ng-template>

    <input
        (click)="trigger.openPanel(); autocompleteInput?.focus()"
        readonly
        class="w-full text-sm font-bold "
        type="text"
        matInput
        #labelInput
        #trigger="matAutocompleteTrigger"
        [matAutocomplete]="auto"
        [(ngModel)]="label"
        style="padding: 14px 0"
    />

    <mat-autocomplete
        #auto="matAutocomplete"
        class="border border-solid min-w-64 bg-card"
    >
        <div class="sticky top-0 z-20 px-2 py-2 border-b border-solid bg-card">
            <div
                class="flex items-center gap-2 border border-solid rounded-full shadow-inner bg-card"
            >
                <input
                    (focus)="autocompleteFocus()"
                    (keydown)="autocompleteKeydown(autocompleteInput.value)"
                    #autocompleteInput
                    class="w-full p-2 text-sm font-bold"
                    type="text"
                    matInput
                    placeholder="Start typing to find an option"
                />
                <mat-icon class="mr-2" svgIcon="search"></mat-icon>
            </div>
        </div>

        <mat-option class="hidden" disabled>
        </mat-option>
        <ng-container
            *ngFor="let option of filteredOptions$ | async; let i = index"
        >
            <ng-container
                *ngTemplateOutlet="
                    optionTemplate;
                    context: {
                        option: option,
                        index: i,
                        level: 0
                    }
                "
            ></ng-container>
        </ng-container>
    </mat-autocomplete>
</ng-container>
