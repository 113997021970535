import {
    Component,
    ChangeDetectionStrategy,
    ViewEncapsulation,
    OnInit,
    ElementRef,
    ViewChild,
} from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { mapGoogleAddress } from './utils/google-address-utils';
import { FormlyAddressDialogComponent } from './formly-address-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import PlaceResult = google.maps.places.PlaceResult;
import { defaultPlaceValue, FormattedAddressPart, Place } from 'app/shared/models/shared/place-model';
import { fuseAnimations } from '@fuse/animations';


@Component({
    selector: 'app-formly-standard-address',
    templateUrl: './formly-standard-address.component.html',
    styleUrls: ['./formly-standard-address.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class FormlyStandardAddressComponent
    extends FieldType implements OnInit {
    @ViewChild('matGoogleAutoComplete', { read: ElementRef, static: true })
    matGoogleAutoCompleteCtrl!: ElementRef;
    defaultValue = defaultPlaceValue;
    override formControl: any;
    override options: any;
    displayValue: undefined | string;
    searching: boolean = false;


    constructor(private dialog: MatDialog) {
        super();
    }

    onAutocompleteSelected($event: google.maps.places.PlaceResult): void {
        const place = mapGoogleAddress($event);
        this.patchValue(place);
        this.displayValue = place.display?.map((placePart) => placePart.value).join(' ');
        this.matGoogleAutoCompleteCtrl.nativeElement.value = '';
    }

    onAutocompleteFocus(): void {
        this.searching = true;
    }

    onAutocompleteBlur(): void {
        this.searching = false;

    }

    ngOnInit(): void {
        this.displayValue = this.formControl.value?.display?.map((placePart) => placePart.value).join(' ');
        this.formControl.valueChanges.subscribe((value) => {
            if (value && value.display) {
                this.displayValue = value?.display?.map((placePart) => placePart.value).join(' ');
            }
        });
    }

    addEditAddress(): void {
        let place = { ...this.formControl.value };
        if (Object.keys(place).length === 0 || place === undefined || place === null) {
            place = this.defaultValue;
        }
        place.provider = undefined;
        const matDialogRef = this.dialog.open(FormlyAddressDialogComponent, {
            maxWidth: '90vw',
            minWidth: '320px',
            width: '75vw',
            minHeight: '90vh',
            maxHeight: '100vh',
            height: '80vh',
            data: place,
        });
        matDialogRef.afterClosed().subscribe((response: Place | undefined) => {
            if (response !== undefined) {
                this.patchValue(response);
                this.displayValue = response.display?.map((placePart) => placePart.value).join(' ');
            }
        });
    }

    patchValue(value: Place): void {
        this.formControl.patchValue(value, { emitValue: false });
        this.enableDisableMailAddressCheckbox(value);
    }

    enableDisableMailAddressCheckbox(place: Place): void {
        const mailAddressKey = this.to['mailAddressKey'];
        const mailAddressCheckboxKey = this.to['mailAddressCheckboxKey'];
        const mailAddressCheckboxControl = this.form.controls[mailAddressCheckboxKey];
        if (mailAddressCheckboxControl && mailAddressKey) {
            if (this.isValidMailingAddress(place.display)) {
                mailAddressCheckboxControl.setValue(true);
                mailAddressCheckboxControl.enable();
                const mailAddressField = this.field?.parent?.fieldGroup?.find((f) => f.key === mailAddressKey);
                if (mailAddressField) {
                    mailAddressField.hide = true;
                }
            } else {
                mailAddressCheckboxControl.setValue(false);
                mailAddressCheckboxControl.disable();
                const mailAddressField = this.field?.parent?.fieldGroup?.find((f) => f.key === mailAddressKey);
                if (mailAddressField) {
                    mailAddressField.hide = false;
                }
            }

        }
    }

    isValidMailingAddress(display: FormattedAddressPart[]): boolean {
        const valid = display.filter((part) => part.type !== 'state').every((part) => part.value.trim().length > 0);
        return valid;
    }

}
