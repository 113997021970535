
<ng-container *ngFor="let column of block?.data?.columns">
    <ng-container [ngSwitch]="column.type">
        <app-text
        class="column"
            (ngModelChange)="ngModelChange.emit(block)"
            [data]="column"
            *ngSwitchCase="'paragraph'"
        >
        </app-text>
        <app-image
        class="column"
            (ngModelChange)="ngModelChange.emit(block)"
            [data]="column"
            *ngSwitchCase="'image'"
        ></app-image>
    </ng-container>
</ng-container>
