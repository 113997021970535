/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';
import { environment } from 'environments/environment';

export const getNavigation = (officeName: string | undefined): FuseNavigationItem[] => (
    [
        {
            id: 'reservations',
            title:'Bookings',
            subtitle: 'Bookings for the ' + officeName,
            type: 'group',
            icon: 'bookmark',
            children: [
                {
                    id: 'recentBookings',
                    title: 'Recent Bookings',
                    type: 'basic',
                    icon: 'dashboard',
                    link: '/reservations/recent-bookings',
                    newTabLink: true,
                    exactMatch: true
                },
                {
                    id: 'directBookingSearch',
                    title: 'Direct Booking Search',
                    type: 'basic',
                    icon: 'person',
                    link: '/reservations/direct-booking-search',
                    newTabLink: true,
                    exactMatch: true
                },
                {
                    id: 'agencyBookingSearch',
                    title: 'Agency Booking Search',
                    type: 'basic',
                    icon: 'storefront',
                    link: '/reservations/agency-booking-search',
                    newTabLink: true,
                    exactMatch: true
                },
                {
                    id: 'direct-client',
                    title: 'New Direct Booking ',
                    subtitle: 'Shift + D',
                    type: 'basic',
                    icon: 'person',
                    link: '/reservations/booking-dashboard/direct-client',
                    newTabLink: true,

                },
                {
                    id: 'agent',
                    title: 'New Agency Booking ',
                    subtitle: 'Shift + A',
                    type: 'basic',
                    icon: 'storefront',
                    link: '/reservations/booking-dashboard/agent',
                    newTabLink: true,
                },
            ],
        },
        {
            id: 'admin',
            title: 'Administration',
            subtitle: 'Admin for the ' + officeName,
            type: 'group',
            children: [
                {
                    id: 'offices',
                    title: 'Sales Offices',
                    type: 'basic',
                    icon: 'store',
                    link: '/admin/offices',
                    newTabLink: true,
                },
                {
                    id: 'dmcs',
                    title: 'DMCs',
                    type: 'basic',
                    icon: 'store',
                    link: '/admin/dmcs',
                    newTabLink: true,
                },
                {
                    id: 'supplierSearch',
                    title: 'Suppliers',
                    type: 'basic',
                    icon: 'shopping_cart',
                    link: '/admin/supplier-search',
                    newTabLink: true,
                },
                {
                    id: 'agencySearch',
                    title: 'Agencies',
                    type: 'basic',
                    icon: 'storefront',
                    link: '/admin/agency-search',
                    newTabLink: true,
                },

/*                 {
                    id: 'productSearch',
                    title: 'Product Search',
                    type: 'basic',
                    icon: 'shopping_cart',
                    link: '/admin/product-search',
                    newTabLink: true,
                },
                {
                    id: 'packageSearch',
                    title: 'Package Search',
                    type: 'basic',
                    icon: 'shopping_cart',
                    link: '/admin/package-search',
                    newTabLink: true,
                }, */
                /*             {
                    id: 'bookingSearch',
                    title: 'Booking Search',
                    type: 'basic',
                    disabled: environment.disableMenu,
                    icon: 'flight_takeoff',
                    link: '/admin/booking-search'
                } */
            ],
        },
/*         {
            id: 'knowledgeAdmin',
            title: 'Knowledge Admin',
            type: 'collapsable',
            icon: 'map',
            children: [
                {
                    id: 'details',
                    title: 'Wiki',
                    type: 'basic',
                    icon: 'map',
                    link: '/knowledge',
                    newTabLink: true,
                },
                {
                    id: 'poiSearch',
                    title: 'Point of Interest Search',
                    type: 'basic',
                    icon: 'location_on',
                    link: '/knowledge-admin/poi-search',
                    newTabLink: true,
                },
                {
                    id: 'experienceSearch',
                    title: 'Experience Search',
                    type: 'basic',
                    icon: 'insights',
                    link: '/knowledge-admin/experience-search',
                    newTabLink: true,
                },
                {
                    id: 'highlightSearch',
                    title: 'Highlight Search',
                    type: 'basic',
                    icon: 'star',
                    link: '/knowledge-admin/highlight-search',
                    newTabLink: true,
                },
            ],
        }, */

/*         {
            id: 'settings',
            title: 'Settings',
            type: 'basic',
            icon: 'settings',
            link: '/settings',
            newTabLink: true,
        }, */
    ]
);

