import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-print-document',
  templateUrl: './print-document.component.html',
  styleUrls: ['./print-document.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrintDocumentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
