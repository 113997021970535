import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ActivityService } from 'app/core/activity/activity.service';
import { ApiPathConfig } from 'app/shared/config/api-path.config';
import { adminEndpoints } from 'app/shared/config/endpoints/admin-endpoints';
import { TaxSchedule } from 'app/shared/models/commercial/commercial-models';
import { Error } from 'app/shared/models/core/core-models';
import { Settings, User } from 'app/shared/models/platform/platform-models';
import { ProductType } from 'app/shared/models/products/product-models';
import { siglaLog } from 'app/shared/utility/logging-util';
import { PathUtil } from 'app/shared/utility/path-util';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class SettingsService {
    private readonly _settings$ = new BehaviorSubject<Settings | undefined>(
        undefined
    );

    private readonly _destroying$ = new Subject<void>();

    constructor(
        private httpClient: HttpClient,
        private activityService: ActivityService,
        @Inject('SiglaApiPath') private siglaApiPath: ApiPathConfig
    ) {}

    /**
     * Accessors
     */

    get settings$(): Observable<Settings | undefined> {
        return this._settings$.asObservable();
    }

    /**
     * Dependent Lists
     */

    loadSettings(): void {
        siglaLog('Loading settings');

        const url = PathUtil.combinePaths(
            this.siglaApiPath.apiPath,
            adminEndpoints.platform.settings
        );

        this.httpClient
            .get<Settings>(url)
            .pipe(takeUntil(this._destroying$))
            .subscribe((settings: Settings | undefined) => {
                if (settings) {
                    siglaLog('Settings loaded');
                    siglaLog(settings);
                    this._settings$.next(settings);
                }
            });
    }
    // Product Type

    loadProductTypeList(): Observable<ProductType[]> {
        const url = PathUtil.combinePaths(
            this.siglaApiPath.apiPath,
            adminEndpoints.products.productTypeList
        );

        const loadRequest = this.httpClient.get<ProductType[]>(url);

        return loadRequest;
    }

    newProductType(): void {
        const url = PathUtil.combinePaths(
            this.siglaApiPath.apiPath,
            adminEndpoints.products.productTypeNew
        );

        const newRequest = this.httpClient.get<ProductType>(url);

        const activity = this.activityService?.newActivity(
            'Adding',
            'ProductType'
        );

        newRequest.pipe(take(1)).subscribe(
            (productType: ProductType) => {
                if (activity) {
                    this.activityService?.completeActivity(
                        activity,
                        productType
                    );
                }
            },
            (error: Error) => {
                if (activity) {
                    this.activityService?.failActivity(activity, error);
                }
            }
        );
    }

    deleteProductType(productTypeUId: string): void {
        let url = PathUtil.combinePaths(
            this.siglaApiPath.apiPath,
            adminEndpoints.products.productTypeWithUId
        );
        url = url.replace('{productTypeUId}', productTypeUId);

        const deleteRequest = this.httpClient.delete<ProductType>(url);

        const activity = this.activityService?.newActivity(
            'Deleting',
            'ProductType',
            productTypeUId
        );

        deleteRequest.pipe(take(1)).subscribe(
            (productType: ProductType) => {
                if (activity) {
                    this.activityService?.completeActivity(
                        activity,
                        productType
                    );
                }
            },
            (error: Error) => {
                if (activity) {
                    this.activityService?.failActivity(activity, error);
                }
            }
        );
    }

    // Tax Schedule

    loadTaxSchedules(): Observable<TaxSchedule[]> {
        let url = PathUtil.combinePaths(
            this.siglaApiPath.apiPath,
            adminEndpoints.commercial.taxScheduleList
        );
        url = PathUtil.addParam(url, 'incl=TaxRates');
        const loadRequest = this.httpClient.get<TaxSchedule[]>(url);

        return loadRequest;
    }

    deleteTaxSchedule(taxScheduleUId: string): void {
        let url = PathUtil.combinePaths(
            this.siglaApiPath.apiPath,
            adminEndpoints.commercial.taxScheduleWithUId
        );
        url = url.replace('{taxScheduleUId}', taxScheduleUId);

        const deleteRequest = this.httpClient.delete<TaxSchedule>(url);
        const activity = this.activityService?.newActivity(
            'Deleting',
            'TaxSchedule',
            taxScheduleUId
        );

        deleteRequest.pipe(take(1)).subscribe(
            (taxSchedule: TaxSchedule) => {
                if (activity) {
                    this.activityService?.completeActivity(
                        activity,
                        taxSchedule
                    );
                }
            },
            (error: Error) => {
                if (activity) {
                    this.activityService?.failActivity(activity, error);
                }
            }
        );
    }

    loadUserList(): Observable<User[]> {
        const url = PathUtil.combinePaths(
            this.siglaApiPath.apiPath,
            adminEndpoints.platform.userList
        );
        return this.httpClient.get<User[]>(url);
    }

    deleteUser(userUId: string): void {
        let url = PathUtil.combinePaths(
            this.siglaApiPath.apiPath,
            adminEndpoints.platform.userWithUId
        );
        url = url.replace('{userUId}', userUId);

        const deleteRequest = this.httpClient.delete<User>(url);

        const activity = this.activityService?.newActivity(
            'Deleting',
            'User',
            userUId
        );

        deleteRequest.pipe(take(1)).subscribe(
            (user: User) => {
                if (activity) {
                    this.activityService?.completeActivity(activity, user);
                }
            },
            (error: Error) => {
                if (activity) {
                    this.activityService?.failActivity(activity, error);
                }
            }
        );
    }
}
