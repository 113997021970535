import {
  HttpClient, HttpErrorResponse, HttpEventType, HttpHeaders, HttpRequest, HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';


import { Subscription } from 'rxjs';


import { CropOptions, FileUploaderOptions } from 'app/shared/models/common/image-uploader-models';


@Injectable()
export class ImageUploaderService {

  constructor(private http: HttpClient) { }

  uploadFile(file: File, options: FileUploaderOptions): Observable<FileQueueObject> {
    this.setDefaults(options);

    const form = new FormData();
    form.append(options.fieldName, file, file.name);


    // upload file and report progress
    const req = new HttpRequest('POST', options.uploadUrl, form, {
      reportProgress: true,
      withCredentials: options.withCredentials,
      // headers: this._buildHeaders(options)
    });

    return Observable.create((obs) => {
      const queueObj = new FileQueueObject(file);

      queueObj.request = this.http.request(req).subscribe(
        (event: any) => {
          if (event.type === HttpEventType.UploadProgress) {
            this._uploadProgress(queueObj, event);
            obs.next(queueObj);
          } else if (event instanceof HttpResponse) {
            this._uploadComplete(queueObj, event);
            obs.next(queueObj);
            obs.complete();
          }
        },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred. Handle it accordingly.
            this._uploadFailed(queueObj, err);
            obs.next(queueObj);
            obs.complete();
          } else {
            // The backend returned an unsuccessful response code.
            this._uploadFailed(queueObj, err);
            obs.next(queueObj);
            obs.complete();
          }
        }
      );
    });
  }

  getFile(url: string, options: { authToken?: string; authTokenPrefix?: string }): Observable<File> {
    return Observable.create((observer: Observer<File>) => {
      const headers = new HttpHeaders();

      if (options.authToken) {
        //  headers = headers.append('Authorization', `${options.authTokenPrefix} ${options.authToken}`);
      }

      this.http.get(url, { responseType: 'blob', headers: headers }).subscribe((res) => {
        const file = new File([res], 'filename', { type: res.type });
        observer.next(file);
        observer.complete();
      }, (err) => {
        observer.error(err.status);
        observer.complete();
      });
    });
  }

  private _buildHeaders(options: FileUploaderOptions): HttpHeaders {
    let headers = new HttpHeaders();

    if (options.authToken) {
      //  headers = headers.append('Authorization', `${options.authTokenPrefix} ${options.authToken}`);
    }

    if (options.customHeaders) {
      Object.keys(options.customHeaders).forEach((key) => {
        headers = headers.append(key, options.customHeaders[key]);
      });
    }

    return headers;
  }

  private _uploadProgress(queueObj: FileQueueObject, event: any): void {
    // update the FileQueueObject with the current progress
    const progress = Math.round(100 * event.loaded / event.total);
    queueObj.progress = progress;
    queueObj.status = FileQueueStatus.Progress;
    // this._queue.next(this._files);
  }

  private _uploadComplete(queueObj: FileQueueObject, response: HttpResponse<any>): void {
    // update the FileQueueObject as completed
    queueObj.progress = 100;
    queueObj.status = FileQueueStatus.Success;
    queueObj.response = response;
    // this._queue.next(this._files);
    // this.onCompleteItem(queueObj, response.body);
  }

  private _uploadFailed(queueObj: FileQueueObject, response: HttpErrorResponse): void {
    // update the FileQueueObject as errored
    queueObj.progress = 0;
    queueObj.status = FileQueueStatus.Error;
    queueObj.response = response;
    // this._queue.next(this._files);
  }

  private setDefaults(options: FileUploaderOptions): void {
    options.withCredentials = options.withCredentials || false;
    options.httpMethod = options.httpMethod || 'POST';
    options.authTokenPrefix = options.authTokenPrefix || 'Bearer';
    options.fieldName = options.fieldName || 'file';
  }
}



export class FileQueueObject {
  public file: any;
  public status: FileQueueStatus = FileQueueStatus.Pending;
  public progress: number = 0;
  public request: Subscription = null;
  public response: HttpResponse<any> | HttpErrorResponse = null;

  constructor(file: any) {
    this.file = file;
  }

  // actions
  // public upload = () => { /* set in service */ };
  // public cancel = () => { /* set in service */ };
  // public remove = () => { /* set in service */ };

  // statuses
  public isPending = () => this.status === FileQueueStatus.Pending;
  public isSuccess = () => this.status === FileQueueStatus.Success;
  public isError = () => this.status === FileQueueStatus.Error;
  public inProgress = () => this.status === FileQueueStatus.Progress;
  public isUploadable = () => this.status === FileQueueStatus.Pending || this.status === FileQueueStatus.Error;
}


export enum FileQueueStatus {
  Pending,
  Success,
  Error,
  Progress
}

