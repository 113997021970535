<div class="absolute flex flex-row gap-2 pointer-events-auto top-8 right-12 z-9999">
    <button
    [ngClass]="{'transform rotate-180': outlet['minimised']}"
        (click)="toggleMinimisePage()"
        mat-icon-button
    >
        <mat-icon svgIcon="keyboard_arrow_down"></mat-icon>
    </button>
    <button mat-icon-button close-button (click)="navigateAway()">
        <mat-icon svgIcon="close"></mat-icon>
    </button>
</div>
<ng-container *ngIf="!inline">
    <router-outlet></router-outlet>
</ng-container>
<ng-container *ngIf="inline">
    <ng-content></ng-content>
</ng-container>
