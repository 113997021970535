<div
    *ngIf="!formControl?.value && !endDate?.value && !focused"
    class="absolute z-0 flex items-center justify-between w-full h-full gap-4 pr-10 text-sm text-disabled"
>
    <span class="flex-grow text-center">Start Date</span>
    <span class="flex-grow text-center">End Date</span>
</div>
<mat-date-range-input
    [rangePicker]="picker"
    [required]="to.required || false"
    [disabled]="to.disabled || false"
    [max]="to['datepickerOptions'].max"
    [min]="to['datepickerOptions'].min"
    [separator]="to['separator'] || '–'"
>
    <input
        class="text-center px-1.5"
        matStartDate
        [formControl]="formControl"
        [id]="id + '-start'"
        [max]="to['datepickerOptions'].max"
        [min]="to['datepickerOptions'].min"
        [formlyAttributes]="field"
        [placeholder]="'Start date'"
        [value]="to['value'] || null"
        [tabindex]="to.tabindex"
        [readonly]="to.readonly"
        (dateInput)="to['datepickerOptions'].dateInput(field, $event)"
        (dateChange)="to['datepickerOptions'].dateChange(field, $event)"
        (focus)="focused = true"
        (blur)="focused = false"
    />

    <input
        class="px-1 text-center"
        matEndDate
        [formControl]="endDate"
        [id]="id + '-end'"
        [max]="to['datepickerOptions'].max"
        [min]="to['datepickerOptions'].min"
        [formlyAttributes]="field"
        [placeholder]="'End date'"
        [value]="to['value'] || null"
        [tabindex]="to.tabindex"
        [readonly]="to.readonly"
        (dateInput)="to['datepickerOptions'].dateInput(field, $event)"
        (dateChange)="to['datepickerOptions'].dateChange(field, $event)"
        (focus)="focused = true"
        (blur)="focused = false"
    />
</mat-date-range-input>
<mat-datepicker-toggle
    (click)="detectChanges()"
    [disabled]="to.disabled"
    [for]="picker"
></mat-datepicker-toggle>
<mat-date-range-picker
    #picker
    [color]="to['color']"
    [dateClass]="to['datepickerOptions'].dateClass"
    [disabled]="to['datepickerOptions'].disabled"
    [opened]="to['datepickerOptions'].opened"
    [panelClass]="to['datepickerOptions'].panelClass"
    [startAt]="to['datepickerOptions'].startAt"
    [startView]="to['datepickerOptions'].startView"
    [touchUi]="to['datepickerOptions'].touchUi"
    [calendarHeaderComponent]="to['datepickerOptions'].calendarHeaderComponent"
    (monthSelected)="
        to['datepickerOptions'].monthSelected(field, $event, picker)
    "
    (yearSelected)="to['datepickerOptions'].yearSelected(field, $event, picker)"
></mat-date-range-picker>
