import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit, TemplateRef, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { ActivatedRoute, Router} from '@angular/router';
import { TabbedPageLayoutComponent } from '../tabbed-page-layout/tabbed-page-layout.component';
import { PageHistoryService } from 'app/shared/services/page-history/page-history.service';
@Component({
    selector: 'app-sidebar-page-layout',
    templateUrl: './sidebar-page-layout.component.html',
    styleUrls: ['./sidebar-page-layout.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SidebarPageLayoutComponent
    extends TabbedPageLayoutComponent
    implements OnInit, OnDestroy {
    @Input() sidebarTemplate!: TemplateRef<any>;
    drawerMode: 'over' | 'side' = 'side';
    drawerOpened: boolean = true;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    constructor(
        override pageHistory: PageHistoryService,
        override  activatedRoute: ActivatedRoute,
        override router: Router,
        override  cdRef: ChangeDetectorRef,
        private fuseMediaWatcherService: FuseMediaWatcherService
    ) {
        super(pageHistory, activatedRoute, router,cdRef);
        this.getChildRoutes();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    override ngOnInit(): void {
        // Subscribe to media changes
        this.fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {
                // Set the drawerMode and drawerOpened
                if (matchingAliases.includes('lg')) {
                    this.drawerMode = 'side';
                    this.drawerOpened = true;
                } else {
                    this.drawerMode = 'over';
                    this.drawerOpened = false;
                }
            });
    }

    /**
     * On destroy
     */
    override ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }


}
