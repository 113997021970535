<ng-container *ngIf="!inlineEditForm">
    <div class="flex items-center"><ng-content></ng-content></div>
    <button
        *ngIf="clearButton"
        type="button"
        type="clear"
        type="reset"
        mat-stroked-button
    >
        Clear
    </button>
    <button
        *ngIf="cancelButton"
        type="button"
        color="warn"
        (click)="cancel()"
        mat-stroked-button
    >
        Cancel
    </button>
    <button
        *ngIf="deleteButton"
        type="button"
        color="warn"
        (click)="delete()"
        mat-raised-button
    >
        Delete
    </button>
    <button
        *ngIf="submitButton"
        (click)="submit()"
        [disabled]="disableSubmit"
        type="submit"
        color="primary"
        mat-raised-button
    >
        {{ submitLabel }}
    </button>
</ng-container>
<ng-container *ngIf="inlineEditForm">
    <div class="flex flex-row items-center justify-end">
        <button
            mat-icon-button
            class="action-button update"
            title="Update"
            (click)="submit()"
        >
            <mat-icon class="text-teal-500" svgIcon="check"></mat-icon>
        </button>
        <button
            mat-icon-button
            class="action-button cancel"
            title="Cancel"
            (click)="cancel()"
        >
            <mat-icon class="text-warn-500" svgIcon="close"></mat-icon>
        </button>
    </div>

</ng-container>
