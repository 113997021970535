<ng-container *ngIf="to['isRange'] === true">
    <div
        *ngIf="!formControl?.value && !endDate?.value && !focused"
        class="absolute z-0 flex items-center justify-between w-full h-full gap-4 pr-10 text-sm text-disabled"
    >
        <span class="flex-grow text-center">Start Date</span>
        <span class="flex-grow text-center">End Date</span>
    </div>
    <mat-date-range-input
        [rangePicker]="rangePicker"
        [required]="to.required || false"
        [disabled]="to.disabled || false"
        [min]="to['datepickerOptions']?.min || null"
        [max]="to['datepickerOptions'].max || null"
        [separator]="to['separator'] || '–'"
    >
        <input
            class="px-1 text-center"
            matStartDate
            [formControl]="formControl"
            [id]="id + '-start'"
            [max]="to['datepickerOptions'].max"
            [min]="to['datepickerOptions'].min"
            [formlyAttributes]="field"
            [value]="to['value'] || null"
            [tabindex]="to.tabindex"
            [readonly]="to.readonly"
            [class.is-invalid]="showError"
            (dateInput)="to['datepickerOptions'].dateInput(field, $event)"
            (dateChange)="fromDateChange($event)"
            (focus)="focused = true"
            (blur)="focused = false"
        />

        <input
            class="px-1 text-center end-date"
            matEndDate
            [formControl]="endDate"
            [id]="id + '-end'"
            [max]="to['datepickerOptions'].max"
            [min]="to['datepickerOptions'].min"
            [formlyAttributes]="field"
            [value]="to['value'] || null"
            [tabindex]="to.tabindex"
            [class.is-invalid]="showError"
            [readonly]="to.readonly"
            [required]="to['datepickerOptions'].endDateRequired"
            (dateInput)="to['datepickerOptions'].dateInput(field, $event)"
            (dateChange)="toDateChange($event)"
            (focus)="focused = true"
            (blur)="focused = false"
        />
    </mat-date-range-input>
    <mat-datepicker-toggle
        (click)="detectChanges()"
        [disabled]="to.disabled"
        [for]="rangePicker"
    ></mat-datepicker-toggle>
    <mat-date-range-picker
        #rangePicker
        [color]="to['color']"
        [dateClass]="to['datepickerOptions'].dateClass"
        [disabled]="to['datepickerOptions'].disabled"
        [opened]="to['datepickerOptions'].opened"
        [panelClass]="to['datepickerOptions'].panelClass"
        [startAt]="to['datepickerOptions'].startAt"
        [startView]="to['datepickerOptions'].startView"
        [touchUi]="to['datepickerOptions'].touchUi"
        [calendarHeaderComponent]="
            to['datepickerOptions'].calendarHeaderComponent
        "
        (monthSelected)="
            to['datepickerOptions'].monthSelected(field, $event, rangePicker)
        "
        (yearSelected)="
            to['datepickerOptions'].yearSelected(field, $event, rangePicker)
        "
    ></mat-date-range-picker>
</ng-container>

<ng-container *ngIf="to['isRange'] === false">
    <div
        *ngIf="!formControl?.value && !focused"
        class="absolute z-0 flex items-center justify-between w-full h-full gap-4 pr-10 text-sm text-disabled"
    >
        <span class="flex-grow text-center">Date</span>
    </div>
    <input
        matInput
        class="text-center"
        [id]="id"
        [formControl]="formControl"
        [matDatepicker]="picker"
        [matDatepickerFilter]="to['datepickerOptions'].filter"
        [max]="to['datepickerOptions'].max"
        [min]="to['datepickerOptions'].min"
        [formlyAttributes]="field"
        placeholder="'Date'"
        [tabindex]="to.tabindex"
        [readonly]="to.readonly"
        [required]="to.required || false"
        (dateInput)="to['datepickerOptions'].dateInput(field, $event)"
        (dateChange)="to['datepickerOptions'].dateChange(field, $event)"
        (focus)="focused = true"
        (blur)="focused = false"
    />

    <mat-datepicker-toggle
        (click)="detectChanges()"
        [disabled]="to.disabled"
        [for]="picker"
    ></mat-datepicker-toggle>

    <mat-datepicker
        #picker
        [color]="to['color']"
        [dateClass]="to['datepickerOptions'].dateClass"
        [disabled]="to['datepickerOptions'].disabled"
        [opened]="to['datepickerOptions'].opened"
        [panelClass]="to['datepickerOptions'].panelClass"
        [startAt]="to['datepickerOptions'].startAt"
        [startView]="to['datepickerOptions'].startView"
        [touchUi]="to['datepickerOptions'].touchUi"
        [calendarHeaderComponent]="
            to['datepickerOptions'].calendarHeaderComponent
        "
        (monthSelected)="
            to['datepickerOptions'].monthSelected(field, $event, picker)
        "
        (yearSelected)="
            to['datepickerOptions'].yearSelected(field, $event, picker)
        "
    >
    </mat-datepicker>
</ng-container>
