<div class="flex align-middle" (click)="toggle(checkBoxValue)">
    <mat-icon
        class="text-slate-900"
        class="align-middle"
        [svgIcon]="
            checkBoxValue
                ? 'mat_solid:check_box'
                : 'mat_solid:check_box_outline_blank'
        "
    ></mat-icon>
    <span class="ml-1 leading-6 align-middle select-none"> {{ to.text }}</span>
</div>
