import { ValueFormatterParams } from '@ag-grid-community/core';
import moment from 'moment';

export class FormatterUtil {
    // dateValueFormatter is configured for valueFormatter in ag-grid.config.ts
    static dateValueFormatter = (params: ValueFormatterParams): string =>
        moment(new Date(params.value)).format('DD-MM-YYYY');
}


export const toCamelCase = (o: any): any => {

    if (o instanceof Array) {
        return o.map((item: any) => {
            if (typeof item === 'object') {
                item = toCamelCase(item);
            }
            return item;
        });
    } else {
        const newO = new Object();
        for (const origKey in o) {
            if (o.hasOwnProperty(origKey)) {
                const newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).toString();
                let value = o[origKey];
                if (value instanceof Array || (value !== null && value.constructor === Object)) {
                    value = toCamelCase(value);
                }
                newO[newKey] = value;
            }
        }
        return newO;
    }

};
