<ng-container *ngIf="content">
    <div      style="color: var(--fuse-bg-card)" class="mr-auto font-bold text-md">Add Layouts</div>
    <ng-container *ngFor="let tool of contentTools; let last = last">
        <a
            (click)="insertBlock(tool.block)"
            class="items-center justify-center p-0 scale-75 border-t-0 border-b-0 border-l border-current border-solid rounded-none min-w"
            [class.border-r-0]="!last"
            [class.border-r]="last"
            style="color: var(--fuse-bg-card)"
            mat-button
            [matTooltip]="tool.name || ''"
        >
            <mat-icon
                *ngFor="let icon of tool.icons"
                class="text-current icon-size-4"
                [svgIcon]="icon"
            ></mat-icon>
        </a>

    </ng-container>
</ng-container>
