import { ICellEditorAngularComp } from '@ag-grid-community/angular';
import { AfterViewInit, Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-ag-grid-checkbox-cell-renderer',
  templateUrl: './ag-grid-checkbox-cell-renderer.component.html',
  styleUrls: ['./ag-grid-checkbox-cell-renderer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AgGridCheckboxCellRendererComponent implements ICellEditorAngularComp, AfterViewInit {

  params: any;
  constructor() {}
  agInit(params: any): void {
      this.params = params;
  }

  ngAfterViewInit(): void {}

  getValue(): string {
      return this.params.value;
  }

}
