import { Component, ChangeDetectionStrategy, ViewEncapsulation, OnInit, AfterViewInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';
import _ from 'lodash';

@Component({
    selector: 'app-formly-date-range-picker',
    templateUrl: './formly-date-range-picker.component.html',
    styleUrls: ['./formly-date-range-picker.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class FormlyDateRangePickerComponent extends FieldType implements OnInit, AfterViewInit {
    @ViewChild('datepickerToggle', { static: true }) datepickerToggle!: TemplateRef<any>;
    override formControl: any;
    override options: any;
    override defaultOptions = {
        className:'date-range-picker',
        templateOptions: {
            datepickerOptions: {
                startView: 'month',
                fields: undefined,
                dateInput: (): void => { },
                dateChange: (): void => { },
                monthSelected: (): void => { },
                yearSelected: (): void => { },
            },
        },
    };
    focused: boolean = false;
    startDate: any;
    endDate: any;
    constructor() {
        super();
    }

    ngOnInit(): void {
        this.initStartEndDateFields();
    }

    initStartEndDateFields(): void {
        this.form.addControl(this.to['endDateKey'], new FormControl());
        if(this.form.get(this.to['endDateKey'])){
            this.endDate = this.form.get(this.to['endDateKey']) as FormControl;
            if (this.to['required']) {
                this.endDate.setValidators(Validators.required);
            }
        }
    }


    detectChanges(): void {
        if (this.options) {
            this.options.detectChanges?.(this.field);
        }
    }

    ngAfterViewInit(): void {
        this.to[this.to['datepickerOptions'].datepickerTogglePosition] = this.datepickerToggle;
    }
}
