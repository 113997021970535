import { ICellRendererParams } from '@ag-grid-community/all-modules';
import { AgRendererComponent } from '@ag-grid-community/angular';
import { Component } from '@angular/core';

@Component({
    selector: 'app-ag-grid-bool-cell-renderer',
    templateUrl: './ag-grid-bool-cell-renderer.component.html',
})
export class AgGridBooleanCellRendererComponent implements AgRendererComponent {
    public params: any | undefined;

    constructor() {
    }

    refresh(params: ICellRendererParams): boolean {
        return false;
    }
    agInit(params: any): void {
        this.params = params;
    }
}
