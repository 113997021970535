import { Component, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import { MAT_INPUT_VALUE_ACCESSOR } from '@angular/material/input';
import { FormlyFieldTextArea } from '@ngx-formly/material/textarea';

@Component({
  selector: 'app-formly-dialog-picker',
  templateUrl: './formly-dialog-picker.component.html',
  styleUrls: ['./formly-dialog-picker.component.scss'],
  providers: [
    // fix for https://github.com/ngx-formly/ngx-formly/issues/1688
    // rely on formControl value instead of elementRef which return empty value in Firefox.
    { provide: MAT_INPUT_VALUE_ACCESSOR, useExisting: FormlyFieldTextArea },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class FormlyDialogPickerComponent extends FormlyFieldTextArea {
    override formControl: any;
}
