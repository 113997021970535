<ng-container *ngIf="wizardState$ | async">
    <button
        class="absolute top-8 left-8 z-99"
        mat-icon-button
        (click)="matLeftDrawer.toggle()"
    >
        <mat-icon [svgIcon]="'menu'"></mat-icon>
    </button>

    <div
        [class.loading]="(wizardState$ | async)?.loading"
        [style.--page-theme-color]="pageThemeColor"
        class="absolute flex flex-col min-w-0 overflow-y-auto border rounded-lg shadow-xl page-outline inset-8 bottom-2 bg-default"
        cdkScrollable
        page
        [class.scrolled-to-bottom]="scrolledToBottom"
    >
        <mat-drawer-container [hasBackdrop]="false" class="flex-auto h-full">
            <!-- Drawer -->
            <mat-drawer
                class="w-64 dark:bg-gray-900"
                [autoFocus]="false"
                [mode]="leftDrawerMode"
                [opened]="leftDrawerOpened"
                #matLeftDrawer
            >
                <ng-container *ngIf="sidebarTemplate">
                    <ng-container
                        *ngTemplateOutlet="sidebarTemplate"
                    ></ng-container>
                </ng-container>
            </mat-drawer>

            <mat-drawer
                class="flex-row items-end justify-center pt-10 pb-0 pl-10 pr-4 overflow-y-hidden bg-transparent shadow-none pointer-events-none w-80"
                [autoFocus]="false"
                [mode]="rightDrawerMode"
                [opened]="(wizardState$ | async)?.panelVisible"
                [position]="'end'"
                #panel
            >
                <!-- Demo sidebar -->
                <div
                    [ngClass]="{ opened: panel?.opened }"
                    class="relative w-full h-full transition-all duration-200 ease-in-out delay-200 origin-center transform translate-y-full border-2 border-gray-200 rounded-t-lg pointer-events-auto dark:border-gray-500 panel filter drop-shadow-2xl bg-card"
                >
                    <ng-container *ngIf="panelTemplate && panel?.opened">
                        <ng-container
                            *ngTemplateOutlet="panelTemplate"
                        ></ng-container>
                    </ng-container>
                </div>
            </mat-drawer>

            <!-- Drawer content -->
            <mat-drawer-content class="flex flex-col">
                <!-- Header -->
                <div
                    [class.saturate-100]="
                        (wizardState$ | async)?.currentStepValid
                    "
                    class="relative flex flex-col flex-wrap gap-4 p-6 filter saturate-0 header z-999 sm:flex-row flex-0 sm:items-center sm:justify-between sm:py-8 sm:px-10 bg-card"
                >
                    <div class="flex-1 min-w-0">
                        <!-- Breadcrumbs -->
                        <div>
                            <div
                                class="flex-wrap items-center font-medium sm:flex"
                            >
                                <span
                                    skeleton
                                    class="text-sm font-bold uppercase page-prefix-text"
                                >
                                    {{
                                        ((pageHeaderConfig$ | async)
                                            ?.headingPrefix$ | async) ||
                                            headingPrefix
                                    }}
                                </span>
                            </div>
                        </div>
                        <!-- Title -->
                        <div skeleton class="mt-2">
                            <h2
                                class="text-3xl font-extrabold leading-7 tracking-tight truncate md:text-4xl sm:leading-10"
                            >
                                {{
                                    ((pageHeaderConfig$ | async)?.heading$
                                        | async) || heading
                                }}
                            </h2>
                            <div class="small max-w-120">
                                {{
                                    ((pageHeaderConfig$ | async)?.description$
                                        | async) || description
                                }}
                            </div>
                        </div>
                    </div>
                    <!-- Actions -->
                    <div
                        skeleton
                        class="flex items-center justify-end flex-shrink-0 mt-6 sm:mt-0 sm:ml-4"
                    >
                        <button
                            @slideInTop
                            @slideOutTop
                            *ngIf="(wizardState$ | async)?.panelVisible"
                            class="absolute top-0 origin-center transform scale-75 right-22 z-99999"
                            mat-button
                            (click)="panel?.toggle()"
                        >
                            <mat-icon [svgIcon]="'menu'"></mat-icon>
                            <span class="ml-1">Summary</span>
                        </button>
                    </div>
                </div>
                <!-- Fixed Header -->
                <div
                    class="sticky top-0 flex flex-row items-center w-full px-10 py-2 border-b shadow-sm sticky-header min-h-10 max-h-10 z-99 bg-card"
                    style="margin-top: -1.75rem"
                >
                    <h5 class="flex gap-2 text-sm font-bold uppercase">
                        <span class="page-prefix-text">
                            {{
                                ((pageHeaderConfig$ | async)?.headingPrefix$
                                    | async) || headingPrefix
                            }}</span
                        >
                        {{
                            ((pageHeaderConfig$ | async)?.heading$ | async) ||
                                heading
                        }}
                    </h5>
                    <div
                        class="absolute bottom-0 left-0 w-full h-1 progress-bar-background"
                    >
                        <mat-progress-bar
                            *ngIf="(wizardState$ | async)?.loading"
                            [mode]="progressMode"
                        ></mat-progress-bar>
                    </div>
                </div>

                <!-- Main -->
                <app-router-animation
                    page-content
                    class="transition-all duration-200 ease-in-out"
                    [ngClass]="{
                        'panel-shown': false
                    }"
                >
                    <router-outlet
                        #outlet="outlet"
                        (activate)="onActivate($event)"
                    ></router-outlet>
                </app-router-animation>
            </mat-drawer-content>
        </mat-drawer-container>
        <app-wizard-footer
            (stepAction)="stepAction.emit($event)"
            [wizardState$]="wizardState$"
        ></app-wizard-footer>
    </div>
</ng-container>
