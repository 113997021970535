import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { FieldType } from '@ngx-formly/core';
import { PriceBreak, Rate } from 'app/shared/models/products/product-models';
import { siglaLog } from 'app/shared/utility/logging-util';

@Component({
  selector: 'app-formly-rate-price-break',
  templateUrl: './formly-price-break-typeahead.component.html',
  styleUrls: ['./formly-price-break-typeahead.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FormlyPriceBreakTypeaheadComponent extends FieldType implements OnInit {
  @ViewChild('priceBreakMenuTrigger') priceBreakMenuTrigger!: MatMenuTrigger;
  @ViewChild('priceBreakMenu') priceBreakMenu!: MatMenu;
  priceByLabel: 'Day' | 'Night' | 'Hour' = 'Day';
  priceBreakList: PriceBreak[] = new Array();
  minPriceBy!: number | null;
  maxPriceBy!: number | null;
  minPriceByChecked!: boolean;
  maxPriceByChecked!: boolean;
  hasError = false;
  constructor() {
    super();
  }

  ngOnInit(): void {
    this.to['data$']?.subscribe((value: Rate) => {
      if (value) {
        siglaLog(value);
        this.handleMenuClose();
        if (value) {
          this.priceByLabel = this.model?.priceBy || this.model?.meta?.priceByInherited || this.model?.priceByLabel || this.model?.meta?.priceByLabelInherited;
        }
        if (value.priceBreakList) {
          this.priceBreakList = value.priceBreakList as PriceBreak[];
        }
      }
    });
  }

  get priceBreakItems(): PriceBreak[] {
    return this.formControl.value;
  }

  handleMenuClose(): void {
    siglaLog(this.priceBreakMenuTrigger);
    if (this.priceBreakMenuTrigger?.menuOpen) {
      this.close();
    }
  }


  checkMinBy(): void {
    this.minPriceByChecked = !this.minPriceByChecked;
    if (this.minPriceByChecked) {
      this.minPriceBy = null;
    }
  }

  checkMaxBy(): void {
    this.maxPriceByChecked = !this.maxPriceByChecked;
    if (this.maxPriceByChecked) {
      this.maxPriceBy = null;
    }
  }

  remove(priceBreak: PriceBreak): void {
    if (this.to['deletePriceBreak']) {
      this.to['deletePriceBreak'](priceBreak);
    }
  }

  submit(): void {
    this.validatePriceBreak();
  }

  checkValidation(): void {
    if (this.priceBreakList.some((priceBreak: PriceBreak) => priceBreak.minPriceBy === this.minPriceBy || priceBreak.maxPriceBy === this.maxPriceBy)) {
      this.hasError = true;
    } else {
      this.hasError = false;
    }
  }

  validatePriceBreak(): void {
    if (this.priceBreakList.some((priceBreak: PriceBreak) => priceBreak.minPriceBy === this.minPriceBy || priceBreak.maxPriceBy === this.maxPriceBy)) {
      this.hasError = true;
    } else {
      this.hasError = false;
      if (!this.minPriceByChecked && !this.maxPriceByChecked) {
        if (this.maxPriceBy >= this.minPriceBy) {
          this.savePriceBreak();
        }
      } else {
        this.savePriceBreak();
      }

    }

  }

  savePriceBreak(): void {
    let priceBreak = new PriceBreak();
    priceBreak = Object.assign(priceBreak, {
      minPriceBy: this.minPriceBy,
      maxPriceBy: this.maxPriceBy,
      status: 'Ready',
      rateUId: this.model?.rateUId
    });
    if (this.to['savePriceBreak']) {
      this.to['savePriceBreak'](priceBreak);
    }
  }

  close(): void {
    if (this.priceBreakMenuTrigger) {
      this.priceBreakMenuTrigger.closeMenu();
      this.resetToDefaults();
    }
  }

  resetToDefaults(): void {
    this.minPriceBy = null;
    this.maxPriceBy = null;
    this.maxPriceByChecked = false;
    this.minPriceByChecked = false;
  }
}

