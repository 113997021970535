import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormStateObserverService } from 'app/shared/services/form-state-observer/form-state-observer.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

@Directive({
    selector: '[formStateObserver]'
})
export class FormStateObserverDirective implements OnInit, OnDestroy {
    @Input() formStateObserver: undefined | {
        formName: string;
        formLabel: string;
        form: FormGroup;
        modelUId: string;
        modelObject: string;
        parentComponentName: string;
    };
    instanceUId = uuidv4();
    private readonly _destroying$ = new Subject<void>();
    constructor(private _formStateObserverService: FormStateObserverService) {
    }

    ngOnInit(): void {
        this._addFormStateRecord();
        this.formStateObserver?.form?.statusChanges.pipe(takeUntil(this._destroying$)).subscribe(() => {
            this._updateFormStateRecord();
        });
    }

    ngOnDestroy(): void {
        this._removeFormStateRecord();
        this._destroying$.next(undefined);
        this._destroying$.complete();
    }

    private _addFormStateRecord(): void {
        if (this.formStateObserver) {
            const existingFormStateIndex = this._formStateObserverService.observedFormStateList.findIndex((formState) => formState.instanceUId === this.instanceUId);
            if (existingFormStateIndex > -1) {
                this._formStateObserverService.observedFormStateList.splice(existingFormStateIndex, 1);
            }

            this._formStateObserverService.observedFormStateList.push({
                instanceUId: this.instanceUId,
                formName: this.formStateObserver.formName,
                formLabel: this.formStateObserver.formLabel,
                formUId: this.formStateObserver.formName.toLowerCase() + '_form_' + this.formStateObserver.parentComponentName,
                dirty: this.formStateObserver.form.dirty,
                pristine: this.formStateObserver.form.pristine,
                touched: this.formStateObserver.form.touched,
                untouched: this.formStateObserver.form.untouched,
                modelUId: this.formStateObserver.modelUId,
                modelObject: this.formStateObserver.modelObject,
                parentComponentName:this.formStateObserver.parentComponentName,
                timestamp: Date.now(),
            });
            this._formStateObserverService.observedFormStateList.sort((x, y) => y.timestamp - x.timestamp);
            this._formStateObserverService.observedFormStateList$.next(this._formStateObserverService.observedFormStateList);

        }
    }

  private  _updateFormStateRecord(): void {
        if (this.formStateObserver) {
            const existingFormStateIndex = this._formStateObserverService.observedFormStateList.find((formState) => formState.instanceUId === this.instanceUId);
            if (existingFormStateIndex) {
                if (existingFormStateIndex.dirty !== this.formStateObserver.form.dirty || existingFormStateIndex.touched !== this.formStateObserver.form.touched) {
                    existingFormStateIndex.dirty = this.formStateObserver.form.dirty;
                    existingFormStateIndex.pristine = this.formStateObserver.form.pristine;
                    existingFormStateIndex.touched = this.formStateObserver.form.touched;
                    existingFormStateIndex.untouched = this.formStateObserver.form.untouched;
                    existingFormStateIndex.timestamp = Date.now();
                    this._formStateObserverService.observedFormStateList.sort((x, y) => y.timestamp - x.timestamp);
                    this._formStateObserverService.observedFormStateList$.next(this._formStateObserverService.observedFormStateList);
                }

            }
        }
    }

   private _removeFormStateRecord(): void {
        if (this.formStateObserver) {
            const existingFormStateIndex = this._formStateObserverService.observedFormStateList.findIndex((formState) => formState.instanceUId = this.instanceUId);
            if (existingFormStateIndex > -1) {
                this._formStateObserverService.observedFormStateList.splice(existingFormStateIndex, 1);
                this._formStateObserverService.observedFormStateList$.next(this._formStateObserverService.observedFormStateList);
            }
        }
    }

}


