import { ICellRendererParams } from '@ag-grid-community/all-modules';
import { AgRendererComponent } from '@ag-grid-community/angular';
import { Component } from '@angular/core';
import { Extra, Product, ProductSeries, Rate } from 'app/shared/models/products/product-models';

@Component({
    selector: 'app-ag-grid-attribute-info-renderer',
    templateUrl: './ag-grid-attribute-info-renderer.component.html',
    styleUrls: ['./ag-grid-attribute-info-renderer.component.scss'],
})
export class AgGridAttributeInfoRendererComponent implements AgRendererComponent {
    public params: (Record<string, any> & ICellRendererParams) | undefined;
    public attributeList: Array<{name: string; icon: string}> | undefined;
    constructor() {}

    refresh(params: ICellRendererParams): boolean {
        return false;
    }

    agInit(params: Record<string, any> & ICellRendererParams): void {
        this.params = params;
       const keyName = this.params['keyName'];
       this.attributeList = this.mapAttributeListIcons(this.params.data[keyName]);
    }
    mapAttributeListIcons(attributes: string[]): {name: string; icon: string}[]| undefined {
      const iconMap = [
        { name: 'Room', icon: 'mat_outline:hotel'},
        { name: 'Location', icon: 'mat_outline:location_on'},
        { name: 'Product Duration', icon: 'mat_outline:access_time'},
        { name: 'Session', icon: 'mat_outline:local_activity'},
        { name: 'Difficulty', icon: 'mat_outline:hiking'},
        { name: 'Itinerary', icon: 'mat_outline:table_rows'},
    ];
    if( attributes && attributes.length ) {
    return iconMap.filter((item)=> (attributes.includes(item.name)))??[];
    } else {
      return [];
    }
  }
}
