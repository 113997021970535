<mat-expansion-panel [ngClass]="{'border-2 border-red-800': (field.formControl?.invalid && field.formControl?.touched)}" [expanded]="true" class="w-full mt-4">
    <mat-expansion-panel-header>
      <mat-panel-title [ngClass]="{'text-red-800': (field.formControl?.invalid && field.formControl?.touched)}">
        {{ to.label }}
      </mat-panel-title>
  
    </mat-expansion-panel-header>

    <ng-container #fieldComponent></ng-container>

  </mat-expansion-panel>