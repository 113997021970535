import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FieldType } from '@ngx-formly/core';
import { FormattedAddressPart } from 'app/shared/models/shared/place-model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-formly-address-part-chip-list',
    templateUrl: './formly-address-part-chip-list.component.html',
    styleUrls: ['./formly-address-part-chip-list.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class FormlyAddressPartChipListComponent extends FieldType implements OnInit, OnDestroy {
    override formControl: any;
    addressPartList: FormattedAddressPart[] = [];
    private readonly _destroying$ = new Subject<void>();
    constructor() { super(); }

    ngOnInit(): void {
        this.addressPartList = this.formControl.value;
        this.field.className = 'address-part-chip-list';
        this.updateAddressPartOnValueChange('addressLine');
        this.updateAddressPartOnValueChange('town');
        this.updateAddressPartOnValueChange('state');
        this.updateAddressPartOnValueChange('postalCode');
        this.updateAddressPartOnValueChange('country');
    }
    ngOnDestroy(): void {
        this._destroying$.next(undefined);
        this._destroying$.complete();
    }

    drop(event: CdkDragDrop<FormattedAddressPart[]>): void {
        moveItemInArray(this.addressPartList, event.previousIndex, event.currentIndex);
        this.formControl.setValue(this.addressPartList);
    }

    updateAddressPartOnValueChange(fieldName: string): void {
        this.form.controls[fieldName].valueChanges.pipe(takeUntil(this._destroying$)).subscribe((value) => {
            const addressPart = this.addressPartList.find((part) => part.type === fieldName);
            if (addressPart) {
                addressPart.value = value;
                this.formControl.setValue(this.addressPartList);
            }
        });
    }


}
