/* eslint-disable @angular-eslint/component-class-suffix */
import { Component, TemplateRef, ViewChild, AfterViewInit } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { siglaLog } from 'app/shared/utility/logging-util';

@Component({
  selector: 'formly-wrapper-addons',
  template: `
  <ng-template #matPrefix>
    <span
    style="width:40px; height:24px;"
    class="m-0 flex flex-row justify-center"
      *ngIf="to['addonLeft']"
      [ngStyle]="{cursor: to['addonLeft'].onClick ? 'pointer' : 'inherit'}"
      (click)="addonLeftClick($event)"
      [matTooltip]="to['addonLeft'].tooltip"
    >
      <mat-icon *ngIf="to['addonLeft'].icon">{{ to['addonLeft'].icon }}</mat-icon>
      <span *ngIf="to['addonLeft'].text">{{ to['addonLeft'].text }}</span>
    </span>
  </ng-template>

  <ng-container #fieldComponent></ng-container>

  <ng-template #matSuffix>
    <span
    style="width:40px; height:24px;"
    class="m-0 flex flex-row justify-center"
      *ngIf="to['addonRight']?.icon || to['addonRight']?.text"
      [ngStyle]="{cursor: to['addonRight'].onClick ? 'pointer' : 'inherit'}"
      (click)="addonRightClick($event)"
      [matTooltip]="to['addonRight'].tooltip"
    >
      &nbsp;<mat-icon *ngIf="to['addonRight']?.icon">{{ to['addonRight']?.icon }}</mat-icon>
      &nbsp;<span *ngIf="to['addonRight']?.text">{{ to['addonRight'].text }}</span>
    </span>
    <button *ngIf="formControl.value && to['addonRight']?.clearable" matSuffix mat-icon-button aria-label="Clear" (click)="clearControl($event)">
    <mat-icon>close</mat-icon>
  </button>
  </ng-template>
  `,
})
export class FormlyWrapperAddons extends FieldWrapper implements AfterViewInit {
  @ViewChild('matPrefix') matPrefix: TemplateRef<any> | undefined;
  @ViewChild('matSuffix') matSuffix: TemplateRef<any> | undefined;

  ngAfterViewInit(): void {
    if (this.matPrefix) {
      Promise.resolve().then(() => this.to['prefix'] = this.matPrefix);
    }

    if (this.matSuffix) {
      Promise.resolve().then(() => this.to['suffix'] = this.matSuffix);
    }
  }

  clearControl($event: MouseEvent): void {
    $event.preventDefault();
    $event.stopPropagation();
    this.formControl.reset();
    this.formControl.updateValueAndValidity();
  }

  addonRightClick($event: any): void {
    if (this.to['addonRight'].onClick) {
      siglaLog($event);
      this.to['addonRight'].onClick(this.to, this, $event);
    }
  }

  addonLeftClick($event: any): void {
    if (this.to['addonLeft'].onClick) {
      this.to['addonLeft'].onClick(this.to, this, $event);
    }
  }
}
