<ng-container *ngIf="wizardState$ | async">
    <div
        class="absolute px-2 py-1 font-bold text-left transform -translate-x-full rounded-lg bg-default bg-opacity-80"
    >
        <div
            class="whitespace-nowrap"
            @zoomIn
            @zoomOut
            *ngIf="!(wizardState$ | async)?.currentStepValid"
        ></div>
    </div>

    <div
        @zoomIn
        @zoomOut
        *ngIf="(wizardState$ | async)?.footerVisible"
        [class.step-valid]="(wizardState$ | async)?.currentStepValid"
        class="relative z-10 flex transform scale-90 opacity-50 pointer-events-none wizard-footer"
    >
        <div
            class="flex items-center justify-center mx-auto bg-current rounded-full shadow-lg"
        >
            <button
                (click)="back()"
                *ngIf="(wizardState$ | async)?.first === false"
                class="flex-0 rounded-3xl"
                mat-button
                @zoomIn
                @zoomOut
            >
                <mat-icon
                    class="mr-2 text-on-primary-500"
                    [svgIcon]="'heroicons_outline:arrow-narrow-left'"
                ></mat-icon>
                <span class="mr-1 text-on-primary-500">Prev</span>
            </button>
            <div
                class="flex items-center justify-center mx-2.5 font-medium leading-5 text-on-primary"
            >
                <span>{{
                    getStepNumber((wizardState$ | async)?.currentStepIndex)
                }}</span>
                <span class="mx-0.5 text-on-primary-500">/</span>
                <span>{{ (wizardState$ | async)?.steps?.length }}</span>
            </div>
            <button
                (click)="next()"
                *ngIf="(wizardState$ | async)?.last === false"
                class="rounded-full flex-0"
                mat-button
                @zoomIn
                @zoomOut
            >
                <span class="ml-1 text-on-primary-500">Next</span>
                <mat-icon
                    class="ml-2 text-on-primary-500"
                    [svgIcon]="'heroicons_outline:arrow-narrow-right'"
                ></mat-icon>
            </button>
        </div>
    </div>
    <label
        @fadeIn
        @fadeOut
        *ngIf="
            !(wizardState$ | async)?.currentStepValid &&
            (wizardState$ | async)?.footerVisible
        "
        class="absolute left-0 z-10 flex items-center h-10 mr-10 transform -translate-x-full pointer-events-none whitespace-nowrap text-secondary"
        >Please complete all required fields before proceeding to the next
        step.</label
    >
    <label
        @fadeIn
        @fadeOut
        *ngIf="
            (wizardState$ | async)?.currentStepValid &&
            (wizardState$ | async)?.footerVisible
        "
        class="absolute left-0 z-10 flex items-center h-10 mr-10 transform -translate-x-full pointer-events-none whitespace-nowrap"
        style="color: var(--page-theme-color)"
        >Click next to proceed to the next step.</label
    >
</ng-container>
