import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FieldType } from '@ngx-formly/core';
import { ProductLookupRoomCriteria } from 'app/shared/models/lookup/lookup-models';
import { take } from 'rxjs/operators';
import { FormlyLookupRoomsDialogComponent } from './formly-lookup-rooms-dialog/formly-lookup-rooms-dialog.component';

@Component({
    selector: 'app-formly-lookup-room-assignment-autocomplete',
    templateUrl: './formly-lookup-room-assignment-autocomplete.component.html',
    styleUrls: ['./formly-lookup-room-assignment-autocomplete.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class FormlyLookupRoomAssignmentAutocompleteComponent extends FieldType implements OnInit {
    roomList: ProductLookupRoomCriteria[] = [];
    // Traveller List
    override formControl: any;

    constructor(private _dialog: MatDialog,) {
        super();
    }

    ngOnInit(): void {
        this.roomList = this.to.options as ProductLookupRoomCriteria[];
    }

    openDialog = (): void => {
        const data = JSON.parse(JSON.stringify({ roomList: this.formControl.value, travellerList: this.to['travellerList'] }));
        const dialogRef = this._dialog.open(FormlyLookupRoomsDialogComponent, {
            data: data,
        });
        dialogRef.afterClosed().pipe(take(1)).subscribe((event) => {
            if (event) {
                this.formControl.patchValue(event.roomList);
            }
        });
    };



}
