<mat-form-field>
    <mat-slide-toggle
        matTooltip="Date Range"
        matPrefix
        [(ngModel)]="range"
        (ngModelChange)="pickerChange()"
        class="transform scale-75"
    ></mat-slide-toggle>
    <ng-container *ngIf="!range">
        <input
            matInput
            [matDatepicker]="picker"
            [matDatepickerFilter]="matDatepickerFilter"
            placeholder="Date"
            [(ngModel)]="fromDate"
            (ngModelChange)="dateChange()"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker (closed)="endDateChange()" #picker></mat-datepicker>
    </ng-container>

    <ng-container *ngIf="range">
        <mat-date-range-input
            [rangePicker]="picker"
            [dateFilter]="matDatepickerFilter"
        >
            <input
                matStartDate
                placeholder="Start date"
                [(ngModel)]="fromDate"
                (ngModelChange)="dateChange()"
            />
            <input
                matEndDate
                placeholder="End date"
                [(ngModel)]="toDate"
                (ngModelChange)="dateChange()"
            />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker
            (closed)="endDateChange()"
            #picker
        ></mat-date-range-picker>
    </ng-container>
</mat-form-field>
