<button
    #trigger="matMenuTrigger"
    class="absolute -mt-1 z-99"
    mat-icon-button
    aria-label="app history"
    [matMenuTriggerFor]="menu"
    (menuOpened)="menuOpened()"
    (document:keydown.meta.h)="historyShortcut(trigger,filter)"
>
    <mat-icon class="text-white" svgIcon="history"></mat-icon>
</button>
<mat-menu #menu="matMenu" class="min-w-80" style="max-height:80vh;">
    <div class="flex flex-row items-center justify-between w-full py-2 pl-4 -mt-2 text-xs shadow-sm">
        <span class="text-xs font-bold leading-3 uppercase">History</span>
        <button (click)="clearAllHistory($event)" mat-stroked-button color="warn" class="transform scale-75">
            Clear History
        </button>
    </div>
    <form>
        <input
            #filter
            [formControl]="filterControl"
            class="p-2 m-2 border border-solid rounded-sm min-h-10 bg-default"
            style="width: calc(100% - 1rem)"
            placeholder="Search History"
            aria-label="State"
            (keyup)="(0)"
            (click)="$event.stopPropagation()"
        />
    </form>

    <ng-container *ngFor="let item of filteredPageHistoryItems; let i = index">
        <div
            class="w-full px-4 py-2 text-xs font-bold leading-3 uppercase bg-default"
            *ngIf="timeStringToDate(item?.time).getDate() !== timeStringToDate(filteredPageHistoryItems?.[i-1]?.time).getDate()"
        >
            {{ item.time | date: "mediumDate" }}
        </div>
        <button
            mat-menu-item
            class="flex flex-row items-center justify-between border border-b border-gray-500 max-h-10"
            (click)="$event.stopPropagation()"
        >
            <div
                (click)="routerLink(item.url)"
                class="flex flex-col items-start justify-center w-full"
                matTooltip="{{ item.url }}"
            >
                <div
                    class="text-xs font-bold leading-3 uppercase max-h-3"
                    *ngIf="item.headingPrefix"
                >
                    {{ item.headingPrefix }}
                </div>
                <div class="text-sm font-bold leading-4 max-h-4">
                    {{ item.heading || item.path }}
                </div>
            </div>

            <div
                class="flex flex-row items-center justify-end text-xs font-bold leading-3"
            >
                <span matTooltip="{{ item.time | date: 'HH:mm:ss a z' }}">
                    {{ item.time | date: "HH:mm" }}
                </span>

                <a
                    [href]="[item.url]"
                    matTooltip="Open in new Tab"
                    target="_blank"
                    class="flex flex-row items-center justify-center ml-2"
                >
                    <mat-icon
                        class="mx-1 cursor-pointer icon-size-4"
                        svgIcon="open_in_new"
                    >
                    </mat-icon>
                </a>

                <div
                    class="flex flex-row items-center justify-center"
                    matTooltip="Copy Url"
                    (click)="copyUrl(item.url)"
                >
                    <mat-icon
                        class="mx-1 cursor-pointer icon-size-4"
                        svgIcon="content_copy"
                    >
                    </mat-icon>
                </div>
            </div>
        </button>
    </ng-container>
</mat-menu>
