import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';

import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import { FuseMockApiModule } from '@fuse/lib/mock-api';
import { CoreModule } from 'app/core/core.module';
import { appConfig } from 'app/core/config/app.config';
import { mockApiServices } from 'app/mock-api';
import { LayoutModule } from 'app/layout/layout.module';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { ReactiveFormsModule } from '@angular/forms';
import { environment } from 'environments/environment';

import { MsalModule, MsalRedirectComponent, MsalGuard, MsalInterceptor, MsalService, MsalGuardConfiguration, MsalInterceptorConfiguration, MsalBroadcastService } from '@azure/msal-angular';
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiPathConfig } from './shared/config/api-path.config';

import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { AgmCoreModule } from '@agm/core';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { BaseModule } from './modules/base/base.module';
import { MsalAuthGuard } from './core/auth/guards/msal-auth.guard';
import { siglaLog } from './shared/utility/logging-util';

// Use by MSAL for controlling authentication/login flow
const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
const isIframe = window !== window.parent && !window.opener;

const routerConfig: ExtraOptions = {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    initialNavigation: !isIframe ? 'enabled' : 'disabled' // Don't perform initial navigation in iframes
};

const googleMapsParams = {
    apiKey: 'AIzaSyD7FfGhQ3h8Q54zWzYl95R-FByAz-GAfLk',
    libraries: ['places'],
    language: 'en',
    // region: 'DE'
};


export const loggerCallback = (logLevel: LogLevel, message: string): void => {
    siglaLog(logLevel + ' - ' + message);
};

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),

        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        FuseMockApiModule.forRoot(mockApiServices),

        // Authentication
        MsalModule.forRoot(new PublicClientApplication({
            auth: {
                clientId: environment.authentication.clientId,
                authority: environment.authentication.authority,
                knownAuthorities: environment.authentication.knownAuthorities,
                redirectUri: window.location.origin,
                navigateToLoginRequestUrl: true
            },
            cache: {
                cacheLocation: environment.authentication.cacheLocation,
                storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
            }/*,
            system: {
                loggerOptions: {
                    loggerCallback,
                    logLevel: LogLevel.Info,
                    piiLoggingEnabled: false
                }
            }*/
        }), {
            interactionType: InteractionType.Redirect, // MSAL Guard Configuration
            authRequest: {
                scopes: environment.authentication.scopes
            },
            loginFailedRoute: '/sign-out'
        }, {
            interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
            protectedResourceMap: new Map([
                [environment.api.siglaApi, [environment.authentication.clientId]] // B2C openid connect requires the client id as a scope to get access token
            ])
        }),

        // Core module of your application
        CoreModule,

        // Layout module of your application
        LayoutModule,

        // 3rd party modules that require global configuration via forRoot

        ReactiveFormsModule,
        // Google Autocomplete;
        AgmCoreModule.forRoot(googleMapsParams),
        MatGoogleMapsAutocompleteModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        MsalService,
        MsalGuard,
        MsalAuthGuard,
        MsalBroadcastService,
        // API Path Configuration
        {
            provide: 'SiglaApiPath',
            useValue: new ApiPathConfig(
                environment.api.siglaApi
            ),
        },
        { provide: MAT_BOTTOM_SHEET_DATA, useValue: {} }
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [
        AppComponent,
        MsalRedirectComponent
    ]
})
export class AppModule {
}
