import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { CarouselComponent } from './components/carousel/carousel.component';
import { EditorComponent } from './components/editor/editor.component';
import { ImageUploaderComponent } from './components/image-uploader/image-uploader.component';
import { ImageComponent } from './components/image/image.component';
import { TextComponent } from './components/text/text.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { RowComponent } from './row/row.component';


@NgModule({
  declarations: [
    EditorComponent,
    ImageComponent,
    CarouselComponent,
    ToolbarComponent,
    TextComponent,
    RowComponent,
    ImageUploaderComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    DragDropModule,
    ReactiveFormsModule,
    AngularEditorModule
  ],
  exports: [
    EditorComponent
  ]
})
export class EditorModule { }
