
import { Component, ContentChildren, HostBinding, QueryList, ViewEncapsulation } from '@angular/core';
import { CarouselNavItemDirective } from '../../directives/carousel-nav-item.directive';

@Component({
    selector: 'app-carousel-nav',
    templateUrl: './carousel-nav.component.html',
    styleUrls: ['./carousel-nav.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CarouselNavComponent {
    @HostBinding('style.--selected-item-position') selectedItemPosition: number = -1000;
    @ContentChildren(CarouselNavItemDirective) navItems: undefined | QueryList<CarouselNavItemDirective>;
    scrolledToStart = true;
    scrolledToEnd = false;
    constructor() { }

    next(): void {
        const lastVisibleItem = this.navItems?.find((item, i) => {
            const previousItem = this.navItems?.get(i - 1);
            if (previousItem !== undefined) {
                return previousItem.isVisibleInView && !item.isVisibleInView;
            }
            return false;
        });
        if (lastVisibleItem) {
            lastVisibleItem.scrollIntoView();
        }
    }

    back(): void {
        let firstVisibleItemIndex = 0;
        const firstVisibleItem = this.navItems?.find((item, i) => { if (item.isVisibleInView) { firstVisibleItemIndex = i; return true; } return false; });
        const visibleItemsInView: number = this.navItems?.filter((item, i) => item.isVisibleInView).length || 1;
        const previousPageItem = this.navItems?.get(firstVisibleItemIndex - visibleItemsInView) || this.navItems?.get(0);
        if (previousPageItem) {
            previousPageItem.scrollIntoView();
        } else {
            firstVisibleItem?.scrollIntoView();
        }

    }

}
