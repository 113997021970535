<ng-select
    #select
    [items]="options$ | async"
    [typeahead]="search$"
    [(ngModel)]="params.value"
    [loading]="loading"
    loadingText="Loading..."
    appendTo="body"
    [editableSearchTerm]="true"
    (change)="changeSelection($event)"
    [clearable]="true"
    (close)="close()"
    (clear)="clear()"
>
</ng-select>
