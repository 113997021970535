import { AbstractControl } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyLookupRoomsDialogComponent } from '../formly-lookup-rooms-dialog.component';

export const fields = (component: FormlyLookupRoomsDialogComponent): FormlyFieldConfig[] => [
    {
        key: 'roomList',
        type: 'repeat',
        templateOptions: {
            addLabel: 'Add Room',
            label: 'Preferred Rooms',
            tooltip: 'The customers preferred rooms for their booking, used for product search. Actual rooms & assignment depends on product & availability.',
            listItemPrefixLabel: 'Room',
            listItemNumbering: true,
            required: true
        },
        fieldArray: {
            hooks: {
                onInit: (fieldArray: FormlyFieldConfig | undefined): void => {
                    component.fieldArrayInit(fieldArray);
                },
                onDestroy: (fieldArray: FormlyFieldConfig | undefined): void => {
                    component.fieldArrayDelete(fieldArray);
                },
            },
            fieldGroup: [
                {
                    className: 'col-span-full',
                    key: 'travellerList',
                    type: 'lookup-traveller-assignment-autocomplete',
                    templateOptions: {
                        label: 'Travellers',
                        required: true
                    }
                },
                {
                    key: 'roomNo',
                    templateOptions: {
                        hidden: true
                    },
                },
            ],
        },
    }
];


