import { Component, OnInit, ChangeDetectionStrategy, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-price-sheet-currency-input',
  templateUrl: './formly-price-sheet-currency-input.component.html',
  styleUrls: ['./formly-price-sheet-currency-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class FormlyPriceSheetCurrencyInputComponent extends FieldType implements OnInit {
  @ViewChild('currencyInput') currencyInput: ElementRef | undefined;
  override formControl: any;
  private regex: RegExp = new RegExp(/^\d*\.?\d{0,2}$/g);
  private specialKeys: Array<string> = [
    'Backspace',
    'Tab',
    'End',
    'Home',
    '-',
    'ArrowLeft',
    'ArrowRight',
    'Del',
    'Delete',
  ];

  constructor() {
    super();
  }

  ngOnInit(): void {
    if (this.formControl.value === 'Manual') {
      this.formControl.disable();
    }
  }

  omitExtraDecimalPlaces(event: KeyboardEvent): void {
    //Check for decimal attribute from formly field configuration.
    if (this.field?.templateOptions?.attributes?.['decimal']) {
      this.regex = new RegExp(`^\\d*\\.?\\d{0,${this.field?.templateOptions?.attributes?.['decimal']}}$`, 'g');
    }
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    const current: string = this.currencyInput?.nativeElement.value;
    const position = this.currencyInput?.nativeElement.selectionStart;
    const next: string = [current.slice(0, position), event.key === 'Decimal' ? '.' : event.key, current.slice(position)].join('');
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }

  togglePriceState(priceSpecified: boolean): void {
    const formControl = this.formControl as FormControl;
    if (priceSpecified) {
      formControl.enable();
      formControl.setValue('0.00');
    } else {
      formControl.setValue(null);
      formControl.markAllAsTouched();
      formControl.disable();
      this.field.form?.markAsDirty();
    }
  }
}
