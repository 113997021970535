import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { adminEndpoints } from 'app/shared/config/endpoints/admin-endpoints';
import { PathUtil } from 'app/shared/utility/path-util';
import { ApiPathConfig } from 'app/shared/config/api-path.config';

import { map, take, takeUntil } from 'rxjs/operators';
import { Currency, List } from 'app/shared/models/ref-data/ref-data-models';
import { ChangeService } from 'app/core/change/change.service';
import { ExchangeRate } from 'app/shared/models/commercial/commercial-models';
import { RefDataService } from 'app/shared/services/ref-data/ref-data.service';
import { changeTicketModelNames } from 'app/shared/models/core/core-models';
import { ActivityService } from 'app/core/activity/activity.service';
import { Error } from 'app/shared/models/core/core-models';

@Injectable()
export class ExchangeRateService implements OnDestroy {
    private readonly _exchangeRate$ = new BehaviorSubject<ExchangeRate | undefined>(
        undefined
    );
    private readonly _exchangeRateUId$ = new BehaviorSubject<string | undefined>(
        undefined
    );
    private readonly _destroying$ = new Subject<void>();


    constructor(
        private httpClient: HttpClient,
        private refDataService: RefDataService,
        private changeService: ChangeService,
        private activityService: ActivityService,
        @Inject('SiglaApiPath') private siglaApiPath: ApiPathConfig
    ) {}



    ngOnDestroy(): void {
        this._destroying$.next(undefined);
        this._destroying$.complete();
    }

    /**
     * Accessors
     */

    get isLoaded(): boolean {
        return this._exchangeRate$.value !== undefined;
    }

    get exchangeRate$(): Observable<ExchangeRate | undefined> {
        return this._exchangeRate$
            .asObservable()
            .pipe(takeUntil(this._destroying$));
    }

   /**
    * Ref data
    */

    get currencyList$(): Observable<Currency[]> {
        return this.refDataService.currencyList$;
    }    get exchangeRateUId(): string | undefined {
        return this._exchangeRateUId$.value;
    }
    get exchangeRate(): ExchangeRate | undefined {
        return this._exchangeRate$.value;
    }
    set exchangeRate(exchangeRate: ExchangeRate | undefined) {
        if (exchangeRate) {
            this._exchangeRateUId$.next(exchangeRate.exchangeRateUId);
        } else {
            this._exchangeRateUId$.next(undefined);
        }
        this._exchangeRate$.next(exchangeRate);
    }

    checkExistingExchangeRateList(fromCurrency: string, toCurrency: string, exchangeRateUId: string): Observable<boolean | undefined> {
        const url = PathUtil.combinePaths(
            this.siglaApiPath.apiPath,
            adminEndpoints.refData.exchangeRateList
        );

        return this.httpClient.get<ExchangeRate[]>(url)
            .pipe(map((exchangeRateList) => exchangeRateList.findIndex((item) =>
                item.fromCurrency === fromCurrency &&
                item.toCurrency === toCurrency &&
                item.exchangeRateUId !== exchangeRateUId
                ) === -1));
    }

    getListList(): Observable<List[]> {

        return this.refDataService.listList$;
    }

    // getCurrencyList(): Observable<Currency[]> {
    //     return this.refDataService.getCurrencyList();
    // }

    /**
     * API methods
     */

    getExchangeRateList(): Observable<ExchangeRate[]> {
        const url = PathUtil.combinePaths(
            this.siglaApiPath.apiPath,
            adminEndpoints.commercial.exchangeRateList
        );

        return this.httpClient.get<ExchangeRate[]>(url).pipe(take(1));
    }

    loadExchangeRate(exchangeRateUId: string): void {

        if (!exchangeRateUId) {
            return;
        }

        this._exchangeRateUId$.next(exchangeRateUId);

        const url = PathUtil.combinePaths(
            this.siglaApiPath.apiPath,
            adminEndpoints.commercial.exchangeRateWithUId
        ).replace('{exchangeRateUId}', exchangeRateUId);

        const loadRequest =  this.httpClient
            .get<ExchangeRate>(url)
            .pipe(takeUntil(this._destroying$));
        const activity = this.activityService?.newActivity('Loading', 'ExchangeRate');

        loadRequest
            .subscribe((exchangeRate: ExchangeRate) => {
                this._exchangeRate$.next(exchangeRate);
                this.activityService.completeActivity(activity, exchangeRate);
            },
                (error: Error) => {
                    this.activityService.failActivity(activity, error);
                });
    }

    saveExchangeRate(exchangeRate: ExchangeRate): void {
        const url = PathUtil.combinePaths(
            this.siglaApiPath.apiPath,
            adminEndpoints.commercial.exchangeRate
        );
        if (exchangeRate.exchangeRateUId) {
            const activity = this.activityService?.newActivity('Updating', 'ExchangeRate', exchangeRate.exchangeRateUId);
            this.httpClient
                .put<ExchangeRate>(url, exchangeRate)
                .pipe(take(1))
                .subscribe((savedExchangeRate: ExchangeRate) => {
                    this.changeService.notifyChange({
                        modelName: changeTicketModelNames.exchangeRate,
                        modelObject: savedExchangeRate,
                        messageName: changeTicketModelNames.exchangeRate,
                    });
                    this.activityService?.completeActivity(activity, savedExchangeRate);
                    this._exchangeRate$.next(savedExchangeRate);
                });
        } else {
           // const activity = this.activityService?.newActivity('Adding', 'ExchangeRate', 'add-new');
            this.httpClient
                .post<ExchangeRate>(url, exchangeRate)
                .pipe(take(1))
                .subscribe((savedExchangeRate: ExchangeRate) => {
                    this.changeService.notifyNew({
                        modelName: changeTicketModelNames.exchangeRate,
                        modelObject: savedExchangeRate,
                        messageName: changeTicketModelNames.exchangeRate,
                    });
                  //  savedExchangeRate = Object.assign(savedExchangeRate, {exchangeRateUId: 'add-new'});
                 //   this.activityService?.completeActivity(activity, savedExchangeRate);
                    this._exchangeRate$.next(savedExchangeRate);
                });
        }
    }

    deleteExchangeRate(exchangeRateUId: string): Observable<ExchangeRate | undefined> {
        const url = PathUtil.combinePaths(
            this.siglaApiPath.apiPath,
            adminEndpoints.commercial.exchangeRateWithUId
        ).replace('{exchangeRateUId}', exchangeRateUId);

        return this.httpClient
                .delete<ExchangeRate>(url)
                .pipe(take(1));

    }
}
