import {
    Component,
    OnInit,
    OnDestroy,
    ViewEncapsulation,
    ChangeDetectorRef,
    Input,
    TemplateRef,
} from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet, ɵangular_packages_router_router_b } from '@angular/router';
import { PageHistoryService } from 'app/shared/services/page-history/page-history.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { PageData, PageRoute } from '../../interfaces/page-data';
import { StandardPageLayoutComponent } from '../standard-page-layout/standard-page-layout.component';
@Component({
    selector: 'app-tabbed-page-layout',
    templateUrl: './tabbed-page-layout.component.html',
    styleUrls: ['./tabbed-page-layout.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class TabbedPageLayoutComponent
    extends StandardPageLayoutComponent
    implements OnInit, OnDestroy {
    @Input() navTemplate: TemplateRef<any> | undefined;
    override currentPath: string | undefined ;
    childrenRoutes$: BehaviorSubject<PageRoute[]> = new BehaviorSubject(new Array());

    constructor(
      override pageHistory: PageHistoryService,
      override  activatedRoute: ActivatedRoute,
      override router: Router,
     override  cdRef: ChangeDetectorRef,
    ) {
        super(pageHistory, activatedRoute,router,cdRef);
        this.getChildRoutes();
    }

    getChildRoutes(): void {
        if (this.activatedRoute.routeConfig?.children) {
            if (this.activatedRoute.routeConfig.children.length > 0) {
                const pageRoutes = this.activatedRoute.routeConfig.children.filter(
                    (child) =>
                        child.component !== undefined && child.outlet === undefined
                );
                const data = this.activatedRoute?.firstChild?.routeConfig?.data;
                this.currentPath = this.activatedRoute?.firstChild?.routeConfig?.path;
                this.setPageData(data as PageData);
                this.childrenRoutes$.next(pageRoutes);
            }
        }
    }

    override onActivate(componentRef: Record<string, any>): void {
        this.pageHeaderConfig$ = componentRef['pageHeaderConfig$'];
        if (this.activatedRoute.firstChild) {
            if (this.activatedRoute.firstChild.routeConfig?.path) {
                const data = this.activatedRoute.firstChild.routeConfig.data;
                this.currentPath = this.activatedRoute.firstChild.routeConfig.path;
                this.setPageData(data as PageData);
            }
        }
    }


    override ngOnDestroy(): void {
        this.childrenRoutes$?.complete();
        this.childrenRoutes$?.unsubscribe();
    }
}
