import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { Content, ContentBlock, ContentTools } from '../../types/editor';
@Component({
    selector: 'app-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit {
    @Input() content!: Content;
    @Output() ngModelChange = new EventEmitter();
    contentTools = contentTools();
    constructor(public cdRef: ChangeDetectorRef) { }

    ngOnInit(): void {
    }

    insertBlock(blockTemplate: ContentBlock): void {
        const block = JSON.parse(JSON.stringify(blockTemplate));
        if (this.content) {
            block.id = Math.random().toString(36).substr(2, 9);
            this.content.blocks.push(block);
            this.ngModelChange.emit();
            this.cdRef.detectChanges();
        }
    }

}

const contentTools = (): ContentTools[] => [
    {
        name: 'One Column Text', icons: ['view_headline'], block: {
            type: 'paragraph', id: '', name: 'One Column Text', data: { text: '' }
        },
    },
    {
        name: 'Two Column Text', icons: ['view_headline', 'view_headline'], block: {
            type: 'twoColumnText', id: '', name: 'Two Column Text', data: {
                columns: [
                    { type: 'paragraph', text: '' }, { type: 'paragraph', text: '' }
                ]
            }
        },
    },
    {
        name: 'Three Column Text', icons: ['view_headline', 'view_headline', 'view_headline'], block: {
            type: 'threeColumnText', id: '', name: 'Two Column Text', data: {
                columns: [
                    { type: 'paragraph', text: '' }, { type: 'paragraph', text: '' }, { type: 'paragraph', text: '' }
                ]
            }
        },
    },
    {
        name: 'Two Column Image Text', icons: ['image', 'view_headline'], block: {
            type: 'twoColumnImageText', id: '', name: 'Two Column Image Text', data: {
                columns: [
                    { type: 'image', file: { url: '', alt: '', caption: '' } }, { type: 'paragraph', text: '' }
                ]
            }
        },
    },

    {
        name: 'Two Column Text Image', icons: ['view_headline', 'image'], block: {
            type: 'twoColumnTextImage', id: '', name: 'Two Column Text Image', data: {
                columns: [
                    { type: 'paragraph', text: '' }, { type: 'image', file: { url: '', alt: '', caption: '' } }
                ]
            }
        },
    },

    {
        name: 'Image', icons: ['image'], block: {
            type: 'image', id: '', name: 'Image', data: { file: { url: '', alt: '', caption: '' } }
        },
    },
    {
        name: 'Gallery', icons: ['apps'], block: {
            type: 'carousel', id: '', name: 'Gallery', data: { images: [] }
        },
    },

];

