<app-dialog-wrapper [dialogConfig]="dialogConfig">
    <form #ngForm="ngForm" [formGroup]="form">
        <app-formly-form-wrapper>
            <formly-form [form]="form" [model]="model" [fields]="fields">
            </formly-form>
            <app-form-actions (submitAction)="ngSubmit()" submitLabel="Submit">

            </app-form-actions>
        </app-formly-form-wrapper>
        <span class="text-sm font-bold text-warn" *ngIf="this.form.value.roomList.length === 0">
            Rooms are required, Please add add at least one room.
        </span>
    </form>
</app-dialog-wrapper>
