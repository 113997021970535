import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { FieldType } from '@ngx-formly/core';

@Component({
    selector: 'app-formly-traveller-assignment-autocomplete',
    templateUrl: './formly-traveller-assignment-autocomplete.component.html',
    styleUrls: ['../../styles/ng-select.scss','./formly-traveller-assignment-autocomplete.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class FormlyTravellerAssignmentAutocompleteComponent extends FieldType implements OnInit {

    @ViewChild('ngSelect') ngSelect!: NgSelectComponent;
    travellerAssignments!: any[];
    override formControl: any;

      clearItem(uid: string): void {
        const formControl: FormControl = this.formControl;
        const index = formControl.value.findIndex((item: any) => item?.value?.uid === uid);
        if (index > -1) {
            formControl.value.splice(index, 1);
        }
        this.formControl.patchValue(formControl.value);
    }

    ngOnInit(): void {
        this.travellerAssignments = this.to.options as [];
    }
}
