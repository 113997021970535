import { ICellRendererParams } from '@ag-grid-community/all-modules';
import { AgRendererComponent } from '@ag-grid-community/angular';
import { Component, OnInit } from '@angular/core';
import { Product } from 'app/shared/models/products/product-models';
import { AgGridNewTabCellRendererComponent } from '../ag-grid-new-tab-cell-renderer/ag-grid-new-tab-cell-renderer.component';

@Component({
  selector: 'app-ag-grid-series-group-renderer',
  templateUrl: './ag-grid-series-group-renderer.component.html',
  styleUrls: ['./ag-grid-series-group-renderer.component.scss']
})
export class AgGridSeriesGroupRendererComponent extends AgGridNewTabCellRendererComponent implements AgRendererComponent {

  public override params: any | undefined;


  override agInit(params: any): void {
    this.params = params;
    if (this.params?.productList?.length) {
      this.params.data = this.params?.productList.find((product: Product) => product.productSeriesUId === this.params.value);
    }
  }

}
