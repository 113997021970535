import { Injectable, OnDestroy } from '@angular/core';
import { ActivationEnd, ActivationStart, Router } from '@angular/router';
import { PageHistory } from 'app/shared/modules/page-layout/interfaces/page-history';
import { Observable, of, Subscription } from 'rxjs';
import { delay, filter } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class PageHistoryService implements OnDestroy {
    private subscription: Subscription;
    private pageHistoryItems: PageHistory[] = [];


    constructor(private router: Router) {
        this.pageHistoryItems = this.getHistory() || [];
        const events: Observable<any> = this.router.events;
        this.subscription = events.pipe(
            filter(event => event instanceof ActivationStart)
        ).pipe(delay(0)).subscribe((event) => {
            if (event.snapshot.routeConfig.path !== '') {
                if (event.snapshot.children.length === 0) {
                    const snapshot: any = event.snapshot;
                    const component: any = snapshot.routeConfig.component;
                    const uid: any = snapshot.parent.params.id || snapshot.params.id || '';
                    const id = component.name + '_' + component.ɵcmp?.id  + '_' +  uid;
                    const pageHistory = this.getHistory();
                    this.pageHistoryItems = pageHistory || [];
                    this.pageHistoryItems.unshift({
                        id: id,
                        headingPrefix: event.snapshot.data.headingPrefix,
                        heading: event.snapshot.data.heading,
                        description: event.snapshot.data.description,
                        time: Date.now().toString(),
                        url: event.snapshot._routerState.url,
                        path: event.snapshot.routeConfig.path,
                    });
                    this.pageHistoryItems = this.pageHistoryItems.slice(0, 100);
                    this.setHistory(this.pageHistoryItems);


                }
            }
        });
    }
    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    updatePageData(id: string, heading: string | undefined, headingPrefix: string | undefined, description: string | undefined): void {
            const item = this.pageHistoryItems.find((historyItem: PageHistory) => historyItem.id === id);
            if (item && heading !== 'Loading...') {
                item.heading = heading || '';
                item.headingPrefix = headingPrefix || '';
                item.description = description || '';
                this.setHistory(this.pageHistoryItems);
            }
    }


    getHistory(): PageHistory[] | null {
        const pageHistoryJSON = localStorage.getItem('pageHistory');
        if (pageHistoryJSON !== null) {
            const pageHistory: any = JSON.parse(pageHistoryJSON);
            return pageHistory;
        }
        return null;
    }

    setHistory(pageHistory: PageHistory[]): void {
        localStorage.setItem('pageHistory', JSON.stringify(pageHistory));
    }

    clearHistory(): void {
        localStorage.removeItem('pageHistory');
    }

}
