<app-dialog-wrapper [dialogConfig]="dialogConfig">
    <form
        [class.loading]="!ready"
        #ngForm="ngForm"
        [formGroup]="form"
        (reset)="reset()"
    >
        <app-formly-form-wrapper>
            <formly-form [form]="form" [model]="place" [fields]="fields">
            </formly-form>
            <app-form-actions
                (submitAction)="submitAction()"
                submitLabel="Save"
            >
            </app-form-actions>
        </app-formly-form-wrapper>
    </form>
</app-dialog-wrapper>
