import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { StandardPageLayoutComponent } from './components/standard-page-layout/standard-page-layout.component';
import { PageLayoutWrapperComponent } from './components/page-layout-wrapper/page-layout-wrapper.component';
import { TabbedPageLayoutComponent } from './components/tabbed-page-layout/tabbed-page-layout.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSidenavModule } from '@angular/material/sidenav';
import { SidebarPageLayoutComponent } from './components/sidebar-page-layout/sidebar-page-layout.component';
import { RouterAnimationModule } from '../router-animation/router-animation.module';
import { AuxiliaryWrapperComponent } from './components/auxiliary-wrapper/auxiliary-wrapper.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { GeneralUtilitiesModule } from '../general-utilities/general-utilities.module';
import { WizardPageLayoutComponent } from './components/wizard-page-layout/wizard-page-layout.component';
import { WizardFooterComponent } from './components/wizard-footer/wizard-footer.component';
import { MatMenuModule } from '@angular/material/menu';
import { PageHistoryComponentComponent } from './components/page-history-component/page-history-component.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
    declarations: [
        StandardPageLayoutComponent,
        PageLayoutWrapperComponent,
        TabbedPageLayoutComponent,
        SidebarPageLayoutComponent,
        AuxiliaryWrapperComponent,
        WizardPageLayoutComponent,
        WizardFooterComponent,
        PageHistoryComponentComponent
    ],
    imports: [
        GeneralUtilitiesModule,
        ScrollingModule,
        CommonModule,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        MatMenuModule,
        MatProgressBarModule,
        MatTabsModule,
        MatSidenavModule,
        RouterModule,
        RouterAnimationModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    exports: [
        StandardPageLayoutComponent,
        PageLayoutWrapperComponent,
        TabbedPageLayoutComponent,
        SidebarPageLayoutComponent,
        AuxiliaryWrapperComponent,
        WizardPageLayoutComponent
    ]
})
export class PageLayoutModule { }
