import { AgRendererComponent } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-enterprise/all-modules';
import { Component } from '@angular/core';

@Component({
    selector: 'app-ag-grid-template-cell-renderer',
    templateUrl: './ag-grid-template-cell-renderer.component.html',
    styleUrls: ['./ag-grid-template-cell-renderer.component.scss']
})
export class AgGridTemplateCellRendererComponent implements AgRendererComponent {
    public params: any | undefined;
    constructor() { }

    refresh(params: ICellRendererParams): boolean {
        return false;
    }
    agInit(params: any): void {
        this.params = params;
    }

    toggleMasterDetail(): void {
        this.params.node.expanded = !this.params.node.expanded;
        this.params.api.onGroupExpandedOrCollapsed();
    }

}
