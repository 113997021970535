import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, EventEmitter, ViewEncapsulation } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { feather, heroicons, material } from 'app/mock-api/ui/icons/data';
import { of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, startWith, switchMap, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-formly-icons-auto-complete',
    templateUrl: './formly-icons-auto-complete.component.html',
    styleUrls: ['../../styles/ng-select.scss', './formly-icons-auto-complete.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class FormlyIconsAutoCompleteComponent extends FieldType implements OnInit, OnDestroy {
    override formControl: any;
    search$ = new EventEmitter();
    onDestroy$ = new Subject<void>();
    options$: any;
    iconList = new Array();
    private readonly _feather: any = feather;
    private readonly _heroicons: any = heroicons;
    private readonly _material: any = material;

    autocompleteChange($event: any): void {
        this.search$.emit($event.target.value);
    }

    ngOnInit(): void {
        this.iconList = Array.prototype.concat.apply(this.iconList,
            [[...this._material],
            [...this._material].map(a => 'mat_solid:' + a),
            [...this._material].map(a => 'mat_outline:' + a),
            [...this._feather].map(a => 'feather:' + a),
            [...this._heroicons].map(a => 'heroicons_solid:' + a),
            ]);
        this.options$ = this.search$.pipe(
            takeUntil(this.onDestroy$),
            startWith(''),
            filter(v => v !== null),
            debounceTime(200),
            distinctUntilChanged(),
            switchMap((term: any) => {
                if ((!term || term === '')) {
                    return of(this.iconList);
                }
                return of(this.iconList.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1));
            }),
        );
        this.options$.subscribe();
    }

    selectIcon(option: string): void {
        this.formControl.patchValue(option);
    }


    ngOnDestroy(): void {
        this.onDestroy$.complete();
    }


}


