<div
    class="flex items-center self-end justify-end origin-right transform scale-75 w-50"
>
    <button
        mat-raised-button
        color="primary"
        [disabled]="to['disableAdd']"
        (click)="addRow()"
        class="w-full"
    >
        <mat-icon class="mr-2 icon-size-5" [svgIcon]="'add'"></mat-icon>
        {{ to["addLabel"] }}
    </button>
</div>

<div cdkDropList (cdkDropListDropped)="drop($event)">
    <div
        skeleton
        *ngFor="let field of field.fieldGroup; let i = index; let first = first"
        cdkDrag
        class="flex flex-col justify-center field-group"
        [class.first-row]="first"
    >
        <div *cdkDragPreview></div>
        <div class="relative flex flex-row items-center">
            <button cdkDragHandle mat-icon-button class="-mt-4">
                <mat-icon svgIcon="drag_indicator"></mat-icon>
            </button>
            <div
                class="absolute flex justify-center w-12 text-xs font-bold origin-center transform -rotate-90 translate-x-3 -translate-y-2"
            >
                {{ to["indexLabel"] }}
                {{field.fieldGroup?.[0]?.formControl?.value}}
            </div>
            <formly-field class="flex-grow" [field]="field"></formly-field>

            <button mat-icon-button class="flex-grow-0" (click)="remove(i)">
                <mat-icon color="warn" [svgIcon]="'delete'"></mat-icon>
            </button>
        </div>
    </div>
</div>
