import {
    Component,
    AfterContentInit,
    ViewEncapsulation,
    ContentChildren,
    QueryList,
    Input,
    ChangeDetectorRef,
} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { trigger, transition, useAnimation } from '@angular/animations';
import {
    moveFromLeft,
    moveFromRight,
    moveFromTop,
    moveFromBottom,
} from './router-animations';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';

@Component({
    selector: 'app-router-animation',
    templateUrl: './router-animation.component.html',
    styleUrls: ['./router-animation.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger('bottom', [transition('* => *', useAnimation(moveFromBottom))]),
        trigger('top', [transition('* => *', useAnimation(moveFromTop))]),
        trigger('right', [transition('* => *', useAnimation(moveFromRight))]),
        trigger('left', [transition('* => *', useAnimation(moveFromLeft))]),
    ],
})
export class RouterAnimationComponent implements AfterContentInit {
    @Input() vertical: boolean = false;
    @ContentChildren(RouterOutlet, { descendants: false })
    outlets: QueryList<RouterOutlet> | undefined;
    previousIndex: number = 0;
    previousAnimation: any;
    animating: boolean = false;
    loading: boolean = false;
    animationDirection: any = {
        top: false,
        bottom: false,
        left: false,
        right: false,
    };

    constructor(private cd: ChangeDetectorRef) { }

    ngAfterContentInit(): void {
        this.outlets?.first.activateEvents.subscribe(() => {
            this.setAnimationDirection();
        });
        this.setAnimationDirection();
    }

    setAnimationDirection(): void {
        const outlet = this.outlets?.first;
        if (outlet?.isActivated) {
            if (
                outlet &&
                outlet.activatedRoute &&
                outlet.activatedRoute.snapshot
            ) {
                const snapshot = outlet?.activatedRoute?.snapshot;
                const animation = snapshot?.data['animation'];
                if (outlet && outlet.isActivated) {
                    let direction: string;
                    const animationIndex = this.getAnimationIndex();
                    if (this.vertical) {
                        direction =
                            animationIndex >= this.previousIndex
                                ? 'bottom'
                                : 'top';
                    } else {
                        direction =
                            animationIndex >= this.previousIndex
                                ? 'right'
                                : 'left';
                    }
                    this.animationDirection[direction] = true;
                    this.animating = true;
                    this.loading = true;
                    this.previousIndex = animationIndex;
                    this.previousAnimation = animation;
                }
            }
        }
    }

    getAnimationIndex(): number {
        // work out animation index from the position of the route object in the routes array
        const outlet = this.outlets?.first;
        const parentRoute = outlet?.activatedRoute.parent;
        const snapshot = parentRoute?.snapshot;
        const siblingRoutes = snapshot?.routeConfig?.children;
        if (siblingRoutes) {
            const validSiblingRoutes = siblingRoutes.filter(
                route => !route.redirectTo && route.path
            );

            return validSiblingRoutes.findIndex((route) => {
                const currentPath =
                    outlet?.activatedRoute?.snapshot?.routeConfig?.path;
                return currentPath === route.path;
            });
        }
        return 0;
    }

    animationEnd(): void {
        this.animationDirection = {
            top: false,
            bottom: false,
            left: false,
            right: false,
        };
        this.animating = false;
        const subscription = of(true)
            .pipe(delay(100))
            .subscribe(() => {
                this.loading = false;
                this.cd.detectChanges();
                subscription.unsubscribe();
            });
    }
}
