import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input,
    Inject,
} from '@angular/core';
import { RowNode } from '@ag-grid-community/core';
import { DetailTemplateData } from '../../interfaces/detail-template-data';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { siglaLog } from 'app/shared/utility/logging-util';

@Component({
    selector: 'app-ag-grid-detail',
    templateUrl: './ag-grid-detail.component.html',
    styleUrls: ['./ag-grid-detail.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgGridDetailComponent implements OnInit {
    @Input() row: DetailTemplateData | undefined;

    constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public sheetData?: any) {}

    ngOnInit(): void {
        siglaLog(this.sheetData);
        if(this.sheetData){
            this.row = this.sheetData;
        }
    }

    close(): void {
        if (this.row) {
            if (this.row.newItem) {
                this.row.bottomSheet?.dismiss();
            } else {
                const row = this.row.params;
                if (row) {
                    const index = row?.node.rowIndex
                        ? row?.node.rowIndex - 1
                        : null;
                    row.api.forEachNode((node: RowNode, i: number) => {
                        if (index === i) {
                            node.setExpanded(false);
                        }
                    });
                }
            }
        }
    }

    cancel(): void {
        this.close();
    }
}
