<ng-select
    #ngSelect
    [items]="to.options | formlySelectOptions: field | async"
    [formControl]="formControl"
    [class.is-invalid]="showError"
    [clearable]="false"
    [multiple]="true"
    [bindLabel]="'label'"
    [bindValue]="'value'"
    [closeOnSelect]="false"
    [hideSelected]="true"

>
    <ng-template ng-label-tmp let-item="item">
        <div class="flex flex-row items-center justify-center gap-1 text-md">
            <span
                (click)="clearItem(item?.value)"
                aria-hidden="true"
                class="ng-value-icon left"
                >×</span
            >
            <span class="ng-value-label">{{ item.label }} </span>
            <mat-icon
                *ngIf="item.tooltip"
                class="text-current icon-size-4"
                svgIcon="info"
                [matTooltip]="item.tooltip"
            ></mat-icon>
        </div>
    </ng-template>

    <ng-template ng-option-tmp let-item="item">
        <div
            class="flex flex-row items-center justify-center gap-1 text-md"
            title="{{ item.label }}"
        >
            {{ item.label }}
            <mat-icon
                *ngIf="item.tooltip"
                class="icon-size-4"
                svgIcon="info"
                [matTooltip]="item.tooltip"
            ></mat-icon>
        </div>
    </ng-template>
</ng-select>
