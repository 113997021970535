import { Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';

@Component({
  selector: 'app-ag-grid-calendar-weekday-picker',
  templateUrl: './ag-grid-calendar-weekday-picker.component.html',
  styleUrls: ['./ag-grid-calendar-weekday-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AgGridCalendarWeekdayPickerComponent implements ICellRendererAngularComp {
  weekDays: string[] = new Array();
  private params: any;
  agInit(params: any): void {
    this.params = params;
    if (params.value) {
      this.weekDays = this.params.value;
    }
  }

  // demonstrates how you can do "inline" editing of a cell
  onChange(checked: boolean, day: string): void {
    const index = this.weekDays.findIndex(d => d === day);
    if (index > -1) {
      this.weekDays.splice(index, 1);
    } else {
      this.weekDays.push(day);
    }
    this.params.node.setDataValue(this.params.colDef, this.weekDays);

    if (this.params.eGridCell) {
      this.params.eGridCell.focus();
    }
  }

  refresh(params: any): boolean {
    return false;
  }
}


