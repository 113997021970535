import { Directive, HostBinding, Attribute } from '@angular/core';

@Directive({
    selector: 'input',
})
export class AutocompleteOffDirective {

    @HostBinding('attr.autocomplete') auto;
    constructor(@Attribute('autocomplete') autocomplete: string) {
        this.auto = autocomplete || 'off';
    }

}

