<ng-container *ngIf="block?.data">
    <ng-container *ngFor="let image of block?.data.images">
        <app-image
            (removeImage)="remove(block)"
            (ngModelChange)="ngModelChange.emit(block)"
            [data]="image"
            [imageOptions]="imageOptions"
        ></app-image>
    </ng-container>
</ng-container>
<div class="flex items-center justify-center w-full gap-2 border border-dashed add-image">
    <a mat-mini-fab (click)="add()" >
        <mat-icon svgIcon="add"></mat-icon>
    </a>
     <span class="text-sm font-bold">Add Gallery Image</span>
</div>

