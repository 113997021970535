<span class="font-bold underline">
    {{ params?.data?.name }}
</span>

<a
    *ngIf="params?.data?.name"
    mat-icon-button
    class="flex items-center justify-center"
    matTooltip="Open in new Tab"
    (click)="openInNewTab($event)"
>
    <mat-icon class="cursor-pointer icon-size-4" svgIcon="open_in_new">
    </mat-icon>
</a>
