import { Component, ChangeDetectionStrategy, ViewEncapsulation, OnInit, ViewChild, AfterViewInit, ElementRef, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';
import { Observable, of, Subscription } from 'rxjs';
import { startWith } from 'rxjs/internal/operators/startWith';
import { delay, map, take } from 'rxjs/operators';

@Component({
    selector: 'app-formly-nested-autocomplete',
    templateUrl: './formly-nested-autocomplete.component.html',
    styleUrls: ['./formly-nested-autocomplete.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class FormlyNestedAutocompleteComponent extends FieldType implements OnInit, OnDestroy {
    optionLabelKey: string = '';
    optionValueKey: string = '';
    optionListKey: string = '';
    label: string = '';
    controlValid = false;
    override formControl: any;
    filteredOptions$!: Observable<any[]>;
    formControlSubscription: undefined | Subscription;
    private _options: any[] = [];

    constructor(public cdRef: ChangeDetectorRef) {
        super();
    }

    ngOnInit(): void {
        if (this._keyConfigExists(this.to['optionLabelKey']) &&
            this._keyConfigExists(this.to['optionValueKey']) &&
            this._keyConfigExists(this.to['optionListKey'])) {
            this.optionLabelKey = this.to['optionLabelKey'];
            this.optionValueKey = this.to['optionValueKey'];
            this.optionListKey = this.to['optionListKey'];
            this.controlValid = true;
            const options: Observable<any[]> = this.to.options as Observable<any[]>;
            options.subscribe((_options: any[]) => {
                this._options = _options;
                this.findLabel(this._options, this.formControl.value);
            });
            this.formControlSubscription = this.formControl.valueChanges.subscribe((optionValue: string) => {
                this.findLabel(this._options, optionValue);
                if (!optionValue) {
                    this.label = '';
                    this.cdRef.detectChanges();
                }
            });
        } else {
            console.error('Configuration Error - Please provide a valid, optionLabelKey, optionValueKey & optionListKey ');
        }
    }

    ngOnDestroy(): void {
        if (this.formControlSubscription) {
            this.formControlSubscription.unsubscribe();
        }
    }

    autocompleteFocus(): void {
        if (this._options) {
            this.filteredOptions$ = of(this._options).pipe(delay(50));
        }
    }
    autocompleteKeydown(currentValue: string): void {
        this.filteredOptions$ = of(currentValue).pipe(
            take(1),
            startWith(''),
            map((value: any) => (typeof value === 'string' ? value : value.name)),
            map((name: string) => (name ? this._filter(this._options, name) : this._options)),
        );
    }

    selectOption(option: any): void {
        const formControl: FormControl = this.formControl;
        formControl.setValue(option[this.optionValueKey]);
    }

    private findLabel(input: undefined | any[], uID: string, label?: string): void {
        if (input?.length && uID?.length) {
            input?.forEach((option) => {
                this.findLabel(option[this.optionListKey], uID, (label ? label + ' > ' + option[this.optionLabelKey] : option[this.optionLabelKey]));
                if (option[this.optionValueKey] === uID) {
                    this.label = label ? label + ' > ' + option[this.optionLabelKey] : option[this.optionLabelKey];
                    this.cdRef.detectChanges();
                }
            });
        }
    }

    private _filter(input: any[], filterValue: string): any[] {
        if (input?.length && filterValue !== '') {
            const options = JSON.parse(JSON.stringify(input));
            if (options?.length) {
                const results: any[] = [];
                options.forEach((option: any) => {
                    const children = this._filter(option[this.optionListKey], filterValue);
                    if (option[this.optionLabelKey].toLowerCase().includes(filterValue.toLowerCase())) { results.push(option); };
                    if (children.length > 0) {
                        option[this.optionListKey] = children;
                        results.push(option);
                    };
                });
                return results;
            }
        }

        return [];
    }

    private _keyConfigExists(key: string): boolean {
        return key !== undefined && key !== '';
    }

}
