<div class="relative flex flex-col w-full h-full">
    <!-- Dismiss button -->
    <ng-container *ngIf="dialogConfig?.dismissible">
        <div class="absolute top-0 right-0 pt-4 pr-4">
            <button mat-icon-button [matDialogClose]="undefined">
                <mat-icon
                    class="text-secondary"
                    [svgIcon]="'heroicons_outline:x'"
                ></mat-icon>
            </button>
        </div>
    </ng-container>

    <!-- Content -->
    <div
        class="flex flex-col items-center pb-6 sm:flex-row sm:items-start sm:pb-8"
    >
        <!-- Icon -->
        <ng-container *ngIf="dialogConfig?.icon?.show">
            <div
                class="flex items-center justify-center w-10 h-10 rounded-full flex-0 sm:mr-4"
                [ngClass]="{
                    'text-primary-600 bg-primary-100 dark:text-primary-50 dark:bg-primary-600':
                        dialogConfig?.icon?.color === 'primary',
                    'text-accent-600 bg-accent-100 dark:text-accent-50 dark:bg-accent-600':
                        dialogConfig?.icon?.color === 'accent',
                    'text-warn-600 bg-warn-100 dark:text-warn-50 dark:bg-warn-600':
                        dialogConfig?.icon?.color === 'warn',
                    'text-gray-600 bg-gray-100 dark:text-gray-50 dark:bg-gray-600':
                        dialogConfig?.icon?.color === 'basic',
                    'text-blue-600 bg-blue-100 dark:text-blue-50 dark:bg-blue-600':
                        dialogConfig?.icon?.color === 'info',
                    'text-green-500 bg-green-100 dark:text-green-50 dark:bg-green-500':
                        dialogConfig?.icon?.color === 'success',
                    'text-amber-500 bg-amber-100 dark:text-amber-50 dark:bg-amber-500':
                        dialogConfig?.icon?.color === 'warning',
                    'text-red-600 bg-red-100 dark:text-red-50 dark:bg-red-600':
                        dialogConfig?.icon?.color === 'error'
                }"
            >
                    <mat-icon
                        class="text-current"
                        [svgIcon]="dialogConfig?.icon?.name || ''"
                    ></mat-icon>
            </div>
        </ng-container>

        <ng-container *ngIf="dialogConfig?.title || dialogConfig?.message">
            <div
                class="flex flex-col items-center mt-4 space-y-1 text-center sm:items-start sm:mt-0 sm:pr-8 sm:text-left"
            >
                <!-- Title -->
                <ng-container *ngIf="dialogConfig?.title">
                    <h3
                        class="text-2xl font-extrabold leading-7 tracking-tight truncate md:text-3xl sm:leading-1"
                        [innerHTML]="dialogConfig?.title"
                    ></h3>
                </ng-container>

                <!-- Message -->
                <ng-container *ngIf="dialogConfig?.message">
                    <div
                        class="text-secondary"
                        [innerHTML]="dialogConfig?.message"
                    ></div>
                </ng-container>
            </div>
        </ng-container>
    </div>
    <div class="flex flex-col gap-4 pb-6">
        <ng-content></ng-content>
    </div>

    <!-- Actions -->
    <ng-container
        *ngIf="dialogConfig?.actions?.confirm?.show || dialogConfig?.actions?.cancel?.show"
    >
        <div
            class="flex items-center justify-center px-2 py-4 space-x-3 sm:justify-end bg-gray-50 dark:bg-black dark:bg-opacity-10"
        >
            <!-- Cancel -->
            <ng-container *ngIf="dialogConfig?.actions?.cancel?.show">
                <button mat-stroked-button [matDialogClose]="{status:'canceled', data:data}">
                    {{ dialogConfig?.actions?.cancel?.label }}
                </button>
            </ng-container>

            <!-- Confirm -->
            <ng-container *ngIf="dialogConfig?.actions?.confirm?.show">
                <button
                    mat-flat-button
                    [color]="dialogConfig?.actions?.confirm?.color"
                    [matDialogClose]="{status:'confirmed', data:data}"
                >
                    {{ dialogConfig?.actions?.confirm?.label }}
                </button>
            </ng-container>
        </div>
    </ng-container>
</div>
