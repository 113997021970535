import { Component, OnInit, Input, OnDestroy, TemplateRef, ViewEncapsulation, ViewChild, AfterViewInit, AfterContentInit, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { PageHistoryService } from 'app/shared/services/page-history/page-history.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';
import { WizardState } from '../../interfaces/wizard-state';
import { TabbedPageLayoutComponent } from '../tabbed-page-layout/tabbed-page-layout.component';

@Component({
    selector: 'app-wizard-page-layout',
    templateUrl: './wizard-page-layout.component.html',
    styleUrls: ['./wizard-page-layout.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class WizardPageLayoutComponent extends TabbedPageLayoutComponent
    implements OnInit, AfterViewInit, OnDestroy {
    @Input() wizardState$: undefined | BehaviorSubject<WizardState>;
    @Output() stepAction: EventEmitter<number> = new EventEmitter();
    @ViewChild('panel') panel: MatDrawer | undefined;
    @Input() sidebarTemplate!: TemplateRef<any>;
    @Input() panelTemplate!: TemplateRef<any>;
    leftDrawerMode: 'over' | 'side' = 'side';
    leftDrawerOpened: boolean = true;
    rightDrawerMode: 'over' | 'side' = 'side';
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    constructor(
        override pageHistory: PageHistoryService,
        public override activatedRoute: ActivatedRoute,
        override router: Router,
        private fuseMediaWatcherService: FuseMediaWatcherService,
        override cdRef: ChangeDetectorRef
    ) {
        super(pageHistory, activatedRoute, router, cdRef);
        this.getChildRoutes();
    }



    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    override ngOnInit(): void {
        // Subscribe to media changes
        this.fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {
                // Set the leftDrawerMode and leftDrawerOpened
                if (matchingAliases.includes('lg')) {
                    this.leftDrawerMode = 'side';
                    this.leftDrawerOpened = true;
                    this.rightDrawerMode = 'over';
                } else {
                    this.leftDrawerMode = 'over';
                    this.leftDrawerOpened = false;
                    this.rightDrawerMode = 'over';
                }
            });
    }

    /**
     * On destroy
     */
    override ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }




}
