import { keyframes, style, AnimationKeyframesSequenceMetadata } from '@angular/animations';
export const moveFromRightKeyframes: AnimationKeyframesSequenceMetadata =
   keyframes([
      style({ 'transform': 'translateX(100vw)', 'offset': 0, 'z-index': '10' }),
      style({ transform: 'translateX(0%)', offset: 1 })
   ]);

export const moveToLeftKeyframes: AnimationKeyframesSequenceMetadata =
   keyframes([
      style({ transform: 'translateX(0%)', offset: 0 }),
      style({ transform: 'translateX(-100vw)', offset: 1 })
   ]);


export const moveFromLeftKeyframes: AnimationKeyframesSequenceMetadata =
   keyframes([
      style({ 'transform': 'translateX(-100vw)', 'offset': 0, 'z-index': '10' }),
      style({ transform: 'translateX(0%)', offset: 1 })
   ]);

export const moveToRightKeyframes: AnimationKeyframesSequenceMetadata =
   keyframes([
      style({ transform: 'translateX(0%)', offset: 0 }),
      style({ transform: 'translateX(100vw)', offset: 1 })
   ]);

export const moveFromTopKeyframes: AnimationKeyframesSequenceMetadata =
   keyframes([
      style({ 'transform': 'translateY(-100vh) scale(.9)', 'offset': 0, 'z-index': '10' }),
      style({ transform: 'translateY(0%) scale(1)', offset: 1 })
   ]);

export const moveToBottomKeyframes: AnimationKeyframesSequenceMetadata =
   keyframes([
      style({ transform: 'translateY(0%) scale(1)', offset: 0 }),
      style({ transform: 'translateY(100vh) scale(.9)', offset: 1 })
   ]);

export const moveFromBottomKeyframes: AnimationKeyframesSequenceMetadata =
   keyframes([
      style({ 'transform': 'translateY(100vh) scale(.9)', 'offset': 0, 'z-index': '10' }),
      style({ transform: 'translateY(0%) scale(1)', offset: 1 })
   ]);

export const moveToTopKeyframes: AnimationKeyframesSequenceMetadata =
   keyframes([
      style({ transform: 'translateY(0%) scale(1)', offset: 0 }),
      style({ transform: 'translateY(-100vh) scale(.9)', offset: 1 })
   ]);

export const scaleDown: AnimationKeyframesSequenceMetadata =
   keyframes([
      style({ opacity: '1', transform: 'scale(1)', offset: 0 }),
      style({ opacity: '0', transform: 'scale(0.8)', offset: 1 })
   ]);

export const fadeOutFrames: AnimationKeyframesSequenceMetadata =
   keyframes([
      style({ opacity: '1', offset: 0 }),
      style({ opacity: '0.3', offset: 1 })
   ]);

export const fadeInFrames: AnimationKeyframesSequenceMetadata =
   keyframes([
      style({ opacity: '0.3', offset: 0 }),
      style({ opacity: '1', offset: 1 })
   ]);

export const overOutFrames: AnimationKeyframesSequenceMetadata =
   keyframes([
      style({ filter: 'brightness(1)', transform: 'scale(1)', marginTop: '0px', offset: 0 }),
      style({ filter: 'brightness(.8)', transform: 'scale(0.95)', marginTop: '-8px', offset: 1 })
   ]);

export const overInFrames: AnimationKeyframesSequenceMetadata =
   keyframes([
      style({ 'filter': 'brightness(.8)', 'transform': 'scale(0.95)', 'marginTop': '-8px', 'z-index': '8', 'offset': 0 }),
      style({ filter: 'brightness(1)', transform: 'scale(1)', marginTop: '0px', offset: 1 })
   ]);
