import { GridApi, ICellRendererParams } from '@ag-grid-community/all-modules';
import { AgRendererComponent } from '@ag-grid-community/angular';
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { siglaLog } from 'app/shared/utility/logging-util';
import { LinkActions } from './ag-grid-link-cell-renderer-types';

@Component({
    selector: 'app-ag-grid-link-cell-renderer',
    templateUrl: './ag-grid-link-cell-renderer.component.html',
    styleUrls: ['./ag-grid-link-cell-renderer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgGridLinkCellRendererComponent implements AgRendererComponent {
    public params: (Record<string, any> & ICellRendererParams) | undefined;
    public agGridApi: GridApi | undefined;
    public editing: boolean = false;
    public linkActions: undefined | LinkActions;

    constructor() {}

    refresh(params: ICellRendererParams): boolean {
        return false;
    }
    agInit(params: Record<string, any> & ICellRendererParams): void {
        this.params = params;
        this.linkActions = this.params['linkActions'];
        this.agGridApi = this.params?.api;
    }

    openLink(): void {
        siglaLog(this.params, this.linkActions?.onOpenLink);
        if (this.params) {
            if (this.linkActions?.onOpenLink !== undefined) {
                this.linkActions?.onOpenLink(this.params.node.data);
            }
        }
    }

    openLinkInNewTab(): void {
        if (this.params) {
            let uIdKey;
            if (this.params['uIdField']) {
                uIdKey = Object.keys(this.params.data).find(
                    (key) => key === this.params?.['uIdField']
                );
            } else {
                uIdKey = Object.keys(this.params.data).find((key) =>
                    key.includes('UId')
                );
            }

            const staticLink = this.params['staticLink'];
            if (uIdKey && staticLink) {
                const uId = this.params.data[uIdKey];
                const url =
                    window.location.origin + staticLink.replace('[[uId]]', uId);
                if (!url.includes('[[uId]]')) {
                    window.open(url, '_blank');
                }
            }
            if (this.linkActions?.onOpenLinkInNewTab !== undefined) {
                this.linkActions?.onOpenLinkInNewTab(this.params.node.data);
            }
        }
    }
}
