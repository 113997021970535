import {
    Component,
    ViewEncapsulation,
    Input,
    HostBinding,
    ContentChild,
    AfterViewInit,
    OnDestroy,
    ElementRef,
    Renderer2
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { FormlyForm } from '@ngx-formly/core';
import { environment } from 'environments/environment';
import { fromEvent, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
    selector: 'app-formly-form-wrapper',
    templateUrl: './formly-form-wrapper.component.html',
    styleUrls: ['./formly-form-wrapper.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class FormlyFormWrapperComponent implements AfterViewInit, OnDestroy {
    @HostBinding('class.loading') @Input() loading = false;
    @ContentChild(FormlyForm) formlyForm: undefined | FormlyForm;
    debug = environment.logging;
    debugFormValueJSON = '';
    debugExpanded = false;
    private readonly _destroying$ = new Subject<void>();
    constructor(private elRef: ElementRef, private renderer: Renderer2) {

    };

    ngAfterViewInit(): void {
        const form = this.elRef.nativeElement.parentElement;
        this.renderer.setAttribute(form, 'autocomplete', 'chrome-off');
        const inputElements = form.querySelectorAll('input');
        inputElements?.forEach((inputElement: HTMLElement) => {
            this.renderer.setAttribute(inputElement, 'autocomplete', 'off');
            this.renderer.setAttribute(inputElement, 'Role', 'Presentation');
        });
        if (this.debug) {
            this.debugFormValueJSON = JSON.stringify(this.formlyForm?.form.value, undefined, 4);
            this.formlyForm?.form.valueChanges.pipe(takeUntil(this._destroying$)).subscribe((value) => {
                this.debugFormValueJSON = JSON.stringify(value, undefined, 4);
            });
        }

        // fromEvent(this.elRef.nativeElement, 'keypress').pipe(takeUntil(this._destroying$)).subscribe(() => {
        //     const formGroup = this.formlyForm?.form;
        //     if (formGroup) {
        //         Object.keys(formGroup.controls).forEach((key) => {
        //             const valueIsString = typeof formGroup?.get(key)?.value === 'string';
        //             if (valueIsString) {
        //                 formGroup?.get(key)?.setValue(formGroup?.get(key)?.value.trim());
        //             }
        //         });
        //     }
        // });
    }

    ngOnDestroy(): void {
        this._destroying$.next(undefined);
        this._destroying$.complete();
    }
}
