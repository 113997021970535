import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ContentBlock } from '../../types/editor';

@Component({
    selector: 'app-text',
    templateUrl: './text.component.html',
    styleUrls: ['./text.component.scss']
})
export class TextComponent implements OnInit {
    @Input() data: any;
    @Input() block: ContentBlock | undefined;
    @Input() editable: boolean = true;
    @Output() ngModelChange = new EventEmitter();
    config: AngularEditorConfig = {
        editable: true,
        spellcheck: true,
        minHeight: '.5rem',
        placeholder: 'Enter text here...',
        translate: 'no',
        defaultParagraphSeparator: 'p',
        enableToolbar: false,
        toolbarHiddenButtons: [
            [
                'strikeThrough',
                'subscript',
                'superscript',
                'justifyLeft',
                'justifyCenter',
                'justifyRight',
                'justifyFull',
                'indent',
                'outdent',
                'insertUnorderedList',
                'insertOrderedList',
                'fontName'
            ],
            [
                'fontSize',
                'textColor',
                'backgroundColor',
                'customClasses',

                'insertImage',
                'insertVideo',
                'insertHorizontalRule',
                'removeFormat',
                'toggleEditorMode'
            ]
        ]
    };
    constructor() {

    }

    ngOnInit(): void {
        if (this.block && this.block?.type !== 'paragraph') {
            this.block.type = 'paragraph';
            this.ngModelChange.emit();
        }
    }

}

