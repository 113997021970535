import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil, startWith, debounceTime, distinctUntilChanged, switchMap, filter } from 'rxjs/operators';

@Component({
  selector: 'app-formly-typeahead',
  templateUrl: './formly-typeahead.component.html',
  styleUrls: ['./formly-typeahead.component.scss']
})
export class FormlyTypeaheadComponent extends FieldType implements OnDestroy, OnInit {
  override formControl: any;
  onDestroy$ = new Subject<void>();
  search$ = new EventEmitter();
  options$!: Observable<any>;

  ngOnInit(): void {
    console.log(this.to['search$']);
    this.options$ = this.search$.pipe(
      takeUntil(this.onDestroy$),
      startWith(''),
      filter(v => v !== null),
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(this.to['search$']),
    );

    this.options$.subscribe();
  }

  ngOnDestroy(): void {
    this.onDestroy$.complete();
  }
}
