import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { ApiPathConfig } from 'app/shared/config/api-path.config';
import { refDataEndpoints } from 'app/shared/config/endpoints/refdata-endpoints';
import {
    Agreement,
    Business,
    Relationship,
} from 'app/shared/models/commercial/commercial-models';
import { CommonBundle } from 'app/shared/models/ref-data/bundle-models';
import {
    AgreementType,
    Consultant,
    Country,
    Currency,
    ExperienceCategory,
    List,
    ModelType,
    Status,
    Option,
    User,
    PriceSheetFilterData,
    BookingLifecycleStatus,
} from 'app/shared/models/ref-data/ref-data-models';
import { BehaviorSubject, combineLatest, forkJoin, merge, Observable, of, Subject } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { PathUtil } from '../../utility/path-util';
import { adminEndpoints } from 'app/shared/config/endpoints/admin-endpoints';
import {
    ProductSeriesRefData,
    ProductTypeRefData,
    RateRefData,
} from 'app/shared/models/ref-data/product-models';
import { BusinessRefData, TravellerRefData } from 'app/shared/models/ref-data/sales-models';
import { filter } from 'lodash';
import { siglaLog } from 'app/shared/utility/logging-util';
import { ModelName } from 'app/shared/models/model-name/model-name';
import { ListName } from './list-name';
import { SettingsService } from '../platform/settings/settings.service';
import { Settings } from 'app/shared/models/platform/platform-models';
import { PersonRefData } from 'app/shared/models/ref-data/marketing-models';
import { PriceSheetSearchCriteria } from 'app/modules/price-sheet/demo-ui-models';



@Injectable({
    providedIn: 'root',
})
export class RefDataService implements OnDestroy {
    private readonly _agreementTypeList$ = new BehaviorSubject<AgreementType[]>(
        []
    );
    private readonly _currencyList$ = new BehaviorSubject<Currency[]>([]);
    private readonly _countryList$ = new BehaviorSubject<Country[]>([]);
    private readonly _experienceCategoryList$ = new BehaviorSubject<
        ExperienceCategory[]
    >([]);
    private readonly _listList$ = new BehaviorSubject<List[]>([]);
    private readonly _statusList$ = new BehaviorSubject<Status[]>([]);
    private readonly _typeList$ = new BehaviorSubject<ModelType[]>([]);
    private readonly _userList$ = new BehaviorSubject<User[]>([]);
    private readonly _officeList$ = new BehaviorSubject<Business[]>(new Array());
    private readonly _bookingLifeCycleStatusList$ = new BehaviorSubject<BookingLifecycleStatus[]>(new Array());
    private readonly _userOfficeList$ = new BehaviorSubject<Business[]>(new Array());
    private readonly _destroying$ = new Subject<void>();

    constructor(
        private settingsService: SettingsService,
        private httpClient: HttpClient,
        @Inject('SiglaApiPath') private siglaApiPath: ApiPathConfig
    ) { }

    ngOnDestroy(): void {
        this._destroying$.next(undefined);
        this._destroying$.complete();
    }

    /**
     *Accessors
     */

    get agreementTypeList$(): Observable<AgreementType[]> {
        return this._agreementTypeList$.asObservable();
    }

    get currencyList$(): Observable<Currency[]> {
        return this._currencyList$.asObservable();
    }

    get countryList$(): Observable<Country[]> {
        return this._countryList$.asObservable();
    }

    get experienceCategoryList$(): Observable<ExperienceCategory[]> {
        return this._experienceCategoryList$.asObservable();
    }

    get listList$(): Observable<List[]> {
        return this._listList$.asObservable();
    }

    get statusList$(): Observable<Status[]> {
        return this._statusList$.asObservable();
    }

    get typeList$(): Observable<ModelType[]> {
        return this._typeList$.asObservable();
    }

    get userList$(): Observable<User[]> {
        return this._userList$.asObservable();
    }

    get officeList$(): Observable<Business[]> {
        return this._officeList$.asObservable();
    }

    get userOfficeList$(): Observable<Business[]> {
        return this._userOfficeList$.asObservable();
    }

    get bookingLifeCycleStatusList$(): Observable<BookingLifecycleStatus[]> {
        return this._bookingLifeCycleStatusList$.asObservable();
    }

    /**
     * Data load methods
     */

    loadData(): void {
        let url = PathUtil.combinePaths(
            this.siglaApiPath.apiPath,
            refDataEndpoints.bundle.commonBundle
        );

        this.httpClient
            .get<CommonBundle>(url)
            .subscribe((bundle: CommonBundle) => {
                this._listList$.next(bundle.listList); // Can't sort until list pulled
                this._statusList$.next(bundle.statusList); // Can't sort until list pulled
                this._typeList$.next(bundle.typeList); // Can't sort until list pulled
                this._agreementTypeList$.next(
                    bundle.agreementTypeList.sort((a, b) => {
                        if (a.seqNo < b.seqNo) { return -1; }
                        if (a.seqNo > b.seqNo) { return 1; }
                        return 0;
                    })
                );
                this._currencyList$.next(
                    bundle.currencyList?.sort((a, b) => {
                        if (a.name < b.name) { return -1; }
                        if (a.name > b.name) { return 1; }
                        return 0;
                    })
                );
                this._experienceCategoryList$.next(
                    bundle.experienceCategoryList?.sort((a, b) => {
                        if (a.name < b.name) { return -1; }
                        if (a.name > b.name) { return 1; }
                        return 0;
                    })
                );
                this._countryList$.next(
                    bundle.countryList?.sort((a, b) => {
                        if (a.name < b.name) { return -1; }
                        if (a.name > b.name) { return 1; }
                        return 0;
                    })
                );
                this._bookingLifeCycleStatusList$.next(
                    bundle.bookingLifecycleStatusList.sort((a, b) => {
                        if (a.seqNo < b.seqNo) { return -1; }
                        if (a.seqNo > b.seqNo) { return 1; }
                        return 0;
                    })
                );
            });

        url = PathUtil.combinePaths(
            this.siglaApiPath.apiPath,
            refDataEndpoints.refData.experienceCategoryList
        );

        this.httpClient
            .get<ExperienceCategory[]>(url)
            .subscribe((list: ExperienceCategory[]) => {
                if (list && list.length) {
                    this._experienceCategoryList$.next(list);
                }
            });

        url = PathUtil.combinePaths(
            this.siglaApiPath.apiPath,
            refDataEndpoints.direct.userList
        );
        this.httpClient.get<User[]>(url).subscribe((userList: User[]) => {
            this._userList$.next(userList);
        });
    }

    /**
     * Cached data methods
     */

    getTypeForModel(modelName: ModelName): Observable<ModelType[]> {
        return this.typeList$.pipe(
            map((typeList: ModelType[]) =>
                typeList
                    .filter((type: ModelType) => type.modelName === modelName)
                    .sort((a, b) => a.seqNo - b.seqNo)
            )
        );
    }

    getStatusForModel(modelName: ModelName): Observable<Status[]> {
        return this.statusList$.pipe(
            map((statusList: Status[]) =>
                statusList
                    .filter((status: Status) => status.statusType === modelName)
                    .sort((a, b) => a.seqNo - b.seqNo)
            )
        );
    }

    getListForListName(listName: ListName): Observable<List[]> {
        return this.listList$.pipe(
            map((listList: List[]) =>
                listList
                    .filter((list: List) => list.listType === listName)
                    .sort((a, b) => a.seqNo - b.seqNo)
            )
        );
    }

    // Service area is a dedicated observable as it must combine information from the platform settings service into the results
    getServiceAreaList(): Observable<List[]> {

        const serviceAreaList$ = this.getListForListName('ServiceArea');
        const settings$ = this.settingsService.settings$;

        return combineLatest([serviceAreaList$, settings$])
            .pipe(
                map((output) => {
                    output[0] = output[0].map((listItem: List) => ({
                        listType: listItem.listType,
                        name: `${listItem.name} ${output[1] ? output[1].generalSettings.distanceUnit.shortDesc : ''}`,
                        labelExt: output[1] ? output[1].generalSettings.distanceUnit.shortDesc : '',
                        seqNo: listItem.seqNo,
                        notes: listItem.notes,
                        default: listItem.default,
                        value: listItem.name,
                    })
                    );
                    output[0].unshift({ listType: '', name: 'Not Applicable', labelExt: '', seqNo: 0, notes: '', default: true, value: 'null' });
                    return output[0];
                }
                )
            );

    }


    /**
     * Inline Request methods
     */

    getProductSeriesList(businessUId: string): Observable<ProductSeriesRefData[]> {
        let url = PathUtil.combinePaths(
            this.siglaApiPath.apiPath,
            adminEndpoints.refData.productTypeList
        );
        url = PathUtil.addParam(url, 'businessUId=' + businessUId);
        return this.httpClient.get<ProductSeriesRefData[]>(url);
    }

    getProductTypeList(withChildItem?: boolean): Observable<ProductTypeRefData[]> {
        const url = PathUtil.combinePaths(
            this.siglaApiPath.apiPath,
            withChildItem ? adminEndpoints.refData.productTypeListNested : adminEndpoints.refData.productTypeList
        );

        return this.httpClient.get<ProductTypeRefData[]>(url).pipe(
            map((list: ProductTypeRefData[]) =>
                list.sort((a, b) => {
                    if (a.name > b.name) { return 1; }
                    if (a.name < b.name) { return -1; }
                    return 0;
                })
            )
        );

    }

    getTravellerList(bookingUId: string): Observable<TravellerRefData[]> {
        let url = PathUtil.combinePaths(
            this.siglaApiPath.apiPath,
            adminEndpoints.refData.travellerList
        );
        url = PathUtil.addParam(url, 'bookingUId=' + bookingUId);

        return this.httpClient.get<TravellerRefData[]>(url);
    }

    getRelationshipList(term?: string): Observable<Relationship[]> {
        let url = PathUtil.combinePaths(
            this.siglaApiPath.apiPath,
            refDataEndpoints.bundle.relationshipList
        );
        if (term) {
            url = PathUtil.addParam(url, 'name=' + term);
        }
        return this.httpClient.get<Relationship[]>(url);
    }

    getAgreementList(relationsUId?: string): Observable<Agreement[]> {
        let url = PathUtil.combinePaths(
            this.siglaApiPath.apiPath,
            refDataEndpoints.bundle.agreementList
        );
        if (relationsUId) {
            url = PathUtil.addParam(url, 'relationshipUId=' + relationsUId);
        }
        return this.httpClient.get<Agreement[]>(url);
    }

    getAgreementListByProduct(productUId?: string): Observable<Agreement[]> {
        let url = PathUtil.combinePaths(
            this.siglaApiPath.apiPath,
            refDataEndpoints.bundle.agreementList
        );
        if (productUId) {
            url = PathUtil.addParam(url, 'productUId=' + productUId);
        }
        return this.httpClient.get<Agreement[]>(url);
    }

    getRateList(productUId: string): Observable<RateRefData[]> {
        if (productUId) {
            let url = PathUtil.combinePaths(
                this.siglaApiPath.apiPath,
                adminEndpoints.products.rateList
            );
            url = PathUtil.addParam(url, 'productUId=' + productUId);

            return this.httpClient.get<RateRefData[]>(url).pipe(take(1));
        } else {
            return of([]);
        }
    }

    getConsultantList(businessUId: string): Observable<Consultant[] | []> {
        if (businessUId) {
            let url = PathUtil.combinePaths(
                this.siglaApiPath.apiPath,
                refDataEndpoints.consultant.list
            );
            url = PathUtil.addParam(url, 'businessUId=' + businessUId);

            return this.httpClient.get<Consultant[]>(url).pipe(take(1));
        } else {
            return of([]);
        }
    }

    getUserList(businessUId: string): Observable<User[] | []> {
        if (businessUId) {
            let url = PathUtil.combinePaths(
                this.siglaApiPath.apiPath,
                refDataEndpoints.direct.userList
            );
            url = PathUtil.addParam(url, 'businessUId=' + businessUId);

            return this.httpClient.get<User[]>(url).pipe(take(1));
        } else {
            return of([]);
        }
    }

    getSupplierList(): Observable<Business[]> {
        const url = PathUtil.combinePaths(
            this.siglaApiPath.apiPath,
            adminEndpoints.refData.supplierList
        );

        return this.httpClient.get<Business[]>(url);
    }

    getOptionList(): Observable<Option[]> {
        const url = PathUtil.combinePaths(
            this.siglaApiPath.apiPath,
            adminEndpoints.refData.optionList
        );

        return this.httpClient.get<Option[]>(url);
    }

    loadPriceSheetFilterData(priceSheetSearchCriteria: PriceSheetSearchCriteria): Observable<PriceSheetFilterData> {
        let url = PathUtil.combinePaths(
            this.siglaApiPath.apiPath,
            adminEndpoints.refData.priceSheet
        );
        if (priceSheetSearchCriteria.calendarUId) {
            url = PathUtil.addParam(url, 'calendarUId=' + priceSheetSearchCriteria.calendarUId);
        }
        if (priceSheetSearchCriteria.supplierUId) {
            url = PathUtil.addParam(url, 'supplierUId=' + priceSheetSearchCriteria.supplierUId);
        }
        if (priceSheetSearchCriteria.productTypeUId) {
            url = PathUtil.addParam(url, 'productTypeUId=' + priceSheetSearchCriteria.productTypeUId);
        }
        if (priceSheetSearchCriteria.productUId) {
            url = PathUtil.addParam(url, 'productUId=' + priceSheetSearchCriteria.productUId);
        }
        if (priceSheetSearchCriteria.productSeriesUId) {
            url = PathUtil.addParam(url, 'productSeriesUId=' + priceSheetSearchCriteria.productSeriesUId);
        }

        return this.httpClient.get<PriceSheetFilterData>(url);
    }

    loadOfficeList(): void {
        const url = PathUtil.combinePaths(
            this.siglaApiPath.apiPath,
            refDataEndpoints.commercial.business.officeList
        );


        this.httpClient.get<Business[]>(url).subscribe((list: Business[]) => {
            this._officeList$.next(list);
        });
    }

    loadUserOfficeList(): void {
        const url = PathUtil.combinePaths(
            this.siglaApiPath.apiPath,
            refDataEndpoints.userOffice.list
        );


        this.httpClient.get<Business[]>(url).subscribe((list: Business[]) => {
            this._userOfficeList$.next(list);
        });
    }


    // Typeaheads

    typeaheadBusiness(name: string): Observable<BusinessRefData[]> {
        let url = PathUtil.combinePaths(
            this.siglaApiPath.apiPath,
            refDataEndpoints.typeahead.business
        );
        url = PathUtil.addParam(url, 'name=' + name);

        return this.httpClient.get<BusinessRefData[]>(url);
    }

    typeaheadPerson(firstName: string | undefined, lastName: string | undefined): Observable<PersonRefData[]> {
        let url = PathUtil.combinePaths(
            this.siglaApiPath.apiPath,
            refDataEndpoints.typeahead.person
        );
        if (firstName) {
            url = PathUtil.addParam(url, 'firstname=' + firstName);
        }

        if (lastName) {
            url = PathUtil.addParam(url, 'lastname=' + lastName);
        }

        return this.httpClient.get<PersonRefData[]>(url);
    }

}
