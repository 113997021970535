<mat-chip-list
    class="m-0"
    #chipList
    aria-label="Traveller selection"
    multiple
    [formControl]="formControl"
    [class.is-invalid]="showError"
>
    <mat-chip
        #chip="matChip"
        *ngFor="let traveller of travellerList"
        [removable]="traveller.removable"
        (removed)="removeTraveller(traveller)"
        [selectable]="false"
        class="h-8 p-0 pl-0 m-0 overflow-hidden"
    >
        <div class="flex flex-row items-center px-2 py-1">
            <button *ngIf="traveller.editable"
                (click)="editTraveller(traveller)"
                [disabled]="!traveller.travellerNo"
                class="flex items-center h-6"
            >
                <mat-icon
                    style="color: var(--page-theme-color)"
                    class="icon-size-4"
                    svgIcon="edit"
                ></mat-icon>
            </button>
            <span>
                {{
                    !traveller.firstName ?  
                        !traveller.age
                            ? traveller.passengerType
                            : traveller.age +
                            " year" +
                            (traveller.age !== 1 ? "s" : "") +
                            " old"
                        : traveller.firstName +' '+ (traveller.lastName? traveller.lastName : '')
                }}
            </span>
            <button matChipRemove class="flex items-center opacity-100" *ngIf="traveller.removable" >
                <mat-icon class="icon-size-4" color="warn"> close</mat-icon>
            </button>
        </div>
    </mat-chip>
    <div
        *ngIf="!to.readonly"
        class="flex items-center justify-center overflow-hidden border border-gray-500 border-solid rounded-full opacity-80"
        style="height: 30px"
    >
        <input
            class="p-2 m-0 bg-hover max-w-30"
            style="height: 14px"
            *ngIf="filteredTravellers$ | async as filteredTravellers"
            placeholder="Add traveller..."
            #travellerInput
            #trigger="matAutocompleteTrigger"
            (focus)="trigger.openPanel()"
            (mouseenter)="trigger.openPanel()"
            [formControl]="travellerCtrl"
            [matAutocomplete]="auto"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            (matChipInputTokenEnd)="addTraveller(filteredTravellers, undefined)"
        />
    </div>
</mat-chip-list>
<mat-autocomplete
    #auto="matAutocomplete"
    (optionSelected)="addTraveller(undefined, $event)"
>
    <mat-option
        *ngFor="let traveller of filteredTravellers$ | async"
        [value]="traveller"
    >
        {{
            !traveller.age
                ? traveller.passengerType
                : traveller.age +
                  " year" +
                  (traveller.age !== 1 ? "s" : "") +
                  " old"
        }}
    </mat-option>
</mat-autocomplete>
