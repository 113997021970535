import { Component, ChangeDetectionStrategy, ViewEncapsulation, ElementRef, ViewChild, OnInit, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FieldType } from '@ngx-formly/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuxiliaryLink } from 'app/shared/modules/general-utilities/directives/auxiliary-link/auxiliary-link.directive';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { confirmationConfig } from './config/confirmation.config';
import { TravellerDTO, Traveller, TravellerOptionDTO } from 'app/shared/models/sales/sales-models';


@Component({
    selector: 'app-formly-traveller-autocomplete',
    templateUrl: './formly-traveller-autocomplete.component.html',
    styleUrls: ['./formly-traveller-autocomplete.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class FormlyTravellerAutocompleteComponent extends FieldType implements OnInit {
    @ViewChild('travellerInput') travellerInput: ElementRef<HTMLInputElement> | undefined;
    @Output() add = new EventEmitter();
    @Output() remove = new EventEmitter<Traveller>();

    override formControl: any;
    travellerOptions = getTravellerOptions();
    separatorKeysCodes: number[] = [ENTER, COMMA];
    travellerCtrl = new FormControl();
    filteredTravellers$: Observable<TravellerOptionDTO[]>;
    travellerList!: any[];
    constructor(private activatedRoute: ActivatedRoute, private router: Router, public confirmationService: FuseConfirmationService, private cdRef: ChangeDetectorRef) {
        super();
        this.filteredTravellers$ = this.travellerCtrl.valueChanges.pipe(
            startWith(this.travellerOptions),
            map(searchTerm => this._filter(searchTerm))
        );
    }


    ngOnInit(): void {
        this.field.className = 'travellers-field';
        this.formControl.valueChanges.subscribe((value) => {
            if(value){
                this.travellerList = value;
                this.cdRef.detectChanges();
            }
        });
        this.travellerList = this.formControl.value;
        if (!this.formControl.value) {
            this.formControl.value = [];
        }
    }

    /* add Chip */
    addTraveller(filteredTravellers: TravellerOptionDTO[] | undefined, selectedEvent: MatAutocompleteSelectedEvent | undefined): void {
        if (filteredTravellers?.length) {
            const travellerOption: any = filteredTravellers[0];
            this._pushTravellerOption(travellerOption);
        }

        if (selectedEvent) {
            const travellerOption: any = selectedEvent.option.value;
            this._pushTravellerOption(travellerOption);
            this.field?.templateOptions?.add(travellerOption, this.model);
        }
        this._resetAutocomplete();
    }

    removeTraveller(traveller: Traveller): void {
        const index = this.formControl.value.indexOf(traveller);
        const dialogRef = this.confirmationService.open(confirmationConfig());
        dialogRef.afterClosed().subscribe((result) => {
            if (result === 'confirmed' && index >= 0) {
                const value: TravellerOptionDTO[] = [...this.formControl.value];
                value.splice(index, 1);
                this._setFormValue(value);
                this.field?.templateOptions?.remove(traveller);
            }
        });
    }

    editTraveller(traveller: Traveller): void {
        if (traveller.travellerUId) {
            this.auxillaryLink({ l3: ['traveller', traveller.travellerUId] });
        }
    }

    private _pushTravellerOption(travellerOption: TravellerOptionDTO): void {
        const value: TravellerOptionDTO[] = [...this.formControl.value];
        value.push({ ...travellerOption });

        this._setFormValue(value as TravellerDTO[]);
    }

    private _setFormValue(value: TravellerDTO[]): void {
        this.formControl.patchValue(value);
        this.cdRef.detectChanges();
    }

    private _resetAutocomplete(): void {
        if (this.travellerInput) {
            this.travellerInput.nativeElement.value = '';
            this.travellerCtrl.setValue(null);
        }
    }

    private _filter(searchTerm: string): TravellerOptionDTO[] {
        if (typeof searchTerm === 'string') {

            return this.travellerOptions.filter((traveller) => {
                const label = (traveller.age + ' ' + traveller.passengerType).toString();
                return label.includes(searchTerm.toLowerCase());
            });
        }
        return this.travellerOptions;
    }

    private auxillaryLink = (auxiliaryLink: AuxiliaryLink, auxiliaryQueries?: Params | null | undefined): void => {
        this.router.navigate([{ outlets: auxiliaryLink }], {
            relativeTo: this.activatedRoute.pathFromRoot[2],
            queryParams: auxiliaryQueries,
            queryParamsHandling: 'merge'
        });
    };
}
const getTravellerOptions = (): TravellerDTO[] => [
    { passengerType: 'Adult', type: 'Passenger', removable: true, editable: true, firstName: 'Adult' },
    { passengerType: 'Child', type: 'Passenger', age: 0, removable: true, editable: true, firstName: 'Infant' },
    { passengerType: 'Child', type: 'Passenger', age: 1, removable: true, editable: true, firstName: '1 Year Old' },
    { passengerType: 'Child', type: 'Passenger', age: 2, removable: true, editable: true, firstName: '2 Years Old' },
    { passengerType: 'Child', type: 'Passenger', age: 3, removable: true, editable: true, firstName: '3 Years Old' },
    { passengerType: 'Child', type: 'Passenger', age: 4, removable: true, editable: true  , firstName: '4 Years Old'},
    { passengerType: 'Child', type: 'Passenger', age: 5, removable: true, editable: true  , firstName: '5 Years Old'},
    { passengerType: 'Child', type: 'Passenger', age: 6, removable: true, editable: true  , firstName: '6 Years Old'},
    { passengerType: 'Child', type: 'Passenger', age: 7, removable: true, editable: true  , firstName: '7 Years Old'},
    { passengerType: 'Child', type: 'Passenger', age: 8, removable: true, editable: true  , firstName: '8 Years Old'},
    { passengerType: 'Child', type: 'Passenger', age: 9, removable: true, editable: true  , firstName: '9 Years Old'},
    { passengerType: 'Child', type: 'Passenger', age: 10, removable: true, editable: true  , firstName: '10 Years Old'},
    { passengerType: 'Child', type: 'Passenger', age: 11, removable: true, editable: true  , firstName: '11 Years Old'},
    { passengerType: 'Child', type: 'Passenger', age: 12, removable: true, editable: true  , firstName: '12 Years Old'},
    { passengerType: 'Child', type: 'Passenger', age: 13, removable: true, editable: true  , firstName: '13 Years Old'},
    { passengerType: 'Child', type: 'Passenger', age: 14, removable: true, editable: true  , firstName: '14 Years Old'},
    { passengerType: 'Child', type: 'Passenger', age: 15, removable: true, editable: true  , firstName: '15 Years Old'},
    { passengerType: 'Child', type: 'Passenger', age: 16, removable: true, editable: true  , firstName: '16 Years Old'},
    { passengerType: 'Child', type: 'Passenger', age: 17, removable: true, editable: true  , firstName: '17 Years Old'}
];
