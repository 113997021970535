
<app-page-history-component *ngIf="!historyDisabled"></app-page-history-component>

<div
    [class.minimised]="l4['minimised']"
    [class.active]="l4.isActivated"
    [@pageAnimation]="animationState"
    (@pageAnimation.start)="l4['done'] = false"
    (@pageAnimation.done)="l4['done'] = true"
    [class.done]="l4['done']"
    class="relative z-40 flex flex-col flex-auto w-full h-full overflow-hidden transition-all duration-500 ease-in-out pointer-events-none router-wrapper"
>
    <router-outlet
        class="hidden w-0 h-0"
        #l4="outlet"
        (deactivate)="onRouterChange(l4)"
        (activate)="onRouterChange(l4)"
        name="l4"
    >
    </router-outlet>
</div>

<div
    [class.minimised]="l3['minimised']"
    [class.active]="l3.isActivated"
    [@pageAnimation]="animationState"
    (@pageAnimation.start)="l3['done'] = false"
    (@pageAnimation.done)="l3['done'] = true"
    [class.done]="l3['done']"
    class="relative z-30 flex flex-col flex-auto w-full h-full overflow-hidden transition-all duration-500 ease-in-out pointer-events-none router-wrapper"
>
    <router-outlet
        class="hidden w-0 h-0"
        #outletElement
        #l3="outlet"
        (deactivate)="onRouterChange(l3)"
        (activate)="onRouterChange(l3)"
        name="l3"
    >
    </router-outlet>
</div>

<div
    [class.minimised]="l2['minimised']"
    [class.active]="l2.isActivated"
    [@pageAnimation]="animationState"
    (@pageAnimation.start)="l2['done'] = false"
    (@pageAnimation.done)="l2['done'] = true"
    [class.done]="l2['done']"
    class="relative z-20 flex flex-col flex-auto w-full h-full overflow-hidden transition-all duration-500 ease-in-out pointer-events-none router-wrapper"
>
    <router-outlet
        class="hidden w-0 h-0"
        #outletElement
        #l2="outlet"
        (deactivate)="onRouterChange(l2)"
        (activate)="onRouterChange(l2)"
        name="l2"
    >
    </router-outlet>
</div>

<div
    [class.minimised]="l1['minimised']"
    [class.active]="l1.isActivated"
    [@pageAnimation]="animationState"
    (@pageAnimation.start)="l1['done'] = false"
    (@pageAnimation.done)="l1['done'] = true"
    [class.done]="l1['done']"
    class="relative z-10 flex flex-col flex-auto w-full h-full overflow-hidden transition-all duration-500 ease-in-out pointer-events-none router-wrapper"
>
    <router-outlet
        class="hidden w-0 h-0"
        #outletElement
        #l1="outlet"
        (deactivate)="onRouterChange(l1)"
        (activate)="onRouterChange(l1)"
        name="l1"
    >
    </router-outlet>
</div>

<ng-content></ng-content>
