import { FormlyFieldConfig } from '@ngx-formly/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FormlyOption } from '../modules/formly-form-utilities/types/formly';
import { siglaLog } from './logging-util';


export const mapToFormlyOptionList = (refDataList$: Observable<any>, optionLabelKey?: string, optionValueKey?: string): Observable<FormlyOption[]> => {
    refDataList$.subscribe((list) => siglaLog(list));
    return refDataList$.pipe(
        map((refDataList) =>
            refDataList?.map((refDataItem: any) =>
                ({ label: refDataItem[optionLabelKey || 'name'], value: refDataItem[optionValueKey || 'name'] })
            )
        )
    ) as Observable<FormlyOption[]>;
};

export const mapToFormlyOptionListWithValueObject = (refDataList$: Observable<any>, optionLabelKey?: string): Observable<FormlyOption[]> => {
    refDataList$.subscribe((list) => siglaLog(list));
    return refDataList$.pipe(
        map((refDataList) =>
            refDataList?.map((refDataItem: any) =>
                ({ label: refDataItem[optionLabelKey || 'name'], value: refDataItem })
            )
        )
    ) as Observable<FormlyOption[]>;
};



export const changeFormlyFieldConfig = (fields: FormlyFieldConfig[], configKeyName: string, configPropertyKey: string, configPropertyValue: boolean): void => {
    const fieldConfig = fields.find((field: FormlyFieldConfig) => field.key === configKeyName);
    if (fieldConfig) {
        (fieldConfig as any)[configPropertyKey] = configPropertyValue;
    }
};


