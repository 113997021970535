<!-- Button -->
<button mat-icon-button [matMenuTriggerFor]="userActions" class="bg-green-200">
    <span class="relative">
        <img
            class="w-10 h-10 rounded-full"
            *ngIf="showAvatar && user?.avatar"
            [src]="user?.avatar"
        />
        <ng-container
            *ngIf="!showAvatar || !user?.avatar"
        >

        <div class="text-lg uppercase ">
            {{user?.initials}}
        </div>

    </ng-container>
        <span
            class="absolute bottom-0 right-0 w-2 h-2 rounded-full"
            [ngClass]="{
                'mr-px mb-px': !showAvatar || !user?.avatar,
                'bg-green-500': user?.status === 'online',
                'bg-amber-500': user?.status === 'away',
                'bg-red-500': user?.status === 'busy',
                'bg-gray-400': user?.status === 'not-visible'
            }"
        ></span>
    </span>
</button>

<mat-menu [xPosition]="'before'" #userActions="matMenu">
     <div class="px-4">
        <span class="flex flex-col leading-none">
            <span class="text-sm">Signed in as</span>
            <span class="mt-1.5 text-md font-medium">{{user?.name}}</span>
        </span>
    </div>
 <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item>
        <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span>Profile</span>
    </button>
    <button mat-menu-item>
        <mat-icon [svgIcon]="'heroicons_outline:cog'"></mat-icon>
        <span>Settings</span>
    </button>
    <button mat-menu-item (click)="signOut()">
        <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
        <span>Sign out</span>
    </button>
</mat-menu>

<!--<mat-menu class="user-status-menu" #userStatus="matMenu">
    <button mat-menu-item (click)="updateUserStatus('online')">
        <span class="w-4 h-4 mr-3 bg-green-500 rounded-full"></span>
        <span>Online</span>
    </button>
    <button mat-menu-item (click)="updateUserStatus('away')">
        <span class="w-4 h-4 mr-3 rounded-full bg-amber-500"></span>
        <span>Away</span>
    </button>
    <button mat-menu-item (click)="updateUserStatus('busy')">
        <span class="w-4 h-4 mr-3 bg-red-500 rounded-full"></span>
        <span>Busy</span>
    </button>
    <button mat-menu-item (click)="updateUserStatus('not-visible')">
        <span class="w-4 h-4 mr-3 bg-gray-400 rounded-full"></span>
        <span>Invisible</span>
    </button>
</mat-menu>-->
