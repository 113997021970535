import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';
import { TravellerCompact } from 'app/shared/models/sales/sales-models';

@Component({
    selector: 'app-formly-lookup-traveller-assignment-autocomplete',
    templateUrl: './formly-lookup-traveller-assignment-autocomplete.component.html',
    styleUrls: ['../../styles/ng-select.scss', './formly-lookup-traveller-assignment-autocomplete.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class FormlyLookupTravellerAssignmentAutocompleteComponent extends FieldType implements OnInit {
    override formControl: any;

    constructor() {
        super();
    }
    ngOnInit(): void {
        if (this.formControl.value) {
            this.formControl.value.forEach((traveller: TravellerCompact) => {
                this.add(traveller.travellerUId);
            });

        }
    }

    remove(travellerUId: string): void {
        const index = this.formControl.value.findIndex((item: TravellerCompact) => item?.travellerUId === travellerUId);
        const removedItem = this.formControl.value[index];
        if (this.to['travellerList']) {
            this.to['travellerList'].push(removedItem);
            this.to['travellerList'] = [...this.to['travellerList']];
        } else if (this.to['parentComponent']?.travellerList) {
            this.to['parentComponent'].travellerList.push(removedItem);
            this.to['parentComponent'].travellerList = [...this.to['parentComponent']?.travellerList];
        }

        if (index > -1) {
            this.formControl.value.splice(index, 1);
            this.formControl.patchValue(this.formControl.value);
        }
    }

    add(travellerUId: string): void {
        if (this.to['travellerList']) {
            const index = (this.to['travellerList'] as TravellerCompact[]).findIndex((o) => o.travellerUId === travellerUId);
            (this.to['travellerList'] as TravellerCompact[]).splice(index, 1);
            this.to['travellerList'] = [...this.to['travellerList']];
        } else if (this.to['parentComponent']?.travellerList) {
            const index = (this.to['parentComponent'].travellerList as TravellerCompact[]).findIndex((o) => o.travellerUId === travellerUId);
            this.to['parentComponent'].travellerList.splice(index, 1);
            this.to['parentComponent'].travellerList = [...this.to['parentComponent']?.travellerList];
        }
    }
}
