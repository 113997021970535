import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Validators } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';
import _ from 'lodash';

@Component({
  selector: 'app-formly-weekday-picker',
  templateUrl: './formly-weekday-picker.component.html',
  styleUrls: ['./formly-weekday-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class FormlyWeekdayPickerComponent extends FieldType implements OnInit {

  dayList = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  ngOnInit(): void {
    this.validateField();
  }

  get controlValue(): string[] {
    return this.formControl.value || new Array();
  }

  change(checked: boolean, day: string): void {
    const weekdayList = this.formControl.value || new Array();
    if (checked) {
      weekdayList.push(day);
    } else {
      _.remove(weekdayList, (weekday: string) => weekday === day);
    }
    this.model[this.key as string] = weekdayList;
    this.formControl.setValue(weekdayList);
    this.validateField();
    this.formControl.markAllAsTouched();
  }

  validateField(): void {
    if (this.controlValue.length === 0) {
      this.formControl.setValidators([Validators.required]);
    } else {
      this.formControl.setValidators(null);
    }
    this.formControl.updateValueAndValidity();
  }

}
