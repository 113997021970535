export const environment = {
    production: false,
    disableMenu: false,
    authentication: {
        cacheLocation: 'localStorage',
        clientId: '5d1adb2f-d7c1-4352-8cd9-da66554fa2e9',
        redirectUri: 'https://dev.siglatravelhub.com',
        postLogoutRedirectUri: 'https://dev.siglatravelhub.com',
        authority: 'https://sthad.b2clogin.com/sthad.onmicrosoft.com/B2C_1A_signup_signin',
        knownAuthorities: ['sthad.b2clogin.com'],
        scopes: ['openid']
    },
    api: {
         siglaApi: 'https://dev-api.siglatravelhub.com',
    },
    mapbox: {
        accessToken:
            'pk.eyJ1IjoiNTBkZWdyZWVzbm9ydGgiLCJhIjoiY2swaHFkOHc3MDB1MjNmdGF2cW1qbDl0MyJ9.LnJk6ykO8eGOgirosa23Ag',
    },
    logging: false
};
