import { ICellEditorAngularComp } from '@ag-grid-community/angular';
import {
    Component,
    AfterViewInit,
    ViewEncapsulation,
    ElementRef,
    ViewChild,
} from '@angular/core';

@Component({
    selector: 'app-ag-grid-input',
    templateUrl: './ag-grid-input.component.html',
    styleUrls: ['./ag-grid-input.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AgGridInputComponent
    implements ICellEditorAngularComp, AfterViewInit
{
    @ViewChild('gridInput') gridInput!: ElementRef;
    params: any;
    disabled: boolean = false;
    readonly: boolean = false;
    type: string = 'text';

    private regex: RegExp = new RegExp(/^\d*\.?\d{0,2}$/g);
    private specialKeys: Array<string> = [
        'Backspace',
        'Tab',
        'End',
        'Home',
        '-',
        'ArrowLeft',
        'ArrowRight',
        'Del',
        'Delete',
    ];

    agInit(params: any): void {
        this.params = params;
        this.type = params.type ? params.type : 'string';
    }

    ngAfterViewInit(): void {
        // this.gridInput?.nativeElement?.focus();
    }
    change(): void {
        this.params.node.setDataValue(
            this.params.colDef.field,
            this.params.value
        );
    }
    getValue(): string {
        return this.params.value;
    }

    omitExtraDecimalPlaces(event: KeyboardEvent): void {
        if (this.type === 'number') {
            // Allow Backspace, tab, end, and home keys
            if (this.specialKeys.indexOf(event.key) !== -1) {
                return;
            }
            const current: string = this.gridInput?.nativeElement.value;
            const position = current.length;
            const next: string = [
                current.slice(0, position),
                event.key === 'Decimal' ? '.' : event.key,
                current.slice(position),
            ].join('');
            if (next && !String(next).match(this.regex)) {
                event.preventDefault();
            }
        }
    }

    focusIn(): void {
        this.gridInput?.nativeElement?.focus();
    }
}
