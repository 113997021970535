import { Component, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { PageHistoryService } from 'app/shared/services/page-history/page-history.service';
import { PageHistory } from '../../interfaces/page-history';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatMenuTrigger } from '@angular/material/menu';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-page-history-component',
    templateUrl: './page-history-component.component.html',
    styleUrls: ['./page-history-component.component.scss']
})
export class PageHistoryComponentComponent implements OnDestroy {
    filterControl: FormControl;
    subscription: Subscription;
    public filteredPageHistoryItems = this.pageHistory.getHistory();

    constructor(private pageHistory: PageHistoryService, public router: Router, private clipboard: Clipboard) {
        this.filterControl = new FormControl();
        this.subscription = this.filterControl.valueChanges.subscribe((term) => {
            this.filterHistoryItems(term);
        });
    }
    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    routerLink(url: string): void {
        this.router.navigateByUrl(url.replace(/^\/+/, ''));
    }

    menuOpened(): void {
        this.filteredPageHistoryItems = this.pageHistory.getHistory();
    }

    filterHistoryItems(searchTerm: string): void {
        if (searchTerm) {
            const term = searchTerm.toLowerCase();
            this.filteredPageHistoryItems = this.pageHistory.getHistory();
            this.filteredPageHistoryItems = this.filteredPageHistoryItems?.filter((item: PageHistory) =>
                item.heading?.toLowerCase()?.includes(term) ||
                item.headingPrefix?.toLowerCase()?.includes(term) ||
                item.description?.toLowerCase()?.includes(term) ||
                item.url?.toLowerCase()?.includes(term) ||
                item.path?.toLowerCase()?.includes(term) ||
                new Date (Number(item.time))?.toString()?.toLowerCase()?.includes(term)) || [];
        } else {
            this.filteredPageHistoryItems = this.pageHistory.getHistory();
        }
    }

    historyShortcut(trigger: MatMenuTrigger, input: HTMLElement): void {
        trigger.openMenu();
        input.focus();
    };


    timeStringToDate(time: string | undefined): Date {
        return new Date(Number(time));
    }

    copyUrl(url: string): void {
        const copiedUrl = location.origin + url;
        this.clipboard.copy(copiedUrl);
    }

    clearAllHistory(event: any): void {
        event.stopPropagation();
        this.pageHistory.clearHistory();
        this.filteredPageHistoryItems = this.pageHistory.getHistory();
    }

}
