import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserService } from 'app/core/user/user.service';
import { MsalService } from '@azure/msal-angular';
import { Observable, of } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable()
export class AuthService
{

    /**
     * Constructor
     */
    constructor(
        private msalService: MsalService,
        private _httpClient: HttpClient,
        private _userService: UserService
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    /*set accessToken(token: string)
    {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string
    {
        return localStorage.getItem('accessToken') ?? '';
    }*/

    /**
     * Getter for authenticated status
     */
    get authenticated(): boolean
    {
        return this.msalService.instance.getAllAccounts().length > 0;
    }

    isLoggedIn(): boolean {
        if(this.msalService.instance.getAllAccounts().length > 0) {
            return true;
        } else {
        //    this.msalService.loginRedirect();
            return false;
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(): void
    {
        // If user isn't logged in, redirect to login page
        /*if (!this.authenticated)
        {
            this.msalService.loginRedirect();
        }*/
    }

    /**
     * Sign out
     */
    signOut(): void
    {
        this.msalService.logoutRedirect({
            postLogoutRedirectUri: environment.authentication.postLogoutRedirectUri
        });
    }

    check(): Observable<boolean> {
        return of(this.authenticated);
    }

}
