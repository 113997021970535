import { Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-form-actions',
  templateUrl: './form-actions.component.html',
  styleUrls: ['./form-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation:ViewEncapsulation.None,
})
export class FormActionsComponent implements OnInit {
@Input() inlineEditForm = false;
@Input() submitLabel = 'Submit';
@Input() clearButton = true;
@Input() submitButton = true;
@Input() cancelButton = false;
@Input() deleteButton = false;
@Input() disableSubmit = false;
@Output() cancelAction = new EventEmitter();
@Output() deleteAction = new EventEmitter();
@Output() submitAction = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  cancel(): void {
    this.cancelAction.next();
  }

  delete(): void {
    this.deleteAction.next();
  }

  submit(): void {
    this.submitAction.next();
  }

}
