export const refDataEndpoints = {
    bundle: {
        commonBundle: 'refdata/bundle/common',
        businessBundle: 'refdata/bundle/business',
        attributeBundle: 'refdata/bundle/attribute',
        productBundle: 'refdata/bundle/product',
        rateBundle: 'refdata/bundle/rate',
        productItemSearchBundle: 'refdata/bundle/productitem/search',
        relationshipList: 'refdata/relationship/list',
        agreementList: 'refdata/agreement/list',
        travellerList: 'refdata/traveller/list'
    },
    consultant: {
        list: 'refdata/consultant/list'
    },
    direct: {
        userList: 'refdata/user/list'
    },
    refData: {
        experienceCategoryList: 'refdata/experiencecategory/list',
    },
    regular: {
        account: {
            payeeList: 'refdata/list/account/payee',
            payerList: 'refdata/list/account/payer'
        },
        product: {
            productSeriesListByProductType: 'refdata/productseries/listbyproducttype'
        }
    },
    commercial: {
        business: {
            officeList: 'refdata/office/list'
        }
    },
    typeahead: {
        business: 'refdata/business/typeahead',
        extra: 'refdata/extra/typeahead',
        optionGroup: 'refdata/optiongroup/typeahead',
        person: 'refdata/person/typeahead'
    },
    user: {
        emailAvailable: 'refdata/user/emailavailable'
    },
    productType: {
        nameCheck: 'refdata/producttype/checkname/{name}'
    },
    userOffice: {
        list: 'refdata/useroffice/list'
    }

};
