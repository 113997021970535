import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({
    name: 'currency',
})
export class AppCurrencyPipe extends CurrencyPipe implements PipeTransform {
     override transform(value: any,code?: string): any {
        return super.transform(value, code, 'symbol-narrow', '1.2-2');
     }
}
