import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FormStateObserverService {
    observedFormStateList: FormStateRecord[] = [];
    observedFormStateList$ = new BehaviorSubject<FormStateRecord[]>([]);
    constructor() { }
}

export interface FormStateRecord {
    instanceUId: string;
    formUId: string;
    formName: string;
    formLabel: string;
    dirty: boolean;
    pristine: boolean;
    touched: boolean;
    untouched: boolean;
    modelUId: string;
    modelObject: string;
    parentComponentName: string;
    timestamp: number;
}
