import { Component, Inject, OnDestroy } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ProductLookupRoomCriteria } from 'app/shared/models/lookup/lookup-models';
import { Subject } from 'rxjs';
import { dialogConfig } from './config/dialog.config';
import { fields } from './config/formly-form.config';

@Component({
    selector: 'app-formly-lookup-rooms-dialog',
    templateUrl: './formly-lookup-rooms-dialog.component.html',
    styleUrls: ['./formly-lookup-rooms-dialog.component.scss'],
})
export class FormlyLookupRoomsDialogComponent implements OnDestroy {
    public form: FormGroup = new FormGroup({});
    fields = fields(this);
    model: { roomList: ProductLookupRoomCriteria[] } = { roomList: [] };
    travellerList = [];
    dialogConfig = dialogConfig;
    formArrayTemplateOptions = {
        label: 'Travellers',
        required: true,
        travellerList: [],
    };

    private readonly _destroying$ = new Subject<void>();
    constructor(private dialogRef: MatDialogRef<FormlyLookupRoomsDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
        this.model.roomList = this.data.roomList;
        this.formArrayTemplateOptions.travellerList = this.data.travellerList;
    }

    fieldArrayInit = (fieldArray: FormlyFieldConfig | undefined): void => {
        const key: any = fieldArray?.key;
        const index: number = parseInt(key, 10);
        if (fieldArray?.fieldGroup?.[0]?.templateOptions) {
            fieldArray.fieldGroup[0].templateOptions = this.formArrayTemplateOptions;
        }
        const numberFormControl: AbstractControl | undefined = fieldArray?.fieldGroup?.[1]?.formControl;
        if (numberFormControl) {
            numberFormControl.patchValue(index + 1);
        }
    };


    fieldArrayDelete = (fieldArray: FormlyFieldConfig | undefined): void => {
        const value = fieldArray?.fieldGroup?.[0]?.formControl?.value;
        if (value) {
            this.formArrayTemplateOptions.travellerList = this.formArrayTemplateOptions.travellerList.concat(value);
        }
    };


    ngSubmit(): void {
        if (this.form.valid) {
            this.dialogRef.close({roomList:this.form.value.roomList, travellerList:this.formArrayTemplateOptions.travellerList});
        }
    }

    ngOnDestroy(): void {
        this._destroying$.next(undefined);
        this._destroying$.complete();
    }

}
