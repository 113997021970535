<div
    class="self-center w-6 h-6 mt-10 overflow-hidden rounded-full"
    [style.background-color]="formControl.value"
>
    <input
        class="w-8 h-8 origin-center transform scale-150 rounded"
        (click)="$event.stopPropagation()"
        (keydown)="$event.stopPropagation()"
        (keyup)="$event.stopPropagation()"
        type="color"
        [formControl]="formControl"
        [formlyAttributes]="field"
    />
</div>
