import { Component, ViewEncapsulation, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { InteractionStatus, Logger, RedirectRequest } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { RefDataService } from './shared/services/ref-data/ref-data.service';
import { Location } from '@angular/common';
import { SettingsService } from './shared/services/platform/settings/settings.service';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, OnDestroy {
    public enable = false;
    public isIframe = false;
    public loggedIn = false;
    private readonly _destroying$ = new Subject<void>();

    constructor(
        private broadcastService: MsalBroadcastService,
        private authService: MsalService,
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        private settingsService: SettingsService,
        private refDataService: RefDataService,
        private router: Router,
        private location: Location,) {
    }

    ngOnInit(): void {

        this.isIframe = window !== window.parent && !window.opener;

        this.broadcastService.inProgress$
            .pipe(
                filter((status: InteractionStatus) => status === InteractionStatus.None),
                takeUntil(this._destroying$)
            )
            .subscribe(() => {
                this.setLoggedIn();
                this.checkAndSetActiveAccount();

                if (this.loggedIn) {

                    // Load platform settings
                    this.settingsService.loadSettings();

                    // Load common reference data
                    this.refDataService.loadData();

                    if (!this.location.path().includes('front')) {
                        this.enable = true;
                    }

                }
            });

        this.isFront();
    }

    ngOnDestroy(): void {
        this._destroying$.next(undefined);
        this._destroying$.complete();
    }

    setLoggedIn(): void {
        this.loggedIn = this.authService.instance.getAllAccounts().length > 0;
    }

    checkAndSetActiveAccount(): void {
        /**
         * If no active account set but there are accounts signed in, sets first account to active account
         * To use active account set here, subscribe to inProgress$ first in your component
         * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
         */
        const activeAccount = this.authService.instance.getActiveAccount();

        if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
            const accounts = this.authService.instance.getAllAccounts();
            this.authService.instance.setActiveAccount(accounts[0]);
        }

    }

    isFront(): void {
        if (this.isIframe && this.location.path().includes('front')) {
            this.enable = true;
            this.router.navigate(['../front']);
        }
    }

}
