import { Component, ElementRef, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { AgGridAngular, ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { DetailTemplateData } from '../../interfaces/detail-template-data';

@Component({
    selector: 'app-ag-grid-template-renderer',
    templateUrl: './ag-grid-template-renderer.component.html',
    styleUrls: ['./ag-grid-template-renderer.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AgGridTemplateRendererComponent implements ICellRendererAngularComp {
    @ViewChild('masterDetailInner') masterDetailInner: ElementRef | undefined;
    agGrid: AgGridAngular | undefined;
    template: TemplateRef<any> | undefined;
    nested: undefined | boolean;
    templateContext!: { $implicit: DetailTemplateData; params: DetailTemplateData } | null;

    refresh(params: ICellRendererParams): boolean {
        this.templateContext = {
            $implicit: { newItem: false, params: params},
            params: { newItem: false, params: params },
        };
        return true;
    }

    agInit(params: any): void {
        this.template = params.template;
        this.agGrid = params.agGrid;
        this.nested = params.nested;
        this.refresh(params);
    }

}
