import { ICellEditorParams } from '@ag-grid-community/all-modules';
import { ICellEditorAngularComp } from '@ag-grid-community/angular';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, EventEmitter, Inject, OnDestroy, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { ApiPathConfig } from 'app/shared/config/api-path.config';
import { PathUtil } from 'app/shared/utility/path-util';
import { Subject, Observable, of } from 'rxjs';
import { takeUntil, startWith, debounceTime, distinctUntilChanged, switchMap, catchError, filter, map, tap } from 'rxjs/operators';
/**
 * Sample Col def
 *  {
            field: 'businessName',
            cellRenderer: 'typeaheadRenderer',
            suppressMenu: true,
            cellEditorParams: {
                url: refDataEndpoints.bundle.businessTypeahead,
                uId: 'businessUId'
            },
        },
 */

@Component({
  selector: 'app-ag-grid-type-ahead-cell-renderer',
  templateUrl: './ag-grid-type-ahead-cell-renderer.component.html',
  styleUrls: ['../../../formly-form-utilities/styles/ng-select.scss', './ag-grid-type-ahead-cell-renderer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AgGridTypeAheadCellRendererComponent implements OnInit, ICellEditorAngularComp, AfterViewInit, OnDestroy {
  @ViewChild('select', { static: true }) select!: NgSelectComponent;
  params!: ICellEditorParams;
  onDestroy$ = new Subject<void>();
  search$ = new EventEmitter();
  options$!: Observable<any>;
  loading: boolean = false;
  searchTerm!: string;
  inputElement!: any;
  constructor(private httpClient: HttpClient, @Inject('SiglaApiPath') private siglaApiPath: ApiPathConfig,
    private renderer2: Renderer2) {

  }

  agInit(params: ICellEditorParams): void {
    this.params = params;
  }

  ngAfterViewInit(): void {

    this.inputElement = this.select.element.querySelector('input');

    this.renderer2.listen(this.inputElement, 'input', (event) => {
      this.searchTerm = event.target.value;
    });
  }

  ngOnInit(): void {
    this.options$ = this.search$.pipe(
      takeUntil(this.onDestroy$),
      startWith(''),
      filter(v => v !== null),
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((term: string): Observable<any> => {
        if ((!term || term === '')) {
          return of([]);
        }

        let url = PathUtil.combinePaths(
          this.siglaApiPath.apiPath,
          this.params.colDef.cellEditorParams['url']
        );
        if (term) {
          url = PathUtil.addParam(url, 'name=' + term);
        }
        this.loading = true;
        return this.httpClient
          .get<any[]>(url).pipe(
            map((list: any[]) => {
              if (list.length) {
                return list.map((r: any) =>
                  ({ label: r.name, value: r.uId })
                );
              } else {
                return [{ label: term, value: '' }];
              }
            }

            ),
            catchError(() => of([{ label: term, value: '' }])), // empty list on error
            tap(() => this.loading = false)
          );
      }),
    );
    this.options$.subscribe();
  }

  changeSelection(e: any): void {
    if (e) {
      this.searchTerm = e.label;
      this.params.data[this.params.colDef.cellEditorParams['uId']] = e.value;
      this.params.node.setDataValue(this.params?.colDef as string, e.label);
    } else {
      this.params.node.setDataValue(this.params?.colDef as string, e.label);
    }
  }


  clear(): void {
    this.searchTerm = '';
  }

  close(): void {
    if (this.searchTerm !== this.params.value) {
      this.params.node.setDataValue(this.params?.colDef as string, this.searchTerm);
      this.inputElement.value = this.searchTerm;
    }
  }


  getValue(): string {
    return this.params.value;
  }


  ngOnDestroy(): void {
    this.onDestroy$.complete();
  }

}
