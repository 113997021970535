<textarea
    type="text"
    class="pl-1 currency-input"
    #currencyInput
    (keypress)="omitExtraDecimalPlaces($event)"
    [formControl]="formControl"
    [formlyAttributes]="field"
    rows="1"
></textarea>
<div class="flex items-center w-6 h-full min-h-4 min-w-6">
    <button
        mat-icon-button
        class="self-center w-6 h-6"
        [matMenuTriggerFor]="menu"
    >
        <mat-icon [svgIcon]="'mat_solid:arrow_drop_down'"></mat-icon>
    </button>
    <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="togglePriceState(true)">
            <span>Price in {{ to["currency"] }}</span>
        </button>
        <button mat-menu-item (click)="togglePriceState(false)">
            <span>Manual</span>
        </button>
    </mat-menu>
</div>
